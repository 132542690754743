import * as React from "react";

export const UpArrow = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46967 14.5303C5.17678 14.2374 5.17678 13.7626 5.46967 13.4697L11.4697 7.46967C11.7626 7.17678 12.2374 7.17678 12.5303 7.46967L18.5303 13.4697C18.8232 13.7626 18.8232 14.2374 18.5303 14.5303C18.2374 14.8232 17.7626 14.8232 17.4697 14.5303L12 9.06066L6.53033 14.5303C6.23744 14.8232 5.76256 14.8232 5.46967 14.5303Z"
      fill="#030D45"
    />
  </svg>
);

export const DownArrow = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z"
      fill="#030D45"
    />
  </svg>
);

export const CheckedIcon = ({
  width = "24px",
  height = "24px",
  color = "currentColor",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={"none"}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-check-square"
  >
    <polyline points="9 11 12 14 22 4" />
    <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
  </svg>
);

export const FilterIcon = (props) => (
  <svg
    id="filter_1_1"
    data-name="filter (1) 1"
    xmlns="http://www.w3.org/2000/svg"
    width={20.288}
    height={20}
    viewBox="0 0 20.288 20"
    {...props}
  >
    <g id="Layer_62" data-name="Layer 62">
      <path
        id="Vector"
        d="M19.273,4H8.115a1.986,1.986,0,0,1-.594,1.414A2.044,2.044,0,0,1,6.086,6H4.058a2.044,2.044,0,0,1-1.435-.586A1.986,1.986,0,0,1,2.029,4H1.014A1.022,1.022,0,0,1,.3,3.707a.99.99,0,0,1,0-1.414A1.022,1.022,0,0,1,1.014,2H2.029A1.986,1.986,0,0,1,2.623.586,2.044,2.044,0,0,1,4.058,0H6.086A2.044,2.044,0,0,1,7.521.586,1.986,1.986,0,0,1,8.115,2H19.273a1.022,1.022,0,0,1,.717.293.99.99,0,0,1,0,1.414A1.022,1.022,0,0,1,19.273,4ZM6.086,2H4.058V4H6.086ZM1.014,9h9.129a1.986,1.986,0,0,1,.594-1.414A2.044,2.044,0,0,1,12.173,7H14.2a2.044,2.044,0,0,1,1.435.586A1.986,1.986,0,0,1,16.23,9h3.043a1.022,1.022,0,0,1,.717.293.99.99,0,0,1,0,1.414,1.022,1.022,0,0,1-.717.293H16.23a1.986,1.986,0,0,1-.594,1.414A2.044,2.044,0,0,1,14.2,13H12.173a2.044,2.044,0,0,1-1.435-.586A1.986,1.986,0,0,1,10.144,11H1.014A1.022,1.022,0,0,1,.3,10.707a.99.99,0,0,1,0-1.414A1.022,1.022,0,0,1,1.014,9ZM14.2,11V9H12.173v2ZM1.014,16H4.058a1.986,1.986,0,0,1,.594-1.414A2.044,2.044,0,0,1,6.086,14H8.115a2.044,2.044,0,0,1,1.435.586A1.986,1.986,0,0,1,10.144,16h9.129a1.022,1.022,0,0,1,.717.293.99.99,0,0,1,0,1.414,1.022,1.022,0,0,1-.717.293H10.144a1.986,1.986,0,0,1-.594,1.414A2.044,2.044,0,0,1,8.115,20H6.086a2.044,2.044,0,0,1-1.435-.586A1.986,1.986,0,0,1,4.058,18H1.014A1.022,1.022,0,0,1,.3,17.707a.99.99,0,0,1,0-1.414A1.022,1.022,0,0,1,1.014,16Zm7.1,2V16H6.086v2Z"
        fill="#7aba4c"
      />
    </g>
  </svg>
);

export const CancelIcon = (props) => (
  <svg
    id="Icon_ionic-ios-close-circle-outline"
    data-name="Icon ionic-ios-close-circle-outline"
    xmlns="http://www.w3.org/2000/svg"
    width={10.748}
    height={10.748}
    viewBox="0 0 17.748 17.748"
    {...props}
  >
    <path
      id="Path_38813"
      data-name="Path 38813"
      d="M18.995,18.028,16.746,15.78l2.248-2.248a.682.682,0,1,0-.964-.964l-2.248,2.248-2.248-2.248a.682.682,0,0,0-.964.964l2.248,2.248-2.248,2.248a.659.659,0,0,0,0,.964.677.677,0,0,0,.964,0l2.248-2.248,2.248,2.248a.685.685,0,0,0,.964,0A.677.677,0,0,0,18.995,18.028Z"
      transform="translate(-6.908 -6.906)"
      fill="#ed4c4c"
    />
    <path
      id="Path_38814"
      data-name="Path 38814"
      d="M12.249,4.57A7.676,7.676,0,1,1,6.818,6.818,7.629,7.629,0,0,1,12.249,4.57m0-1.195a8.874,8.874,0,1,0,8.874,8.874,8.873,8.873,0,0,0-8.874-8.874Z"
      transform="translate(-3.375 -3.375)"
      fill="#ed4c4c"
    />
  </svg>
);
