import React from "react";

function TimeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.939"
      height="27.939"
      viewBox="0 0 27.939 27.939"
    >
      <g data-name="Group 39153" transform="translate(-3.868 -3.868)">
        <g transform="translate(3.868 3.868)">
          <g>
            <g fill="#7aba4c">
              <path d="M24.958 5.344h.863a.545.545 0 000-1.091h-2.072a.545.545 0 00-.549.547v2.071a.545.545 0 101.091 0v-.6a12.879 12.879 0 11-10.321-5.18 13.073 13.073 0 011.571.095A.545.545 0 0015.672.1a14.168 14.168 0 00-1.7-.1 13.97 13.97 0 00-9.88 23.848 13.97 13.97 0 0020.867-18.5z"></path>
              <path
                d="M67.539 69.86a.546.546 0 00-.753.169 10.413 10.413 0 103.259-3.253.545.545 0 10.582.923A9.326 9.326 0 1169 82.189a9.385 9.385 0 01-1.291-11.576.545.545 0 00-.17-.753z"
                transform="translate(-61.624 -61.625)"
              ></path>
              <path
                d="M376.714 247.421h.672a.545.545 0 100-1.091h-.672a.545.545 0 100 1.091z"
                transform="translate(-355.655 -232.897)"
              ></path>
              <path
                d="M103.835 246.551a.545.545 0 00.545.545h.672a.545.545 0 100-1.091h-.672a.545.545 0 00-.545.546z"
                transform="translate(-98.173 -232.59)"
              ></path>
              <path
                d="M247.421 105.053v-.672a.545.545 0 10-1.091 0v.672a.545.545 0 101.091 0z"
                transform="translate(-232.897 -98.173)"
              ></path>
              <path
                d="M246.006 376.714v.672a.545.545 0 101.091 0v-.672a.545.545 0 10-1.091 0z"
                transform="translate(-232.59 -355.655)"
              ></path>
              <path
                d="M189.9 156.522a.545.545 0 00-.771.771l1.848 1.848a1.879 1.879 0 103.313 0l3.729-3.729a.545.545 0 00-.771-.771l-3.729 3.729a1.875 1.875 0 00-1.77 0zm3.521 3.5a.788.788 0 11-.788-.788.789.789 0 01.792.792z"
                transform="translate(-178.667 -146.057)"
              ></path>
              <path
                d="M119.109 119.109a.545.545 0 10-.386.16.548.548 0 00.386-.16z"
                transform="translate(-111.733 -111.733)"
              ></path>
              <path
                d="M315.983 10.969a.546.546 0 10-.385-.16.548.548 0 00.385.16z"
                transform="translate(-298.236 -9.339)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TimeIcon;
