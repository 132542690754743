import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getBreeds } from "../../../api/store/actions/saga/settings/breeds";
import { getDajinTypes } from "../../../api/store/actions/saga/settings/dajinTypes";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";
import ListHeader from "../../../screenParts/listingViews/listHeader";
import TitleAndBack from "../../../screenParts/listingViews/titleAndBack";

const BreedsIndex = () => {
  const [breedData, setBreedData] = useState(null);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { dajinId } = useParams();
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getBreeds, setBreedData, { dajinId: dajinId });
  }, [reload]);
  return breedData ? (
    <>
      <ListHeader
        hasAdd
        hasFilter={false}
        hasSearch={false}
        hasTitle
        back
        addLink={"createBreed"}
        linkType={"modal"}
        title={query.get("type")}
      />
      <ListView
        objName={"breeds"}
        stateLocation
        links={{
          edit: "updateBreed",
          delete: "delete/deleteBreed/breed",
          show: ``,
          query: {
            show: "",
            delete: "",
            edit: "",
          },
        }}
        data={breedData}
      ></ListView>
    </>
  ) : (
    <Loading />
  );
};

export default BreedsIndex;
