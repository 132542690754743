import React, { useEffect } from "react";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import Button from "../../../reusableComponents/button";
import CustomImage from "../../../reusableComponents/customImage";
import BinIcon from "../../../reusableComponents/iconsSvg/binIcon";
import CartIcon from "../../../reusableComponents/iconsSvg/cartIcon";
import ChickenIcon from "../../../reusableComponents/iconsSvg/chickenIcon";
import CostIcon from "../../../reusableComponents/iconsSvg/costIcon";
import LargeChickenIcon from "../../../reusableComponents/iconsSvg/largeChickenIcon";
import LocationIcon from "../../../reusableComponents/iconsSvg/locationIcon";
import { EditIcon } from "../../../reusableComponents/iconsSvg/tableI/tableIcons";
export const ChickenCard = ({ dajinType, price, date, icon }) => {
  //useResponsiveWidth();

  return (
    <div
      style={{
        marginTop: 10,
        ...useResponsiveWidth({
          desktop: 95,
          tablet: 95,
          mobile: 95,
          defaults: 95,
        }),
        marginRight: 20,
        marginBottom: 20,
        boxShadow: "0px 0px 20px #c1cfd924",
        border: "1px solid #EDEDED",
        borderRadius: 10,
        backgroundColor: "white",
        position: "relative",
      }}
    >
      {icon ? (
        <div
          style={{
            height: 100,
            width: 100,
            borderRadius: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            boxShadow: "0px 2px 20px #c1cfd924",
            position: "absolute",
            right: -40,
          }}
        >
          <CustomImage borderRadius={200} src={icon} />
        </div>
      ) : (
        <></>
      )}
      <div style={{ height: 50, backgroundColor: "white" }}>
        <p
          style={{
            fontSize: 24,
            textAlign: "center",
            paddingTop: 5,
            marginRight: 50,
          }}
        >
          {dajinType}
        </p>
      </div>
      <div
        style={{
          height: 50,
          backgroundColor: "#F5F5F5",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontSize: 13,
            paddingTop: 5,
            marginLeft: 10,
            marginRight: 50,
          }}
        >
          سعر بورصة داﭼن
        </p>
        <p
          style={{
            marginLeft: 10,
            fontSize: 13,

            marginRight: 10,
          }}
        >
          {price}
        </p>
        <CostIcon />
        <p
          style={{
            fontSize: 13,
            paddingTop: 5,
            marginLeft: 10,
            marginRight: 10,
            fontWeight: "bold",
          }}
        >
          {date}
        </p>
      </div>
    </div>
  );
};
