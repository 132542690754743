import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { arToEn } from "../../../alogrithms";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { deleteEmptyProps, objToFormData } from "../../../alogrithms/requests";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  createDajinType,
  getDajinTypeDetails,
  updateDajinType,
} from "../../../api/store/actions/saga/settings/dajinTypes";

import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";
import PhotoSelect from "../../../screenParts/photoSelect";

const DajinTypesForm = ({ isEdit }) => {
  const { dajinId } = useParams();
  const [dajinDetails, setDajinType] = useState(null);
  useEffect(() => {
    if (isEdit)
      resolveRequest(getDajinTypeDetails, setDajinType, {
        id: dajinId,
      });
  }, []);
  const submit = async (data) => {
    data.trader_commission = arToEn(data?.trader_commission);
    data.farmer_commission = arToEn(data?.farmer_commission);

    data.image =
      dajinDetails?.data?.image_url == values.image ? null : values.image;
    if (isEdit) {
      const res = await updateDajinType({
        id: dajinId,
        body: objToFormData(deleteEmptyProps(data)),
      });
      setBackEndErrors(res?.errors, setFieldError);
    } else {
      const res = await createDajinType({
        body: objToFormData(deleteEmptyProps(data)),
      });
      setBackEndErrors(res?.errors, setFieldError);
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? dajinDetails?.data : {},
      validationSchema,
      "dajinType",
      submit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    setFieldError,
    handleBlur,
  } = formik;
  console.log(errors, values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <div>
      <AppModal
        buttonTitleReject={false}
        onClickReject={false}
        buttonTitle={isEdit ? "تعديل" : "إضافة"}
        modalTitle={isEdit ? "تعديل داجن" : "إضافة  داجن"}
        onClick={handleSubmit}
      >
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={"الاسم"}
          value={values.type}
          defaultValue={values.type}
          name={"type"}
          errorMessage={isValid("type")}
          onChange={handleChange("type")}
        />
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={"العمولة على التاجر "}
          value={values.trader_commission}
          defaultValue={values.trader_commission}
          name="trader_commission"
          errorMessage={isValid("trader_commission")}
          onChange={handleChange("trader_commission")}
        />
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={" العمولة على المربي"}
          defaultValue={values.farmer_commission}
          value={values.farmer_commission}
          name="farmer_commission"
          errorMessage={isValid("farmer_commission")}
          onChange={handleChange("farmer_commission")}
        />
        <PhotoSelect
          require
          src={values.image}
          title={"الصورة"}
          errorMessage={isValid("image")}
          onChange={(e) => {
            setFieldValue("image", e.target.files[0]);
          }}
        />
      </AppModal>
    </div>
  );
};

export default DajinTypesForm;
