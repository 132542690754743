import { borderRadius } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteEmptyProps } from "../../alogrithms/requests";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  getFinalPrice,
  updateOnePrice,
} from "../../api/store/actions/saga/prices";
import Button from "../../reusableComponents/button";
import { EditIcon } from "../../reusableComponents/iconsSvg/tableI/tableIcons";
import TextInput from "../../reusableComponents/textInput";
import WhiteIcon from "./pricesIcons";
const PricesItem = ({
  price,
  title,
  finalPrice,
  id,
  setUpdatedPrices,
  updatedPrices,
  dajinId,
  flag = false,
  setFlag
}) => {
  const dispatch = useDispatch();
  const width = useResponsiveWidth({ desktop: 30 });
  const [editFinalPrice, setEditFinalPrice] = useState(false);
  const [editIndicativePrice, setEditIndicativePrice] = useState(false);
  const [theFinalPrice, setTheFinalPrice] = useState(null);
  const [indicativePrice, setIndicativePrice] = useState(price);
  const [calcFinalPrice, setCalcFinalPrice] = useState(null);
  const onClick = () => {
    resolveRequest(getFinalPrice, setCalcFinalPrice, { id: id });
  };

  const updateOne = async () => {
    let res = await updateOnePrice({
      id: id,
      body: deleteEmptyProps({
        final_price: editFinalPrice ? theFinalPrice : null,
        indicative_price: editIndicativePrice ? indicativePrice : null,
      }),
    });
    if (res.code) {
      dispatch({ type: "RELOAD" });
      let s = setTimeout(() => {
        setEditFinalPrice(false);
        setEditIndicativePrice(false);
        clearTimeout(s);
      }, 500);
    }
  };
  console.log('updatedPrices', updatedPrices);
  useEffect(() => {
    if (flag) {
      let setTime = setTimeout(() => {
        setEditFinalPrice(false)
        setEditIndicativePrice(false)
        clearTimeout(setTime)
      }, 100)
    }
  }, [flag])
  useEffect(() => {

    if (calcFinalPrice?.data?.final_price) {
      setUpdatedPrices({
        ...updatedPrices,
        [id]: {
          final_price: calcFinalPrice?.data?.final_price,
          dajin_id: dajinId,
          indicative_price: price,
        },
      });
      setTheFinalPrice(calcFinalPrice?.data?.final_price);
    }
  }, [calcFinalPrice]);
  return (
    <div
      style={{
        border: "1px solid #C2C2C2",
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: 10,
        }}
      >
        <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <WhiteIcon />
          </div>
          <p style={{ paddingLeft: 10, paddingRight: 10 }}>{title}</p>
        </div>
        <div style={{ width: "20%", display: "flex", alignItems: "center" }}>
          {editIndicativePrice ? (
            <TextInput
              type={"number"}
              height={30}
              responsiveWidth={{ ...width }}
              defaultValue={price}
              onChange={(e) => {
                const oldData = updatedPrices[id] != undefined ? updatedPrices[id] : {};
                setUpdatedPrices({
                  ...updatedPrices, [id]: {
                    ...oldData,
                    dajin_id: dajinId,
                    indicative_price: e.target.value,
                  }
                })
                setIndicativePrice(e.target.value);
              }}
            />
          ) : (
            <p style={{ paddingLeft: 10, paddingRight: 10 }}> {price} ج.م </p>
          )}
          {editIndicativePrice ? (
            <p
              onClick={() => {
                setEditIndicativePrice(!editIndicativePrice);
              }}
              style={{
                backgroundColor: "#7ABA4C1A",
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 3,
                paddingTop: 3,
                color: "#7ABA4C",
                marginRight: 5,
                marginLeft: 5,
                borderRadius: 8,
                cursor: "pointer",
              }}
            >
              الغاء
            </p>
          ) : (
            <div
              style={{ paddingLeft: 10, paddingRight: 10, cursor: "pointer" }}
              onClick={() => {
                setEditIndicativePrice(!editIndicativePrice);
              }}
            >
              <EditIcon />
            </div>
          )}
        </div>
        <div style={{ width: "25%" }}>
          {finalPrice || calcFinalPrice ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              {editFinalPrice ? (
                <TextInput
                  type={"number"}
                  height={30}
                  responsiveWidth={{ ...width }}
                  defaultValue={
                    calcFinalPrice
                      ? calcFinalPrice?.data?.final_price ?? 0
                      : finalPrice ?? 0
                  }
                  onChange={(e) => {
                    const oldData = updatedPrices[id] != undefined ? updatedPrices[id] : {};
                    setUpdatedPrices({
                      ...updatedPrices, [id]: {
                        ...oldData,
                        dajin_id: dajinId,
                        final_price: e.target.value
                      }
                    })
                    setTheFinalPrice(e.target.value);
                  }}
                />
              ) : (
                <p>
                  {finalPrice
                    ? finalPrice
                    : calcFinalPrice?.data?.final_price ??
                    "لا يتوفر طلبات لاحتساب السعر"}
                </p>
              )}
              {editFinalPrice ? (
                <p
                  onClick={() => {
                    setEditFinalPrice(!editFinalPrice);
                  }}
                  style={{
                    backgroundColor: "#7ABA4C1A",
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 3,
                    paddingTop: 3,
                    color: "#7ABA4C",
                    marginRight: 5,
                    marginLeft: 5,
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                >
                  الغاء
                </p>
              ) : (
                <div
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditFinalPrice(!editFinalPrice);
                  }}
                >
                  <EditIcon />
                </div>
              )}
            </div>
          ) : (
            <Button onClick={onClick}>احسب السعر النهائي</Button>
          )}
        </div>
        {(editFinalPrice || editIndicativePrice) && (
          <div
            style={{ width: "20%", justifyContent: "center", display: "flex" }}
          >
            <Button width={"50%"} onClick={updateOne}>
              حفظ
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricesItem;
