export const resolveRequest = async (request, store, ...params) => {
  let data = await request(...params);
  store(data);
};

export const dataFormat = (response) => {
  return {
    code: response?.status,
    message: response.data?.message ?? response?.statusText,
    errors: response?.response?.data?.errors,
    data: response?.data?.data || response?.data,
  };
};
export const dataFormatRedux = (response) => {};
export const dataFormatPagination = (response) => {
  return {
    code: response?.status,
    message: response.data?.message ?? response?.statusText,
    errors: null,
    data:
      response.data?.data?.data || response.data?.data || response.data || [],
    total:
      response.data?.data?.total ||
      response.data?.meta?.total ||
      response?.meta?.total ||
      response?.data?.total,
    perPage:
      response.data?.data?.per_page ||
      response.data?.meta?.per_page ||
      response?.meta?.per_page ||
      response?.data?.per_page,
    poll_interval: response.data?.poll_interval,
    timestamp: response.data?.timestamp,
  };
};
export const catchFromat = (response) => {
  return {
    code: response?.status,
    message: response.data?.message ?? response?.statusText,
    errors: response?.data?.errors,
    data: null,
  };
};

export const concatFilters = (params) => {
  let newParams = getFilteredObj(params);
  let filters = "";
  for (const [key, value] of Object.entries(newParams)) {
    if (filters) filters += `&filter[${key}]=${value}`;
    else filters += `filter[${key}]=${value}`;
  }
  return filters;
};

export const getFilteredObj = (obj) => {
  for (const [key, value] of Object.entries(obj)) {
    if (!value && value !== false && value !== 0) {
      delete obj[key];
    }
  }
  return obj;
};
