import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/articles`;
export const createArticles = async ({ body }) => {
  try {
    let data = await axiosCall.post(apiUrl, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return dataFormat(data);
  } catch (e) {
    console.log("error req", e?.response);
    return catchFromat(e.response);
  }
};
export const getArticles = async ({
  pageNumber: pageNumber = 1,
  pageCount: pageCount = 7,
  params: params = {},
}) => {
  let filters = concatFilters(params);
  try {
    let data = await axiosCall.get(
      `${apiUrl}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateArticles = async ({ body, id }) => {
  try {
    let data = await axiosCall.post(`${apiUrl}/${id}`, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getArticlesDetails = async ({ id }) => {
  try {
    let data = await axiosCall.get(`${apiUrl}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
