import React from "react";

function BinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.638"
      height="13.787"
      viewBox="0 0 12.638 13.787"
    >
      <g fill="#ed4c4c" transform="translate(-7.47 -6.894)">
        <path
          d="M10 13.17v7.037a1.579 1.579 0 001.58 1.579h7.18a1.579 1.579 0 001.58-1.579V13.17zm3.447 5.745a.575.575 0 01-1.15 0v-3.447a.575.575 0 011.15 0zm2.3 0a.575.575 0 01-1.15 0v-3.447a.575.575 0 011.15 0zm2.3 0a.575.575 0 01-1.15 0v-3.447a.575.575 0 011.15 0v3.447zm-4.6-9.766h3.447v.575h1.149v-.575A1.152 1.152 0 0016.894 8h-3.447A1.152 1.152 0 0012.3 9.149v.575h1.149z"
          data-name="Path 4118"
          transform="translate(-1.382 -1.106)"
        ></path>
        <path
          d="M21.305 11.579v1.436a.434.434 0 01-.431.431H9.1a.434.434 0 01-.431-.431v-1.436A1.579 1.579 0 0110.247 10h9.479a1.579 1.579 0 011.579 1.579z"
          data-name="Path 4119"
          opacity="0.5"
          transform="translate(-1.197 -1.383)"
        ></path>
      </g>
    </svg>
  );
}

export default BinIcon;
