import React from "react";

function FeaturedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.624"
      height="22.624"
      viewBox="0 0 22.624 22.624"
    >
      <g>
        <path
          fill="#2196f3"
          d="M20.8 8.624l.31-2.968-2.728-1.214a.394.394 0 01-.2-.2l-1.213-2.727-2.969.31a.4.4 0 01-.271-.073L11.312 0 8.9 1.752a.4.4 0 01-.271.073l-2.969-.31-1.219 2.727a.39.39 0 01-.2.2L1.514 5.655l.31 2.968a.394.394 0 01-.073.272L0 11.312l1.752 2.417a.394.394 0 01.072.271l-.31 2.968 2.727 1.214a.394.394 0 01.2.2l1.214 2.728 2.969-.31h.039a.391.391 0 01.231.075l2.418 1.751 2.417-1.751A.392.392 0 0114 20.8l2.969.31 1.213-2.727a.392.392 0 01.2-.2l2.727-1.214L20.8 14a.392.392 0 01.073-.272l1.751-2.417L20.873 8.9a.392.392 0 01-.073-.272z"
          data-name="Path 38787"
        ></path>
        <path
          fill="#fafafa"
          d="M152.129 166.937L146 173.065a.942.942 0 01-1.333 0L141.6 170a.942.942 0 011.333-1.333l2.4 2.4 5.467-5.467a.942.942 0 011.333 1.333zm0 0"
          data-name="Path 38788"
          transform="translate(-135.555 -158.023)"
        ></path>
      </g>
    </svg>
  );
}

export default FeaturedIcon;
