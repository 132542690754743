import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  getNotifications,
  getTargets,
} from "../../api/store/actions/saga/notification";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";

const Notification = () => {
  const [searchKey, setSearchKey] = useState("");
  const [filterKey, setFilterKey] = useState(null);
  const [notificationData, setNotificationData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [targetsData, setTargetsData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    if (!targetsData) {
      resolveRequest(getTargets, setTargetsData);
    }
    resolveRequest(getNotifications, setNotificationData, {
      pageNumber: currentPage,
    });
  }, [currentPage, searchKey, filterKey, reload]);
  //   const onChange = (value) => {
  //     if (currentPage != 1) {
  //       setCurrentPage(1);
  //     }
  //     setSearchKey(value);
  //   };
  //   const onSelect = (value) => {
  //     if (currentPage != 1) {
  //       setCurrentPage(1);
  //     }
  //     setFilterKey(value);
  //   };
  return (
    <div
      style={{
        backgroundColor: "white",
        paddingBottom: 20,
        paddingTop: 20,
        borderRadius: 10,
      }}
    >
      <ListHeader
        hasFilter={false}
        hasSearch={false}
        // onChange={onChange}
        // onSelect={onSelect}
        // dataSource={targetsData?.data?.map((item) => {
        //   return { name: item, id: item };
        // })}
        addLink={"addNotification"}
        searchKey={searchKey}
      />
      {notificationData && targetsData ? (
        <ListView
          objName={"notification"}
          links={{
            edit: "updateNotification",
            delete: "",
            show: ``,
            query: {
              show: { key: "", prop: "" },
              delete: "",
              edit: "",
            },
          }}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={notificationData}
        ></ListView>
      ) : (
        <Loading />
      )}
    </div>
  );
};
export default Notification;
