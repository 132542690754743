import { useParams } from "react-router";
import { deleteFarm } from "../../api/store/actions/saga/farms";
import AppModal from "../modal/customModal";

const DeleteFarm = () => {
  const { id } = useParams();
  console.log("id", id);
  const submit = () => {
    deleteFarm({ farm_id: id });
  };
  return (
    <AppModal
      buttonTitle={"حذف"}
      modalTitle={"هل انت متاكد من الحذف ؟"}
      onClick={submit}
      children={" "}
    ></AppModal>
  );
};

export default DeleteFarm;
