import { useEffect, useState } from "react";
import {
  CheckedIcon,
  DownArrow,
  UpArrow,
  cancelIcon,
  FilterIcon,
} from "./dropdownIcons";
import "./dropdownStyle.css";
const SelectDropdown = ({
  placeholder,
  dataSource = [],
  selectedItem,
  onSelect,
  responsiveWidth,
  schema = { id: "id", name: "name" },
  errorMessage,
  width = "auto",
  dependId,
}) => {
  const [listIsOpen, setListIsOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [items, setItems] = useState([...dataSource]);
  console.log("selected", selected);
  useEffect(() => {
    setItems([...dataSource])
  }, [schema.id, schema.name])
  useEffect(() => {
    if (dependId) {
      setItems([
        ...dataSource.filter(
          (item) => (item?.governorate?.id || item?.dajin?.id) == dependId
        ),
      ]);
    };
  }, [dependId]);
  useEffect(() => {
    setItems([...dataSource]);
  }, []);
  useEffect(() => {
    if (selectedItem || selectedItem === 0) {
      setSelected(
        dataSource?.find((item) => item[schema.id] == selectedItem) ?? {}
      );
    }
  }, [selectedItem]);

  return (
    <>
      <div
        className="dd-wrapper"
        style={{ width: "20%", width: width, ...responsiveWidth }}
      >
        <button
          type="button"
          className={`dd-header ${listIsOpen ? "noraduis" : ""}`}
          onClick={() => {
            setListIsOpen(!listIsOpen);
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FilterIcon />
            <div className="dd-header-title">
              <p>
                {selected[schema.name] ? selected[schema.name] : placeholder}
              </p>
            </div>
          </div>

          {listIsOpen ? <UpArrow /> : <DownArrow />}
        </button>
        {listIsOpen && (
          <div role="list" type="button" className="dd-list">
            <div className="dd-scroll-list">
              {items.length ? (
                items.map((item) => (
                  <button
                    type="button"
                    className="dd-list-item"
                    key={item[schema.id]}
                    onClick={(e) => {
                      setSelected(item);
                      if (selected) setListIsOpen(!listIsOpen);
                      if (onSelect) onSelect(item.id);
                    }}
                  >
                    <p>{item[schema.name]}</p>
                    {item[schema.id] == selected?.id && (
                      <CheckedIcon width={20} height={20} color={"#7ABA4C"} />
                    )}
                  </button>
                ))
              ) : (
                <>
                  <button type="button" className="dd-list-item">
                    <p>لا يوجد عناصر</p>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {errorMessage && (
          <p style={{ marginTop: 5, marginBottom: 5, color: "red" }}>
            {errorMessage}
          </p>
        )}
      </div>
    </>
  );
};

export default SelectDropdown;
