import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getSalesDetails } from "../../api/store/actions/saga/sales_transactions";
import SectionLabel from "../../reusableComponents/sectionLabel";
import Rating from "@mui/material/Rating";
import PhoneIcon from "../../reusableComponents/iconsSvg/phoneIcon";
import WhatsAppIcon from "../../reusableComponents/iconsSvg/whatsappIcon";
import FacebookIcon from "../../reusableComponents/iconsSvg/facebookIcon";
import LocationIcon from "../../reusableComponents/iconsSvg/locationIcon";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import { LabelWIcon } from "../../screenParts/farms/labelWIcon";
import { useMediaQuery } from "react-responsive";
import ListHeader from "../../screenParts/listingViews/listHeader";
import TimeIcon from "../../reusableComponents/iconsSvg/timeIcon";
import Loading from "../../reusableComponents/loadingScreen/loading";
import Button from "../../reusableComponents/button";
export const useTablet = () => useMediaQuery({ maxWidth: 1100 });

export const SalesDetails = () => {
  const isTablet = useTablet();
  const { transaction_id } = useParams();
  const [salesDetails, setSalesDetails] = useState();
  console.log(transaction_id);
  useEffect(() => {
    resolveRequest(getSalesDetails, setSalesDetails, { id: transaction_id });
  }, []);
  useEffect(() => {
    console.log("salesDetails", salesDetails);
  }, [salesDetails]);
  const halfWidth = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  return (
    <>
      {salesDetails ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListHeader
              hasTitle={true}
              title={"تفاصيل العملية"}
              back
              hasAdd={false}
              hasFilter={false}
              hasSearch={false}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p> الحالة:</p>
                <p
                  style={{
                    color:
                      salesDetails?.data?.status?.id == 4 ||
                      salesDetails?.data?.status?.id == 2
                        ? "#7ABA4C"
                        : salesDetails?.data?.status?.id == 5
                        ? "#FE646F"
                        : "#B1B1B1",
                    marginRight: 10,
                  }}
                >
                  {salesDetails?.data?.status?.status_for_farmer}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <TimeIcon />
                <p style={{ color: "#535353", marginRight: 10 }}>
                  {salesDetails?.data?.created_at}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "column" : "row",
            }}
          >
            <div
              style={{
                ...halfWidth,
              }}
            >
              <div>
                <SectionLabel content={"بيانات التاجر"} />
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <img
                      style={{ width: 140, height: 140, borderRadius: 10 }}
                      src={
                        salesDetails?.data?.trader?.avatar ??
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                      }
                    />
                  </div>
                  <div style={{ flex: 3, marginRight: 10 }}>
                    <p>{salesDetails?.data?.trader?.name}</p>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      <Rating
                        value={salesDetails?.data?.trader?.rating}
                        readOnly
                        dir="ltr"
                      />
                      <p style={{ marginRight: 10, color: "#B7B7B7" }}>
                        {salesDetails?.data?.trader?.rating?.toFixed(2)}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <PhoneIcon />
                      <p
                        style={{
                          marginRight: 15,
                          marginLeft: 15,
                          color: "#646D82",
                        }}
                      >
                        {salesDetails?.data?.trader?.mobile_number}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 10,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <WhatsAppIcon />
                        <p
                          style={{
                            marginRight: 15,
                            marginLeft: 15,
                            color: "#646D82",
                          }}
                        >
                          {salesDetails?.data?.trader?.whatsapp_number}
                        </p>
                      </div>
                      <div style={{ marginRight: 10, marginLeft: 10 }}>
                        <Button
                          backgroundColor="transparent"
                          onClick={() => {
                            window.open(
                              salesDetails?.data?.trader?.facebook_account ?? ""
                            );
                          }}
                        >
                          <FacebookIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <SectionLabel content={"تفاصيل الطلب"} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <LabelWIcon
                    icon={<ChickenIcon />}
                    text={` العدد: ${salesDetails?.data?.order_details?.amount}`}
                  />
                  <LabelWIcon
                    icon={<ChickenIcon />}
                    text={` النوع: ${salesDetails?.data?.order_details?.dajin_type?.type}`}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <LabelWIcon
                    icon={<ChickenIcon />}
                    text={` متوسط الوزن: ${salesDetails?.data?.order_details?.average_weight}`}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <LabelWIcon icon={<ChickenIcon />} text={` نطاق الشراء: `} />
                  <div
                    style={{ display: "flex", marginTop: 5, flexWrap: "wrap" }}
                  >
                    {salesDetails?.data?.order_details?.governorates?.map(
                      (item) => (
                        <p
                          style={{
                            width: 200,
                            padding: 5,
                            borderRadius: 10,
                            marginRight: 10,
                            backgroundColor: "lightgray",
                            marginTop: 10,
                            textAlign: "center",
                          }}
                        >
                          {item?.name}
                        </p>
                      )
                    )}
                  </div>
                </div>
                <div>
                  <SectionLabel content={"حالة التاجر"} />

                  <p
                    style={{
                      backgroundColor: "#B1B1B1",
                      width: 200,
                      textAlign: "center",
                      borderRadius: 10,
                      color: "white",
                      padding: 10,
                    }}
                  >
                    {salesDetails?.data?.status?.status_for_trader}
                  </p>
                </div>
                {salesDetails?.data?.cancelFeedback ? (
                  <div>
                    <SectionLabel content={"سبب عدم اتمام عملية الشراء"} />

                    <p
                      style={{
                        border: "1px solid red",

                        textAlign: "center",
                        borderRadius: 10,

                        padding: 10,
                      }}
                    >
                      {salesDetails?.data?.cancelFeedback?.reason}
                      <SectionLabel content={"التعليق"} />
                      <p
                        style={{
                          border: "1px solid black",

                          textAlign: "center",
                          borderRadius: 10,

                          padding: 10,
                        }}
                      >
                        {salesDetails?.data?.cancelFeedback?.comment}
                      </p>
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              style={{
                ...halfWidth,
              }}
            >
              <div>
                <SectionLabel content={"بيانات العنبر"} />
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <img
                      style={{ width: 140, height: 140, borderRadius: 10 }}
                      src={
                        salesDetails?.data?.farm?.photo ??
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                      }
                    />
                  </div>
                  <div style={{ flex: 3, marginRight: 10 }}>
                    <p>{salesDetails?.data?.farm?.name}</p>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 20,
                        marginBottom: 20,
                        alignItems: "center",
                      }}
                    >
                      <LocationIcon />
                      <p style={{ marginRight: 10 }}>
                        {salesDetails?.data?.farm?.full_address}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <PhoneIcon />
                      <p
                        style={{
                          marginRight: 15,
                          marginLeft: 15,
                          color: "#646D82",
                        }}
                      >
                        {salesDetails?.data?.farm?.farmer?.mobile_number}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 10,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <WhatsAppIcon />
                        <p
                          style={{
                            marginRight: 15,
                            marginLeft: 15,
                            color: "#646D82",
                          }}
                        >
                          {salesDetails?.data?.farm?.farmer?.whatsapp_number}
                        </p>
                      </div>
                      <div style={{ marginRight: 10, marginLeft: 10 }}>
                        <Button
                          backgroundColor="transparent"
                          onClick={() => {
                            window.open(
                              salesDetails?.data?.farm?.farmer
                                ?.facebook_account ?? ""
                            );
                          }}
                        >
                          <FacebookIcon />
                        </Button>
                      </div>
                    </div>
                    {/*  <div style={{ display: "flex" }}>
                <p>العدد المتاح: ${salesDetails?.data?.farm?.full_address}</p>
              </div> */}
                  </div>
                </div>
                <div>
                  <SectionLabel content={"حالة المربى"} />

                  <p
                    style={{
                      backgroundColor: "#B1B1B1",
                      width: 200,
                      textAlign: "center",
                      borderRadius: 10,
                      color: "white",
                      padding: 10,
                    }}
                  >
                    {salesDetails?.data?.status?.status_for_farmer}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
