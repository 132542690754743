import moment from "moment";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../../reusableComponents/button";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import TimeIcon from "../../reusableComponents/iconsSvg/timeIcon";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { useTablet } from "../../screens/dispute/disputeDetails";
export const StatusNButtonsSection = ({
  housing_link,
  status,
  onActiveFarm,
  farm_state,
  create_offer_link,
  offer_details,
  edit_offer_link,
  onRejectFarm,
}) => {
  const location = useLocation();
  console.log("offer details", offer_details);
  const isTablet = useTablet();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          paddingRight: 20,
          paddingLeft: 20,
        }}
      >
        <div style={{ width: "60%" }}>
          <SectionLabel content={"حالة العنبر"} />
        </div>
        {status?.id == 2 && (
          <Link
            to={`${housing_link}`}
            style={{ display: "contents" }}
            state={{
              backgroundLocation: location,
            }}
          >
            <Button width={210} height={48} style={{ marginLeft: 10 }}>
              تسكين العنبر
            </Button>
          </Link>
        )}

        {status?.id == 3 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link
              to={`${create_offer_link}`}
              style={{ display: "contents" }}
              state={{
                backgroundLocation: location,
              }}
            >
              <Button width={210} height={48} style={{ marginLeft: 10 }}>
                عرض بيع عنبر
              </Button>
            </Link>
            {/*     <Link
              to={`${housing_link}`}
              style={{ display: "contents" }}
              state={{
                backgroundLocation: "modal",
              }}
            >
              <Button
                width={210}
                height={48}
                style={{ marginLeft: 10 }}
                onClick={onActiveFarm}
              >
                طلبات شراء
              </Button>
            </Link> */}
          </div>
        )}
        {status?.id == 4 &&
          (offer_details ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link
                to={`${edit_offer_link}`}
                style={{ display: "contents" }}
                state={{
                  backgroundLocation: location,
                }}
              >
                <Button width={210} height={48} style={{ marginLeft: 10 }}>
                  تعديل عرض بيع العنبر
                </Button>
              </Link>
              {/* <Link
              to={`${housing_link}`}
              style={{ display: "contents" }}
              state={{
                backgroundLocation: "modal",
              }}
            >
              <Button
                width={210}
                height={48}
                style={{ marginLeft: 10 }}
                onClick={onActiveFarm}
              >
                طلبات شراء
              </Button>
            </Link> */}
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link
                to={`${create_offer_link}`}
                style={{ display: "contents" }}
                state={{
                  backgroundLocation: location,
                }}
              >
                <Button width={210} height={48} style={{ marginLeft: 10 }}>
                  عرض بيع عنبر
                </Button>
              </Link>
              {/*     <Link
                  to={`${housing_link}`}
                  style={{ display: "contents" }}
                  state={{
                    backgroundLocation: "modal",
                  }}
                >
                  <Button
                    width={210}
                    height={48}
                    style={{ marginLeft: 10 }}
                    onClick={onActiveFarm}
                  >
                    طلبات شراء
                  </Button>
                </Link> */}
            </div>
          ))}
        <div style={{ display: "flex" }}>
          <p> الحالة:</p>
          <p
            style={{
              marginRight: 10,
              color:
                status?.id == 1
                  ? "#8C8C8C"
                  : status?.id == 2
                  ? "#F75757"
                  : "#2196F3",
            }}
          >
            {status?.status}
          </p>
        </div>
      </div>
      {status?.id == 1 && (
        <div style={{ display: "flex", marginTop: 20 }}>
          <Button
            width={210}
            height={48}
            style={{ marginLeft: 10 }}
            onClick={onActiveFarm}
          >
            تفعيل العنبر
          </Button>
          <Button
            style={{ marginLeft: 10, marginRight: 10 }}
            backgroundColor="white"
            buttonStyles={{ border: "1px solid #FE646F" }}
            textColor={"#FE646F"}
            width={210}
            height={48}
            onClick={onRejectFarm}
          >
            رفض العنبر
          </Button>
        </div>
      )}

      {(status?.id == 2 || status?.id == 3 || status?.id == 4) && (
        <div
          style={{
            display: "flex",
            flexDirection: isTablet ? "column" : "row",
            marginTop: 20,
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <ChickenIcon />
              <p style={{ marginRight: 10, fontSize: 20 }}>
                {" "}
                نوع السلالة: {farm_state?.breed?.breed_type}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <ChickenIcon />
              <p style={{ marginRight: 10, fontSize: 20 }}>
                {" "}
                نوع الداجن: {farm_state?.breed?.dajin?.type}{" "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <ChickenIcon />
              <p style={{ marginRight: 10, fontSize: 20 }}>
                نوع التربية: {farm_state?.farming_type?.type}
              </p>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <ChickenIcon />
              <p style={{ marginRight: 10, fontSize: 20 }}>
                العدد: {farm_state?.total_count}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <TimeIcon />
              <p style={{ marginRight: 10, fontSize: 20 }}>
                تاريخ الادخال:
                {` ${
                  farm_state?.entry_date
                    ? moment(farm_state?.entry_date).format("yyyy-MM-DD")
                    : ""
                }`}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <TimeIcon />
              <p style={{ marginRight: 10, fontSize: 20 }}>
                تاريخ البيع المتوقع:
                {` ${
                  farm_state?.expected_selling_date
                    ? moment(farm_state?.expected_selling_date).format(
                        "yyyy-MM-DD"
                      )
                    : ""
                }`}
              </p>
            </div>
          </div>
        </div>
      )}

      {status?.id == 4 &&
        (offer_details ? (
          <>
            <SectionLabel content={"تفاصيل العرض"} />
            <div style={{ display: "flex", marginTop: 20 }}>
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <ChickenIcon />
                  <p style={{ marginRight: 10, fontSize: 20 }}>
                    {" "}
                    العدد المعروض للبيع: {offer_details?.count_for_sale}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <ChickenIcon />
                  <p style={{ marginRight: 10, fontSize: 20 }}>
                    متوسط الوزن: {offer_details?.average_weight}{" "}
                  </p>
                </div>
                {/*    <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <ChickenIcon />
                <p style={{ marginRight: 10, fontSize: 20 }}>
                  الرصيد المتبقي بالعنبر : :{offer_details?.remaining ?? "0000"}
                </p>
              </div> */}
              </div>
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <ChickenIcon />
                  <p style={{ marginRight: 10, fontSize: 20 }}>
                    الحالة الصحية: {offer_details?.dajin_health}
                  </p>
                </div>
                {/*  <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <TimeIcon />
                  <p style={{ marginRight: 10, fontSize: 20 }}>
                    العمر:
                    {offer_details?.age}
                  </p>
                </div> */}
              </div>
            </div>
          </>
        ) : (
          <></>
        ))}
    </>
  );
};
