import { useEffect, useState } from "react";
import React from "react";
//import { TraderAccountDetails } from "./traderAccountDetails";
import ListHeader from "../../screenParts/listingViews/listHeader";
//import TraderRatings from "./traderRatings";
//import { TraderOrdersScreen } from "../orders/traderOrdersScreen";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FarmerAccountDetails } from "./farmerAccountDetails";
import { FarmsScreen } from "../farms/farmScreen";
import { FarmerOrdersScreen } from "./farmerOrdersScreen";
import { getAllSettings } from "../../api/store/actions/saga/settings";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import FarmerRatings from "./farmerRatings";
import { EditIcon } from "../../reusableComponents/iconsSvg/tableI/tableIcons";
import { useDispatch, useSelector } from "react-redux";
import { clearActiveDetailsState } from "../../api/store/reducers/reload";

const FarmerDetails = () => {
  const activeDetailsState = useSelector(state => state?.reload?.activeDetails)

  const [active, setActive] = useState(activeDetailsState ?? 1);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const { farmer_id } = useParams();
  const onChange = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSearchKey(value);
  };
  const settingsData = useSelector(state => state?.settings?.setting)


  console.log("settingsData", settingsData);
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
    console.log(value, "select");
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ListHeader
          hasTitle={true}
          title={"تفاصيل الحساب"}
          back
          hasAdd={false}
          hasFilter={false}
          hasSearch={false}
        />
        <Link to={`/farmers/editFarmer/${farmer_id}`}>
          <EditIcon />
        </Link>
      </div>
      {settingsData ? (
        <ListHeader
          switchType={'details'}
          onSelect={onSelect}
          addLink={active == 2 ? "addFarm" : undefined}
          pages={[
            { title: "بيانات الحساب", id: 1 },
            { title: "العنابر", id: 2 },

            { title: "عروض الشراء", id: 3 },
            { title: "التقييمات", id: 4 },
          ]}
          hasAdd={active === 2}
          schema={
            active == 3
              ? { name: "status_for_trader", id: "id" }
              : active == 2
                ? { name: "status", id: "id" }
                : undefined
          }
          dataSource={
            active == 2 && settingsData
              ? [
                { status: "الكل", id: null },
                ...settingsData?.data?.data?.farms?.status,
              ]
              : active === 3 && settingsData
                ? [
                  { status_for_trader: "الكل", id: null },
                  ...settingsData?.data?.data["order-request"]?.statuses,
                ]
                : null
          }
          hasFilter={active === 2 || active === 3}
          hasSearch={active === 2 || active === 3 || active === 4}
          onChange={active == 2 || active == 3 ? onChange : null}
          active={active}
          setActive={setActive}
        />
      ) : (
        <></>
      )}
      {active == 1 && <FarmerAccountDetails />}
      {active == 2 && (
        <FarmsScreen
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchKey={searchKey}
          filterKey={filterKey}
        />
      )}
      {active == 3 && (
        <FarmerOrdersScreen
          searchKey={searchKey}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          filterKey={filterKey}
        />
      )}
      {active == 4 && <FarmerRatings searchKey={searchKey} />}
    </>
  );
};

export default FarmerDetails;
