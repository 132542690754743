import axiosCall from "../../../../axios/axiosConfig";
import {
  catchFromat,
  dataFormat,
} from "../../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/settings/bonus/points`;

export const getBonusPoints = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}/index`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getBonusPointsDetails = async ({ bonus_id }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}/${bonus_id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateBonusPoints = async ({ bonus_id, body }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${bonus_id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
