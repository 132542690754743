import React from "react";

function BlueBG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      viewBox="0 0 76 76"
    >
      <rect width="76" height="76" fill="#2196f3" rx="5"></rect>
    </svg>
  );
}

export default BlueBG;
