import { objToFormData } from "../../../../../alogrithms/requests";
import axiosCall from "../../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../../middlewares/resolverRequests";

const apiUrl = (type) => `/admin/v1/settings/${type}/rating/dimensions`;
export const getRating = async ({ type }) => {
  try {
    const data = await axiosCall.get(`${apiUrl(type)}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getRatingDetails = async ({ id, type }) => {
  try {
    const data = await axiosCall.get(`${apiUrl(type)}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const deleteRating = async ({ id, type }) => {
  try {
    const data = await axiosCall.delete(`${apiUrl(type)}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateRating = async ({ id, body, type }) => {
  try {
    const data = await axiosCall.put(`${apiUrl(type)}/${id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const createRating = async ({ body, type }) => {
  try {
    const data = await axiosCall.post(`${apiUrl(type)}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
