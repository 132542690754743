import { newsTypes } from "../../../utlity/actionTypes";

const initialState = {
  news: [],
  loading: false,
  error: null,
};

export default function news(state = initialState, action) {
  switch (action.type) {
    case newsTypes.GET_NEWS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case newsTypes.GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: action.users,
      };
    case newsTypes.GET_NEWS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}
