import React from "react";
export const LabelWIcon = ({
  icon,
  text,
  not,
  width = "100%",
  justifyContent,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: justifyContent ?? "flex-start",
        width: width,
      }}
    >
      {icon}
      <p style={{ marginRight: 10, fontSize: 14 }}>{text}</p>
    </div>
  );
};
