import { loginTypes } from "../../../utlity/actionTypes";

const initialState = {
  user: {},
  loading: false,
  error: null,
  isAuth: false,
  isRemember: false,
};
console.log("initialState", initialState);
export default function login(state = initialState, action) {
  console.log("ACTIONREDUCER", action);
  switch (action.type) {
    case loginTypes.LOGIN_REQUESTED:
      return {
        ...state,
        loading: true,
        isAuth: false,
      };
    case loginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        isAuth: true,
      };
    case loginTypes.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
        isAuth: false,
        user: {},
      };

    default:
      return state;
  }
}
