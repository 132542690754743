import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getCompanies } from "../../../api/store/actions/saga/settings/companies";
import { getFarmingTypes } from "../../../api/store/actions/saga/settings/farmingTypes";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const CompaniesTypes = () => {
  const [companiesData, setCompanyData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getCompanies, setCompanyData);
  }, [reload]);
  console.log(companiesData);
  return companiesData ? (
    <ListView
      objName={"companies"}
      stateLocation
      links={{
        edit: "updateCompany",
        delete: "delete/company",
        show: ``,
        query: {
          show: "",
          delete: "",
          edit: "",
        },
      }}
      data={companiesData}
    ></ListView>
  ) : (
    <Loading />
  );
};

export default CompaniesTypes;
