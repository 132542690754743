import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getAllSales } from "../../api/store/actions/saga/sales_transactions";
import { getAllSettings } from "../../api/store/actions/saga/settings";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";
import DisputesList from "../dispute";

const SalesTransactionsList = () => {
  const [searchKey, setSearchKey] = useState("");
  const [FarmersData, setFarmersData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const activeState = useSelector(state => state?.reload?.active)

  const [filterKey, setSFilterKey] = useState(null);
  const settingsData = useSelector(state => state?.settings?.setting)


  useEffect(() => {
    // setCourseData(null);
    resolveRequest(getAllSales, setFarmersData, {
      pageNumber: currentPage,
      params: { trader: searchKey, status_id: filterKey },
    });
  }, [currentPage, searchKey, filterKey]);
  console.log("settingsData", settingsData);
  useEffect(() => {
    // setCourseData(null);
    console.log("sales Data", FarmersData);
  }, [FarmersData]);
  const onChange = (value) => {
    setSearchKey(value);
  };
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
    console.log(value, "select");
  };
  const [active, setActive] = useState(activeState ?? 1);
  console.log('activeState', activeState)

  return (
    <>
      {settingsData && (
        <ListHeader
          onSelect={onSelect}
          onChange={onChange}
          schema={{ name: "status_for_farmer", id: "id" }}
          active={active}
          setActive={setActive}
          dataSource={
            settingsData
              ? [
                { status_for_farmer: "الكل", id: null },
                ...settingsData?.data?.data["order-request"]?.statuses,
              ]
              : []
          }
          hasFilter={active == 1}
          hasSearch={active == 1}
          hasAdd={false}
          addLink={"addFarmer"}
          linkType={undefined}
          pages={[
            { title: "جميع العمليات", id: 1 },
            { title: "فض المنازعات", id: 2 },
          ]}
        />
      )}
      {active == 1 ? (
        FarmersData ? (
          <ListView
            objName={"salesTransactions"}
            links={{
              show: "salesDetails",
            }}
            data={FarmersData}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        ) : (
          <Loading />
        )
      ) : active == 2 ? (
        <DisputesList />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default SalesTransactionsList;
