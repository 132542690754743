import { toast } from "react-toastify";
import axiosCall from "./axiosConfig";
import { interceptorsErrorsResponse } from "./interceptorsResponseCases";
import { SuccessToast } from "./toastify";
const methods = ["put", "patch", "post"];
export const axiosInterceptors = (dispatch, navigate) => {
  axiosCall.interceptors.response.use(
    (res) => {
      console.log("res int ", res);
      if (methods.includes(res.config.method.toLowerCase())) {
        dispatch({ type: "BTN_LOADING", payload: false });
      }
      if (res.config.method == "delete") {
        if (res?.config?.url.includes("offer")) {
          SuccessToast(res?.data?.data?.message ?? "تم الحذف بنجاح");
          dispatch({ type: "RELOAD" });
        } else {
          SuccessToast(res?.data?.data?.message ?? "تم الحذف بنجاح");
          navigate(-1, { replace: true });
          dispatch({ type: "RELOAD" });
        }
      } else if (res.config.method == "put" || res.config.method == "patch") {
        if (
          !res.config.url.includes("prices") &&
          !res.config.url.includes("status") &&
          !res.config.url.includes("feature") &&
          !res.config.url.includes("prices/") &&
          !res.config.url.includes("feature") &&
          !res.config.url.includes("redemption") &&
          !res.config.url.includes("approve") &&
          !res.config.url.includes("disapprove") &&
          !res.config.url.includes("notifications/read") &&
          !res.config.url.includes("activate") &&
          !res.config.url.includes("deactivate") &&
          !res.config.url.includes("reject") &&
          !res.config.url.includes("confirm") &&
          !res.config.url.includes("disconfirm") &&
          !res.config.url.includes("/close")
        ) {
          SuccessToast(res?.data?.data?.message ?? "تم التعديل بنجاح");
          dispatch({ type: "RELOAD" });
          navigate(-1, { replace: true });
        } else {
          if (res.config.url.includes("notifications/read")) {
          } else {
            SuccessToast(res?.data?.data?.message ?? "تم التعديل بنجاح");
            dispatch({ type: "RELOAD" });
          }
        }
      } else if (
        res.config.method == "post" &&
        !res.config.url.includes("login")
      ) {
        if (
          !res.config.url.includes("feature") &&
          !res.config.url.includes("about-us")
        ) {
          SuccessToast(res?.data?.data?.message ?? "تمت الاضافة بنجاح");
          dispatch({ type: "RELOAD" });
          navigate(-1, { replace: true });
        } else {
          SuccessToast(res?.data?.data?.message ?? "تمت الاضافة بنجاح");
          dispatch({ type: "RELOAD" });
        }
      }
      // if (res.config.url.includes("approve") && res.config.method == "put") {
      //   SuccessToast("تمت الموافقة علي التعليق");
      //   dispatch({ type: "RELOAD" });
      // }
      // if (
      //   res.config.url.includes("disapprove") &&
      //   res.config.method == "patch"
      // ) {
      //   SuccessToast("تمت الغاء الموافقة علي التعليق");
      //   dispatch({ type: "RELOAD" });
      // }
      return res;
    },
    (error) => {
      const err = { ...error };
      if (methods.includes(err.response.config.method.toLowerCase())) {
        dispatch({ type: "BTN_LOADING", payload: false });
      }

      interceptorsErrorsResponse(err, dispatch, navigate);

      return err;
    }
  );
  axiosCall.interceptors.request.use((req) => {
    if (methods.includes(req.method.toLowerCase())) {
      dispatch({ type: "BTN_LOADING", payload: true });
    }
    return req;
  });
};
