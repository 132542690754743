//import moment from 'moment';
import moment from "moment";
import * as Yup from "yup";
import { string } from "yup/lib/locale";
const passwordValidation = Yup.string()
  .when("showEmail", {
    is: true,
    then: Yup.string().required("Must enter email address"),
  })
  .required("يلزم إدخال الرقم السرى")
  .min(6, "الرقم السرى آقل ٦ من حروف أو أرقام")
  .max(255, "الرقم السرى أكثر من ٢٥٥ حروف أو أرقام");
/*  .matches("(?=.*[a-z])", "يلزم على الأقل وجود حرف صغير فى الرقم السرى")
  .matches("(?=.*[0-9])", "يلزم على الأقل وجود رقم فى الرقم السرى")
  .matches("(?=.*[A-Z])", "يلزم على الأقل وجود حرف كبير فى الرقم السرى"); */

export const formikFieldValidation = (touched, errors) => {
  return (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? errors[fieldName] : false;
  };
};
export const setBackEndErrors = (errorsObject, setErrorFunction) => {
  console.log("in here");
  for (let [key, value] of Object.entries(errorsObject ?? {})) {
    setErrorFunction(key, value[0]);
    console.log(key, value);
  }
};
export const validationSchema = () => {
  return {
    contactreply: {
      reply: Yup.string().required("يلزم ادخال الرد "),
    },
    farming: {
      type: Yup.string().required("يلزم ادخال نوع التربية"),
    },
    breed: {
      breed_type: Yup.string().required("يلزم ادخال نوع السلالة"),

      poultry_farming_average: Yup.string()
        .matches(
          "(^(-)|([\u0660-\u0669-9 0-9]).*[\u0660-\u0669-9 0-9]|(^[\u0660-\u0669-9 0-9]))+$",
          "يلزم ان يكون رقم"
        )
        .required("يلزم ادخال متوسط مدة التربية"),
      poultry_farming_maximum: Yup.string()
        .matches(
          "(^(-)|([\u0660-\u0669-9 0-9]).*[\u0660-\u0669-9 0-9]|(^[\u0660-\u0669-9 0-9]))+$",
          "يلزم ان يكون رقم"
        )
        .required("يلزم ادخال أقصى مدة للتربية"),
    },
    facility: {
      facility: Yup.string().required("يلزم ادخال المرفق"),
    },
    company: {
      type: Yup.string().required("يلزم ادخال نوغ الشركة"),
    },
    rating: {
      dimension: Yup.string().required("يلزم ادخال اسم التقييم"),
    },
    addBalance: {
      value_for_trader: Yup.string()
        .required("يلزم ادخال الرصيد")
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم"),
      description: Yup.string().required("يلزم ادخال الوصف"),
    },
    addPoints: {
      amount: Yup.string()
        .matches(
          "(^(-)|([\u0660-\u0669-9 0-9]).*[\u0660-\u0669-9 0-9]|(^[\u0660-\u0669-9 0-9]))+$",
          "يلزم ان يكون رقم"
        )
        .required("يلزم ادخال النقاط"),
      description: Yup.string().required("يلزم ادخال الوصف"),
    },
    tawreedSettings: {
      normal_price: Yup.string()
        .matches(
          "(^([\u0660-\u0669-9 0-9]).*[\u0660-\u0669-9 0-9]|(^[\u0660-\u0669-9 0-9]))+$",
          "يلزم ان يكون رقم"
        )
        .required("يلزم ادخال السعر العادى"),
      featured_price: Yup.string()
        .matches(
          "(^([\u0660-\u0669-9 0-9]).*[\u0660-\u0669-9 0-9]|(^[\u0660-\u0669-9 0-9]))+$",
          "يلزم ان يكون رقم"
        )
        .required("يلزم ادخال السعر المتميز"),
      expiration_in_days: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال مدة انتهاء الصلاحية"),
    },
    AddTimeLimtedOffer: {
      points: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال النقاط"),
      valid_from: Yup.string().required("يلزم ادخال بداية الصلاحية"),
      valid_until: Yup.string().required("يلزم ادخال نهاية الصلاحية"),
      description: Yup.string().required("يلزم ادخال الوصف"),
    },
    editTimeLimtedOffer: {
      points: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال النقاط"),
      valid_until: Yup.string().required("يلزم ادخال نهاية الصلاحية"),
      description: Yup.string().required("يلزم ادخال الوصف"),
    },
    traderLevels: {
      name: Yup.string().required(" يلزم ادخال اسم الشريحة"),
      from_number: Yup.string().required("يلزم ادخال من عدد"),
      to_number: Yup.string().required("يلزم ادخال إلى عدد"),
      discount: Yup.string()
        .matches(
          "^([\u0660-\u0669-9 0-9]*((.?[\u0660-\u0669-9 0-9]+)|[\u0660-\u0669-9 0-9]))$",
          "يلزم ان يكون رقم"
        )

        .required("يلزم ادخال نسبه الخصم"),
    },
    dajinType: {
      type: Yup.string().required(" يلزم ادخال النوع"),
      trader_commission: Yup.string()
        .matches(
          "^([\u0660-\u0669-9 0-9]*((.?[\u0660-\u0669-9 0-9]+)|[\u0660-\u0669-9 0-9]))$",
          "يلزم ان يكون رقم"
        )
        .required("يلزم ادخال عمولة التاجر"),
      farmer_commission: Yup.string()
        .matches(
          "^([\u0660-\u0669-9 0-9]*((.?[\u0660-\u0669-9 0-9]+)|[\u0660-\u0669-9 0-9]))$",
          "يلزم ان يكون رقم"
        )
        .required("يلزم ادخال عمولة المربى"),
      image: Yup.string()
        .typeError("يجب ان يدخل بالطريقة الصحيحة")
        .required(" يلزم ادخال الصورة"),
    },
    addGift: {
      points: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال النقاط"),
      description: Yup.string().required("يلزم ادخال الوصف "),
      no_available: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال الغير متاح "),
      image: Yup.string().required("يلزم ادخال الصورة "),
    },
    login: {
      email: Yup.string(),
      password: Yup.string(),
      remember: Yup.number(),
    },
    createOfferFalse: {
      count: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم إدخال العدد"),
      /*  age: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم إدخال العمر"), */
      average_weight: Yup.string()
        .matches(
          "^([\u0660-\u0669-9 0-9]*((.?[\u0660-\u0669-9 0-9]+)|[\u0660-\u0669-9 0-9]))$",
          "يلزم ان يكون رقم"
        )
        .required("يلزم إدخال متوسط الوزن"),
      dajin_health_id: Yup.string().required("يلزم إدخال الحالة الصحية"),
      price: Yup.number().typeError("يلزم ادخال رقم"),
      // .required("يلزم إدخال السعر"),
    },
    createOfferTrue: {
      count: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم إدخال العدد"),
    },

    addTrader: {
      tawreed: Yup.string()
        .typeError("يلزم ادخال")
        .required("يلزم ادخال النوع")
        .nullable(),
      // avatar: dataSource?.image ?? "",
      name: Yup.string()
        .required("يلزم إدخال الاسم")
        .min(3, "الاسم علي الاقل ٣ احرف"),
      email: Yup.string().required("يلزم إدخال البريد الإلكترونى"),
      password: Yup.string().required("يلزم إدخال الرقم السرى"),
      password_confirmation: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "غير مطابق لكلمة المرور"
        ),
      }),
      mobile_number: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("رقم الموبايل مطلوب")
        .min(3, "رقم الموبايل غير صحيح"),
      facebook_account: Yup.string(),

      city_id: Yup.string().required("يلزم إدخال المدينة "),
      governorate_id: Yup.string().required("يلزم إدخال المحافظة "),
      work_days: Yup.array()
        .typeError("يلزم ادخال ايام العمل")
        .required("يلزم إدخال ايام العمل "),
      work_areas: Yup.array()
        .typeError("يلزم إدخال مناطق العمل ")
        .required("يلزم إدخال مناطق العمل "),
      whatsapp_number: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("رقم الواتساب مطلوب"),
      daily_loading_capacity: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال سعة التحميل التقريبية"),
      village: Yup.string(),
      full_address: Yup.string(),
    },
    editTrader: {
      // avatar: dataSource?.image ?? "",
      tawreed: Yup.string()
        .typeError("يلزم ادخال")
        .required("يلزم ادخال النوع")
        .nullable(),
      name: Yup.string()
        .required("يلزم إدخال الاسم")
        .min(3, "الاسم علي الاقل ٣ احرف"),
      email: Yup.string().required("يلزم إدخال البريد الإلكترونى"),

      mobile_number: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("رقم الموبايل مطلوب")
        .min(3, "رقم الموبايل غير صحيح"),
      facebook_account: Yup.string(),

      city_id: Yup.string().required("يلزم إدخال المدينة "),
      governorate_id: Yup.string().required("يلزم إدخال المحافظة "),
      work_days: Yup.array()
        .typeError("يلزم ادخال ايام العمل")
        .required("يلزم إدخال ايام العمل "),
      work_areas: Yup.array()
        .typeError("يلزم إدخال مناطق العمل ")
        .required("يلزم إدخال مناطق العمل "),
      whatsapp_number: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("رقم الواتساب مطلوب"),
      daily_loading_capacity: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال سعة التحميل التقريبية"),
      village: Yup.string(),
      full_address: Yup.string(),
    },
    addFarmer: {
      // avatar: dataSource?.image ?? "",
      name: Yup.string()
        .required("يلزم إدخال الاسم")
        .min(3, "الاسم علي الاقل ٣ احرف"),
      email: Yup.string().required("يلزم إدخال البريد الإلكترونى"),
      password: Yup.string().required("يلزم إدخال الرقم السرى"),
      password_confirmation: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "غير مطابق لكلمة المرور"
        ),
      }),
      village: Yup.string(),
      full_address: Yup.string(),
      mobile_number: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("رقم الموبايل مطلوب"),
      city_id: Yup.number()
        .typeError("يلزم ان يكون رقم")
        .required("يلزم إدخال المدينة"),
      governorate_id: Yup.string().required("يلزم إدخال المحافظه"),
      facebook_account: Yup.string(),
      interests: Yup.array().typeError("يلزم ادخال البيانات"),
      activity_type_id: Yup.number().typeError("يلزم ان يكون رقم"),
      whatsapp_number: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("رقم الواتساب مطلوب"),
      farms_count: Yup.string().matches(
        "^[\u0660-\u0669-9 0-9]+$",
        "يلزم ان يكون رقم"
      ),
      farms_capacity_id: Yup.number().typeError("يلزم ان يكون رقم"),
    },
    editFarmer: {
      // avatar: dataSource?.image ?? "",
      name: Yup.string()
        .required("يلزم إدخال الاسم")
        .min(3, "الاسم علي الاقل ٣ احرف"),
      email: Yup.string().required("يلزم إدخال البريد الإلكترونى"),

      village: Yup.string(),
      full_address: Yup.string(),
      mobile_number: Yup.string()
        .required("رقم الموبايل مطلوب")
        .min(3, "رقم الموبايل غير صحيح"),
      city_id: Yup.number()
        .typeError("يلزم ان يكون رقم")
        .required("يلزم إدخال المدينة"),
      governorate_id: Yup.string().required("يلزم إدخال المحافظة "),
      facebook_account: Yup.string(),
      interests: Yup.array().typeError("يلزم ادخال البيانات"),
      activity_type_id: Yup.number().typeError("يلزم ان يكون رقم"),
      whatsapp_number: Yup.string().required("رقم الواتساب مطلوب"),
      farms_count: Yup.string().matches(
        "^[\u0660-\u0669-9 0-9]+$",
        "يلزم ان يكون رقم"
      ),
      farms_capacity_id: Yup.number().typeError("يلزم ان يكون رقم"),
    },
    commentsScreen: {
      email: Yup.string().required("يلزم ادخال البريد الالكترونى"),
      name: Yup.string().required("يلزم ادخال الاسم"),
      comment: Yup.string(),
      date: Yup.string(),
      time: Yup.string(),
      approved: Yup.string(),
    },
    createCourse: {
      title: Yup.string().required("يلزم ادخال العنوان"),
      category_id: Yup.string().required("يلزم ادخال القسم"),
      description: Yup.string().required("يلزم ادخال الوصف"),
      instructor_id: Yup.string().required("يلزم ادخال المدرب"),
      image: Yup.string().required("يلزم ادخال الصورة "),
      sponsor_logo: Yup.string().required("يلزم ادخال الصورة "),
    },

    createInstructor: {
      name: Yup.string().required("يلزم ادخال العنوان"),
      certified: Yup.string().required("يلزم ادخال الاعتماد"),
      description: Yup.string().required("يلزم ادخال الوصف"),
      image: Yup.string().required("يلزم ادخال الصورة"),
    },
    createArticleNnews: {
      title: Yup.string().required("يلزم ادخال العنوان"),
      category_id: Yup.string().required("يلزم ادخال القسم"),
      date: Yup.string(),
      time: Yup.string(),
      allow_comments: Yup.string().required("يلزم اختيار الموافقة"),
      content: Yup.string().required("يلزم ادخال المحتوى"),

      image: Yup.string().required("يلزم ادخال الصورة"),
    },
    createNews: {
      image: Yup.string().required("يلزم ادخال الصورة").nullable(),
      title: Yup.string().required("يلزم ادخال العنوان"),
      category_id: Yup.number()
        .typeError("يلزم ان يكون رقم")

        .nullable()
        .required("يلزم ادخال القسم"),
      date: Yup.string(),
      time: Yup.string(),
      allow_comments: Yup.number()
        .typeError("يلزم ان يكون رقم")

        .nullable()
        .required("يلزم اختيار الموافقة"),
      content: Yup.string().required("يلزم ادخال المحتوى"),
    },
    housingFarm: {
      dajin_id: Yup.string().required("يلزم ادخال نوع الداجن"),
      company_id: Yup.string().required("يلزم ادخال الشركة"),
      total_count: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال العدد"),
      expected_selling_date: Yup.string().required(
        "يلزم ادخال وقت البيع المتوقع"
      ),
      entry_date: Yup.string().required("يلزم ادخال تاريخ الادخال"),
      dajin_breed_id: Yup.string().required("يلزم ادخال نوع السلالة"),
    },
    createLectures: {
      image: Yup.string().required("يلزم ادخال الصورة"),
      title: Yup.string().required("يلزم ادخال العنوان"),
      url: Yup.string()
        .required("يلزم ادخال القسم")
        .matches(
          /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/,
          "الرابط مكتوب بطريقة خطأ"
        ),

      minutes: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال مدة المحاضرة"),
    },
    accountDetails: {
      status_id: Yup.string(),
      featured: Yup.string(),
    },
    users: {
      name: Yup.string().required(" يلزم ادخال الاسم "),
      email: Yup.string().required("يلزم ادخال البريد الالكترونى"),
      roles: Yup.array().typeError("يلزم ادخاله بالطريقة الصحيحة"),
      mobile_number: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال "),
      password: Yup.string()
        .min(8, "الرقم السرى آقل ٦ من حروف أو أرقام")
        .max(255, "الرقم السرى أكثر من ٢٥٥ حروف أو أرقام")
        .matches("(?=.*[0-9])", "يلزم على الأقل وجود رقم فى الرقم السرى")
        .matches("(?=.*[A-Z])", "يلزم على الأقل وجود حرف كبير فى الرقم السرى")
        .required(" يلزم ادخال كلمة المرور"),
      password_confirmation: Yup.string()
        .min(8, "الرقم السرى آقل ٦ من حروف أو أرقام")
        .max(255, "الرقم السرى أكثر من ٢٥٥ حروف أو أرقام")
        .matches("(?=.*[0-9])", "يلزم على الأقل وجود رقم فى الرقم السرى")
        .matches("(?=.*[A-Z])", "يلزم على الأقل وجود حرف كبير فى الرقم السرى")
        .required("يلزم تأكيد كلمة المرور ")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "غير مطابق لكلمة المرور"
          ),
        }),
    },
    editUsers: {
      roles: Yup.array().typeError("يلزم ادخاله بالطريقة الصحيحة"),
    },
    aboutUs: {
      email: Yup.string(),
      linkedin: Yup.string(),
      instagram: Yup.string(),
      facebook: Yup.string(),
      youtube: Yup.string(),
      mobile_number: Yup.string(),
      image: Yup.string(),
      description: Yup.string(),
    },
    addFarm: {
      total_count: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .nullable(),
      dajin_id: Yup.string(),

      name: Yup.string().required("يلزم ادخال الاسم").nullable(),
      area: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .required("يلزم ادخال المساحة")
        .nullable(),
      dajin_breed_id: Yup.number().typeError("يلزم ادخال رقم").nullable(),
      /*  poultry_farming_expected_period: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .nullable(), */
      village: Yup.string().required("يلزم ادخال الحى").nullable(),
      full_address: Yup.string().nullable(),
      photo: Yup.string().nullable(),

      ventilation_type_id: Yup.number()
        .typeError("يلزم ان يكون رقم")

        .nullable(),
      city_id: Yup.number()
        .typeError("يلزم ان يكون رقم")
        .required("يلزم ادخال المدينة")
        .nullable(),
      governorate_id: Yup.number()
        .typeError("يلزم ان يكون رقم")
        .nullable()
        .required("يلزم إدخال المحافظة "),
      rented: Yup.string().nullable(),
      facilities: Yup.array().typeError("يلزم ان يكون متعدد").nullable(),
      farming_type_id: Yup.number()
        .typeError("يلزم ادخال رقم")
        .required("يلزم ادخال نوع التربية")
        .nullable(),
      farms_capacity_id: Yup.number()
        .typeError("يلزم ان يكون رقم")
        .required("يلزم ادخال سعة العنبر")
        .nullable(),
      license_number: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .when("licensed", {
          is: 1,
          then: Yup.string()
            .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
            .required("يلزم ادخال رقم الترخيص"),
          otherwise: (schema) => schema,
        }),

      rental_start_date: Yup.string().nullable(),
      rental_end_date: Yup.string().nullable(),
    },
    updateBonusPoints: {
      // active: Yup.string().nullable(),
      points: Yup.string().matches(
        "^([\u0660-\u0669-9 0-9]*((.?[\u0660-\u0669-9 0-9]+)|[\u0660-\u0669-9 0-9]))$",
        "يلزم ان يكون رقم"
      ),
      rating_value: Yup.string()
        .matches("^[\u0660-\u0669-9 0-9]+$", "يلزم ان يكون رقم")
        .nullable(),
    },
    notification: {
      title: Yup.string().required("يلزم ادخال العنوان").nullable(),
      description: Yup.string().required("يلزم ادخال الوصف").nullable(),
      publishTime: Yup.string().required("يلزم ادخال وقت النشر").nullable(),
      publishDate: Yup.string().required("يلزم ادخال تاريخ النشر").nullable(),
      target: Yup.string().required("يلزم ادخال الجهة").nullable() ?? "",
      publish_at: Yup.string().typeError("يلزم ان يكون تاريخ"),
    },
  };
};

export const initialValues = (dataSource) => {
  return {
    notification: {
      title: dataSource?.title ?? "",
      description: dataSource?.description ?? "",
      publishTime: dataSource?.publish_at?.split(" ")[1] ?? "",
      publishDate: dataSource?.publish_at?.split(" ")[0] ?? "",
      target: dataSource?.target ?? "",
      publish_at: dataSource?.publish_at ?? "",
    },
    tawreedSettings: {
      normal_price: dataSource?.normal_price ?? "",
      featured_price: dataSource?.featured_price ?? "",
      expiration_in_days: dataSource?.expiration_in_days ?? "",
    },
    contactreply: {
      reply: dataSource?.reply ?? "",
    },
    farming: {
      type: dataSource?.type ?? "",
    },
    breed: {
      breed_type: dataSource?.breed_type ?? "",
      poultry_farming_average: dataSource?.poultry_farming_average ?? "",
      poultry_farming_maximum: dataSource?.poultry_farming_maximum ?? "",
    },
    updateBonusPoints: {
      active: dataSource?.active ?? false,
      points: dataSource?.points ?? "",
      rating_value: dataSource?.rating_value ?? "",
    },
    facility: {
      facility: dataSource?.facility ?? "",
    },
    company: {
      type: dataSource?.type ?? "",
    },
    rating: {
      dimension: dataSource?.dimension ?? "",
    },
    addBalance: { balance: "", description: "" },
    addPoints: { amount: "", description: "" },

    traderLevels: {
      name: dataSource?.name ?? "",
      from_number: dataSource?.from_number ?? "",
      to_number: dataSource?.to_number ?? "",
      discount: dataSource?.discount ?? "",
    },
    dajinType: {
      type: dataSource?.type ?? "",
      trader_commission: dataSource?.trader_commission ?? "",
      farmer_commission: dataSource?.farmer_commission ?? "",
      image: dataSource?.image_url ?? "",
    },
    addGift: {
      points: dataSource?.points ?? "",
      no_available: dataSource?.no_available ?? "",
      description: dataSource?.description ?? "",
      image: dataSource?.image_url ?? "",
    },

    addFarm: {
      licensed:
        dataSource?.farm?.licensed != null
          ? dataSource?.farm?.licensed === false
            ? 0
            : 1
          : "",

      name: dataSource?.farm?.name ?? "",
      area: dataSource?.farm?.area ?? "",
      total_count: dataSource?.farm_state?.data?.total_count ?? "",
      dajin_id: dataSource?.farm?.breed?.dajin?.id ?? "",

      dajin_breed_id: dataSource?.farm?.breed?.id ?? "",
      /*   poultry_farming_expected_period:
        dataSource?.farm?.poultry_farming_expected_period ?? "",
      */ village: dataSource?.farm?.village ?? "",
      full_address: dataSource?.farm?.full_address ?? "",
      photo: dataSource?.farm?.photo ?? "",

      ventilation_type_id: dataSource?.farm?.ventilation_type?.id ?? "",
      city_id: dataSource?.farm?.city?.id ?? "",
      governorate_id: dataSource?.farm?.city?.governorate?.id ?? "",
      rented:
        dataSource?.farm?.rented != null
          ? dataSource?.farm?.rented == false
            ? 0
            : 1
          : 1,
      facilities: dataSource?.farm?.facilities?.map((item) => item?.id) ?? [],
      farming_type_id: dataSource?.farm?.farming_type?.id ?? "",
      farms_capacity_id: dataSource?.farm?.capacity?.id ?? "",
      license_number: dataSource?.farm?.license_number ?? "",

      rental_start_date: dataSource?.farm?.rental_start_date
        ? moment(dataSource?.farm?.rental_start_date).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      rental_end_date: dataSource?.farm?.rental_end_date
        ? moment(dataSource?.farm?.rental_end_date).format("YYYY-MM-DD")
        : moment(
            moment(new Date(), "YYYY-MM-DD").add(3, "years").toDate()
          ).format("YYYY-MM-DD"),
    },

    createOfferFalse: {
      count: dataSource?.count ?? "",
      /*       age: dataSource?.age ?? "",
       */ average_weight: dataSource?.average_weight ?? "",
      dajin_health_id: dataSource?.dajin_health?.id ?? "",
      price: dataSource?.requested_price ?? "",
    },
    createOfferTrue: {
      count: dataSource?.count ?? "",
    },
    addTrader: {
      tawreed:
        dataSource?.tawreed == undefined
          ? ""
          : dataSource?.tawreed == true
          ? 1
          : 0,

      avatar: dataSource?.avatar ?? "",
      name: dataSource?.name ?? "",
      email: dataSource?.email ?? "",
      password: dataSource?.password ?? "",
      password_confirmation: dataSource?.password_confirmation ?? "",
      mobile_number: dataSource?.mobile_number ?? "",
      city_id: dataSource?.city?.id ?? "",
      governorate_id: dataSource?.city?.governorate?.id ?? "",
      work_days: dataSource?.work_days?.map((item) => item?.id) ?? null,
      work_areas: dataSource?.work_areas?.map((item) => item?.id) ?? null,
      whatsapp_number: dataSource?.whatsapp_number ?? "",
      daily_loading_capacity: dataSource?.daily_loading_capacity ?? "",
      village: dataSource?.village ?? "",
      full_address: dataSource?.full_address ?? "",
      facebook_account: dataSource?.facebook_account ?? "",
    },
    editTrader: {
      tawreed:
        dataSource?.tawreed == undefined
          ? ""
          : dataSource?.tawreed == true
          ? 1
          : 0,

      avatar: dataSource?.avatar ?? "",
      name: dataSource?.name ?? "",
      email: dataSource?.email ?? "",
      mobile_number: dataSource?.mobile_number ?? "",
      city_id: dataSource?.city?.id ?? "",
      governorate_id: dataSource?.city?.governorate?.id ?? "",
      work_days: dataSource?.work_days?.map((item) => item?.id) ?? null,
      work_areas: dataSource?.work_areas?.map((item) => item?.id) ?? null,
      whatsapp_number: dataSource?.whatsapp_number ?? "",
      daily_loading_capacity: dataSource?.daily_loading_capacity ?? "",
      village: dataSource?.village ?? "",
      full_address: dataSource?.full_address ?? "",
      facebook_account: dataSource?.facebook_account ?? "",
    },
    addFarmer: {
      avatar: dataSource?.avatar ?? "",
      name: dataSource?.name ?? "",
      email: dataSource?.email ?? "",
      password: dataSource?.password ?? "",
      password_confirmation: dataSource?.password_confirmation ?? "",
      village: dataSource?.village ?? "",
      full_address: dataSource?.full_address ?? "",
      mobile_number: dataSource?.mobile_number ?? "",
      city_id: dataSource?.city?.id ?? "",
      governorate_id: dataSource?.city?.governorate?.id ?? "",
      facebook_account: dataSource?.facebook_account ?? "",
      interests: dataSource?.interests?.map((item) => item?.id) ?? [],
      activity_type_id: dataSource?.activity_type?.id ?? "",
      farms_capacity_id: dataSource?.farms_capacity?.id ?? "",
      farms_count: dataSource?.farms_count ?? "",
      whatsapp_number: dataSource?.whatsapp_number ?? "",
    },
    editFarmer: {
      avatar: dataSource?.avatar ?? "",
      name: dataSource?.name ?? "",
      email: dataSource?.email ?? "",
      village: dataSource?.village ?? "",
      full_address: dataSource?.full_address ?? "",
      mobile_number: dataSource?.mobile_number ?? "",
      city_id: dataSource?.city?.id ?? "",
      governorate_id: dataSource?.city?.governorate?.id ?? "",
      facebook_account: dataSource?.facebook_account ?? "",
      interests: dataSource?.interests?.map((item) => item?.id) ?? [],
      activity_type_id: dataSource?.activity_type?.id ?? "",
      farms_capacity_id: dataSource?.farms_capacity?.id ?? "",
      farms_count: dataSource?.farms_count ?? "",

      whatsapp_number: dataSource?.whatsapp_number ?? "",
    },
    accountDetails: {
      status_id: dataSource?.status ?? 0,
      featured: dataSource?.featured ?? 0,
    },
    login: {
      email: "",
      password: "",
      remember: 0,
    },
    AddTimeLimtedOffer: {
      points: dataSource?.points ?? "",

      valid_from: dataSource?.valid_from
        ? moment(dataSource?.valid_from).format("YYYY-MM-DD")
        : "",
      valid_until: dataSource?.valid_until
        ? moment(dataSource?.valid_until).format("YYYY-MM-DD")
        : "",
      description: dataSource?.description ?? "",
    },
    editTimeLimtedOffer: {
      points: dataSource?.points ?? "",
      valid_from: dataSource?.valid_from
        ? moment(dataSource?.valid_from).format("YYYY-MM-DD")
        : "",
      valid_until: dataSource?.valid_until
        ? moment(dataSource?.valid_until).format("YYYY-MM-DD")
        : "",
      description: dataSource?.description ?? "",
    },
    housingFarm: {
      dajin_id: "",
      company_id: "",
      total_count: "",
      expected_selling_date: "",
      entry_date: "",
      dajin_breed_id: "",
    },
    commentsScreen: {
      email: dataSource?.user?.email ?? "",
      name: dataSource?.user?.name ?? "",
      comment: dataSource?.content ?? "",
      date: moment(dataSource?.created_at).format("yyyy-MM-DD") ?? "",
      time: moment(dataSource?.created_at).format("HH:mm") ?? "",

      approved:
        dataSource?.approved == undefined
          ? ""
          : dataSource?.approved == true
          ? 1
          : 0,
    },
    createLectures: {
      title: dataSource?.title ?? "",
      url: dataSource?.url ?? "",
      image: dataSource?.image_url ?? "",
      minutes: dataSource?.minutes ?? "",
    },
    createArticleNnews: {
      title: dataSource?.title ?? "",
      category_id: dataSource?.category?.id ?? "",
      date: moment(dataSource?.publish_at).format("yyyy-MM-DD") ?? "",
      time: moment(dataSource?.publish_at).format("HH:mm") ?? "",
      image: dataSource?.image_url ?? "",
      allow_comments:
        dataSource?.allow_comments == undefined
          ? ""
          : dataSource?.allow_comments == true
          ? 1
          : 0,

      content: dataSource?.content ?? "",
    },
    createNews: {
      image: dataSource?.image_url ?? "",

      title: dataSource?.title ?? "",
      category_id: dataSource?.category?.id ?? "",
      date: moment(dataSource?.publish_at).format("yyyy-MM-DD") ?? "",
      time: moment(dataSource?.publish_at).format("HH:mm") ?? "",
      allow_comments:
        dataSource?.allow_comments == undefined
          ? ""
          : dataSource?.allow_comments == true
          ? 1
          : 0,

      content: dataSource?.content ?? "",
    },
    createInstructor: {
      name: dataSource?.name ?? "",
      certified: dataSource?.certified === true ? 1 : 0,
      // dataSource?.certified == undefined
      //   ? ""
      //   : dataSource?.certified == true
      //     ? 1
      //     : 0,
      description: dataSource?.description ?? "",
      image: dataSource?.image_url ?? "",
    },
    createCourse: {
      description: dataSource?.description ?? "",
      title: dataSource?.title ?? "",
      category_id: dataSource?.category?.id ?? "",
      image: dataSource?.image_url ?? "",
      sponsor_logo: dataSource?.sponsor_logo_url ?? "",
      instructor_id: dataSource?.instructor?.id ?? "",
    },
    users: {
      name: dataSource?.name ?? "",
      email: dataSource?.email ?? "",
      mobile_number: dataSource?.mobile_number ?? "",
      roles: dataSource?.roles?.map((item) => item?.id) ?? null ?? [],
      password: "",
      password_confirmation: "",
    },
    editUsers: {
      roles: dataSource?.roles?.map((item) => item?.id) ?? null ?? [],
    },
    aboutUs: {
      email: dataSource?.email ?? "",
      linkedin: dataSource?.linkedin ?? "",
      instagram: dataSource?.instagram ?? "",
      facebook: dataSource?.facebook ?? "",
      youtube: dataSource?.youtube ?? "",
      mobile_number: dataSource?.mobile_number ?? "",
      image: dataSource?.image ?? "",
      description: dataSource?.description ?? "",
    },
  };
};

export const formikSetup = (
  initialValues,
  sourceOfIntailData, //could be undefined
  validationSchema,
  pageName,
  submitFunction
) => {
  return {
    enableReinitialize: true,
    initialValues: {
      ...initialValues(sourceOfIntailData)[pageName],
    },
    validationSchema: Yup.object({ ...validationSchema(Yup)[pageName] }),
    onSubmit: submitFunction,
  };
};
