import { combineReducers } from "redux";
import news from "./news";
import login from "./login";
import reload from "./reload";
import loading from "./BTNLoading";
import settings from "./settings";

export default combineReducers({
  news,
  login,
  reload,
  loading,
  settings
});
