import ReactPaginate from "react-paginate";
import "./paginationStyles.css";
const AppPaginate = ({
  currentPage = 1,
  pageCount = 7,
  setCurrentPage,
  previousLabel,
  nextLabel,
}) => {
  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
  };
  return (
    <div className="justify-content-end py-3 d-flex w-100">
      <div style={{ backgroundColor: "white", padding: 5, borderRadius: 10 }}>
        <ReactPaginate
          previousLabel={previousLabel ?? "<<"}
          nextLabel={nextLabel ?? ">>"}
          forcePage={currentPage - 1}
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </div>
  );
};

export default AppPaginate;
