import React from "react";

function PhoneIcon({ width = "35.267", height = "35.267" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35.267 35.267"
    >
      <g>
        <path
          fill="#114634"
          d="M17.634 0a17.634 17.634 0 1017.633 17.634A17.653 17.653 0 0017.634 0zm0 0"
          data-name="Path 38774"
        ></path>
        <g transform="translate(8.287 8.287)">
          <g data-name="Group 38553">
            <g fill="#fff" data-name="Group 38552">
              <path
                d="M18.33 14.864l-2.072-1.382-1.842-1.228a.816.816 0 00-1.1.178L12.18 13.9a.812.812 0 01-1.037.215 13.273 13.273 0 01-3.706-2.856A13.368 13.368 0 014.581 7.55.812.812 0 014.8 6.514l1.465-1.14a.816.816 0 00.178-1.1L5.249 2.493 3.83.363a.816.816 0 00-1.1-.247L1.094 1.1a2 2 0 00-.908 1.174c-.448 1.632-.538 5.232 5.232 11s9.37 5.68 11 5.232A2 2 0 0017.6 17.6l.982-1.637a.816.816 0 00-.252-1.099z"
                data-name="Path 38778"
              ></path>
              <path
                d="M282.8 62.437a5.485 5.485 0 015.479 5.479.322.322 0 10.645 0 6.13 6.13 0 00-6.124-6.124.322.322 0 100 .645z"
                data-name="Path 38779"
                transform="translate(-272.169 -59.536)"
              ></path>
              <path
                d="M282.8 115.4a3.549 3.549 0 013.545 3.545.322.322 0 00.645 0 4.2 4.2 0 00-4.19-4.19.322.322 0 100 .645z"
                data-name="Path 38780"
                transform="translate(-272.168 -110.566)"
              ></path>
              <path
                d="M282.8 168.368a1.613 1.613 0 011.611 1.611.322.322 0 00.645 0 2.259 2.259 0 00-2.256-2.256.322.322 0 100 .645z"
                data-name="Path 38781"
                transform="translate(-272.169 -161.599)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PhoneIcon;
