import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getTawreedLevels } from "../../../api/store/actions/saga/settings/tawreedSettings";
import { getTraderLevels } from "../../../api/store/actions/saga/settings/traderLevels";
import Button from "../../../reusableComponents/button";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const TawreedSettings = () => {
  const [tawreedSettings, setTawreedSettings] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getTawreedLevels, setTawreedSettings);
  }, [reload]);
  const location = useLocation();
  return tawreedSettings ? (
    <>
      <Link
        to={"updateTawreedSettings"}
        style={{ display: "contents" }}
        state={{
          backgroundLocation: location,
        }}
      >
        <Button width={100}>تعديل</Button>
      </Link>
      <div
        style={{
          marginTop: 10,
          width: "100%",
          border: "1px solid #7ABA4C",
          backgroundColor: "white",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",

            border: "1px dashed black",
            padding: 20,
            marginTop: 10,
          }}
        >
          <p style={{ fontSize: 20 }}> السعر العادى :</p>
          <p style={{ fontSize: 20 }}>
            {tawreedSettings?.data?.normal_price} جم
          </p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",

            border: "1px dashed black",
            padding: 20,
            marginTop: 10,
          }}
        >
          <p style={{ fontSize: 20 }}> السعر المميز :</p>
          <p style={{ fontSize: 20 }}>
            {tawreedSettings?.data?.featured_price} جم
          </p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",

            border: "1px dashed black",
            padding: 20,
            marginTop: 10,
          }}
        >
          <p style={{ fontSize: 20 }}> ايام صلاحية الاعلان :</p>
          <p style={{ fontSize: 20 }}>
            {tawreedSettings?.data?.expiration_in_days + " "}
            {tawreedSettings?.data?.expiration_in_days < 10 ? "ايام" : "يوم"}
          </p>
        </div>
      </div>{" "}
    </>
  ) : (
    <Loading />
  );
};

export default TawreedSettings;
