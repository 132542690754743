import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getArticles } from "../../api/store/actions/saga/articles";
import { getCourses } from "../../api/store/actions/saga/courses";
import { getAllFarmers } from "../../api/store/actions/saga/farmer";
import { getAllSales } from "../../api/store/actions/saga/sales_transactions";
import {
  getAllTraderRatings,
  getAllTraders,
} from "../../api/store/actions/saga/traders";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";

const TraderRatings = ({ searchKey, currentPage, setCurrentPage }) => {
  const { user_id } = useParams();

  const [tradersData, setTradersData] = useState(null);

  useEffect(() => {
    setTradersData(null);

    resolveRequest(getAllTraderRatings, setTradersData, {
      user_id: user_id,
      pageNumber: currentPage,
      params: { rater_name: searchKey },
    });
  }, [currentPage, searchKey]);

  return (
    <>
      {tradersData ? (
        <ListView
          objName={"traderRating"}
          links={{
            show: `traderRatingDetails`,

            query: { show: "", delete: "", edit: "" },
          }}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={tradersData}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TraderRatings;
