import MobileMood from "../../alogrithms/mobileMiddleware";
import Header from "./header";
import SideBar from "./sideBar/sideBar";

const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <div style={{ maxWidth: 250, minWidth: 250 }}>
          <SideBar />
        </div>
        <div style={{ flexGrow: 1, padding: 10 }}>{children}</div>
      </div>
    </>
  );
};

export default MainLayout;
