import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import ListHeader from "../../screenParts/listingViews/listHeader";
import BonusPoints from "./bonusPoints";
import CompaniesTypes from "./companies";
import DajinTypes from "./dajinTypes/dajinTypes";
import FacilitiesTypes from "./facilitiesTypes/facilitiesTypes";
import FarmingTypes from "./farmingTypes/farmingTypes";
import GiftsList from "./gifts";
import SettingRating from "./rating/rating";
import SupportTypes from "./suppotrTypes/supportTypes";
import TraderLevels from "./tarderLevels/traderLevels";
import TawreedSettings from "./tawreedSettings/tawreedSettings";
import TimeLimtedOffer from "./timeLimtedOffer";

const SettingIndex = () => {
  const activeState = useSelector((state) => state?.reload?.active);
  const width = useResponsiveWidth({
    tablet: 50,
    tabletDesktop: 50,
    tabletMobile: 100,
  });
  const content = useResponsiveWidth(
    {
      desktop: "flex-end",
      tablet: "flex-start",
      tabletDesktop: "flex-start",
    },
    "justifyContent"
  );
  console.log(content);
  const [active, setActive] = useState(activeState ?? 1);

  return (
    <div style={{ backgroundColor: "white", borderRadius: 8 }}>
      <ListHeader
        hasFilter={false}
        hasSearch={false}
        hasTitle={false}
        linkType="modal"
        hasAdd={
          active == 1 || active == 8 || active == 10 || active == 11
            ? false
            : true
        }
        addLink={
          active == 2
            ? "createDajin"
            : active == 3
            ? "createFarmingType"
            : active == 4
            ? "createFacility"
            : active == 5
            ? "createCompany"
            : active == 6
            ? "createSupportType"
            : active == 7
            ? "createTraderLevel"
            : active == 9
            ? "addGift"
            : ""
        }
        pages={[
          { id: 1, title: "التقييم" },
          { id: 2, title: "انواع الدواجن" },
          { id: 3, title: "انواع التربية" },
          { id: 4, title: "انواع المرافق" },
          { id: 5, title: "الشركات" },
          { id: 6, title: "مجالات الدعم" },
          { id: 7, title: "شرائح التجار" },
          { id: 8, title: "اعدادات التوريد" },

          { id: 9, title: "الجوائز" },
          { id: 10, title: "النقاط الإضافية" },
          { id: 11, title: "عرض لفترة محدودة" },
        ]}
        responsiveContent={{ ...content }}
        responsiveWidth={{ ...width }}
        active={active}
        setActive={(x) => {
          setActive(x);
        }}
      />
      <>
        {active == 1 ? (
          <SettingRating />
        ) : active == 2 ? (
          <DajinTypes />
        ) : active == 3 ? (
          <FarmingTypes />
        ) : active == 4 ? (
          <FacilitiesTypes />
        ) : active == 5 ? (
          <CompaniesTypes />
        ) : active == 6 ? (
          <SupportTypes />
        ) : active == 7 ? (
          <TraderLevels />
        ) : active == 8 ? (
          <TawreedSettings />
        ) : active == 9 ? (
          <GiftsList />
        ) : active == 10 ? (
          <BonusPoints />
        ) : active == 11 ? (
          <TimeLimtedOffer />
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default SettingIndex;
