import { useEffect, useState } from "react";
import { isObject } from "../alogrithms";

const CustomImage = ({
  width = "100%",
  height = "100%",
  borderRadius = 0,
  src = "",
  alt = "Image",
  style,
}) => {
  console.log(src);
  const newSrc = isObject(src) ? URL.createObjectURL(src) : src;
  return (
    <>
      <img
        loading={"lazy"}
        style={{
          width: width,
          height: height,
          borderRadius: borderRadius,
          objectFit: "fill",
          ...style,
        }}
        src={newSrc}
        alt={alt}
        onError={({ currentTarget }) => {
          currentTarget.src = "/images/placeholder-image.png";
        }}
      />
    </>
  );
};

export default CustomImage;
