import Axios from "axios";
const axiosCall = Axios.create({
  baseURL: "https://api.dajin-platform.com",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

export default axiosCall;
