import { useNavigate } from "react-router";
import BackButton from "./icons";

const TitleAndBack = ({ title, back = true }) => {
  const navigate = useNavigate();
  return (
    <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
      {back && (
        <div
          style={{ marginLeft: 10, cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <BackButton />
        </div>
      )}

      <p
        style={{
          fontFamily: "boldKufi",
          fontSize: 20,
        }}
      >
        {title ?? "title"}
      </p>
    </div>
  );
};
export default TitleAndBack;
