import { useEffect, useState } from "react";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  getFinalPrice,
  getPrices,
  updatePrice,
} from "../../api/store/actions/saga/prices";
import Loading from "../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../reusableComponents/sectionLabel";
import TextInput from "../../reusableComponents/textInput";
import TimeInput from "../../reusableComponents/timeInput";
import TitleAndBack from "../../screenParts/listingViews/titleAndBack";
import PricesItem from "./pricesItem";
import Button from "../../reusableComponents/button";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const PricesIndex = () => {
  const reload = useSelector((state) => state.reload.reload);

  const [difference_range_percentage, setDifference_range_percentage] =
    useState(null);
  /*   const [indicative_price_time, setIndicative_price_time] = useState(null);
   */ const [updatedPrices, setUpdatedPrices] = useState({});
  const [pricesData, setPricesData] = useState(null);
  const [flag, setFlag] = useState(false);

  console.log("updatedPrices", updatedPrices);
  const width = useResponsiveWidth({ desktop: 45, tablet: 45 });
  useEffect(() => {
    resolveRequest(getPrices, setPricesData);
  }, [reload]);

  const submit = async () => {
    console.log("submit", updatedPrices);

    const res = await updatePrice({
      body: {
        prices: [...Object.values(updatedPrices)],
        /*  indicative_price_time:
          indicative_price_time ?? pricesData?.data?.indicative_price_time,
        */ difference_range_percentage:
          difference_range_percentage ??
          pricesData?.data?.difference_range_percentage,
      },
    });
    if (res.code <= 206) {
      setFlag(true);
      setPricesData(res);
    }
  };
  console.log(pricesData);
  return pricesData ? (
    <div
      style={{ backgroundColor: "white", borderRadius: 8, paddingBottom: 50 }}
    >
      <TitleAndBack title={"التسعيير"} back={false} />
      <SectionLabel content={"اسعار البورصة"} />
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ color: "#7ABA4C", fontFamily: "boldKufi", width: "40%" }}>
            نوع الداجن
          </p>
          <p style={{ color: "#7ABA4C", fontFamily: "boldKufi", width: "20%" }}>
            السعر الاسترشادي
          </p>
          <p
            style={{ color: "#7ABA4C", fontFamily: "boldKufi", width: "auto" }}
          >
            السعر النهائي
          </p>
        </div>
        <div>
          {pricesData?.data?.prices?.map((item, index) => {
            console.log("first", pricesData?.data?.prices);
            return (
              <PricesItem
                flag={flag}
                setFlag={setFlag}
                setUpdatedPrices={setUpdatedPrices}
                updatedPrices={updatedPrices}
                id={item?.id}
                dajinId={item?.dajin?.id}
                title={item?.dajin?.type}
                price={item?.indicative_price}
                finalPrice={item?.final_price}
                key={index}
                newsFinalPrice={updatedPrices[item?.id]?.final_price}
              />
            );
          })}
        </div>
      </div>
      <div style={{ marginTop: 40 }}>
        <SectionLabel content={"اعدادات السعر الاسترشادي"} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 30,
            paddingTop: 10,
          }}
        >
          {/*     <TimeInput
            require
            responsiveWidth={width}
            // defaultValue={pricesData?.data?.indicative_price_time}
            value={
              indicative_price_time ?? pricesData?.data?.indicative_price_time
            }
            label={"يتم احتساب السعر الاسترشادي يومياً من الساعة"}
            onChange={(e) => {
              console.log(e.target.value);
              setIndicative_price_time(e.target.value);
            }}
          /> */}
          <TextInput
            require
            // defaultValue={pricesData?.data?.difference_range_percentage * 10}
            value={
              difference_range_percentage ??
              pricesData?.data?.difference_range_percentage
            }
            placeholder={"1 %"}
            responsiveWidth={width}
            label={
              "نطاق احتساب سعر البيع اقل او اكتر من السعر الاسترشادي(نسبة مئوية)"
            }
            onChange={(e) => {
              setDifference_range_percentage(e.target.value);
            }}
          />
        </div>
        <Button onClick={submit} width={"30%"} style={{ marginTop: 30 }}>
          حفظ
        </Button>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default PricesIndex;
