import React from "react";

function CostIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.79"
      height="31.32"
      viewBox="0 0 28.79 31.32"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y1="-0.096"
          y2="1.229"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fe4747"></stop>
          <stop offset="1" stopColor="#fee"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.5"
          x2="0.5"
          y1="0.234"
          y2="1.552"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#b50000"></stop>
          <stop offset="1" stopColor="#ff9c9c"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 80">
        <g data-name="Group 77" transform="translate(0 16.686)">
          <g data-name="Group 76">
            <path
              fill="url(#linear-gradient)"
              d="M65.754 74.221l-.324.267V69.4a1.129 1.129 0 00-2.215 0v5.088l-.324-.267a1.282 1.282 0 00-1.566 0 .8.8 0 000 1.288l2.213 1.821a1.285 1.285 0 001.569 0l2.213-1.821a.8.8 0 000-1.288 1.282 1.282 0 00-1.566 0zm23.712 0a1.282 1.282 0 00-1.566 0l-.324.267V69.4a1.129 1.129 0 00-2.215 0v5.088l-.324-.267a1.282 1.282 0 00-1.566 0 .8.8 0 000 1.288l2.213 1.821a1.285 1.285 0 001.569 0l2.213-1.821a.8.8 0 000-1.288zm-9.641 2.526h-1.108v-5.768a.861.861 0 00-.413-.71 1.285 1.285 0 00-.93-.18 12.308 12.308 0 01-3.958 0 1.284 1.284 0 00-.93.18.861.861 0 00-.413.71v5.769h-1.107a1.149 1.149 0 00-1 .516.784.784 0 00.133.964l4.429 4.554a1.266 1.266 0 001.73 0l4.429-4.554a.784.784 0 00.133-.964 1.149 1.149 0 00-1-.516zm-4.429-7.408z"
              data-name="Path 316"
              transform="translate(-61 -68.489)"
            ></path>
          </g>
        </g>
        <g data-name="Group 79" transform="translate(2.214)">
          <path
            fill="url(#linear-gradient-2)"
            d="M12.18 20.039a13.479 13.479 0 01-8.661-2.977A9.168 9.168 0 010 10.019C0 4.495 5.464 0 12.18 0s12.18 4.495 12.18 10.019-5.46 10.02-12.18 10.02zM8 9.658v1.03h1.75v1.367a2.242 2.242 0 01-1.937 2.287v.987h8v-1.022H9.492a2.514 2.514 0 001.715-2.478v-1.14h2.63V9.658h-2.63V7.934c0-1.489.685-2.244 2.035-2.244a4.381 4.381 0 011.875.417V4.974a5.709 5.709 0 00-1.893-.3 3.605 3.605 0 00-2.505.877A2.912 2.912 0 009.75 7.8v1.858z"
            data-name="Subtraction 1"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default CostIcon;
