import SectionLabel from "../../reusableComponents/sectionLabel";
import { MyEditor } from "../../reusableComponents/editor";
import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import avatar from "../../assets/avatar.jpg";
import { useNavigate } from "react-router-dom";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";

import Loading from "../../reusableComponents/loadingScreen/loading";
import { useParams } from "react-router";
import TextInput from "../../reusableComponents/textInput";
import { getCategories } from "../../api/store/actions/saga/categories";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import MultiSelectDropdown from "../../reusableComponents/dropdown/dropdownMultiSelect";
import { getAllSettings } from "../../api/store/actions/saga/settings";
import {
  createFarmer,
  getFarmerDetails,
  updateFarmer,
} from "../../api/store/actions/saga/farmer";
import { useTablet } from "../salesTransactions/salesDetails";
import { arToEn } from "../../alogrithms";
import { useSelector } from "react-redux";

function AddFarmerScreen({ isEdit }) {
  const { farmer_id } = useParams();
  const [farmerDetails, setFarmerDetails] = useState();
  const [image, setImage] = useState();
  const settingsData = useSelector((state) => state?.settings?.setting);
  const navigate = useNavigate();
  useEffect(() => {
    if (isEdit) {
      resolveRequest(getFarmerDetails, setFarmerDetails, {
        farmer_id: farmer_id,
      });
    }
  }, []);

  const [checked, setChecked] = useState(false);

  console.log("settings,", settingsData);
  const [imageChanged, setImageChanged] = useState(false);
  const submitEdit = async (values) => {
    let body;

    if (!imageChanged) {
      console.log(new Date().toISOString());

      body = {
        name: values?.name,
        email: values?.email,
        //  password: values?.password,
        //  password_confirmation: values?.password_confirmation,
        village: values?.village,
        full_address: values?.full_address,
        mobile_number: `0${arToEn(values?.mobile_number)}`,
        city_id: values?.city_id,
        governorate_id: values?.governorate_id,
        interests: values?.interests,
        activity_type_id: values?.activity_type_id,
        whatsapp_number: `0${arToEn(values?.whatsapp_number)}`,
        farms_count: arToEn(values?.farms_count),
        farms_capacity_id: values?.farms_capacity_id,
        facebook_account: values?.facebook_account,
        fcm_token: "mohamed",
      };
    } else {
      console.log(new Date().toISOString());
      body = {
        name: values?.name,
        email: values?.email,
        // password: values?.password,
        // password_confirmation: values?.password_confirmation,
        avatar: values?.avatar,
        village: values?.village,
        full_address: values?.full_address,
        mobile_number: `0${arToEn(values?.mobile_number)}`,
        city_id: values?.city_id,
        governorate_id: values?.governorate_id,
        interests: values?.interests,
        activity_type_id: values?.activity_type_id,
        whatsapp_number: `0${arToEn(values?.whatsapp_number)}`,
        farms_count: arToEn(values?.farms_count),
        farms_capacity_id: values?.farms_capacity_id,
        facebook_account: values?.facebook_account,
        fcm_token: "mohamed",
      };
    }
    if (values?.facebook_account?.length == 0) {
      delete body?.facebook_account;
    }
    if (values?.full_address?.length == 0) {
      delete body?.full_address;
    }
    if (values?.village?.length == 0) {
      delete body?.village;
    }
    if (values?.farms_capacity_id?.length == 0) {
      delete body?.farms_capacity_id;
    }
    if (values?.interests?.length == 0) {
      delete body?.interests;
    }
    if (values?.activity_type_id?.length == 0) {
      delete body?.activity_type_id;
    }
    if (values?.farms_count?.length == 0) {
      delete body?.farms_count;
    }
    if (values?.name == farmerDetails?.data?.name) {
      delete body?.name;
    }
    if (values?.email == farmerDetails?.data?.email) {
      delete body?.email;
    }
    if (values?.mobile_number == farmerDetails?.data?.mobile_number) {
      delete body?.mobile_number;
    }
    if (values?.whatsapp_number == farmerDetails?.data?.whatsapp_number) {
      delete body?.whatsapp_number;
    }
    if (values?.avatar?.length == 0) {
      delete body?.avatar;
    }
    // resolveRequest(updateFarmer, setFar, { body: body, id: farmer_id });
    let res = await updateFarmer({ body: body, farmer_id: farmer_id });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const submit = async (values) => {
    const body = {
      name: values?.name,
      email: values?.email,
      password: values?.password,
      password_confirmation: values?.password_confirmation,
      avatar: values?.avatar,
      village: values?.village,
      full_address: values?.full_address,
      mobile_number: `0${arToEn(values?.mobile_number)}`,
      city_id: values?.city_id,
      governorate_id: values?.governorate_id,
      interests: values?.interests,
      activity_type_id: values?.activity_type_id,
      whatsapp_number: `0${arToEn(values?.whatsapp_number)}`,
      farms_count: arToEn(values?.farms_count),
      farms_capacity_id: values?.farms_capacity_id,
      facebook_account: values?.facebook_account,
      fcm_token: "mohamed",
    };
    if (values?.facebook_account?.length == 0) {
      delete body?.facebook_account;
    }
    if (values?.avatar?.length == 0) {
      delete body?.avatar;
    }
    if (values?.full_address?.length == 0) {
      delete body?.full_address;
    }
    if (values?.farms_capacity_id?.length == 0) {
      delete body?.farms_capacity_id;
    }
    if (values?.interests?.length == 0) {
      delete body?.interests;
    }
    if (values?.activity_type_id?.length == 0) {
      delete body?.activity_type_id;
    }
    if (values?.farms_count?.length == 0) {
      delete body?.farms_count;
    }
    if (values?.village?.length == 0) {
      delete body?.village;
    }
    // resolveRequest(createArticles, setArticleData, { body: body });
    let res = await createFarmer({ body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };
  console.log("farmerDetails", console.errors);
  const formik = useFormik(
    formikSetup(
      initialValues,
      !isEdit ? undefined : farmerDetails?.data,

      validationSchema,
      isEdit ? "editFarmer" : "addFarmer",
      !isEdit ? submit : submitEdit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log("errors", errors);
  const isValid = formikFieldValidation(touched, errors);

  const width = useResponsiveWidth({
    desktop: 100,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const halfWidth = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const isTablet = useTablet();
  console.log("errors", errors);
  console.log("values", values);

  return (
    <>
      {!isEdit || (isEdit && farmerDetails) ? (
        <div
          style={{
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <form style={{}} onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: isTablet ? "column" : "row",
              }}
            >
              <div style={{ padding: 20, ...halfWidth }}>
                <SectionLabel content={"المعلومات الرئيسية"} />
                <p style={{ marginBottom: 10, marginTop: 10 }}>
                  صورة الملف الشخصى
                </p>
                <div
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#7ABA4C0D",
                    border: "1px dashed #7ABA4C99",
                    height: "10%",
                    display: "flex",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    width={50}
                    height={50}
                    style={{ borderRadius: 500 }}
                    src={
                      isEdit && image != undefined
                        ? image
                        : isEdit
                        ? farmerDetails?.data?.avatar
                        : isEdit && image == undefined
                        ? avatar
                        : !isEdit && image == undefined
                        ? avatar
                        : image
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        color: "#7ABA4C",
                        marginRight: 10,
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        onChange={(e) => {
                          setFieldValue("avatar", e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));

                          setImageChanged(true);
                        }}
                        style={{ display: "none" }}
                      />
                      استعرض
                    </label>
                  </div>
                </div>
                <p style={{ marginBottom: 10, marginTop: 10, color: "red" }}>
                  {isValid("avatar")}
                </p>
                <div style={{ marginTop: 10 }}>
                  <TextInput
                    label={"الاسم"}
                    require
                    placeholder={"اكتب الاسم"}
                    onChange={handleChange("name")}
                    errorMessage={isValid("name")}
                    defaultValue={values["name"]}
                    value={values["name"]}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>البريد الإلكترونى *</p>
                  <TextInput
                    placeholder={"اكتب البريد الإلكترونى"}
                    onChange={handleChange("email")}
                    errorMessage={isValid("email")}
                    defaultValue={values["email"]}
                    value={values["email"]}
                  />
                </div>

                <div style={{ marginTop: 10 }}>
                  <p>الهاتف *</p>
                  <TextInput
                    placeholder={"01xxxxxxxxx"}
                    onChange={handleChange("mobile_number")}
                    errorMessage={isValid("mobile_number")}
                    defaultValue={values["mobile_number"]}
                    value={values["mobile_number"]}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <p style={{ marginRight: 3, marginLeft: 3 }}>
                    هذا الرقم عليه واتس اب *
                  </p>
                  <input
                    type="checkbox"
                    name="dateNow"
                    value={checked}
                    onChange={() => {
                      setChecked((prevState) => !prevState);
                      if (!checked) {
                        setFieldValue("whatsapp_number", values?.mobile_number);
                      } else {
                        setFieldValue(
                          "whatsapp_number",
                          values?.whatsapp_number
                        );
                      }
                    }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>واتس اب *</p>
                  <TextInput
                    readOnly={!checked ? false : true}
                    placeholder={"01xxxxxxxxx"}
                    onChange={handleChange("whatsapp_number")}
                    errorMessage={isValid("whatsapp_number")}
                    defaultValue={values["whatsapp_number"]}
                    value={values["whatsapp_number"]}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>حساب فيسبوك *</p>
                  <TextInput
                    placeholder={"https://facebook.com/username"}
                    onChange={handleChange("facebook_account")}
                    errorMessage={isValid("facebook_account")}
                    value={values["facebook_account"]}
                    defaultValue={values["facebook_account"]}
                  />
                </div>
                {!isEdit && (
                  <>
                    <SectionLabel content={"كلمة المرور"} />
                    <div style={{ marginTop: 10 }}>
                      <p>كلمة المرور *</p>
                      <TextInput
                        password
                        placeholder={"**************"}
                        onChange={handleChange("password")}
                        errorMessage={isValid("password")}
                        defaultValue={values["password"]}
                        value={values["password"]}
                      />
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <p>تأكيد كلمة المرور *</p>
                      <TextInput
                        password
                        placeholder={"**************"}
                        onChange={handleChange("password_confirmation")}
                        errorMessage={isValid("password_confirmation")}
                        defaultValue={values["password_confirmation"]}
                        value={values["password_confirmation"]}
                      />
                    </div>
                  </>
                )}
                {/* <div style={{ marginTop: 10 }}>
                  <p>التفعيل</p>
                  <SelectDropdown
                    placeholder={"اختر"}
                    //responsiveWidth={"100%"}
                  />
                </div> */}
              </div>
              <div
                style={{
                  ...halfWidth,
                  padding: 20,
                }}
              >
                <SectionLabel content={"العنوان"} />
                <div style={{ marginTop: 10 }}>
                  <p>المحافظة *</p>
                  <SelectDropdown
                    responsiveWidth={{
                      ...width,
                    }}
                    placeholder={"اختر"}
                    dataSource={settingsData?.data?.data?.governorates}
                    errorMessage={isValid("governorate_id")}
                    onSelect={(e) => {
                      console.log(e);
                      setFieldValue("governorate_id", e);
                    }}
                    schema={{ name: "name", id: "id" }}
                    selectedItem={values["governorate_id"]}

                    //responsiveWidth={"100%"}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>المدينة *</p>
                  <SelectDropdown
                    responsiveWidth={{
                      ...width,
                    }}
                    placeholder={"اختر"}
                    dataSource={
                      values?.governorate_id
                        ? settingsData?.data?.data?.cities
                        : []
                    }
                    errorMessage={isValid("city_id")}
                    onSelect={(e) => {
                      console.log(e);
                      setFieldValue("city_id", e);
                    }}
                    schema={{ name: "name", id: "id" }}
                    selectedItem={values["city_id"]}
                    dependId={values["governorate_id"]}

                    //responsiveWidth={"100%"}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>حي/ قرية</p>
                  <TextInput
                    placeholder={"اكتب حي/ قرية"}
                    onChange={handleChange("village")}
                    errorMessage={isValid("village")}
                    defaultValue={values["village"]}
                    value={values["village"]}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>الوصف التفصيلي </p>
                  <TextInput
                    placeholder={"اكتب الوصف التفصيلي للعنوان"}
                    onChange={handleChange("full_address")}
                    errorMessage={isValid("full_address")}
                    defaultValue={values["full_address"]}
                    value={values["full_address"]}
                  />
                </div>
                <SectionLabel content={"معلومات النشاط"} />
                <div style={{ marginTop: 10 }}>
                  <p>نوع النشاط</p>
                  <SelectDropdown
                    responsiveWidth={{
                      ...width,
                    }}
                    placeholder={"اختر"}
                    dataSource={
                      settingsData?.data?.data?.user?.farmer?.activity_types
                    }
                    //errorMessage={isValid("governorate_id")}
                    onSelect={(e) => {
                      console.log(e);
                      setFieldValue("activity_type_id", e);
                    }}
                    schema={{ name: "name", id: "id" }}
                    selectedItem={values["activity_type_id"]}

                    //responsiveWidth={"100%"}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p> حجم التربية التقريبي</p>
                  <SelectDropdown
                    responsiveWidth={{
                      ...width,
                    }}
                    placeholder={"اختر"}
                    dataSource={
                      settingsData?.data?.data?.user?.farmer?.farms_capacities
                    }
                    //errorMessage={isValid("governorate_id")}
                    onSelect={(e) => {
                      console.log(e);
                      setFieldValue("farms_capacity_id", e);
                    }}
                    schema={{ name: "capacity", id: "id" }}
                    selectedItem={values["farms_capacity_id"]}

                    //responsiveWidth={"100%"}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p> عدد عنابر التربية</p>
                  {/*     <SelectDropdown
                    responsiveWidth={{
                      ...width,
                    }}
                    placeholder={"اختر"}
                    dataSource={[
                      { id: 1, name: "1" },
                      { id: 2, name: "2" },
                      { id: 3, name: "3" },
                    ]}
                    errorMessage={isValid("farms_count")}
                    onSelect={(e) => {
                      setFieldValue("farms_count", e);
                    }}
                    schema={{ name: "name", id: "id" }}
                    selectedItem={values["farms_count"]}

                    //responsiveWidth={"100%"}
                  /> */}
                  <TextInput
                    placeholder={"0000"}
                    onChange={handleChange("farms_count")}
                    errorMessage={isValid("farms_count")}
                    defaultValue={values["farms_count"]}
                    value={values["farms_count"]}
                  />
                </div>

                <SectionLabel content={"معلومات إضافية"} />
                <div style={{ marginTop: 10 }}>
                  <p> الاهتمامات</p>
                  <MultiSelectDropdown
                    responsiveWidth={{
                      ...width,
                    }}
                    placeholder={"اختر"}
                    dataSource={
                      settingsData?.data?.data?.user?.farmer?.interests
                    }
                    //errorMessage={isValid("governorate_id")}
                    onSelect={(e) => {
                      console.log(e);
                      setFieldValue("interests", e);
                    }}
                    schema={{ name: "name", id: "id" }}
                    selectedItems={values["interests"]}
                  />
                  <p style={{ color: "red" }}>{isValid("interests")}</p>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", marginTop: 20 }}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                type={"reset"}
                backgroundColor="#F4F4F4"
                textColor="#9F9F9F"
                style={{ marginRight: 10, marginLeft: 10, width: "20%" }}
              >
                إلغاء
              </Button>
              <Button
                type={"submit"}
                style={{ width: "20%", marginRight: 10, marginLeft: 10 }}
              >
                {!isEdit ? "إضافة" : "تعديل"}
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default AddFarmerScreen;
