import { objToFormData } from "../../../../../alogrithms/requests";
import axiosCall from "../../../../axios/axiosConfig";
import {
  catchFromat,
  dataFormat,
} from "../../../../middlewares/resolverRequests";

const apiUrl = (id) => `/admin/v1/settings/dajin/types/${id}/breeds`;
export const getBreeds = async ({ dajinId }) => {
  try {
    const data = await axiosCall.get(`${apiUrl(dajinId)}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getBreedDetails = async ({ id, dajinId }) => {
  try {
    const data = await axiosCall.get(`${apiUrl(dajinId)}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const deleteBreeds = async ({ id, dajinId }) => {
  try {
    const data = await axiosCall.delete(`${apiUrl(dajinId)}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateBreed = async ({ dajinId, id, body }) => {
  try {
    const data = await axiosCall.put(`${apiUrl(dajinId)}/${id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const createBreed = async ({ dajinId, body }) => {
  try {
    const data = await axiosCall.post(`${apiUrl(dajinId)}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
