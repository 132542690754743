import React from "react";

function MenuHeader() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
    >
      <rect width="46" height="46" fill="rgba(233,236,255,0)" rx="8"></rect>
      <rect
        width="21"
        height="3"
        fill="#616161"
        data-name="1"
        rx="1.5"
        transform="translate(15 14)"
      ></rect>
      <rect
        width="21"
        height="3"
        fill="#616161"
        data-name="2"
        rx="1.5"
        transform="translate(8 22)"
      ></rect>
      <rect
        width="21"
        height="3"
        fill="#616161"
        data-name="3"
        rx="1.5"
        transform="translate(15 30)"
      ></rect>
    </svg>
  );
}

export default MenuHeader;
