import { newsTypes } from "../../../../utlity/actionTypes";
import axiosCall from "../../../axios/axiosConfig";
import { call, put, takeEvery } from "redux-saga/effects";
import { objToFormData } from "../../../../alogrithms/requests";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";
export const initReq = async () => {
  try {
    let res = await axiosCall.get("/sanctum/csrf-cookie");
    return res;
  } catch (e) {
    console.log(e?.response);
    return catchFromat(e.response);
  }
};

const apiUrl = `/admin/v1/news`;
export const createNews = async ({ body }) => {
  try {
    let data = await axiosCall.post(apiUrl, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("error req", data?.response?.data?.errors);

    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const updateNews = async ({ body, id }) => {
  try {
    let data = await axiosCall.post(`${apiUrl}/${id}`, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getNews = async ({
  pageNumber: pageNumber = 1,
  pageCount: pageCount = 7,
  params: params = {},
}) => {
  try {
    let filters = concatFilters(params);
    let data = await axiosCall.get(
      `${apiUrl}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getNewsDetails = async ({ id }) => {
  console.log(id);
  try {
    const data = await axiosCall.get(`${apiUrl}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

function* fetchUsers(action) {
  try {
    const users = yield call(getNews);
    yield put({ type: newsTypes.GET_NEWS_SUCCESS, users: users });
  } catch (e) {
    yield put({ type: newsTypes.GET_NEWS_FAILED, message: e.message });
  }
}

function* newsSaga() {
  yield takeEvery(newsTypes.GET_NEWS_REQUESTED, fetchUsers);
}

export default newsSaga;

// export function getNews() {
//   return {
//     type: newsTypes.GET_NEWS_REQUESTED,
//   };
// }
