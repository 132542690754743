import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/courses`;
export const createCourses = async ({ body }) => {
  try {
    return await axiosCall.post(apiUrl, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getCourses = async ({
  pageNumber: pageNumber = 1,
  pageCount: pageCount = 7,
  params: params = {},
}) => {
  try {
    let filters = concatFilters(params);
    let data = await axiosCall.get(
      `${apiUrl}?include=lectures&per_page=${pageCount}&page=${pageNumber}&${filters}`
    );
    console.log("COURSES", data);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

const instructorsUrl = `/admin/v1/instructors`;

export const getAllInstructors = async () => {
  try {
    const data = await axiosCall.get(`${instructorsUrl}`);
    console.log("articles :", data);
    return data;
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateCourses = async ({ body, id }) => {
  try {
    return await axiosCall.post(`${apiUrl}/${id}`, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getCoursesDetails = async ({ id }) => {
  console.log(id);
  try {
    return await axiosCall.get(`${apiUrl}/${id}`);
  } catch (e) {
    return catchFromat(e.response);
  }
};
