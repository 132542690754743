import CustomImage from "../reusableComponents/customImage";

const PhotoSelect = ({ src, onChange, title, errorMessage, require }) => {
  return (
    <>
      <p style={{ marginBottom: 10, marginTop: 10 }}> {title} {require ? <span > * </span> : null} </p>
      <div
        style={{
          borderRadius: 10,
          backgroundColor: "#7ABA4C0D",
          border: "1px dashed #7ABA4C99",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        <CustomImage src={src} width={100} height={100} borderRadius={"50%"} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label
            style={{
              color: "#7ABA4C",
            }}
          >
            <input
              type="file"
              name="file"
              accept=".jpg,.jpeg,.gif,.png"
              onChange={onChange}
              style={{ display: "none" }}
            />
            استعرض
          </label>
        </div>
      </div>
      {errorMessage && (
        <p style={{ marginBottom: 10, marginTop: 10, color: "red" }}>
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default PhotoSelect;
