import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getFarmerFarms } from "../../api/store/actions/saga/farms";
import {
  getTraderOrderOffers,
  getTraderOrders,
} from "../../api/store/actions/saga/traders";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import { FarmCard } from "../../screenParts/farms/farmCard";
import ListHeader from "../../screenParts/listingViews/listHeader";
import NoData from "../../screenParts/noData";
import { OrdersRequestsDetailsCard } from "../../screenParts/orders/orderRequestDetailsCard";
import { OrdersRequestsCard } from "../../screenParts/orders/ordersRequestCard";
export const TraderOrdersOfRequestScreen = ({}) => {
  const { order_id } = useParams();
  const [traderOrders, setTraderOrders] = useState();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    resolveRequest(getTraderOrderOffers, setTraderOrders, {
      order_id: order_id,

      pageNumber: currentPage,
      pageCount: 4,
    });
  }, [currentPage]);

  useEffect(() => {
    console.log("traderOrders", traderOrders);
  }, [traderOrders]);
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
      }}
    >
      <ListHeader
        hasTitle={true}
        title={`تفاصيل الطلب #${order_id}`}
        back
        hasAdd={false}
        hasSearch={false}
        hasFilter={false}
      />

      {traderOrders ? (
        traderOrders?.data?.length != 0 ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {traderOrders?.data?.map((item, index) => {
              return (
                <OrdersRequestsDetailsCard
                  location={`${item?.farm?.city?.name} , ${item?.farm?.city?.governorate?.name}`}
                  farmName={item?.farm?.name}
                  amount={item?.offer?.count}
                  averageWeight={item?.offer?.average_weight}
                  dajinHealth={item?.offer?.health_status?.status}
                  requestStatus={item?.status?.status_for_farmer}
                  image={item?.farm?.photo}
                  onPress={() => {
                    navigate(
                      `/traders/traderDetails/orderOffers/offerDetails/${item?.id}`
                    );
                  }}
                  key={index}
                />
              );
            })}

            {setCurrentPage && (
              <AppPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={Math.ceil(traderOrders?.total / 4)}
              />
            )}
          </div>
        ) : (
          <NoData title="لا يوجد بيانات حالية" />
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};
