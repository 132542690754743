import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getFarmerOfferDetails } from "../../api/store/actions/saga/farmer";
import {
  getActivateFarm,
  getFarmDetails,
} from "../../api/store/actions/saga/farms";
import { getPriceByRequest } from "../../api/store/actions/saga/settings";
import Button from "../../reusableComponents/button";
import CartIcon from "../../reusableComponents/iconsSvg/cartIcon";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import FacebookIcon from "../../reusableComponents/iconsSvg/facebookIcon";
import FeaturedIcon from "../../reusableComponents/iconsSvg/featuredIcon";
import LocationIcon from "../../reusableComponents/iconsSvg/locationIcon";
import PhoneIcon from "../../reusableComponents/iconsSvg/phoneIcon";
import TimeIcon from "../../reusableComponents/iconsSvg/timeIcon";
import WhatsAppIcon from "../../reusableComponents/iconsSvg/whatsappIcon";
import Loading from "../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { FarmHorizontalCardDetails } from "../../screenParts/farms/farmHorizontalCardDetails";
import { LabelWIcon } from "../../screenParts/farms/labelWIcon";
import { ChickenCard } from "../../screenParts/farms/offers/chickenCard";
import { StatusNButtonsSection } from "../../screenParts/farms/statusNButtonsSection";
import ListHeader from "../../screenParts/listingViews/listHeader";
import { useTablet } from "../salesTransactions/salesDetails";

export const FarmerOrderDetails = () => {
  const [offerDetails, setOfferDetails] = useState();
  const [activateFarm, setActivateFarm] = useState();
  const { farmer_id, order_id } = useParams();
  console.log("order_id", order_id);
  const [dajin_price, setDajinPrice] = useState();

  useEffect(() => {
    resolveRequest(getFarmerOfferDetails, setOfferDetails, {
      order_id: order_id,
    });
  }, []);
  useEffect(() => {
    resolveRequest(getPriceByRequest, setDajinPrice, {
      request_id: order_id,
    });
  }, [order_id]);
  console.log("offerDetails", offerDetails);
  const width = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const isTablet = useTablet();
  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ListHeader
          hasTitle={true}
          title={"تفاصيل العرض"}
          back
          hasAdd={false}
          hasFilter={false}
          hasSearch={false}
        />
        <div style={{ display: "flex", alignItems: "center", marginLeft: 20 }}>
          <TimeIcon />
          <p style={{ marginRight: 10 }}>{offerDetails?.data?.created}</p>
        </div>
      </div>
      {offerDetails ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "column" : "row",
            }}
          >
            <div
              style={{
                ...width,
              }}
            >
              <SectionLabel content={"تفاصيل التاجر"} />
              <div style={{ display: "flex" }}>
                <div style={{ width: "25%" }}>
                  <img
                    style={{ width: "100%", height: 140, borderRadius: 10 }}
                    src={
                      offerDetails?.data?.trader?.avatar ??
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                    }
                  />
                </div>
                <div style={{ width: "75%", marginRight: 10 }}>
                  <p style={{ marginLeft: 5 }}>
                    {offerDetails?.data?.trader?.name}
                  </p>
                  {/* {accountDetails?.data?.data?.featured && <FeaturedIcon />}
                   */}
                  <div
                    style={{ display: "flex", marginTop: 20, marginBottom: 20 }}
                  >
                    <Rating
                      value={offerDetails?.data?.trader?.rating}
                      readOnly
                      dir="ltr"
                    />
                    <p style={{ marginRight: 10, color: "#B7B7B7" }}>
                      {offerDetails?.data?.trader?.rating.toFixed(2)}
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <PhoneIcon />
                    <p
                      style={{
                        marginRight: 15,
                        marginLeft: 15,
                        color: "#646D82",
                      }}
                    >
                      {offerDetails?.data?.trader?.mobile_number}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      marginBottom: 10,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <WhatsAppIcon />
                      <p
                        style={{
                          marginRight: 15,
                          marginLeft: 15,
                          color: "#646D82",
                        }}
                      >
                        {offerDetails?.data?.trader?.whatsapp_number}
                      </p>
                    </div>
                    <div style={{ marginRight: 10, marginLeft: 10 }}>
                      <Button
                        backgroundColor="transparent"
                        onClick={() => {
                          window.open(
                            offerDetails?.data?.trader?.facebook_account ?? ""
                          );
                        }}
                      >
                        <FacebookIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                ...width,
              }}
            >
              <SectionLabel content={"بيانات العنبر"} />
              <div style={{ display: "flex" }}>
                <div style={{ width: "25%" }}>
                  <img
                    style={{ width: "100%", height: 140, borderRadius: 10 }}
                    src={
                      offerDetails?.data?.farm?.photo ??
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                    }
                  />
                </div>
                <div style={{ width: "75%", marginRight: 10 }}>
                  <div style={{ display: "flex" }}>
                    <p style={{ marginLeft: 5, marginRight: 10 }}>
                      {offerDetails?.data?.farm?.name}
                    </p>
                    {offerDetails?.data?.farm?.trusted == 1 && <FeaturedIcon />}
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <LabelWIcon
                      text={offerDetails?.data?.farm?.requested_price + " ج.م"}
                      icon={<CartIcon />}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <LabelWIcon
                      text={offerDetails?.data?.farm?.count}
                      icon={<ChickenIcon />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <SectionLabel content={"تفاصيل الطلب"} />
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LabelWIcon
                text={`العدد : ${offerDetails?.data?.order_details?.amount}`}
                icon={<ChickenIcon />}
              />
              <LabelWIcon
                text={`النوع :${offerDetails?.data?.order_details?.dajin_type?.type}`}
                icon={<ChickenIcon />}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LabelWIcon
                text={`متوسط الوزن :${offerDetails?.data?.order_details?.average_weight}`}
                icon={<ChickenIcon />}
              />
            </div>
            {offerDetails?.data[0]?.order_details?.governorates?.length !=
              0 && (
              <div
                style={{
                  marginTop: 10,

                  alignItems: "center",
                }}
              >
                <LabelWIcon text={"نطاق الشراء:"} icon={<LocationIcon />} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {offerDetails?.data?.order_details?.governorates?.map(
                    (item) => {
                      return (
                        <p
                          style={{
                            backgroundColor: "lightgray",
                            padding: 5,
                            marginRight: 10,
                            marginLeft: 10,
                            borderRadius: 10,
                          }}
                        >
                          {item?.name}
                        </p>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ ...width }}>
              <SectionLabel content={"حالة الطلب"} />
              <div>
                <p
                  style={{
                    backgroundColor: "grey",
                    color: "white",
                    paddingRight: 30,
                    paddingLeft: 30,
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderRadius: 10,
                    width: "30%",
                    textAlign: "center",
                  }}
                >
                  {offerDetails?.data?.status?.status_for_farmer}
                </p>
              </div>
            </div>
            <div style={{ ...width }}>
              <SectionLabel content={"سعر البورصة"} />
              <div>
                <p
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  أسعار البورصة قابلة للتعديل ثم يخرج سعر اليوم النهائي و لذلك
                  قد يختلف السعر من وقت لاخر حتى حتى الساعه 3م من كل يوم
                </p>
                <ChickenCard
                  dajinType={`${offerDetails?.data?.order_details?.dajin_type?.type}`}
                  price={`${offerDetails?.data?.order_details?.price}`}
                  date={`${offerDetails?.data?.created_at}`}
                />
              </div>
            </div>
          </div>
          <div>
            <SectionLabel
              content={`السعر النهائى لل${offerDetails?.data?.order_details?.dajin_type?.type}`}
            />
            <p
              style={{
                borderRadius: 10,
                border: ".5px solid black",
                padding: 20,
                fontSize: 15,
              }}
            >
              {dajin_price?.data?.price?.final_price != null
                ? dajin_price?.data?.price?.final_price + " ج.م"
                : "لم يتم حساب السعر النهائى حتى الآن"}
            </p>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
