import { useEffect, useState } from "react";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getContactUsRecords } from "../../../api/store/actions/saga/support";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const ContactUs = ({ filterKey, currentPage, setCurrentPage }) => {
  const [contactUsData, setContactUsData] = useState(null);

  useEffect(() => {
    setContactUsData(null);
    resolveRequest(getContactUsRecords, setContactUsData, {
      pageNumber: currentPage,
      params: {
        has_reply: filterKey,
      },
    });
  }, [filterKey, currentPage]);
  console.log(filterKey);
  return (
    <>
      {contactUsData ? (
        <ListView
          links={{ show: "contactDetails" }}
          objName={"contactUs"}
          data={contactUsData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};
export default ContactUs;
