import { useMediaQuery } from "react-responsive";
const useDesktop = () => useMediaQuery({ minWidth: 1200 });
const useTablet = () => useMediaQuery({ minWidth: 768, maxWidth: 1200 });
const useTabletDesktop = () => useMediaQuery({ minWidth: 992, maxWidth: 1199 });
const useTabletMobile = () => useMediaQuery({ minWidth: 576, maxWidth: 767 });
const useMobile = () => useMediaQuery({ maxWidth: 575 });
export const useResponsiveWidth = (
  {
    desktop = "auto",
    tablet = "auto",
    mobile = "auto",
    defaults = "auto",
    tabletDesktop = "auto",
    tabletMobile = "auto",
  },
  prop,
  propDefault
) => {
  const isDesktop = useDesktop();
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isaTbletDesktop = useTabletDesktop();
  const isaTabletMobile = useTabletMobile();
  if (isDesktop)
    return prop
      ? { [prop]: desktop ?? propDefault }
      : desktop != 0
      ? { width: isFinite(desktop) ? desktop + "%" : desktop }
      : { display: "none" };
  else if (isTablet)
    return prop
      ? { [prop]: tablet ?? propDefault }
      : tablet != 0
      ? { width: isFinite(tablet) ? tablet + "%" : tablet }
      : { display: "none" };
  else if (isMobile)
    return prop
      ? { [prop]: mobile ?? propDefault }
      : mobile != 0
      ? { width: isFinite(mobile) ? mobile + "%" : mobile }
      : { display: "none" };
  else if (isaTbletDesktop)
    return prop
      ? { [prop]: tabletDesktop ?? propDefault }
      : tabletDesktop != 0
      ? { width: isFinite(tabletDesktop) ? tabletDesktop + "%" : tabletDesktop }
      : { display: "none" };
  else if (isaTabletMobile)
    return prop
      ? { [prop]: tabletMobile ?? propDefault }
      : tabletMobile != 0
      ? { width: isFinite(tabletMobile) ? tabletMobile + "%" : tabletMobile }
      : { display: "none" };
  else
    return prop
      ? { [prop]: defaults ?? propDefault }
      : defaults != 0
      ? { width: isFinite(defaults) ? defaults + "%" : defaults }
      : { display: "none" };
};

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767.98px) { ... }

// // Large devices (desktops, 992px and up)

// // Extra large devices (large desktops, 1200px and up)

// const Tablet = ({ children }) => {
//   const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
//   return isTablet ? children : null;
// };
