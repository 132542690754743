import { useEffect, useState } from "react";
import {
  CheckedIcon,
  DownArrow,
  UpArrow,
  CancelIcon,
  FilterIcon,
} from "./dropdownIcons";
import "./dropdownStyle.css";
const MultiSelectDropdown = ({
  placeholder,
  dataSource = [],
  selectedItems = [],
  onSelect,
  responsiveWidth,
  schema = { id: "id", name: "name" },
  maxSelect = 7,
  label,
  errorMessage,
  require,
}) => {
  const [listIsOpen, setListIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedItems ?? []);
  useEffect(() => {
    if (selectedItems?.length)
      setSelected(
        dataSource?.filter((item) => {
          console.log(item);
          const c = selectedItems?.includes(item[schema.id]) ? item : null;
          if (c != null) return c;
        })
      );
  }, [selectedItems]);
  console.log("selected", selectedItems);
  return (
    <>
      <div className="dd-wrapper" style={{ width: "20%", ...responsiveWidth }}>
        {label && (
          <p style={{ paddingBottom: 10 }}>
            {" "}
            {label} {require ? <span> * </span> : null}{" "}
          </p>
        )}
        <div
          type="button"
          className={`dd-header ${listIsOpen ? "noraduis" : ""}`}
          onClick={() => {
            setListIsOpen(!listIsOpen);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "95%",
              overflowX: "hidden",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "10%" }}
            >
              <FilterIcon />
            </div>
            <div className="dd-header-title" style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "initial",
                  width: "90%",
                  overflowX: "scroll",
                  whiteSpace: "nowrap",
                }}
                className="scrollItems"
              >
                {selected.length
                  ? selected.map((item, index) => {
                      return (
                        <div key={index} className="multiSelectItem">
                          <p style={{ marginLeft: 3 }}>{item[schema.name]}</p>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelected([
                                ...selected.filter(
                                  (select) => select.id != item.id
                                ),
                              ]);
                              if (onSelect)
                                onSelect([
                                  ...selected
                                    .filter((select) => select.id != item.id)
                                    .map((select) => select.id),
                                ]);
                            }}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CancelIcon />
                          </div>
                        </div>
                      );
                    })
                  : placeholder}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", width: "auto" }}>
            {listIsOpen ? <UpArrow /> : <DownArrow />}
          </div>
        </div>
        {listIsOpen && (
          <div role="list" type="button" className="dd-list">
            <div className="dd-scroll-list">
              {dataSource.length ? (
                dataSource.map((item) => (
                  <button
                    id={item[schema.id]}
                    type="button"
                    className="dd-list-item"
                    key={item[schema.id]}
                    onClick={(e) => {
                      if (selected?.some((select) => select.id == item.id)) {
                        setSelected([
                          ...selected?.filter((select) => select.id != item.id),
                        ]);
                        if (onSelect)
                          onSelect([
                            ...selected
                              ?.filter((select) => select.id != item.id)
                              ?.map((select) => select.id),
                          ]);
                      } else {
                        if (maxSelect > selected?.length) {
                          setSelected([...selected, item]);
                          if (onSelect)
                            onSelect([
                              ...selected?.map((select) => select.id),
                              item.id,
                            ]);
                        }
                      }
                    }}
                  >
                    <p>{item[schema?.name]}</p>
                    {selected?.some((select) => select?.id == item.id) && (
                      // item[schema.id] == selected?.id
                      <CheckedIcon width={20} height={20} color={"#7ABA4C"} />
                    )}
                  </button>
                ))
              ) : (
                <>
                  <button type="button" className="dd-list-item">
                    <p>لا يوجد عناصر</p>
                  </button>
                </>
              )}
            </div>
          </div>
        )}

        {errorMessage && (
          <p style={{ paddingTop: 10, color: "red" }}> {errorMessage} </p>
        )}
      </div>
    </>
  );
};

export default MultiSelectDropdown;
