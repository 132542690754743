import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";

import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  addRole,
  getPremission,
  getRole,
  updateRole,
} from "../../api/store/actions/saga/roles";

import CheckBox from "../../reusableComponents/checkBox";
import SectionLabel from "../../reusableComponents/sectionLabel";
import TextInput from "../../reusableComponents/textInput";
import TitleAndBack from "../../screenParts/listingViews/titleAndBack";
import { RotatingLines } from "react-loader-spinner";
import { filterCheckBoxObj } from "../../alogrithms";
import Button from "../../reusableComponents/button";
import { useDispatch } from "react-redux";
const RolesForm = ({ isEdit }) => {
  const { roleId } = useParams();
  const location = useLocation();
  const [premission, setPremission] = useState(null);
  const [role, setRole] = useState(null);
  const [selectedPremission, setSelectedPremission] = useState({});
  const [roleName, setRoleName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    resolveRequest(getPremission, setPremission);
    if (isEdit && !role) resolveRequest(getRole, setRole, { id: roleId });
    if (role) {
      setRoleName(role?.data?.name);
      const x = role?.data?.permissions?.map((item) => {
        return { [item?.id]: true };
      });
      setSelectedPremission(Object.assign({}, selectedPremission, ...x));
    }
  }, [role]);
  console.log(premission, selectedPremission, role);

  const submit = () => {
    if (roleName) {
      if (errorMessage) setErrorMessage(null);
      isEdit
        ? updateRole({
            id: roleId,
            body: {
              name: roleName,
              permissions: filterCheckBoxObj(selectedPremission),
            },
          })
        : addRole({
            body: {
              name: roleName,
              permissions: filterCheckBoxObj(selectedPremission),
            },
          });
      location?.reload();
    } else {
      if (!errorMessage);
      setErrorMessage("اسم الصلاحية فارغ");
    }
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: 8 }}>
      <TitleAndBack title={!isEdit ? "إضافة صلاحيات" : "تعديل الصلاحيات"} />
      <div>
        <SectionLabel content={"معلومات الدور"} />
        <TextInput
          require
          value={roleName ?? ""}
          errorMessage={errorMessage}
          onChange={(e) => {
            if (errorMessage && e.target.value) {
              setErrorMessage(null);
            }
            setRoleName(e.target.value);
          }}
          label={"الاسم"}
          responsiveWidth={useResponsiveWidth({
            defaults: 50,
            desktop: 50,
            tablet: 70,
            tabletDesktop: 50,
          })}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <p> الصلاحيات *</p>
        <div>
          {premission ? (
            premission?.data?.map((item, index) => {
              return (
                <CheckBox
                  key={index}
                  id={item?.id}
                  label={item?.name}
                  setToggleCheckBox={setSelectedPremission}
                  toggleCheckBox={selectedPremission}
                />
              );
            })
          ) : (
            <RotatingLines />
          )}
        </div>
      </div>
      <Button
        width={"20%"}
        style={{ marginTop: 20, marginBottom: 20 }}
        onClick={submit}
      >
        {isEdit ? "تعديل" : "إضافة"}
      </Button>
    </div>
  );
};

export default RolesForm;
