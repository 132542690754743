import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import MobileMood from "../alogrithms/mobileMiddleware";
import MainLayout from "../screenParts/layout";
const PrivateRoute = ({ children }) => {
  const isAuth = useSelector((state) => state.login.isAuth);

  if (!isAuth) {
    return <Navigate to="/" />;
  }
  // if(!hasPermission){
  //   toast.warning('ليس لديك صلاحية')
  //   return <Navigate to="/NoPermission" />;
  // }
  return (
    <MainLayout>
      <MobileMood>{children}</MobileMood>
    </MainLayout>
  );
};

export default PrivateRoute;
