import SectionLabel from "../../reusableComponents/sectionLabel";
import { MyEditor } from "../../reusableComponents/editor";
import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { useNavigate } from "react-router-dom";

import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  createNews,
  getNewsDetails,
  updateNews,
} from "../../api/store/actions/saga/news";
import avatar from "../../assets/avatar.jpg";
import { dateToIso } from "../../alogrithms/dateAlgo";
import { useParams } from "react-router";
import Loading from "../../reusableComponents/loadingScreen/loading";
import { getCategories } from "../../api/store/actions/saga/categories";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import TextInput from "../../reusableComponents/textInput";
import TimeInput from "../../reusableComponents/timeInput";
import { deleteEmptyProps } from "../../alogrithms/requests";
import PhotoSelect from "../../screenParts/photoSelect";
import CustomDatePicker from "../../reusableComponents/dateInput/dateInput";
import moment from "moment";
import { useTablet } from "../dispute/disputeDetails";
function CreateNews({ isEdit }) {
  const width = useResponsiveWidth({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [newsDetails, setNewsDetails] = useState();

  useEffect(() => {
    if (isEdit) {
      resolveRequest(getNewsDetails, setNewsDetails, { id: id });
    }
  }, []);

  const [categoriesData, setCategoriesData] = useState();
  const [checked, setChecked] = useState(false);
  const checktime = new Date(newsDetails?.data?.publish_at) < new Date();
  const options = [
    { id: 1, name: " نعم" },
    { id: 0, name: "لا" },
  ];
  useEffect(() => {
    resolveRequest(getCategories, setCategoriesData, "news");
  }, []);

  useEffect(() => {
    if (checktime) {
      setChecked(true);
    }
  }, [checktime]);
  const [imageObj, setImageObj] = useState("");
  const [title, setTitle] = useState("");
  const [content, setcontent] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [allow_comments, setallow_comments] = useState("");
  const [date, setdate] = useState(moment(new Date()).format("yyyy-MM-DD"));
  const [time, setTime] = useState(moment(new Date()).format("HH:mm"));
  const [errorImage, setErrorImage] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorContent, setErrorContent] = useState(null);
  const [errorCategoryId, setErrorCategoryId] = useState(null);
  const [errorAllowComments, setErrorAllowComments] = useState(null);

  const [errorPublishAt, setErrorPublishAt] = useState(null);

  console.log("error", errorImage);
  console.log("content", typeof content);
  const handleBackendError = (errors) => {
    if (errors?.image) {
      setErrorImage(errors?.image);
    }
    if (errors?.title) {
      setErrorTitle(errors?.title);
    }
    if (errors?.content) {
      setErrorContent(errors?.content);
    }
    if (errors?.category_id) {
      setErrorCategoryId(errors?.category_id);
    }
    if (errors?.publish_at) {
      setErrorPublishAt(errors?.publish_at);
    }
    if (errors?.allow_comments) {
      setErrorAllowComments(errors?.allow_comments);
    }
  };
  const [submitFlag, setSubmitFlag] = useState(false);
  console.log("title", title?.length);
  const submit = async () => {
    setSubmitFlag(false);
    if (errorImage) setErrorImage(null);
    if (errorTitle) setErrorImage(null);
    if (errorContent) setErrorImage(null);
    if (errorCategoryId) setErrorCategoryId(null);
    if (errorPublishAt) setErrorPublishAt(null);
    if (errorAllowComments) setErrorAllowComments(null);

    if (title?.length == 0) {
      setErrorTitle("يلزم ادخال العنوان");
    }
    if (content?.length == 0) {
      setErrorContent("يلزم ادخال محتوى الخبر");
    }
    if (content == "<p></p>") {
      setErrorContent("يلزم ادخال محتوى الخبر");
    }

    if (typeof imageObj != "object") {
      setErrorImage("يلزم ادخال الصورة بالشكل الصحيح");
    }
    if (typeof category_id != "number" && category_id?.length == 0) {
      setErrorCategoryId("يلزم ادخال القسم");
    }
    if (typeof allow_comments != "number" && allow_comments?.length == 0) {
      setErrorAllowComments("يلزم ادخال الموافقة على التعليق");
    }
    if (
      submitFlag == true &&
      errorImage == null &&
      errorTitle == null &&
      errorContent == null &&
      errorCategoryId == null &&
      errorPublishAt == null
    ) {
      const body = {
        title: title,
        content: content,
        category_id: category_id,
        image: imageObj,
        allow_comments: allow_comments,
        publish_at: checked ? "" : dateToIso(new Date(date + " " + time)),
      };
      const res = await createNews({ body: body });
      handleBackendError(res?.errors);
    }
    setSubmitFlag(true);
  };
  console.log("JJJJJJJJ", checked);
  return (
    <>
      {categoriesData ? (
        <div
          style={{
            padding: 10,
            backgroundColor: "white",
          }}
        >
          <SectionLabel content={"تفاصيل الخبر"} />

          <div
            style={{
              display: "flex",

              flexDirection: "row",
            }}
          >
            <div style={{ width: "50%", padding: 20 }}>
              <p style={{ marginBottom: 10, marginTop: 10 }}>العنوان *</p>
              <TextInput
                value={title}
                placeholder={"اكتب العنوان"}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.target.value) {
                    setErrorTitle(null);
                  }
                  if (title?.length == 0) {
                    setErrorTitle("يلزم ادخال العنوان");
                  }
                }}
                errorMessage={errorTitle}
              />

              <p style={{ marginBottom: 10, marginTop: 10 }}>القسم *</p>
              <SelectDropdown
                placeholder={"اختر"}
                errorMessage={errorCategoryId}
                responsiveWidth={{ width }}
                selectedItem={category_id}
                dataSource={categoriesData?.data}
                onSelect={(e) => {
                  setcategory_id(e);
                  if (e) {
                    setErrorCategoryId(null);
                  } else if (
                    typeof category_id != "number" &&
                    category_id?.length == 0
                  ) {
                    setErrorCategoryId("يلزم ادخال القسم");
                  }
                }}
              />
            </div>
            <div
              style={{
                width: "50%",
                padding: 20,
              }}
            >
              <PhotoSelect
                require
                errorMessage={errorImage}
                title={"صورة الخبر"}
                src={imageObj ?? avatar}
                onChange={(e) => {
                  if (e) {
                    setErrorImage(null);
                  }
                  setImageObj(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <SectionLabel content={"الخبر"} />
          <MyEditor
            placeholder={"اكتب الخبر"}
            content={isEdit ? newsDetails?.data?.content ?? "" : ""}
            onChange={(e) => {
              if (e) {
                setErrorContent(null);
              }
              if (content == "" || content == "<p></p>") {
                setErrorContent("يلزم ادخال محتوى الخبر");
              }
              setcontent(e);
            }}
          />
          <p style={{ color: "red" }}>{errorContent}</p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
                paddingTop: 20,
              }}
            >
              <SectionLabel content={"النشر"} />
              <p style={{ marginBottom: 10, marginTop: 10 }}>تاريخ النشر</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{
                      width: "40%",

                      height: 54,
                      border: 0,
                      borderRadius: 10,
                      backgroundColor: "#FBFBFB",
                      color: "#7ABA4C",
                      paddingRight: 20,
                      paddingLeft: 20,
                    }}
                    onChange={(e) => {
                      setdate(e.target.value);
                    }}
                    value={date}
                    dir="rtl"
                    type="date"
                    name="date"
                    placeholder="اكتب العنوان"
                  />

                  <TimeInput
                    responsiveWidth={{ ...width }}
                    value={time}
                    name={"name"}
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                  />
                  {/* <input
                    style={{
                      width: "40%",
                      height: 54,
                      border: 0,
                      borderRadius: 10,
                      backgroundColor: "#FBFBFB",
                      color: "#7ABA4C",
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginRight: 20,
                    }}
                    onChange={handleChange("time")}
                    value={values["time"]}
                    dir="rtl"
                    type="time"
                    name="time"
                    placeholder="اكتب العنوان"
                  /> */}
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      justifyContent: "flex-end",
                      paddingRight: 10,
                    }}
                  >
                    <p style={{ marginRight: 3, marginLeft: 3 }}>الآن</p>
                    <input
                      type="checkbox"
                      name="dateNow"
                      value={checked}
                      checked={checked}
                      onChange={() => {
                        setChecked((prevState) => !prevState);
                      }}
                    />
                  </div>
                </div>
              </div>
              <p style={{ color: "red" }}>{errorPublishAt}</p>
            </div>
            <div
              style={{
                width: "50%",
                paddingTop: 20,
                paddingRight: 20,
                paddingLeft: 20,
              }}
            >
              <SectionLabel content={"التعليقات"} />
              <p style={{ marginBottom: 10, marginTop: 10 }}>
                الموافقة على نشر التعليقات *
              </p>

              <SelectDropdown
                schema={{ id: "id", name: "name" }}
                placeholder={"اختر"}
                responsiveWidth={{ ...width }}
                selectedItem={allow_comments}
                dataSource={options}
                errorMessage={errorAllowComments}
                onSelect={(e) => {
                  setallow_comments(e);
                  if (e) {
                    setErrorAllowComments(null);
                  } else if (
                    typeof allow_comments != "number" &&
                    allow_comments?.length == 0
                  ) {
                    setErrorAllowComments("يلزم ادخال الموافقة على التعليق");
                  }
                }}
              />
            </div>
          </div>

          <div style={{ display: "flex", marginTop: 20 }}>
            <Button
              onClick={() => {
                submit();
              }}
              type={"submit"}
              style={{ width: "20%" }}
            >
              {!isEdit ? "إضافة" : "تعديل"}
            </Button>

            <Button
              onClick={() => {
                navigate(-1);
              }}
              // type={"reset"}
              backgroundColor="#F4F4F4"
              textColor="#9F9F9F"
              style={{ marginRight: 20, width: "20%" }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default CreateNews;
