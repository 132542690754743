import CustomImage from "../../reusableComponents/customImage";
import { DeleteIcon } from "./icons";
import { nanoid } from "nanoid";

const PartinersGallery = ({ setImageList, imagesList }) => {
  console.log(imagesList);
  return (
    <>
      <div
        style={{
          borderRadius: 10,
          backgroundColor: "#7ABA4C0D",
          border: "1px dashed #7ABA4C99",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
          width: "95%",
        }}
      >
        <CustomImage
          borderRadius={10}
          width={40}
          height={40}
          style={{ marginRight: 10, marginLeft: 10 }}
        />

        <label
          style={{
            color: "#7ABA4C",
          }}
        >
          <input
            type="file"
            name="file"
            accept="image/png, image/gif, image/jpeg, image/svg, image/jpg"
            style={{ display: "none" }}
            onChange={(e) => {
              setImageList([
                { id: nanoid(10), value: e.currentTarget.files[0] },
                ...imagesList,
              ]);
            }}
          />
          استعرض
        </label>
      </div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          width: "95%",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: 20,
        }}
      >
        {imagesList?.map((item, index) => {
          if (index <= 4) {
            return (
              <div
                style={{
                  position: "relative",

                  height: 100,
                  width: 100,
                }}
              >
                <CustomImage src={item?.value} borderRadius={"50%"} />
                <div
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "rgba(0 ,0 ,0 , .2)",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0%",
                    display: "flex",
                    placeContent: "center",
                    placeItems: "center",
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setImageList(imagesList?.filter((i) => i.id != item?.id));
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default PartinersGallery;
