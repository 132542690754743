import { useEffect, useState } from "react";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getRoles } from "../../api/store/actions/saga/roles";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";

const RolesList = () => {
  const [rolesData, setRolesData] = useState(null);

  useEffect(() => {
    resolveRequest(getRoles, setRolesData, {});
  }, []);

  return rolesData ? (
    <ListView
      objName={"roles"}
      links={{
        edit: "updateRole",
        delete: "",
        show: ``,
        query: {
          show: { key: "", prop: "" },
          delete: "",
          edit: "",
        },
      }}
      data={rolesData}
    ></ListView>
  ) : (
    <Loading />
  );
};

export default RolesList;
