import MainLayout from "..";
import {
  AboutUsIcon,
  AcademyIcon,
  ChickenIcon,
  CreditIcon,
  EmailIcon,
  NewsIcon,
  NotifyIcon,
  PricesIcon,
  SettingIcon,
  TraderIcon,
  UsersIcon,
} from "../../../reusableComponents/iconsSvg/sideBar/icons";
import ArticlesList from "../../../screens/articles";
import ArticleEditScreen from "../../../screens/articles/articleScreen";
import CommentsList from "../../../screens/comments";
import CommentForm from "../../../screens/comments/commentForm";
import CoursesList from "../../../screens/courses";
import CreateCourse from "../../../screens/courses/createCourse";
import CreateInstructorScreen from "../../../screens/instructor/createInstructor";
import FarmersList from "../../../screens/farmers";
import { SalesDetails } from "../../../screens/salesTransactions/salesDetails";
import { FarmDetailsScreen } from "../../../screens/farms/farmDetails";
import LecturesList from "../../../screens/lectures";
import CreateLectures from "../../../screens/lectures/createLectures";
import NewsList from "../../../screens/news";
import EditNews from "../../../screens/news/editNews";
import TradersList from "../../../screens/traders";
import SalesTransactionsList from "../../../screens/salesTransactions";
import TraderDetails from "../../../screens/traders/traderDetails";
import { TraderOrdersOfRequestScreen } from "../../../screens/orders/traderOrdersOfRequest";
import { OrderRequestDetailsScreen } from "../../../screens/orders/orderRequestDetailsScreen";
import AddTraderScreen from "../../../screens/traders/addTrader";
import AddFarmerScreen from "../../../screens/farmers/addFarmer";
import FarmerDetails from "../../../screens/farmers/farmerDetails";
import { FarmLogScreen } from "../../../screens/farms/farmLogScreen";
import { FarmerOrderDetails } from "../../../screens/farmers/farmerOrderDetails";
import AddFarmScreen from "../../../screens/farms/addFarm";
import Support from "../../../screens/support";
import AboutUs from "../../../screens/aboutUs.js/aboutUs";
import UsersList from "../../../screens/users";
import RolesForm from "../../../screens/roles/rolesForm";
import UsersForm from "../../../screens/users/usersForm";
import PricesIndex from "../../../screens/prices";
import { RatingDetails } from "../../../screens/ratings/ratingDetails";
import { AccountsList } from "../../../screens/accounts";
import { AccountBalance } from "../../../screens/traders/accountBalance";
import SettingIndex from "../../../screens/setting";
import BreedsIndex from "../../../screens/setting/breeds/breeds";
import ContactDetails from "../../../screens/support/contact/contactDetails";
import { DisputeDetails } from "../../../screens/dispute/disputeDetails";
import Notification from "../../../screens/notification";
import NotificationForm from "../../../screens/notification/notificationForm";
import { RedeemsList } from "../../../screens/redeem";
import CreateNewsG from "../../../screens/news/createNewsG";
import { GetAllFarmsScreen } from "../../../screens/farms/getAllFarmsScreen";
import ArticleCreateScreen from "../../../screens/articles/addArticleScreen";
import CreateNews from "../../../screens/news/createNews";
import { TechnicalSupportDetailsScreen } from "../../../screens/support/technical/technicalDetails";
export const appRoutes = [
  {
    id: "الاشعارات",
    name: "الاشعارات",
    icon: (color) => <NotifyIcon color={color} />,
    component: <Notification />,
    link: "/notification",
    side: true,
    permission: [0],
  },

  {
    id: " التسعير",
    name: " التسعير",
    icon: (color) => <PricesIcon color={color} />,
    component: <PricesIndex />,
    link: "/prices",
    side: true,
    permission: [8],
  },

  {
    id: "التجار / الموردين",
    name: "التجار / الموردين",
    icon: (color) => <TraderIcon color={color} />,
    component: <TradersList />,
    link: "/traders",
    side: true,
    permission: [1],
  },
  {
    id: "العنابر",
    name: "العنابر",
    icon: (color) => <ChickenIcon color={color} />,
    component: <GetAllFarmsScreen />,
    link: "/farms",
    side: true,
    permission: [0],
  },
  {
    id: "المربيين / العنابر",
    name: "المربيين / العنابر",
    icon: (color) => <ChickenIcon color={color} />,
    component: <FarmersList />,
    link: "/farmers",
    side: true,
    permission: [2],
  },
  {
    id: "farmerDetails",
    name: "farmerDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <FarmerDetails />,
    link: "/farmers/farmerDetails/:farmer_id",
    side: false,
  },
  {
    id: "traderRatingDetails",
    name: "traderRatingDetails",
    icon: (color) => <TraderIcon color={color} />,
    component: <RatingDetails />,
    link: "/traders/traderDetails/:user_id/traderRatingDetails/:rating_id",
    side: false,
  },
  {
    id: "farmerRatingDetails",
    name: "farmerRatingDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <RatingDetails isFarmer />,
    link: "/farmers/farmerDetails/:farmer_id/farmerRatingDetails/:rating_id",
    side: false,
  },
  {
    id: "farmerDetails",
    name: "farmerDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <FarmerOrderDetails />,
    link: "/farmers/:farmer_id/farmerOrders/orderDetails/:order_id",
    side: false,
  },
  {
    id: "FarmDetails",
    name: "FarmDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <FarmDetailsScreen />,
    link: "/farmers/farmDetails/:farm_id",
    side: false,
  },
  {
    id: "FarmLog",
    name: "FarmLog",
    icon: (color) => <NotifyIcon color={color} />,
    component: <FarmLogScreen />,
    link: "/farmers/farms/farmLog/:farm_id",
    side: false,
  },
  {
    id: "عمليات البيع",
    name: "عمليات البيع",
    icon: (color) => <NotifyIcon color={color} />,
    component: <SalesTransactionsList />,
    link: "/salesTransactions",
    side: true,
    permission: [0],
  },
  {
    id: "الحسابات و الارصدة",
    name: "الحسابات و الارصدة",
    icon: (color) => <CreditIcon color={color} />,
    component: <AccountsList />,
    link: "/accounts/balances",
    side: true,
    permission: [3],
  },
  ,
  {
    id: "جوائز قيد المراجعة",
    name: "جوائز قيد المراجعة",
    icon: (color) => <CreditIcon color={color} />,
    component: <RedeemsList />,
    link: "/gifts/redeemption",
    side: true,
    permission: [8],
  },
  {
    id: "AccountBalance",
    name: "AccountBalance",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AccountBalance isFromAccount />,
    link: "/accounts/balances/accountBalanceDetails/:user_id",
    side: false,
  },
  {
    id: "أخبار السوق",
    name: "أخبار السوق",
    icon: (color) => <NewsIcon color={color} />,
    component: <NewsList />,
    link: "/news",
    side: true,
    permission: [4],
  },
  {
    id: "المقالات العلمية",
    name: "المقالات العلمية",
    icon: (color) => <NewsIcon color={color} />,
    component: <ArticlesList />,
    link: "/articles",
    side: true,
    permission: [5],
  },
  {
    id: "التعليقات",
    name: "التعليقات",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CommentsList />,
    link: "/comments",
    side: true,
    permission: [0],
  },
  {
    id: "الأكاديمية",
    name: "الأكاديمية",
    icon: (color) => <AcademyIcon color={color} />,
    component: <CoursesList />,
    link: "/courses",
    side: true,
    permission: [6],
  },
  {
    id: "المستخدمين",
    name: "المستخدمين",
    icon: (color) => <UsersIcon color={color} />,
    component: <UsersList />,
    link: "/users",
    side: true,
    permission: [7],
  },
  {
    id: "الإعدادات",
    name: "الإعدادات",
    icon: (color) => <SettingIcon color={color} />,
    component: <SettingIndex />,
    link: "/setting",
    side: true,
    permission: [8],
  },
  {
    id: "من نحن",
    name: "من نحن",
    icon: (color) => <AboutUsIcon color={color} />,
    component: <AboutUs />,
    link: "/aboutUs",
    side: true,
    permission: [9, 10],
  },
  {
    id: "التواصل",
    name: "التواصل",
    icon: (color) => <EmailIcon color={color} />,
    component: <Support />,
    link: "/support",
    side: true,
    permission: [9, 10],
  },
  {
    id: "addNews",
    name: "addNews",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateNews />,
    link: "/news/addNews",
    side: false,
  },
  {
    id: "editNews",
    name: "editNews/id",
    icon: (color) => <NotifyIcon color={color} />,
    component: <EditNews isEdit={true} />,
    link: "/news/editNews/:id",
    side: false,
  },
  {
    id: "SupportTicket",
    name: "SupportTicket/id",
    icon: (color) => <NotifyIcon color={color} />,
    component: <TechnicalSupportDetailsScreen />,
    link: "/support/SupportTicket/:id",
    side: false,
  },
  {
    id: "addArticle",
    name: "addArticle",
    icon: (color) => <NotifyIcon color={color} />,
    component: <ArticleCreateScreen />,
    link: "/articles/addArticle",
    side: false,
  },
  {
    id: "editArticle",
    name: "editArticle",
    icon: (color) => <NotifyIcon color={color} />,
    component: <ArticleEditScreen isEdit />,
    link: "/articles/editArticle/:id",
    side: false,
  },
  {
    id: "addCourse",
    name: "addCourse",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateCourse />,
    link: "/courses/addCourse",
    side: false,
  },
  {
    id: "editCourse",
    name: "editCourse",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateCourse isEdit />,
    link: "/courses/editCourse/:id",
    side: false,
  },
  {
    id: "lecture",
    name: "addlectureLecture",
    icon: (color) => <NotifyIcon color={color} />,
    component: <LecturesList />,
    link: "/courses/lectures/:courseId",
    side: false,
  },
  {
    id: "addLecture",
    name: "addLecture",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateLectures />,
    link: "/courses/lectures/:courseId/addLecture",
    side: false,
  },
  {
    id: "editLecture",
    name: "editLecture",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateLectures isEdit />,
    link: "/courses/lectures/:courseId/editLecture/:lectureId",
    side: false,
  },
  {
    id: "editComment",
    name: "editComment",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CommentForm isEdit />,
    link: "/comments/editComment/:commentId",
    side: false,
  },
  {
    id: "salesDetails",
    name: "salesDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <SalesDetails />,
    link: "/salesTransactions/salesDetails/:transaction_id",
    side: false,
  },
  {
    id: "disputeDetails",
    name: "disputeDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DisputeDetails />,
    link: "/salesTransactions/disputeDetails/:dispute_id",
    side: false,
  },

  {
    id: "traderDetails",
    name: "traderDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <TraderDetails />,
    link: "/traders/traderDetails/:user_id",
    side: false,
  },
  {
    id: "orderOffers",
    name: "orderOffers",
    icon: (color) => <NotifyIcon color={color} />,
    component: <TraderOrdersOfRequestScreen />,
    link: "/traders/traderDetails/orderOffers/:order_id",
    side: false,
  },
  {
    id: "offerDetails",
    name: "offerDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <OrderRequestDetailsScreen />,
    link: "/traders/traderDetails/orderOffers/offerDetails/:offer_id",
    side: false,
  },
  {
    id: "addInstructor",
    name: "addInstructor",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateInstructorScreen />,
    link: "/courses/addInstructor",
    side: false,
  },
  {
    id: "addFarmer",
    name: "addFarmer",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddFarmerScreen />,
    link: "/farmers/addFarmer",
    side: false,
  },
  {
    id: "addFarm",
    name: "addFarm",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddFarmScreen />,
    link: "/farmers/farmerDetails/:farmer_id/addFarm",
    side: false,
  },
  {
    id: "editFarm",
    name: "editFarm",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddFarmScreen isEdit />,
    link: "/farmers/farmDetails/editFarm/:farm_id",
    side: false,
  },
  {
    id: "addTrader",
    name: "addTrader",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddTraderScreen />,
    link: "/traders/addTrader",
    side: false,
  },
  {
    id: "editFarmer",
    name: "editFarmer",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddFarmerScreen isEdit={true} />,
    link: "/farmers/editFarmer/:farmer_id",
    side: false,
  },
  {
    id: "editTrader",
    name: "editTrader",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddTraderScreen isEdit={true} />,
    link: "/traders/editTrader/:trader_id",
    side: false,
  },
  {
    id: "editInstructor",
    name: "editInstructor",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateInstructorScreen isEdit />,
    link: "/courses/editInstructor/:instructorId",
    side: false,
  },
  {
    id: "addRoles",
    name: "addRoles",
    icon: (color) => <NotifyIcon color={color} />,
    component: <RolesForm />,
    link: "/users/addRoles",
    side: false,
  },
  {
    id: "updateRole",
    name: "updateRole",
    icon: (color) => <NotifyIcon color={color} />,
    component: <RolesForm isEdit />,
    link: "/users/updateRole/:roleId",
    side: false,
  },
  {
    id: "addUser",
    name: "addUser",
    icon: (color) => <NotifyIcon color={color} />,
    component: <UsersForm />,
    link: "/users/addUser",
    side: false,
  },
  {
    id: "editUser",
    name: "editUser",
    icon: (color) => <NotifyIcon color={color} />,
    component: <UsersForm isEdit />,
    link: "/users/editUser/:userId",
    side: false,
  },
  {
    id: "dajinTypeBreeds",
    name: "dajinTypeBreeds",
    icon: (color) => <NotifyIcon color={color} />,
    component: <BreedsIndex />,
    link: "/setting/dajinTypeBreeds/:dajinId",
    side: false,
  },

  {
    id: "contactDetails",
    name: "contactDetails",
    icon: (color) => <NotifyIcon color={color} />,
    component: <ContactDetails />,
    link: "/support/contactDetails/:contactId",
    side: false,
  },
  {
    id: "addNotification",
    name: "addNotification",
    icon: (color) => <NotifyIcon color={color} />,
    component: <NotificationForm />,
    link: "/notification/addNotification",
    side: false,
  },
  {
    id: "updateNotification",
    name: "updateNotification",
    icon: (color) => <NotifyIcon color={color} />,
    component: <NotificationForm isEdit />,
    link: "/notification/updateNotification/:notifyId",
    side: false,
  },
  // {
  //   id: "showCourse",
  //   name: "showCourse",
  //   icon: (color) => <NotifyIcon color={color} />,
  //   component: <LecturesList />,
  //   link: "/showCourse/:courseId",
  //   side: false,
  // },
];
