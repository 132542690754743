import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = "/admin/v1/ondemand/notifications";

export const getNotifications = async ({
  params: params = {},
  pageNumber = 1,
  pageCount = 7,
}) => {
  try {
    let filters = concatFilters(params);
    const data = await axiosCall.get(
      `${apiUrl}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getTargets = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}/targets`);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const addNotify = async ({ body }) => {
  try {
    const data = await axiosCall.post(`${apiUrl}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getNotify = async ({ id }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateNotify = async ({ id, body }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
