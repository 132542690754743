import { objToFormData } from "../../../../../alogrithms/requests";
import axiosCall from "../../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/settings/gifts`;
export const getGifts = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const deleteGift = async ({ gift_id }) => {
  try {
    const data = await axiosCall.delete(`${apiUrl}/${gift_id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateGift = async ({ gift_id, body }) => {
  try {
    const data = await axiosCall.post(
      `${apiUrl}/${gift_id}`,
      objToFormData(body),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const showGift = async ({ gift_id }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}/${gift_id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const createGift = async ({ body }) => {
  try {
    const data = await axiosCall.post(`${apiUrl}`, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
