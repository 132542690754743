export const objToFormData = (data) => {
  const formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      if (key == "attachments") {
        value.forEach((element, index) => {
          let obj = {
            uri: element?.item?.uri,
            name: element?.item?.name,
            type: element?.item?.type,
          };
          formData.append(`${key}[]`, obj);
        });
      } else if (key == "partners") {
        value.forEach((element, index) => {
          formData.append(`${key}[${element.id}]`, element.value);
        });
      } else {
        value.forEach((element, index) => {
          formData.append(`${key}[${index}]`, element);
        });
      }
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};

export const deleteEmptyProps = (obj) => {
  for (let [key, value] of Object.entries(obj)) {
    if (!value && value !== false && value !== 0) delete obj[key];
  }

  return obj;
};
