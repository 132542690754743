import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";
export const disputeAPI = `/admin/v1/disputed-orders`;

export const getAllDisputes = async ({
  pageNumber: pageNumber = 1,
  pageCount: pageCount = 9,
  params: params = {},
}) => {
  try {
    let filters = concatFilters(params);

    let res = await axiosCall.get(
      `${disputeAPI}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getDisputeDetails = async ({ dispute_id }) => {
  try {
    let res = await axiosCall.get(`${disputeAPI}/show/${dispute_id}`);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const acceptDispute = async ({ dispute_id }) => {
  try {
    let res = await axiosCall.put(`${disputeAPI}/accept/${dispute_id}`);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const rejectDispute = async ({ dispute_id }) => {
  try {
    let res = await axiosCall.put(`${disputeAPI}/reject/${dispute_id}`);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};
