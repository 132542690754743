import { useEffect, useState } from "react";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getArticles } from "../../api/store/actions/saga/articles";
import { getCategories } from "../../api/store/actions/saga/categories";
import { getNews } from "../../api/store/actions/saga/news";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";
import TableBase from "../../screenParts/listingViews/tableBase";
import Categories from "../categories";

const NewsList = () => {
  const [newsData, setNewsData] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const [active, setActive] = useState(1);
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    resolveRequest(getCategories, setCategories, "news");
  }, []);
  useEffect(() => {
    setNewsData(null);
    resolveRequest(getNews, setNewsData, {
      pageNumber: currentPage,
      params: { title: searchKey, "category.id": filterKey },
    });
  }, [currentPage, searchKey, filterKey]);
  const onChange = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSearchKey(value);
  };
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
  };
  return (
    <>
      {categories ? (
        <ListHeader
          onSelect={onSelect}
          hasFilter={active === 1}
          hasSearch={active === 1}
          dataSource={
            categories ? [{ name: "الكل", id: null }, ...categories?.data] : []
          }
          onChange={onChange}
          pages={[
            { title: "الأخبار", id: 1 },
            { title: "التصنيفات", id: 2 },
          ]}
          addLink={active == 1 ? "addNews" : `addCategory/${"news"}`}
          linkType={active == 1 ? undefined : "modal"}
          active={active}
          setActive={setActive}
        />
      ) : (
        <></>
      )}
      {active == 1 ? (
        newsData ? (
          <ListView
            objName={"news"}
            links={{
              edit: "editNews",
              delete: "",
              show: "",
              query: { show: "", delete: "", edit: "" },
            }}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={newsData}
          ></ListView>
        ) : (
          <Loading />
        )
      ) : (
        <Categories type={"news"} />
      )}
    </>
  );
};

export default NewsList;
