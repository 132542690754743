import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";
export const accountsAPI = `/admin/v1/accounts`;

export const getAllAccounts = async ({
  pageNumber: pageNumber = 1,
  pageCount: pageCount = 9,
  params: params = {},
}) => {
  try {
    let filters = concatFilters(params);

    let res = await axiosCall.get(
      `${accountsAPI}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const addUserPoints = async ({ user_id, body }) => {
  try {
    return dataFormat(
      await axiosCall.post(
        `${accountsAPI}/${user_id}/account/add/points`,
        JSON.stringify(body)
      )
    );
  } catch (e) {
    throw e;
  }
};
/* export const addCategories = async ({ type, body }) => {
  try {
    let res = await axiosCall.post(categoriesUrl(type), body);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
}; */
