import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import { catchFromat, dataFormat } from "../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/courses`;
export const createLectures = async ({ body, course_id }) => {
  try {
    return dataFormat(
      await axiosCall.post(
        `${apiUrl}/${course_id}/lectures`,
        objToFormData(body),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    );
  } catch (e) {
    throw e;
  }
};
/* export const getArticles = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}?page=2`);
    console.log("articles :", data);
    return data;
  } catch (e) {
    throw e;
  }
}; */
const getApi = (courseId) => `/admin/v1/courses/${courseId}/lectures`;
export const getLectures = async ({ courseId }) => {
  try {
    let data = await axiosCall.get(getApi(courseId));
    console.log("LECTURES", data);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateLectures = async ({ body, course_id, lecture_id }) => {
  try {
    return dataFormat(
      await axiosCall.post(
        `${apiUrl}/${course_id}/lectures/${lecture_id}`,
        objToFormData(body),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    );
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getLecturesDetails = async ({ course_id, lecture_id }) => {
  try {
    return await axiosCall.get(`${apiUrl}/${course_id}/lectures/${lecture_id}`);
  } catch (e) {
    return catchFromat(e.response);
  }
};
