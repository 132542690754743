import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getAdminInf } from "../../../api/store/actions/saga/users";
import {
  clearActiveDetailsState,
  clearActiveState,
} from "../../../api/store/reducers/reload";
import NotifyIcon from "../../../reusableComponents/iconsSvg/sideBar/icons";
import SideBarItem from "./sideBarItem";
import { appRoutes } from "./sideBarRoutes";

const SideBar = () => {
  const [isactive, setIsActive] = useState({});
  const dispatch = useDispatch();

  const [adminInf, setAdminInf] = useState();
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    setAdminInf(null);
    resolveRequest(getAdminInf, setAdminInf);
  }, [reload]);
  return (
    <>
      {adminInf?.data ? (
        <div
          style={{
            backgroundColor: "white",
            padding: 5,
            maxWidth: "100%",
            paddingRight: 20,
          }}
        >
          {appRoutes.map((item, index) => {
            if (item.side) {
              const hasPermission = item?.permission?.some((permissionItem) => {
                return (
                  adminInf?.data?.permissions
                    ?.map((f) => f?.id)
                    ?.includes(permissionItem) || permissionItem == 0
                );
              });
              if (hasPermission !== false)
                return (
                  <NavLink
                    key={index}
                    to={item.link}
                    style={{ cursor: "pointer", textDecoration: "none" }}
                    onClick={() => {
                      dispatch(clearActiveState());
                      dispatch(clearActiveDetailsState());
                    }}
                  >
                    {({ isActive }) => (
                      <SideBarItem
                        color={isActive ? "#010A14B2" : "#010A144D"}
                        title={item.name}
                        active={isactive}
                        backgroundColor={isActive ? "#7ABA4C" : "white"}
                      >
                        {item.icon(isActive ? "white" : "rgba(1,10,20,0.3)")}
                      </SideBarItem>
                    )}
                  </NavLink>
                );
            }
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SideBar;
