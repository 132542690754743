import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const farmAPI = "/admin/v1/farms";

const farmerAPI = "/admin/v1/farmers";
export const getFarmerFarms = async ({
  farmer_id,
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);

    return dataFormatPagination(
      await axiosCall.get(
        `${farmerAPI}/${farmer_id}/farms?page=${pageNumber}&per_page=${pageCount}&${filters}`
      )
    );
  } catch (e) {
    throw e;
  }
};

export const getAllFarms = async ({
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);

    return dataFormatPagination(
      await axiosCall.get(
        `${farmAPI}?page=${pageNumber}&per_page=${pageCount}&${filters}`
      )
    );
  } catch (e) {
    throw e;
  }
};

export const getFarmDetails = async ({ farm_id }) => {
  try {
    return await axiosCall.get(`${farmAPI}/${farm_id}`);
  } catch (e) {
    throw e;
  }
};
export const deleteFarm = async ({ farm_id }) => {
  try {
    return await axiosCall.delete(`${farmAPI}/${farm_id}`);
  } catch (e) {
    throw e;
  }
};
export const getFarmLog = async ({
  farm_id,
  pageNumber = 1,
  pageCount = 7,
}) => {
  try {
    return dataFormatPagination(
      await axiosCall.get(
        `${farmAPI}/${farm_id}/log?page=${pageNumber}&per_page=${pageCount}`
      )
    );
  } catch (e) {
    throw e;
  }
};

export const getActivateFarm = async ({ farm_id }) => {
  try {
    return await axiosCall.put(`${farmAPI}/${farm_id}/activate`);
  } catch (e) {
    throw e;
  }
};

export const houseFarm = async ({ farm_id, body }) => {
  try {
    console.log(body);
    return dataFormat(
      await axiosCall.post(
        `${farmAPI}/${farm_id}/housing`,
        JSON.stringify(body)
      )
    );
  } catch (e) {
    throw e;
  }
};

export const rejectFarm = async ({ farm_id }) => {
  try {
    return dataFormat(await axiosCall.put(`${farmAPI}/${farm_id}/reject`));
  } catch (e) {
    throw e;
  }
};

export const addFarm = async ({ body }) => {
  try {
    console.log(body);
    const data = await axiosCall.post(`${farmAPI}/store`, objToFormData(body));
    return dataFormat(data);
  } catch (e) {
    throw e;
  }
};

export const updateFarm = async ({ body, farm_id }) => {
  try {
    console.log(body);
    const data = await axiosCall.post(
      `${farmAPI}/${farm_id}`,
      objToFormData(body),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return dataFormat(data);
  } catch (e) {
    throw e;
  }
};

export const createOffer = async ({ farm_id, body }) => {
  try {
    console.log(body);
    return dataFormat(
      await axiosCall.post(`${farmAPI}/${farm_id}/offer`, JSON.stringify(body))
    );
  } catch (e) {
    throw e;
  }
};
export const showOffer = async ({ farm_id }) => {
  try {
    const data = await axiosCall.get(`${farmAPI}/${farm_id}/offer`);
    return dataFormat(data);
  } catch (e) {
    throw e;
  }
};

export const updateOffer = async ({ farm_id, body }) => {
  try {
    console.log(body);
    return dataFormat(
      await axiosCall.put(`${farmAPI}/${farm_id}/offer`, JSON.stringify(body))
    );
  } catch (e) {
    throw e;
  }
};

export const rejectOffer = async ({ farm_id }) => {
  try {
    return dataFormat(
      await axiosCall.patch(`${farmAPI}/${farm_id}/offer/cancel`)
    );
  } catch (e) {
    throw e;
  }
};
