import React from "react";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import RedBG from "../../reusableComponents/iconsSvg/redBG";
export const AccountsNPrizesCard = ({
  BG,
  text,
  date,
  farmer_val,
  offer,
  moneyPoint,
  transaction_type_id,
  trader_val,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        border: "1px solid #C2C2C2",
        boxShadow: "10px 10px 50px #c1cfd924",
        padding: 10,
        paddingRight: 20,
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            // ...useResponsiveWidth({ tabletDesktop: 10, tabletMobile: 20 }),
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", top: "30%", right: "30%" }}>
            <ChickenIcon color="white" />
          </div>
          {BG}
        </div>
        <div
          style={{
            // ...useResponsiveWidth({ tabletDesktop: 90, tabletMobile: 80 }),
            paddingRight: 20,
          }}
        >
          <p style={{ fontSize: 16 }}>{text}</p>
          {offer != null && (
            <>
              <p style={{ fontSize: 16, marginTop: 10 }}>
                {offer?.dajin?.type}
              </p>
              <p style={{ fontSize: 16, marginTop: 10 }}>
                الكمية :{offer?.amount}
              </p>
            </>
          )}
          <p style={{ fontSize: 16, color: "#8C8C8C", marginTop: 10 }}>
            {date}
          </p>
        </div>
      </div>
      {transaction_type_id == 1 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid black",
              padding: 20,
              borderRadius: 10,
            }}
          >
            <p>عمولة المربى</p>
            <p
              style={{
                color: Math.sign(farmer_val) == 1 ? "green" : "red",
                marginRight: 10,
              }}
            >
              {farmer_val}ج.م
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid black",
              padding: 20,
              borderRadius: 10,
              marginRight: 10,
            }}
          >
            <p>عمولة التاجر</p>
            <p
              style={{
                color: Math.sign(trader_val) == 1 ? "green" : "red",
                marginRight: 10,
              }}
            >
              {trader_val}ج.م
            </p>
          </div>
        </div>
      ) : (
        <div>
          <p
            style={{
              color: Math.sign(trader_val) == 1 ? "green" : "red",
              marginLeft: 20,
            }}
          >
            {trader_val}ج.م
          </p>
          {transaction_type_id == 5 ? (
            <p
              style={{
                marginLeft: 20,
              }}
            >
              {"النقطة = " + moneyPoint + "ج.م"}
            </p>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
