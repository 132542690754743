import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  getActivateFarm,
  getFarmDetails,
  rejectFarm,
} from "../../api/store/actions/saga/farms";
import { EditIcon } from "../../reusableComponents/iconsSvg/tableI/tableIcons";
import Loading from "../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { FarmHorizontalCardDetails } from "../../screenParts/farms/farmHorizontalCardDetails";
import { StatusNButtonsSection } from "../../screenParts/farms/statusNButtonsSection";
import ListHeader from "../../screenParts/listingViews/listHeader";

export const FarmDetailsScreen = () => {
  const [farmDetails, setFarmDetails] = useState();
  const [activateFarm, setActivateFarm] = useState();
  const [rejecFarmVal, setRejectFarm] = useState();
  const reload = useSelector((state) => state.reload.reload);

  const { farm_id } = useParams();

  useEffect(() => {
    resolveRequest(getFarmDetails, setFarmDetails, { farm_id: farm_id });
  }, [activateFarm, rejecFarmVal, reload]);
  console.log("farm details", farmDetails);

  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      {farmDetails ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListHeader
              hasTitle={true}
              title={"تفاصيل العنبر"}
              back
              hasAdd={false}
              hasFilter={false}
              hasSearch={false}
            />
            <Link
              to={`/farmers/farmDetails/editFarm/${farmDetails?.data?.data?.farm?.id}`}
            >
              <EditIcon />
            </Link>
          </div>
          <div>
            <SectionLabel content={"تفاصيل العنبر"} />
            <FarmHorizontalCardDetails
              photo={farmDetails?.data?.data?.farm?.photo}
              farm={farmDetails?.data?.data?.farm}
              options={farmDetails?.data?.data?.farm?.facilities}
              name={farmDetails?.data?.data?.farm?.name}
              license={
                farmDetails?.data?.data?.farm?.licensed == true
                  ? "مرخص"
                  : "غير مرخص" ?? "لا يوجد"
              }
              city={farmDetails?.data?.data?.farm?.city}
              poultry_farming_expected_period={
                farmDetails?.data?.data?.farm?.poultry_farming_expected_period
              }
              ventilation_type={
                farmDetails?.data?.data?.farm?.ventilation_type?.type
              }
              ownership={
                farmDetails?.data?.data?.farm?.rented == true
                  ? "ايجار"
                  : "تمليك"
              }
              rental_end_date={
                farmDetails?.data?.data?.farm?.rental_end_date
                  ? moment(
                      farmDetails?.data?.data?.farm?.rental_end_date
                    ).format("YYYY-MM-DD")
                  : ""
              }
              rental_start_date={
                farmDetails?.data?.data?.farm?.rental_start_date
                  ? moment(
                      farmDetails?.data?.data?.farm?.rental_start_date
                    ).format("YYYY-MM-DD")
                  : ""
              }
            />

            {
              <StatusNButtonsSection
                create_offer_link={`/farmers/farmDetails/${farmDetails?.data?.data?.farm?.id}/createOffer/${farmDetails?.data?.data?.farm_state?.data?.breed?.dajin?.id}`}
                edit_offer_link={`/farmers/farmDetails/editOffer/${farmDetails?.data?.data?.farm_state?.data?.breed?.dajin?.id}/${farmDetails?.data?.data?.farm?.id}/${farmDetails?.data?.data?.offer_details?.id}`}
                housing_link={`/farmers/farmDetails/${farmDetails?.data?.data?.farm?.id}/housing`}
                onRejectFarm={async () => {
                  resolveRequest(rejectFarm, setRejectFarm, {
                    farm_id: farm_id,
                  });
                }}
                farm_state={farmDetails?.data?.data?.farm_state?.data}
                offer_details={farmDetails?.data?.data?.offer_details}
                status={farmDetails?.data?.data?.farm?.status}
                onActiveFarm={() => {
                  resolveRequest(getActivateFarm, setActivateFarm, {
                    farm_id: farm_id,
                  });
                }}
              />
            }
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
