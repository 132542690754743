import React from "react";

const SectionLabel = ({ content, lineColor, require }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <p style={{ color: "#7ABA4C", fontSize: 18, flex: "0 0 auto" }}>
        {content} {require ? <span>*</span> : null}
      </p>
      {content ? (
        <div
          style={{
            flex: "1 1 auto",
            backgroundColor: lineColor ?? "#7ABA4C",
            height: 1,
            marginLeft: 10,
            marginRight: 10,
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default SectionLabel;
