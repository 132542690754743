import SectionLabel from "../../reusableComponents/sectionLabel";
import { MyEditor } from "../../reusableComponents/editor";
import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { useNavigate } from "react-router-dom";

import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  createNews,
  getNewsDetails,
  updateNews,
} from "../../api/store/actions/saga/news";
import avatar from "../../assets/avatar.jpg";
import { dateToIso } from "../../alogrithms/dateAlgo";
import { useParams } from "react-router";
import Loading from "../../reusableComponents/loadingScreen/loading";
import { getCategories } from "../../api/store/actions/saga/categories";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import TextInput from "../../reusableComponents/textInput";
import TimeInput from "../../reusableComponents/timeInput";
import { deleteEmptyProps } from "../../alogrithms/requests";
import PhotoSelect from "../../screenParts/photoSelect";
import CustomDatePicker from "../../reusableComponents/dateInput/dateInput";
function EditNews({ isEdit }) {
  const width = useResponsiveWidth({});
  const navigate = useNavigate();
  const { id } = useParams();

  const [newsDetails, setNewsDetails] = useState();

  useEffect(() => {
    if (isEdit) {
      resolveRequest(getNewsDetails, setNewsDetails, { id: id });
    }
  }, []);

  const [categoriesData, setCategoriesData] = useState();
  const [checked, setChecked] = useState(false);
  const checktime = new Date(newsDetails?.data?.publish_at) < new Date();
  const options = [
    { id: 1, name: " نعم" },
    { id: 0, name: "لا" },
  ];
  useEffect(() => {
    resolveRequest(getCategories, setCategoriesData, "news");
  }, []);

  useEffect(() => {
    if (checktime) {
      setChecked(true);
    }
  }, [checktime]);

  const submit = async (data) => {
    data.publish_at = checked
      ? ""
      : dateToIso(new Date(values?.date + " " + values?.time));

    data.image =
      newsDetails?.data?.image_url == values.image ? null : values?.image;
    let res = {};
    isEdit
      ? (res = await updateNews({ body: deleteEmptyProps(data), id: id }))
      : (res = await createNews({ body: data }));
    console.log("res", res);
    setBackEndErrors(res?.errors, setFieldError);
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? newsDetails?.data : {},
      validationSchema,
      "createNews",
      submit
    )
  );

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    setFieldError,
  } = formik;
  console.log("values", values);
  const isValid = formikFieldValidation(touched, errors);
  console.log("JJJJJJJJ", checked);
  return (
    <>
      {categoriesData ? (
        <div
          style={{
            padding: 10,
            backgroundColor: "white",
          }}
        >
          <SectionLabel content={"تفاصيل الخبر"} />

          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "50%", padding: 20 }}>
                <p style={{ marginBottom: 10, marginTop: 10 }}>العنوان *</p>
                <TextInput
                  value={values?.title}
                  placeholder={"اكتب العنوان"}
                  onChange={handleChange("title")}
                  errorMessage={isValid("title")}
                />

                <p style={{ marginBottom: 10, marginTop: 10 }}>القسم *</p>
                <SelectDropdown
                  placeholder={"اختر"}
                  errorMessage={isValid("category_id")}
                  responsiveWidth={{ width }}
                  selectedItem={values.category_id}
                  dataSource={categoriesData?.data}
                  onSelect={(e) => {
                    setFieldValue("category_id", e);
                  }}
                />
              </div>
              <div
                style={{
                  width: "50%",
                  padding: 20,
                }}
              >
                <PhotoSelect
                  require
                  errorMessage={isValid("image")}
                  title={"صورة الخبر"}
                  src={values.image ?? avatar}
                  onChange={(e) => {
                    setFieldValue("image", e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <SectionLabel content={"الخبر"} />
            <MyEditor
              placeholder={"اكتب الخبر"}
              content={isEdit ? newsDetails?.data?.content ?? "" : ""}
              onChange={(e) => {
                setFieldValue("content", e);
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  paddingTop: 20,
                }}
              >
                <SectionLabel content={"النشر"} />
                <p style={{ marginBottom: 10, marginTop: 10 }}>تاريخ النشر</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{
                      width: "40%",

                      height: 54,
                      border: 0,
                      borderRadius: 10,
                      backgroundColor: "#FBFBFB",
                      color: "#7ABA4C",
                      paddingRight: 20,
                      paddingLeft: 20,
                    }}
                    onChange={handleChange("date")}
                    value={values["date"]}
                    dir="rtl"
                    type="date"
                    name="date"
                    placeholder="اكتب العنوان"
                  />

                  <TimeInput
                    responsiveWidth={{ ...width }}
                    value={values.time}
                    name={"name"}
                    onChange={handleChange("time")}
                  />
                  {/* <input
                    style={{
                      width: "40%",
                      height: 54,
                      border: 0,
                      borderRadius: 10,
                      backgroundColor: "#FBFBFB",
                      color: "#7ABA4C",
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginRight: 20,
                    }}
                    onChange={handleChange("time")}
                    value={values["time"]}
                    dir="rtl"
                    type="time"
                    name="time"
                    placeholder="اكتب العنوان"
                  /> */}
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      justifyContent: "flex-end",
                      paddingRight: 10,
                    }}
                  >
                    <p style={{ marginRight: 3, marginLeft: 3 }}>الآن</p>
                    <input
                      type="checkbox"
                      name="dateNow"
                      value={checked}
                      checked={checked}
                      onChange={() => {
                        setChecked((prevState) => !prevState);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  paddingTop: 20,
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <SectionLabel content={"التعليقات"} />
                <p style={{ marginBottom: 10, marginTop: 10 }}>
                  الموافقة على نشر التعليقات *
                </p>

                <SelectDropdown
                  schema={{ id: "id", name: "name" }}
                  placeholder={"اختر"}
                  responsiveWidth={{ ...width }}
                  selectedItem={values.commentApproved}
                  dataSource={options}
                  errorMessage={isValid("allow_comments")}
                  onSelect={(e) => {
                    setFieldValue("allow_comments", e);
                  }}
                />
              </div>
            </div>

            <p style={{ color: "red" }}>{isValid("content")}</p>

            <div style={{ display: "flex", marginTop: 20 }}>
              <Button type={"submit"} style={{ width: "20%" }}>
                {!isEdit ? "إضافة" : "تعديل"}
              </Button>

              <Button
                onClick={() => {
                  navigate(-1);
                }}
                // type={"reset"}
                backgroundColor="#F4F4F4"
                textColor="#9F9F9F"
                style={{ marginRight: 20, width: "20%" }}
              >
                إلغاء
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default EditNews;
