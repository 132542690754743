import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { moneyForPoint } from "../../api/store/actions/saga/settings";
import {
  getAllTraderAccounts,
  getTraderBalance,
} from "../../api/store/actions/saga/traders";
import Button from "../../reusableComponents/button";
import BlueBG from "../../reusableComponents/iconsSvg/blueBG";
import GreenBG from "../../reusableComponents/iconsSvg/greenBG";
import OrangeBG from "../../reusableComponents/iconsSvg/orangeBG";
import RedBG from "../../reusableComponents/iconsSvg/redBG";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { AccountsNPrizesCard } from "../../screenParts/accountsNPrizes/accountsNPrizesCard";
import ListHeader from "../../screenParts/listingViews/listHeader";
import { useTablet } from "../salesTransactions/salesDetails";
export const AccountBalance = ({ isFromAccount }) => {
  const { user_id } = useParams();
  const [accountsData, setAccountsData] = useState();
  const [balancesData, setBalanceData] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [moneyPoint, setMoneyPoint] = useState();

  const reload = useSelector((state) => state.reload.reload);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  useEffect(() => {
    resolveRequest(getTraderBalance, setBalanceData, {
      user_id: user_id,
    });
    resolveRequest(moneyForPoint, setMoneyPoint);
  }, [reload]);
  useEffect(() => {
    resolveRequest(getAllTraderAccounts, setAccountsData, {
      user_id: user_id,
      pageNumber: currentPage,
    });
  }, [currentPage, reload]);
  console.log("accountsData", accountsData);
  console.log("balanceData", balancesData);

  const widthGreen = useResponsiveWidth({
    desktop: 76,
    tablet: 76,
    mobile: 76,
    defaults: 76,
  });
  const istablet = useTablet();

  const widthButtons = useResponsiveWidth({
    desktop: !istablet ? 30 : 40,
    tablet: !istablet ? 30 : 40,
    mobile: !istablet ? 30 : 40,
    defaults: !istablet ? 30 : 40,
  });
  let location = useLocation();
  return (
    <>
      {accountsData && balancesData ? (
        <div style={{ padding: 20 }}>
          {isFromAccount && (
            <ListHeader
              hasTitle={true}
              title={"تفاصيل الحساب"}
              back
              hasAdd={false}
              hasFilter={false}
              hasSearch={false}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: istablet ? "column" : "row",
              // justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                // height: 105,
                flexDirection: istablet ? "column" : "row",

                backgroundColor: "#7ABA4C",
                display: "flex",
                justifyContent: "space-between",
                ...widthGreen,
                padding: 20,
                borderRadius: 10,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontSize: 25, color: "white" }}>الرصيد</p>
                <div style={{ marginRight: 20 }}>
                  {" "}
                  <p
                    style={{ fontSize: 25, color: "white", fontWeight: "bold" }}
                  >
                    {" "}
                    {balancesData?.data?.balance}
                  </p>
                  <p style={{ fontSize: 12, color: "white" }}>جنيه مصري</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",

                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: 10,
                    marginRight: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    paddingTop: 10,
                    paddingBottom: 10,
                    width: 150,
                  }}
                >
                  {" "}
                  <p
                    style={{
                      fontSize: 30,
                      color: "#646D82",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {balancesData?.data?.total_commision}
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      color: "#646D82",
                      textAlign: "center",
                    }}
                  >
                    اجمالي العمولات
                  </p>
                </div>
                <div
                  style={{
                    borderRadius: 10,
                    marginRight: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    paddingTop: 10,
                    paddingBottom: 10,
                    width: 150,
                  }}
                >
                  {" "}
                  <p
                    style={{
                      fontSize: 30,
                      color: "#646D82",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {balancesData?.data?.total_points}
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      color: "#646D82",
                      textAlign: "center",
                    }}
                  >
                    اجمالي النقاط
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                ...widthButtons,
                display: "flex",
                flexDirection: istablet ? "row" : "column",
              }}
            >
              <Link
                to={`addBalance`}
                style={{ display: "contents" }}
                state={{
                  backgroundLocation: location,
                }}
              >
                <Button
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                >
                  إضافة رصيد
                </Button>
              </Link>
              <Link
                to={"addPrize"}
                style={{ display: "contents" }}
                state={{
                  backgroundLocation: location,
                }}
              >
                <Button
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                  backgroundColor="white"
                  textColor="#7ABA4C"
                  buttonStyles={{ border: "1px solid #7ABA4C" }}
                >
                  إضافة نقاط
                </Button>
              </Link>
            </div>
          </div>
          <div>
            <SectionLabel content={"تفاصيل الحساب"} />
            {accountsData?.data?.map((item) => {
              return (
                <AccountsNPrizesCard
                  offer={item?.order}
                  BG={
                    item?.transaction_type?.id == 1 ? (
                      <RedBG />
                    ) : item?.transaction_type?.id == 2 ? (
                      <OrangeBG />
                    ) : item?.transaction_type?.id == 3 ? (
                      <BlueBG />
                    ) : (
                      <GreenBG />
                    )
                  }
                  moneyPoint={moneyPoint?.data?.money_value_for_one_point}
                  text={`${item?.transaction_type?.type}: ${item?.description}`}
                  date={item?.created_at}
                  transaction_type_id={item?.transaction_type?.id}
                  farmer_val={item?.value_for_farmer}
                  trader_val={item?.value_for_trader}
                  color="red"
                />
              );
            })}
            {setCurrentPage && (
              <AppPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={Math.ceil(accountsData?.total / parseInt(7))}
              />
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
