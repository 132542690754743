import { Rating } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  getAllFarmerRatings,
  getFarmerRatingDetails,
} from "../../api/store/actions/saga/farmer";
import {
  getPriceByDajin,
  getPriceByDajinFinal,
} from "../../api/store/actions/saga/settings";
import {
  getAllTraderRatings,
  getTraderRatingsDetails,
} from "../../api/store/actions/saga/traders";
import Button from "../../reusableComponents/button";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import FacebookIcon from "../../reusableComponents/iconsSvg/facebookIcon";
import FeaturedIcon from "../../reusableComponents/iconsSvg/featuredIcon";
import LocationIcon from "../../reusableComponents/iconsSvg/locationIcon";
import PhoneIcon from "../../reusableComponents/iconsSvg/phoneIcon";
import StarIcon from "../../reusableComponents/iconsSvg/starIcon";
import TimeIcon from "../../reusableComponents/iconsSvg/timeIcon";
import WhatsAppIcon from "../../reusableComponents/iconsSvg/whatsappIcon";
import Loading from "../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { CardsCommonItem } from "../../screenParts/cardsCommonItem";
import { LabelWIcon } from "../../screenParts/farms/labelWIcon";
import { ChickenCard } from "../../screenParts/farms/offers/chickenCard";
import ListHeader from "../../screenParts/listingViews/listHeader";
import { useTablet } from "../salesTransactions/salesDetails";
export const RatingDetails = ({ isFarmer }) => {
  const [ratingData, setRatingData] = useState(null);
  const { farmer_id, user_id, rating_id } = useParams();
  const isTablet = useTablet();
  const [dajinPrice, setDajinPrice] = useState();
  const [dajinPriceFinal, setDajinPriceFinal] = useState();
  const halfWidth = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  useEffect(() => {
    // setFarmersData(null);
    if (isFarmer)
      resolveRequest(getFarmerRatingDetails, setRatingData, {
        user_id: farmer_id,

        rating_id: rating_id,
      });
    else
      resolveRequest(getTraderRatingsDetails, setRatingData, {
        user_id: user_id,

        rating_id: rating_id,
      });
  }, [isFarmer]);
  console.log(ratingData);
  useEffect(() => {
    if (ratingData) {
      resolveRequest(getPriceByDajin, setDajinPrice, {
        dajin_id: ratingData?.data?.order_details?.dajin_type?.id,
      });
      resolveRequest(getPriceByDajinFinal, setDajinPriceFinal, {
        dajin_id: ratingData?.data?.order_details?.dajin_type?.id,
      });
    }
  }, [ratingData]);
  console.log("dajinPrice", dajinPrice);

  console.log("dajinPriceFinal", dajinPriceFinal);

  return (
    <>
      {ratingData ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListHeader
              hasTitle={true}
              title={"تفاصيل العرض"}
              back
              hasAdd={false}
              hasFilter={false}
              hasSearch={false}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <TimeIcon />
              <p style={{ marginRight: 10 }}> {ratingData?.data?.created_at}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "column" : "row",
            }}
          >
            {" "}
            <div
              style={{
                ...halfWidth,
              }}
            >
              {!isFarmer && (
                <div>
                  <SectionLabel content={"تفاصيل العنبر"} />
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <img
                        style={{ width: 140, height: 140, borderRadius: 10 }}
                        src={
                          ratingData?.data?.farm?.photo ??
                          "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                        }
                      />
                    </div>
                    <div style={{ flex: 3, marginRight: 10 }}>
                      <div
                        style={{
                          display: "flex",

                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ marginLeft: 5 }}>
                            {ratingData?.data?.farm?.name}
                          </p>
                          {ratingData?.data?.farm?.trusted && <FeaturedIcon />}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 20,
                          }}
                        >
                          <StarIcon />

                          <p style={{ marginRight: 10 }}>
                            {ratingData?.data?.farm?.rating?.toFixed(1)}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <LocationIcon />
                        <p style={{ marginRight: 10 }}>
                          {ratingData?.data?.farm?.city?.name} ,{" "}
                          {ratingData?.data?.farm?.city?.governorate?.name}
                        </p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <PhoneIcon />
                        <p
                          style={{
                            marginRight: 15,
                            marginLeft: 15,
                            color: "#646D82",
                          }}
                        >
                          {ratingData?.data?.farm?.farmer?.mobile_number}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 10,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <WhatsAppIcon />
                          <p
                            style={{
                              marginRight: 15,
                              marginLeft: 15,
                              color: "#646D82",
                            }}
                          >
                            {ratingData?.data?.farm?.farmer?.whatsapp_number}
                          </p>
                        </div>
                        <div style={{ marginRight: 10, marginLeft: 10 }}>
                          <Button
                            backgroundColor="transparent"
                            onClick={() => {
                              window.open(
                                ratingData?.data?.farm?.farmer
                                  ?.facebook_account ?? ""
                              );
                            }}
                          >
                            <FacebookIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isFarmer && (
                <div>
                  <SectionLabel content={"تفاصيل التاجر"} />
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <img
                        style={{ width: 140, height: 140, borderRadius: 10 }}
                        src={
                          ratingData?.data?.rater?.avatar ??
                          "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                        }
                      />
                    </div>
                    <div style={{ flex: 3, marginRight: 10 }}>
                      <div
                        style={{
                          display: "flex",

                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ marginLeft: 5 }}>
                            {ratingData?.data?.farm?.name}
                          </p>
                          {ratingData?.data?.rater?.featured && (
                            <FeaturedIcon />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <Rating
                          value={ratingData?.data?.rater?.total_ratings}
                          readOnly
                        />

                        <p style={{ marginRight: 10 }}>
                          {ratingData?.data?.rater?.total_ratings?.toFixed(1)}
                        </p>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        {" "}
                        <PhoneIcon />
                        <p
                          style={{
                            marginRight: 15,
                            marginLeft: 15,
                            color: "#646D82",
                          }}
                        >
                          {ratingData?.data?.rater?.mobile_number}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 10,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <WhatsAppIcon />
                          <p
                            style={{
                              marginRight: 15,
                              marginLeft: 15,
                              color: "#646D82",
                            }}
                          >
                            {ratingData?.data?.rater?.whatsapp_number}
                          </p>
                        </div>
                        <div style={{ marginRight: 10, marginLeft: 10 }}>
                          <Button
                            backgroundColor="transparent"
                            onClick={() => {
                              window.open(
                                ratingData?.data?.rater?.facebook_account ?? ""
                              );
                            }}
                          >
                            <FacebookIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                ...halfWidth,
              }}
            >
              <SectionLabel content={"بيانات العنبر"} />

              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <img
                    style={{ width: 140, height: 140, borderRadius: 10 }}
                    src={
                      ratingData?.data?.farm?.photo ??
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                    }
                  />
                </div>
                <div style={{ flex: 3, marginRight: 10 }}>
                  <div style={{}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginLeft: 5 }}>
                        {ratingData?.data?.farm?.name}
                      </p>
                      {ratingData?.data?.farm?.trusted && <FeaturedIcon />}
                    </div>
                    <CardsCommonItem
                      icon={<ChickenIcon />}
                      text={ratingData?.data?.order_details?.amount}
                      marginTop={20}
                    />
                    <CardsCommonItem
                      icon={<ChickenIcon />}
                      text={ratingData?.data?.order_details?.price}
                      marginTop={20}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <SectionLabel content={"تفاصيل الطلب"} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <LabelWIcon
                icon={<ChickenIcon />}
                text={` العدد: ${ratingData?.data?.order_details?.amount}`}
              />
              <LabelWIcon
                icon={<ChickenIcon />}
                text={` النوع: ${ratingData?.data?.order_details?.dajin_type?.type}`}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <LabelWIcon
                icon={<ChickenIcon />}
                text={` متوسط الوزن: ${ratingData?.data?.order_details?.average_weight}`}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <LabelWIcon icon={<ChickenIcon />} text={` نطاق الشراء: `} />
              <div style={{ display: "flex", marginTop: 5, flexWrap: "wrap" }}>
                {ratingData?.data?.order_details?.governorates?.map((item) => (
                  <p
                    style={{
                      width: 200,
                      padding: 5,
                      borderRadius: 10,
                      marginRight: 10,
                      backgroundColor: "lightgray",
                      marginTop: 5,
                      textAlign: "center",
                    }}
                  >
                    {item?.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "column" : "row",
            }}
          >
            <div
              style={{
                ...halfWidth,
              }}
            >
              <div>
                {/*  <SectionLabel content={"حالة الطلب "} />
                <p
                  style={{
                    backgroundColor: "#8C8C8C",
                    padding: 10,
                    color: "white",
                    borderRadius: 20,
                    width: 150,
                    textAlign: "center",
                  }}
                >
                  {ratingData?.data?.order_request_status}
                </p> */}
                <SectionLabel content={"سعر البورصة"} />
                <p style={{ textAlign: "center", fontSize: 10 }}>
                  أسعار البورصة قابلة للتعديل ثم يخرج سعر اليوم النهائي و لذلك
                  قد يختلف السعر من وقت لاخر حتى حتى الساعه 3م من كل يوم
                </p>
                <ChickenCard
                  dajinType={ratingData?.data?.order_details?.dajin_type?.type}
                  price={
                    dajinPriceFinal?.data?.data?.final_price ??
                    dajinPrice?.data?.indicative_price
                  }
                  date={moment(dajinPrice?.data?.created_at).format(
                    "YYYY-MM-DD"
                  )}
                />
              </div>
            </div>
            <div
              style={{
                ...halfWidth,
              }}
            >
              <SectionLabel
                content={!isFarmer ? "تقييم التاجر " : "تقييم المربى "}
              />

              {ratingData?.data?.values?.map((item) => (
                <div
                  style={{ display: "flex", marginTop: 20, marginBottom: 20 }}
                >
                  <p style={{ marginLeft: 10, width: 100 }}>
                    {item?.dimension?.dimension}
                  </p>
                  <Rating value={item?.value} readOnly dir="rtl" />{" "}
                </div>
              ))}

              <p>التعليق</p>
              <p
                style={{
                  marginTop: 10,
                  padding: 10,
                  border: "1px solid #C2C2C2",
                  borderRadius: 10,
                }}
              >
                {ratingData?.data?.comment}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
