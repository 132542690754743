const NoData = ({ title = "لا يوجد بيانات حاليا" }) => {
  return (
    <div>
      <p style={{ textAlign: "center", marginTop: 20, marginBottom: 10 }}>
        {title}
      </p>
    </div>
  );
};

export default NoData;
