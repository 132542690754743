import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const farmerAPI = "/admin/v1/farmers";
export const getAllFarmers = async ({
  pageNumber = 1,
  pageCount = 7,
  params = {},
  sort = "",
}) => {
  try {
    let filters = concatFilters(params);
    console.log("filters :", filters);
    let data = await axiosCall.get(
      `${farmerAPI}?sort=${sort}&page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getAllFarmerRatings = async ({
  user_id,
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);
    console.log("user_id", user_id);
    return dataFormatPagination(
      await axiosCall.get(
        `${farmerAPI}/${user_id}/ratings?page=${pageNumber}&per_page=${pageCount}&${filters}`
      )
    );
  } catch (e) {
    throw e;
  }
};
export const getFarmerRatingDetails = async ({ user_id, rating_id }) => {
  try {
    return dataFormat(
      await axiosCall.get(`${farmerAPI}/${user_id}/ratings/${rating_id}`)
    );
  } catch (e) {
    throw e;
  }
};
export const getFarmerDetails = async ({ farmer_id }) => {
  try {
    let data = await axiosCall.get(`${farmerAPI}/${farmer_id}/account-details`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getAllFarmerOffer = async ({
  pageNumber = 1,
  pageCount = 9,
  params = {},
  farmer_id,
}) => {
  try {
    let filters = concatFilters(params);

    let data = await axiosCall.get(
      `${farmerAPI}/${farmer_id}/requests?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getFarmerOfferDetails = async ({ order_id }) => {
  console.log("order_id");

  try {
    let data = await axiosCall.get(`${farmerAPI}/requests/${order_id}/details`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const createFarmer = async ({ body }) => {
  console.log("body", body);
  try {
    let data = await axiosCall.post(`${farmerAPI}/create`, objToFormData(body));
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateFarmer = async ({ body, farmer_id }) => {
  console.log("body", body);
  try {
    let data = await axiosCall.post(
      `${farmerAPI}/${farmer_id}/update`,
      objToFormData(body),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const farmerChangeAccountStatus = async ({ farmer_id, body }) => {
  try {
    return await axiosCall.put(
      `${farmerAPI}/${farmer_id}/status`,
      JSON.stringify(body)
    );
  } catch (e) {
    throw e;
  }
};
