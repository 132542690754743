import React from "react";
import Button from "../../reusableComponents/button";
import SortIcon from "../../reusableComponents/iconsSvg/sortIcon";
import { propsMapper } from "../../utlity/GeneralProps";

const TableHeadItem = ({ item, objName,onClick }) => {
  return (
    <th scope="col" title={item}>
      {
        item == 'total_rating' ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {propsMapper[objName][item]}
          <span style={{ cursor: 'pointer', paddingRight: 5, paddingLeft: 5 ,width:20,height:20}} onClick={onClick}>
            <SortIcon /></span>

        </div> : propsMapper[objName][item]}
    </th>
  );
};

export default TableHeadItem;
