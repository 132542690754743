import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/redemption/requests`;

export const redeemtionRequests = async ({
  pageNumber: pageNumber = 1,
  pageCount: pageCount = 7,
  params: params = {},
}) => {
  try {
    let filters = concatFilters(params);

    const data = await axiosCall.get(
      `${apiUrl}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const approveRedeem = async ({ id }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${id}/confirm`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const disApproveRedeem = async ({ id }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${id}/disconfirm`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
