import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getAllSettings } from "../../api/store/actions/saga/settings";
import { getAllTraders } from "../../api/store/actions/saga/traders";
import { clearActiveDetailsState } from "../../api/store/reducers/reload";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";
import BlockedAccountsScreen from "./blockedAccountsScreen";
import NewAccountsScreen from "./newAccounts";

const TradersList = () => {
  const activeState = useSelector((state) => state?.reload?.active);
  const dispatch = useDispatch();
  const [active, setActive] = useState(activeState ?? 1);
  const [searchKey, setSearchKey] = useState("");
  const [tradersData, setTradersData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const [filterKey2, setSFilterKey2] = useState(null);

  const settingsData = useSelector((state) => state?.settings?.setting);
  const [sort, setSort] = useState("");
  useEffect(() => {
    dispatch(clearActiveDetailsState());
  }, []);

  useEffect(() => {
    resolveRequest(getAllTraders, setTradersData, {
      pageNumber: currentPage,
      params: { name: searchKey, work_areas: filterKey, tawreed: filterKey2 },
      sort: sort,
    });
  }, [currentPage, searchKey, filterKey, filterKey2, sort]);
  console.log("TraderssData", tradersData);

  const onChange = (value) => {
    setSearchKey(value);
  };
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
  };
  const onSelect2 = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey2(value);
  };
  return (
    <>
      {settingsData && (
        <ListHeader
          onSelect={onSelect}
          onSelect2={onSelect2}
          hasFilter2={true}
          dataSource2={[
            { name: "الكل", id: null },
            { name: "مورد", id: 1 },
            { name: "تاجر", id: 0 },
          ]}
          dataSource={
            settingsData
              ? [
                  { name: "الكل", id: null },
                  ...settingsData?.data?.data?.user?.trader?.work_areas,
                ]
              : []
          }
          // hasFilter={active === 1 || active === 2}
          //dataSource={categories?.data}
          addLink={"addTrader"}
          linkType={undefined}
          active={active}
          setActive={setActive}
          onChange={onChange}
          pages={[
            { title: "جميع الحسابات", id: 1 },
            { title: "حسابات جديدة", id: 2 },
            { title: "حسابات موقوفة", id: 3 },
          ]}
          // addLink={"addFarmer"}
        />
      )}

      {active == 1 ? (
        tradersData ? (
          <ListView
            onClick={() => {
              setSort(
                sort == "total_rating" ? "-total_rating" : "total_rating"
              );
            }}
            objName={"traders"}
            links={{
              // edit: "editNews",
              // delete: "",
              show: "traderDetails",
              edit: "editTrader",

              query: { show: "", delete: "", edit: "" },
            }}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={tradersData}
          />
        ) : (
          <Loading />
        )
      ) : active == 2 ? (
        <NewAccountsScreen searchKey={searchKey} filterKey={filterKey} />
      ) : active == 3 ? (
        <BlockedAccountsScreen searchKey={searchKey} filterKey={filterKey} />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default TradersList;
