import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import Button from "../../reusableComponents/button";
import LocationIcon from "../../reusableComponents/iconsSvg/locationIcon";
import MapIcon from "../../reusableComponents/iconsSvg/mapIcon";

export const FarmHorizontalCardDetails = ({
  photo,
  options,
  name,
  license,
  city,
  poultry_farming_expected_period,
  ventilation_type,
  ownership,
  rental_start_date,
  rental_end_date,
  farm,
}) => {
  const isSmall = useMediaQuery({ minWidth: 1200 });
  const location = useLocation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: !isSmall ? "column" : "row",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      <div style={{ flex: 1 }}>
        <img
          src={
            photo ??
            "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
          }
          style={{
            borderRadius: 10,
            ...useResponsiveWidth({
              desktop: 100,
              tablet: 100,
              mobile: 100,
              defaults: 100,
            }),
          }}
          height={150}
        />
      </div>
      <div
        style={{
          height: "auto",
          flex: 2.5,
          paddingRight: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <p style={{ fontSize: 20, marginTop: 10, marginBottom: 10 }}>{name}</p>
        <div style={{ display: "flex", marginTop: 10, marginBottom: 10 }}>
          <p style={{ fontSize: 15, fontWeight: "bold" }}>الترخيص </p>
          <p style={{ fontSize: 15, marginRight: 10 }}>{license} </p>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
            alignItems: "center",
          }}
        >
          <LocationIcon width="32.17" height="42" />
          <p style={{ fontSize: 20, marginRight: 10, width: 200 }}>
            {`${city?.governorate?.name} , ${city?.name}`}{" "}
          </p>
          {/*  <Button width={127} height={38} style={{ marginRight: 5 }}>
            تفاصيل العنوان
          </Button>*/}
          {farm?.latitude != null && farm?.longitude != null ? (
            <Link
              to={`/farmers/farmerDetails/mapScreen?lat=${farm?.latitude}&long=${farm?.longitude}&full_address=${farm?.full_address}`}
              style={{ display: "contents" }}
              state={{
                backgroundLocation: location,
              }}
            >
              <div
                style={{ cursor: "pointer", marginRight: 10, marginBottom: 5 }}
              >
                <MapIcon />
              </div>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          height: "auto",
          flex: 2,
          paddingRight: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        {poultry_farming_expected_period && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <p
              style={{
                color: "#7ABA4C",
                fontWeight: "bold",
              }}
            >
              مده دوره التربيه المتوقعه
            </p>
            <p style={{ marginRight: 20, marginLeft: 20 }}>
              {poultry_farming_expected_period}
            </p>
            <p
              style={{
                color: "#7ABA4C",
                fontWeight: "bold",
              }}
            >
              يوم
            </p>
          </div>
        )}
        {ventilation_type ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <p
              style={{
                color: "#7ABA4C",
                fontWeight: "bold",
              }}
            >
              نوع التهوئة
            </p>
            <p style={{ marginRight: 20, marginLeft: 20 }}>
              {ventilation_type}
            </p>
          </div>
        ) : (
          <></>
        )}

        {options != undefined && options?.length != 0 && (
          <div style={{ display: "flex" }}>
            <p
              style={{
                color: "#7ABA4C",
                fontWeight: "bold",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              المرافق
            </p>

            {options.map((item, index) => {
              return (
                <p
                  key={index}
                  style={{
                    backgroundColor: "#F8FAFA",
                    border: "1px solid #EDEDED",
                    padding: 10,
                    borderRadius: 10,
                    marginLeft: 5,
                    marginRight: index != 0 ? 5 : 0,
                  }}
                >
                  {item?.facility}
                </p>
              );
            })}
          </div>
        )}
      </div>
      <div style={{ flex: 2, paddingRight: 20 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <p
            style={{
              color: "#7ABA4C",
              fontWeight: "bold",
            }}
          >
            تبعية "ملكية" العنبر
          </p>
          <p style={{ marginRight: 20, marginLeft: 20 }}>{ownership}</p>
        </div>
        {ownership == true ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <p
                style={{
                  color: "#7ABA4C",
                  fontWeight: "bold",
                }}
              >
                تاريخ بداية الايجار
              </p>
              <p style={{ marginRight: 20, marginLeft: 20 }}>
                {rental_start_date ?? "DD/MM/YYYY"}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 15,
                marginBottom: 20,
              }}
            >
              <p
                style={{
                  color: "#7ABA4C",
                  fontWeight: "bold",
                }}
              >
                تاريخ نهاية الايجار
              </p>
              <p style={{ marginRight: 20, marginLeft: 20 }}>
                {rental_end_date ?? "DD/MM/YYYY"}
              </p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
