import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { arToEn } from "../../alogrithms";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";

import {
  createOffer,
  houseFarm,
  rejectOffer,
  showOffer,
  updateOffer,
} from "../../api/store/actions/saga/farms";
import {
  getAllSettings,
  getPriceByDajin,
  getPriceByDajinFinal,
} from "../../api/store/actions/saga/settings";
import Button from "../../reusableComponents/button";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import Loading from "../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../reusableComponents/sectionLabel";
import TextInput from "../../reusableComponents/textInput";
import { ChickenCard } from "../../screenParts/farms/offers/chickenCard";
import { PriceCard } from "../../screenParts/farms/offers/priceCard";
import AppModal from "../../screenParts/modal/customModal";
import { useTablet } from "../salesTransactions/salesDetails";

const CreateOffer = ({ isEdit }) => {
  const dispatch = useDispatch();
  const { farm_id, dajin_id, offer_id } = useParams();
  console.log("farm_id", farm_id);
  const navigate = useNavigate();
  const settingsData = useSelector((state) => state?.settings?.setting);
  const [offerDetails, setOfferDetails] = useState();

  const [dajinPrice, setDajinPrice] = useState();
  const [dajinPriceFinal, setDajinPriceFinal] = useState();
  const [price, setPrice] = useState();
  console.log("dajinPrice", dajinPrice);
  console.log("dajinPriceFinal", dajinPriceFinal);
  useEffect(() => {
    resolveRequest(getPriceByDajin, setDajinPrice, { dajin_id: dajin_id });
    resolveRequest(getPriceByDajinFinal, setDajinPriceFinal, {
      dajin_id: dajin_id,
    });
  }, []);
  useEffect(() => {
    if (isEdit) {
      resolveRequest(showOffer, setOfferDetails, { farm_id: farm_id });
    }
  }, []);
  useEffect(() => {
    setPrice(
      dajinPrice?.data?.final_price ?? dajinPrice?.data?.indicative_price
    );
  }, [dajinPrice]);
  useEffect(() => {
    console.log("settings:  ", settingsData?.data?.data);
  }, [settingsData]);
  const submit = async (values) => {
    console.log("submit values", values);
    const body = {
      count: arToEn(values?.count),
      /*  age: arToEn(values?.age), */
      average_weight: arToEn(values?.average_weight),
      dajin_health_id: values?.dajin_health_id,
      requested_price: price,
    };
    const res = await createOffer({ farm_id: farm_id, body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };
  const editSubmit = async (values) => {
    console.log("submit values", values);
    const body = {
      count: arToEn(values?.count),
      /*       age: arToEn(values?.age),
       */ average_weight: arToEn(values?.average_weight),
      dajin_health_id: values?.dajin_health_id,
      requested_price: price,
    };
    const res = await updateOffer({ farm_id: farm_id, body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? offerDetails?.data?.offer : undefined,
      validationSchema,
      !offerDetails?.data?.has_requests
        ? "createOfferFalse"
        : "createOfferTrue",
      !isEdit ? submit : editSubmit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log(values);
  const isValid = formikFieldValidation(touched, errors);
  const fullWidth = useResponsiveWidth({
    desktop: 100,
    tablet: 100,
    tabletDesktop: 100,
    tabletMobile: 100,
  });
  const halfWidth = useResponsiveWidth({
    desktop: 48,
    tablet: 100,
    tabletDesktop: 100,
    tabletMobile: 100,
  });
  const currentPrice =
    dajinPrice?.data?.final_price ?? dajinPrice?.data?.indicative_price;
  console.log("offerDetails", offerDetails);
  const rejectFun = async () => {
    await rejectOffer({ farm_id: farm_id });
  };

  return (
    <AppModal
      buttonTitle={!isEdit ? "إدخال" : "تعديل"}
      modalTitle={!isEdit ? "تسجيل عرض العنبر" : "تعديل عرض العنبر"}
    >
      {!isEdit || (isEdit && offerDetails) ? (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                ...halfWidth,
              }}
            >
              <p>العدد *</p>
              <TextInput
                placeholder={"0000"}
                errorMessage={isValid("count")}
                onChange={handleChange("count")}
                value={values["count"]}
              />

              {/*              <p>العمر *</p>
              <TextInput
                placeholder={"0000"}
                errorMessage={isValid("age")}
                onChange={handleChange("age")}
                value={values["age"]}
              /> */}
              {!isEdit || (isEdit && !offerDetails?.data?.has_requests) ? (
                <>
                  <p>متوسط الوزن *</p>
                  <TextInput
                    placeholder={"0000"}
                    errorMessage={isValid("average_weight")}
                    onChange={handleChange("average_weight")}
                    value={values["average_weight"]}
                  />

                  <p> الحالة الصحية *</p>
                  <SelectDropdown
                    dataSource={settingsData?.data?.data?.farms?.dajin_health}
                    selectedItem={values["dajin_health_id"]}
                    onSelect={(e) => {
                      setFieldValue("dajin_health_id", e);
                    }}
                    errorMessage={isValid("dajin_health_id")}
                    schema={{ id: "id", name: "status" }}
                    placeholder={"اختر"}
                    responsiveWidth={{ ...fullWidth }}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            {!isEdit || (isEdit && !offerDetails?.data?.has_requests) ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 30,
                  ...halfWidth,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ color: "#114634", fontSize: 10 }}>
                    {" "}
                    * سعر البيع المطلوب
                  </p>
                  <p
                    style={{
                      color: "#B0B0B1",
                      fontSize: 10,
                      marginRight: 10,
                    }}
                  >
                    {" "}
                    * يجب ان يكون اقل او اكتر من
                    {dajinPriceFinal?.data?.saturation_ratio} من السعر
                    الاسترشادي
                  </p>
                </div>

                <PriceCard
                  final={dajinPrice?.data?.final_price ? true : false}
                  price={price}
                  min={
                    currentPrice -
                      currentPrice *
                        dajinPrice?.data?.difference_range_percentage ?? 0
                  }
                  max={
                    currentPrice +
                      currentPrice *
                        dajinPrice?.data?.difference_range_percentage ?? 0
                  }
                  setPrice={setPrice}
                />
                <SectionLabel content={"سعر بورصة داﭼن"} />
                <p style={{ textAlign: "center", fontSize: 12 }}>
                  أسعار البورصة قابلة للتعديل ثم يخرج سعر اليوم النهائي و لذلك
                  قد يختلف السعر من وقت لاخر حتى حتى الساعه 3م من كل يوم
                </p>
                <ChickenCard
                  icon={dajinPrice?.data?.dajin?.image_url}
                  dajinType={dajinPrice?.data?.dajin?.type}
                  price={
                    dajinPrice?.data?.final_price?.toFixed(2) ??
                    dajinPrice?.data?.indicative_price?.toFixed(2)
                  }
                  date={moment(dajinPrice?.data?.created_at).format(
                    "DD-MM-YYYY"
                  )}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent:
                isEdit && !offerDetails?.data?.has_requests
                  ? "space-between"
                  : "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {isEdit && !offerDetails?.data?.has_requests && (
              <Button
                backgroundColor="white"
                textColor="#FE646F"
                buttonStyles={{ border: "1px solid #FE646F" }}
                onClick={rejectFun}
                textAlign={"center"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
                width={"40%"}
              >
                {"الغاء عرض البيع"}
              </Button>
            )}
            <Button
              onClick={handleSubmit}
              textAlign={"center"}
              type={"submit"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              width={"40%"}
            >
              {isEdit ? "تعديل" : "ادخال"}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </AppModal>
  );
};

export default CreateOffer;
