import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { arToEn } from "../../alogrithms";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { useResponsiveWidth } from "../../alogrithms/responsive";

import { addTraderBalance } from "../../api/store/actions/saga/traders";
import TextInput from "../../reusableComponents/textInput";
import AppModal from "../../screenParts/modal/customModal";

const AddBalance = () => {
  const { trader_id } = useParams();
  const submit = async (values) => {
    const body = {
      value_for_trader: arToEn(values?.value_for_trader),
      description: values?.description,
    };
    const res = await addTraderBalance({ body: body, user_id: trader_id });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      undefined,

      validationSchema,
      "addBalance",
      submit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldError,
    touched,
    errors,
    handleBlur,
  } = formik;
  console.log("balues", values);
  const isValid = formikFieldValidation(touched, errors);
  console.log(errors);
  const width = useResponsiveWidth({
    desktop: 100,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  return (
    <AppModal
      buttonTitle={"إضافة"}
      modalTitle={"إضافة رصيد"}
      onClick={handleSubmit}
    >
      <TextInput
        require
        // defaultValue={isEdit ? category?.data?.name : ""}
        label={"الرصيد"}
        placeholder={"0000"}
        onChange={handleChange("value_for_trader")}
        errorMessage={isValid("value_for_trader")}
      />
      <TextInput
        require
        onChange={handleChange("description")}
        label={"الوصف"}
        placeholder={"اكتب الوصف"}
        errorMessage={isValid("description")}
      />
    </AppModal>
  );
};

export default AddBalance;
