import React from "react";

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g data-name="Group 38872" transform="translate(-823 -350)">
        <circle
          cx="17.5"
          cy="17.5"
          r="17.5"
          fill="#1877f2"
          data-name="Ellipse 1608"
          transform="translate(823 350)"
        ></circle>
        <path
          fill="#fff"
          d="M10.542 10.039l.5-3.23h-3.1v-2.1a1.615 1.615 0 011.817-1.741h1.409V.218A17.182 17.182 0 008.667 0C6.115 0 4.446 1.547 4.446 4.347v2.462H1.609v3.23h2.837v7.808h3.492v-7.808z"
          data-name="Icon awesome-facebook-f"
          transform="translate(834.111 358.577)"
        ></path>
      </g>
    </svg>
  );
}

export default FacebookIcon;
