import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { arToEn } from "../../../alogrithms";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  editTawreedSettings,
  getTawreedLevels,
} from "../../../api/store/actions/saga/settings/tawreedSettings";
import {
  addTimeLimitedOffer,
  editTimeLimitedOffer,
  showTimeLimitedOffer,
} from "../../../api/store/actions/saga/settings/timerLimtedOffer";
import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";
import { useTablet } from "../../salesTransactions/salesDetails";

const EditTawreedSettings = () => {
  const { offer_id } = useParams();
  const [offerDetails, setOfferDetails] = useState();
  useEffect(() => {
    resolveRequest(getTawreedLevels, setOfferDetails);
  }, []);
  const dispatch = useDispatch();
  const submitEdit = async (values) => {
    const body = {
      normal_price: arToEn(values?.normal_price),
      featured_price: arToEn(values?.featured_price),
      expiration_in_days: arToEn(values?.expiration_in_days),
    };
    dispatch({ type: "RELOAD" });

    const res = await editTawreedSettings({ body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      offerDetails?.data,

      validationSchema,
      "tawreedSettings",
      submitEdit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log("balues", values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <AppModal
      buttonTitle={"تعديل"}
      modalTitle={"تعديل اعدادات التوريد"}
      onClick={handleSubmit}
    >
      <TextInput
        require
        // defaultValue={isEdit ? category?.data?.name : ""}
        label={"السعر العادى"}
        placeholder={"0000"}
        onChange={handleChange("normal_price")}
        errorMessage={isValid("normal_price")}
        value={values["normal_price"]}
      />
      <TextInput
        require
        // defaultValue={isEdit ? category?.data?.name : ""}
        label={"سعر المتميز"}
        placeholder={"0000"}
        onChange={handleChange("featured_price")}
        errorMessage={isValid("featured_price")}
        value={values["featured_price"]}
      />
      <TextInput
        require
        // defaultValue={isEdit ? category?.data?.name : ""}
        label={"ايام صلاحية الاعلان"}
        placeholder={"0000"}
        onChange={handleChange("expiration_in_days")}
        errorMessage={isValid("expiration_in_days")}
        value={values["expiration_in_days"]}
      />
    </AppModal>
  );
};

export default EditTawreedSettings;
