import SectionLabel from "../../reusableComponents/sectionLabel";

import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import avatar from "../../assets/avatar.jpg";
import { useNavigate } from "react-router-dom";

import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";

import {
  createLectures,
  getLecturesDetails,
  updateLectures,
} from "../../api/store/actions/saga/lectures";
import { useParams } from "react-router";
import TextInput from "../../reusableComponents/textInput";
import Loading from "../../reusableComponents/loadingScreen/loading";
import { arToEn } from "../../alogrithms";

function LectureCreateScreen({ isEdit }) {
  const { courseId, lectureId } = useParams();
  console.log(courseId, lectureId);
  const [lecturesData, setLecturesData] = useState();
  const navigate = useNavigate();
  const [lecturesDetails, setLecturesDetails] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    if (isEdit) {
      resolveRequest(getLecturesDetails, setLecturesDetails, {
        course_id: courseId,
        lecture_id: lectureId,
      });
    }
  }, []);

  const [imageChanged, setImageChanged] = useState(false);
  const submitEdit = async (values) => {
    let body;

    if (!imageChanged) {
      body = {
        title: values?.title,
        url: values?.url,
        minutes: arToEn(values?.minutes),
      };
    } else {
      body = {
        title: values?.title,
        url: values?.url,
        minutes: arToEn(values?.minutes),
        image: values?.image,
      };
    }
    const res = await updateLectures({
      body: body,
      course_id: courseId,
      lecture_id: lectureId,
    });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const submit = async (values) => {
    const body = {
      title: values?.title,
      url: values?.url,
      minutes: arToEn(values?.minutes),
      image: values?.image,
      /*   publish_at: checked
        ? dateToIso(new Date())
        : dateToIso(new Date(values?.date + " " + values?.time)),
   */
    };
    const res = await createLectures({
      body: body,
      course_id: courseId,
    });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      !isEdit ? undefined : lecturesDetails?.data?.data,
      validationSchema,
      "createLectures",
      !isEdit ? submit : submitEdit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log(values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <>
      {!isEdit || (isEdit && lecturesDetails) ? (
        <div
          style={{
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <SectionLabel content={"تفاصيل المحاضرة"} />

          <form style={{}} onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "55%", padding: 20 }}>
                <p style={{ marginBottom: 10, marginTop: 10 }}>العنوان *</p>
                <TextInput
                  placeholder={"اكتب العنوان"}
                  onChange={handleChange("title")}
                  errorMessage={isValid("title")}
                  value={values.title}
                />

                <p style={{ marginBottom: 10, marginTop: 10 }}>رابط المحاضره *</p>
                <TextInput
                  placeholder={"اكتب العنوان"}
                  onChange={handleChange("url")}
                  errorMessage={isValid("url")}
                  value={values.url}
                />
                <p style={{ marginBottom: 10, marginTop: 10 }}>مدة المحاضره *</p>
                <TextInput
                  placeholder={"اكتب مدة المحاضرة بالدقائق"}
                  onChange={handleChange("minutes")}
                  errorMessage={isValid("minutes")}
                  value={values.minutes}
                />
              </div>
              <div
                style={{
                  width: "45%",
                  padding: 20,
                }}
              >
                <p style={{ marginBottom: 10, marginTop: 10 }}>صورة المحاضرة *</p>
                <div
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#7ABA4C0D",
                    border: "1px dashed #7ABA4C99",
                    height: "80%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    width={100}
                    height={100}
                    style={{ borderRadius: 500 }}
                    src={
                      isEdit && image != undefined
                        ? image
                        : isEdit
                          ? `${lecturesDetails?.data?.data?.image_url}`
                          : isEdit && image == undefined
                            ? avatar
                            : !isEdit && image == undefined
                              ? avatar
                              : image
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        color: "#7ABA4C",
                        marginRight: 10,
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        onChange={(e) => {
                          setFieldValue("image", e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));

                          setImageChanged(true);
                        }}
                        style={{ display: "none" }}
                      />
                      استعرض
                    </label>
                  </div>
                </div>
                <p style={{ marginBottom: 10, marginTop: 10, color: "red" }}>
                  {isValid("image")}
                </p>
              </div>
            </div>

            {/*     <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
                paddingTop: 20,
              }}
            >
              <p style={{ marginBottom: 10, marginTop: 10 }}>تاريخ النشر</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    width: "40%",

                    height: 54,
                    border: 0,
                    borderRadius: 10,
                    backgroundColor: "#FBFBFB",
                    color: "#7ABA4C",
                    paddingRight: 20,
                    paddingLeft: 20,
                  }}
                  onChange={handleChange("date")}
                  value={values["date"]}
                  dir="rtl"
                  type="date"
                  name="date"
                  placeholder="اكتب العنوان"
                />
                <input
                  style={{
                    width: "40%",
                    height: 54,
                    border: 0,
                    borderRadius: 10,
                    backgroundColor: "#FBFBFB",
                    color: "#7ABA4C",
                    paddingRight: 20,
                    paddingLeft: 20,
                    marginRight: 20,
                  }}
                  onChange={handleChange("time")}
                  value={values["time"]}
                  dir="rtl"
                  type="time"
                  name="time"
                  placeholder="اكتب العنوان"
                />
                <div
                  style={{
                    width: "20%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    paddingRight: 10,
                  }}
                >
                  <p style={{ marginRight: 3, marginLeft: 3 }}>الآن</p>
                  <input
                    type="checkbox"
                    name="dateNow"
                    value={checked}
                    onChange={() => {
                      setChecked((prevState) => !prevState);
                    }}
                  />
                </div>
              </div>
            </div>
          </div> */}
            <div style={{ display: "flex", marginTop: 20 }}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                // type={"reset"}
                backgroundColor="#F4F4F4"
                textColor="#9F9F9F"
                style={{ marginRight: 10, marginLeft: 10, width: "20%" }}
              >
                إلغاء
              </Button>
              <Button
                type={"submit"}
                style={{ width: "20%", marginRight: 10, marginLeft: 10 }}
              >
                {!isEdit ? "إضافة" : "تعديل"}
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default LectureCreateScreen;
