const ArrayFormat = ({ item }) => {
  return item[1]?.length != 0 ? (
    item[1]?.map((i, index) => {
      console.log(index, item[1]?.length);
      return (
        <span key={index}>
          {`${i[item[4]]}${index == item[1]?.length - 1 ? "." : ","} `}
        </span>
      );
    })
  ) : (
    <span>{"لا يوجد"}</span>
  );
};

export default ArrayFormat;
