import { useMediaQuery } from "react-responsive";

const MobileMood = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 580 });
  return isMobile ? (
    children
  ) : (
    <div>
      <p style={{ textAlign: "center", fontFamily: "boldKufi" }}>
        لا يمكن التشغيل عبر الشاشات الصغيرة{" "}
      </p>
    </div>
  );
};
export default MobileMood;
