import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const contactUsAPI = "/admin/v1/contact/messages";
const technicalSupportAPI = "/admin/v1/support/tickets";
const supportTypes = "admin/v1/settings/support/types";
export const getContactUsRecords = async ({
  params = {},
  pageNumber = 1,
  pageCount = 7,
}) => {
  try {
    let filters = concatFilters(params);
    const data = await axiosCall.get(
      `${contactUsAPI}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    console.log(data);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getContactDetails = async ({ id }) => {
  try {
    const data = await axiosCall.get(`${contactUsAPI}/${id}`);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const addReplyContact = async ({ id, body }) => {
  try {
    const data = await axiosCall.patch(`${contactUsAPI}/${id}`, body);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getSupportDetails = async ({ id }) => {
  try {
    const data = await axiosCall.get(`${supportTypes}/${id}`);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getSupportTypes = async () => {
  try {
    const data = await axiosCall.get(`${supportTypes}`);
    console.log(data);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getTechinacalSupportRecords = async ({
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);

    const data = await axiosCall.get(
      `${technicalSupportAPI}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    console.log(data);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getTechinacalSupportDetails = async ({ id }) => {
  try {
    const data = await axiosCall.get(`${technicalSupportAPI}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const deleteTicket = async ({ id }) => {
  try {
    let res = await axiosCall.delete(technicalSupportAPI + "/" + id);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const closeTicket = async ({ id }) => {
  try {
    let res = await axiosCall.put(technicalSupportAPI + "/" + id + "/close");
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};

// {{baseUrl}}/
