import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const tradersAPI = "/admin/v1/traders";
export const getAllTraders = async ({
  pageNumber = 1,
  pageCount = 7,
  sort = "",
  params = {},
}) => {
  try {
    let filters = concatFilters(params);
    let data = await axiosCall.get(
      `${tradersAPI}?sort=${sort}&page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    throw e;
  }
};

export const getAllTraderRatings = async ({
  user_id,
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);

    return dataFormatPagination(
      await axiosCall.get(
        `${tradersAPI}/${user_id}/ratings?page=${pageNumber}&per_page=${pageCount}&${filters}`
      )
    );
  } catch (e) {
    throw e;
  }
};
export const getTraderRatingsDetails = async ({ user_id, rating_id }) => {
  try {
    return dataFormat(
      await axiosCall.get(`${tradersAPI}/${user_id}/ratings/${rating_id}`)
    );
  } catch (e) {
    throw e;
  }
};
export const getAllTraderRatingDetails = async ({
  user_id,

  params = {},
}) => {
  try {
    let filters = concatFilters(params);

    return dataFormatPagination(
      await axiosCall.get(`${tradersAPI}/${user_id}/ratings?${filters}`)
    );
  } catch (e) {
    throw e;
  }
};
export const getAllTraderAccounts = async ({
  user_id,
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);

    return dataFormatPagination(
      await axiosCall.get(
        `${tradersAPI}/${user_id}/account?page=${pageNumber}&per_page=${pageCount}&${filters}`
      )
    );
  } catch (e) {
    throw e;
  }
};
export const getTraderBalance = async ({ user_id }) => {
  try {
    return dataFormat(
      await axiosCall.get(`${tradersAPI}/${user_id}/account/balance`)
    );
  } catch (e) {
    throw e;
  }
};
export const traderAccountDetails = async ({ user_id }) => {
  try {
    const data = await axiosCall.get(
      `${tradersAPI}/${user_id}/account-details`
    );
    return dataFormat(data);
  } catch (e) {
    throw e;
  }
};

export const traderChangeAccountStatus = async ({ user_id, body }) => {
  try {
    return dataFormat(
      await axiosCall.put(
        `${tradersAPI}/${user_id}/status`,
        JSON.stringify(body)
      )
    );
  } catch (e) {
    throw e;
  }
};

export const addTraderBalance = async ({ user_id, body }) => {
  try {
    return dataFormat(
      await axiosCall.post(
        `${tradersAPI}/${user_id}/account/payment`,
        JSON.stringify(body)
      )
    );
  } catch (e) {
    throw e;
  }
};

export const traderFeatured = async ({ user_id, body }) => {
  try {
    return dataFormat(
      await axiosCall.post(
        `${tradersAPI}/${user_id}/feature`,
        JSON.stringify(body)
      )
    );
  } catch (e) {
    throw e;
  }
};

export const getTraderOrders = async ({
  trader_id,
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);

    return dataFormatPagination(
      await axiosCall.get(
        `${tradersAPI}/${trader_id}/orders?page=${pageNumber}&per_page=${pageCount}&${filters}`
      )
    );
  } catch (e) {
    throw catchFromat(e);
  }
};

export const getTraderOrderOffers = async ({
  order_id,
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);

    return dataFormatPagination(
      await axiosCall.get(
        `${tradersAPI}/orders/${order_id}/requests?page=${pageNumber}&per_page=${pageCount}&${filters}`
      )
    );
  } catch (e) {
    throw catchFromat(e);
  }
};

export const getTraderOfferDetails = async ({ offer_id }) => {
  try {
    return dataFormat(
      await axiosCall.get(`${tradersAPI}/orders/requests/${offer_id}/details`)
    );
  } catch (e) {
    throw catchFromat(e);
  }
};
export const createTrader = async ({ body }) => {
  console.log("body", body);
  try {
    let data = await axiosCall.post(
      `${tradersAPI}/create`,
      objToFormData(body),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const updateTrader = async ({ body, trader_id }) => {
  console.log("body", body);
  try {
    let data = await axiosCall.post(
      `${tradersAPI}/${trader_id}/update`,
      objToFormData(body),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
