import moment from "moment";
import { useNavigate } from "react-router-dom";
import { markAsReadNotify } from "../../api/store/actions/saga/upcomingNotification";
import { selectedNotificationRoute } from "./notificationRoute";

const NotificationList = ({ data, onSeeMore, currentPage, totalPages }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        borderRadius: 10,
        backgroundColor: "white",
        boxShadow: "1px -1px 15px 1px rgba(0,0,0,0.41)",
        maxHeight: 400,
        overflow: "scroll",
      }}
    >
      <div
        style={{
          padding: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        الاشعارات
      </div>
      {/* <hr style={{ width: "80%", margin: "auto" }} /> */}
      {data?.map((item, index) => {
        return (
          <div
            onClick={async () => {
              const res = await markAsReadNotify({
                notificationId: item?.data?.notification_id,
              });
              if (res?.code < 206) {
                navigate(
                  selectedNotificationRoute(item?.data?.type, item?.data?.id)
                );
                navigate(0);
              }
            }}
            className="notification-hover"
            style={{
              backgroundColor: item?.read_at != null ? "white" : "#EAEFE0",
            }}
          >
            <div style={{ padding: 15, cursor: "pointer" }}>
              <p>{item?.notification?.body}</p>
              <p style={{ textAlign: "left", color: "GrayText" }}>
                {moment(item?.data?.created_at).format("YYYY/MM/DD hh:mm")}
              </p>
            </div>
            {index !== data?.length - 1 && (
              <hr style={{ width: "80%", margin: "auto" }} />
            )}
          </div>
        );
      })}
      {totalPages > currentPage ? (
        <p
          onClick={onSeeMore}
          style={{
            cursor: "pointer",
            textAlign: "center",
            backgroundColor: "lightgray",
            padding: 5,
          }}
        >
          مشاهدة المزيد
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NotificationList;
