import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getArticles } from "../../api/store/actions/saga/articles";
import { getCourses } from "../../api/store/actions/saga/courses";
import { getLectures } from "../../api/store/actions/saga/lectures";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";

const LecturesList = () => {
  const [lectureData, setLectureData] = useState(null);
  const { courseId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  console.log("courseId", courseId);
  useEffect(() => {
    resolveRequest(getLectures, setLectureData, {
      courseId: courseId,
    });
  }, [courseId]);
  return lectureData ? (
    <ListView
      objName={"lecture"}
      links={{
        edit: "editLecture",
        delete: "",
        show: "",
        query: { show: "", delete: "", edit: "" },
      }}
      data={lectureData}
    >
      <ListHeader
        back={true}
        hasSearch={false}
        hasFilter={false}
        hasTitle
        title={`${query.get("title")?.substring(0, 20)}...`}
        addLink={"addLecture"}
      />
    </ListView>
  ) : (
    <Loading />
  );
};

export default LecturesList;
