import React from "react";

function CartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.94"
      height="25.936"
      viewBox="0 0 25.94 25.936"
    >
      <path
        fill="#7aba4c"
        stroke="#fff"
        strokeWidth="1"
        d="M25.217 6.168a.974.974 0 00-.756-.36H6.818l-.072-.664v-.021A5.329 5.329 0 001.474.5a.974.974 0 000 1.948 3.376 3.376 0 013.337 2.919L5.969 16a2.926 2.926 0 00-1.719 2.666v.048a2.925 2.925 0 002.922 2.922h.4a2.873 2.873 0 105.441 0h4.2a2.874 2.874 0 102.721-1.948H7.172a.975.975 0 01-.974-.974v-.024-.024a.975.975 0 01.974-.974h12.435a4.461 4.461 0 004.043-2.725.974.974 0 10-1.784-.783 2.547 2.547 0 01-2.259 1.56H7.9l-.87-7.987h16.233l-.477 2.285a.974.974 0 101.907.4l.721-3.458a.974.974 0 00-.197-.816zm-5.285 15.469a.925.925 0 11-.925.925.926.926 0 01.925-.925zm-9.643 0a.925.925 0 11-.925.925.926.926 0 01.925-.925z"
        data-name="Path 38886"
      ></path>
    </svg>
  );
}

export default CartIcon;
