import SectionLabel from "../../reusableComponents/sectionLabel";
import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import avatar from "../../assets/avatar.jpg";

import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";

import { updateArticles } from "../../api/store/actions/saga/articles";

import { dateToIso } from "../../alogrithms/dateAlgo";
import {
  createInstructor,
  getInstructorDetails,
  updateInstructor,
} from "../../api/store/actions/saga/instructors";
import TextInput from "../../reusableComponents/textInput";
import { useNavigate, useParams } from "react-router";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import Loading from "../../reusableComponents/loadingScreen/loading";

function CreateInstructorScreen({ isEdit }) {
  const width = useResponsiveWidth({});

  const navigate = useNavigate();
  const { instructorId: id } = useParams();
  const [instructorData, setInstructorData] = useState();
  const [instructorDetails, setInstructorsDetails] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    if (isEdit) {
      resolveRequest(getInstructorDetails, setInstructorsDetails, { id: id });
    }
  }, []);

  const [imageChanged, setImageChanged] = useState(false);
  const submitEdit = (values) => {
    let body;

    if (!imageChanged) {
      body = {
        name: values?.name,
        description: values?.description,
        certified: values?.certified,
      };
    } else {
      body = {
        name: values?.name,
        description: values?.description,
        certified: values?.certified,
        image: values?.image,
      };
    }
    updateInstructor({
      body: body,
      id: id,
    });
  };

  const submit = async (values) => {
    const body = {
      name: values?.name,
      description: values?.description,
      certified: values?.certified,
      image: values?.image,
    };
    let res = await createInstructor({
      body: body,
    });
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      !isEdit ? undefined : instructorDetails?.data,
      validationSchema,
      "createInstructor",
      !isEdit ? submit : submitEdit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
  } = formik;
  const isValid = formikFieldValidation(touched, errors);
  console.log("values", instructorDetails?.data);
  return (
    <>
      {!isEdit || (isEdit && instructorDetails) ? (
        <div style={{ padding: 10 }}>
          <SectionLabel content={"تفاصيل المدرب"} />
          <form
            style={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingBottom: 50,
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ width: "55%", padding: 20 }}>
                <p style={{ marginBottom: 10, marginTop: 10 }}>اسم المدرب *</p>

                <TextInput
                  placeholder={"اكتب الاسم"}
                  onChange={handleChange("name")}
                  errorMessage={isValid("name")}
                  value={values.name}
                />

                <p style={{ marginBottom: 10, marginTop: 10 }}>التوصيف *</p>
                <textarea
                  rows="5"
                  style={{
                    width: "100%",
                    height: 54,
                    border: 0,
                    borderRadius: 10,
                    backgroundColor: "#FBFBFB",
                    color: "black",
                    paddingRight: 20,
                  }}
                  value={values["description"]}
                  onChange={handleChange("description")}
                  dir={"rtl"}
                  placeholder={"اكتب التعليق"}
                  name="description"
                ></textarea>

                <p style={{ marginBottom: 10, marginTop: 10, color: "red" }}>
                  {isValid("description")}
                </p>
                <p style={{ marginBottom: 10, marginTop: 10 }}>الاعتماد *</p>
                <SelectDropdown
                  schema={{ name: "name", id: "id" }}
                  placeholder={"اختر"}
                  responsiveWidth={{ ...width }}
                  selectedItem={values["certified"]}
                  dataSource={[
                    { id: 1, name: " معتمد" },
                    { id: 0, name: "غير معتمد" },
                  ]}
                  errorMessage={isValid("certified")}
                  onSelect={(e) => {
                    setFieldValue("certified", e);
                  }}
                />
              </div>
              <div
                style={{
                  width: "45%",
                  padding: 20,
                }}
              >
                <p style={{ marginBottom: 10, marginTop: 10 }}>صورة الخبر *</p>
                <div
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#7ABA4C0D",
                    border: "1px dashed #7ABA4C99",
                    height: "80%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    width={100}
                    height={100}
                    style={{ borderRadius: 500 }}
                    src={
                      isEdit && image != undefined
                        ? image
                        : isEdit
                          ? `${instructorDetails?.data?.image_url}`
                          : isEdit && image == undefined
                            ? avatar
                            : !isEdit && image == undefined
                              ? avatar
                              : image
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        color: "#7ABA4C",
                        marginRight: 10,
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        onChange={(e) => {
                          setFieldValue("image", e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));

                          setImageChanged(true);
                        }}
                        style={{ display: "none" }}
                      />
                      استعرض
                    </label>
                  </div>
                </div>
                <p style={{ marginBottom: 10, marginTop: 10, color: "red" }}>
                  {isValid("image")}
                </p>
              </div>
            </div>

            <div style={{ display: "flex", marginTop: 20 }}>
              <Button type={"submit"} style={{ width: "20%" }}>
                {!isEdit ? "إضافة" : "تعديل"}
              </Button>

              {/* <Button
                // type={"reset"}
                backgroundColor="#F4F4F4"
                textColor="#9F9F9F"
                style={{ marginRight: 20, width: "20%" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                إلغاء
              </Button> */}
            </div>
          </form>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default CreateInstructorScreen;
