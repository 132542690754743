const TextInput = ({
  placeholder,
  onChange,
  errorMessage,
  responsiveWidth,
  styles,
  label,
  defaultValue,
  value,
  icon,
  password,
  name,
  readOnly = false,
  height = 45,
  type = "text",
  leftIcon,
  require = false,
  onKeyUp,
  onBlur,
}) => {
  return (
    <div
      style={{
        ...styles,
        ...responsiveWidth,
      }}
    >
      {label && (
        <div style={{ paddingBottom: 10 }}>
          <p>
            {label}
            {require ? <span> * </span> : null}{" "}
          </p>
        </div>
      )}
      <div style={{ position: "relative" }}>
        <input
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          readOnly={readOnly}
          value={value}
          defaultValue={defaultValue}
          style={{
            direction: "rtl",
            width: "100%",
            height: height,
            border: "1px #EDEDED solid",
            borderRadius: 10,
            backgroundColor: "#FBFBFB",
            color: "black",
            paddingRight: icon ? 50 : 20,
            outline: "none",
          }}
          onChange={onChange}
          type={password ? "password" : type}
          name={name}
          placeholder={placeholder}
          dir="rtl"
        />
        {icon && (
          <div
            style={{
              position: "absolute",
              right: "0%",
              top: "0%",
              height: "100%",
              width: "8%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {icon}
          </div>
        )}
        {leftIcon && (
          <div
            style={{
              position: "absolute",
              left: "0%",
              top: "0%",
              height: "100%",
              width: "8%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {leftIcon}
          </div>
        )}
      </div>
      {errorMessage && (
        <p style={{ marginTop: 5, marginBottom: 5, color: "red" }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default TextInput;
