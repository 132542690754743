import React, { useEffect, useState } from "react";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import Button from "../../reusableComponents/button";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import { useTablet } from "../../screens/salesTransactions/salesDetails";
import { LabelWIcon } from "../farms/labelWIcon";
export const FarmerOrderCard = ({
  farmerImage,
  traderName,
  traderImage,
  farmerStatus,
  dajinHealth,
  dajinType,
  averageWeight,
  amount,
  farmName,
  requestedPrice,
  farmAvailability,
  onPress,
}) => {
  const isTablet = useTablet();
  return (
    <div
      style={{
        border: "1px solid #C2C2C2",
        borderRadius: 10,
        marginRight: "2.5%",
        marginLeft: "2.5%",
        marginTop: 10,
        marginBottom: 10,

        ...useResponsiveWidth({
          desktop: 45,
          tablet: 100,
          mobile: 100,
          tabletDesktop: 50,
          tabletMobile: 100,
        }),
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isTablet ? "column" : "row",
          padding: 10,
        }}
      >
        <div
          style={{
            ...useResponsiveWidth({
              tabletDesktop: 20,
              desktop: 20,
              tablet: 20,
              mobile: 20,
              tabletMobile: 20,
            }),
          }}
        >
          <img
            src={
              traderImage ??
              "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
            }
            style={{
              borderRadius: 10,
              ...useResponsiveWidth({
                desktop: 100,
                tablet: 100,
                mobile: 100,
                defaults: 100,
              }),
            }}
            height={140}
          />
        </div>
        <div
          style={{
            marginRight: 10,
            ...useResponsiveWidth({
              tabletDesktop: 90,
              desktop: 90,
              tablet: 90,
              mobile: 90,
              tabletMobile: 90,
            }),
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ color: "#114634", fontWeight: "bold", fontSize: 18 }}>
              {traderName}
            </p>
            <p
              style={{
                backgroundColor: "grey",
                color: "white",
                paddingRight: 30,
                paddingLeft: 30,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 10,
              }}
            >
              {farmerStatus?.status_for_farmer}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <LabelWIcon text={`النوع :${dajinType}`} icon={<ChickenIcon />} />
            <LabelWIcon text={`العدد : ${amount}`} icon={<ChickenIcon />} />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <LabelWIcon
              text={`متوسط الوزن :${averageWeight}`}
              icon={<ChickenIcon />}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <LabelWIcon text={"26/12/2021"} icon={<ChickenIcon />} />
            <Button height={35} onClick={onPress}>
              {" "}
              التفاصيل
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid #C2C2C2",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "30%" }}>
          <img
            style={{ borderRadius: 50 }}
            src={
              farmerImage ??
              "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
            }
            width={30}
            height={30}
          />
          <p style={{ fontSize: 14, marginRight: 10 }}>{farmName}</p>
        </div>
        <LabelWIcon
          width="30%"
          text={requestedPrice}
          justifyContent="flex-end"
          icon={<ChickenIcon />}
        />
        <LabelWIcon
          width="30%"
          justifyContent="flex-end"
          text={farmAvailability}
          icon={<ChickenIcon />}
        />
      </div>
    </div>
  );
};
