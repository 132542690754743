import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getComments } from "../../api/store/actions/saga/comments";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import ListHeader from "../../screenParts/listingViews/listHeader";
import NoData from "../../screenParts/noData";
import CommentsCard from "./commentsCard";

const CommentsList = () => {
  const [comments, setComments] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getComments, setComments, {
      pageNumber: currentPage,
      params: { approved: filterKey }, //need to change
    });
  }, [currentPage, filterKey, reload]);
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
  };
  console.log(comments);
  return comments ? (
    <>
      <ListHeader
        onSelect={onSelect}
        hasSearch={false}
        hasAdd={false}
        title={"التعليقات"}
        hasTitle
        dataSource={[
          { name: "لم تتم الموافقة", id: 0 },
          { name: "تمت الموافقة", id: 1 },
        ]}
      />
      <div style={{ backgroundColor: "white", padding: 20 }}>
        {/* <ListHeader hasTitle title={"التعليقات"} hasAdd={false} /> */}
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "15%",
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10,
            }}
          >
            <p style={{ fontSize: 14, color: "#7ABA4C" }}>الكاتب</p>
          </div>
          <div
            style={{
              width: "40%",
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10,
            }}
          >
            <p style={{ fontSize: 14, color: "#7ABA4C" }}>التعليق</p>
          </div>
          <div
            style={{
              width: "30%",
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10,
            }}
          >
            <p style={{ fontSize: 14, color: "#7ABA4C" }}>رداً على</p>
          </div>
          <div
            style={{
              width: "15%",
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10,
            }}
          >
            <p style={{ fontSize: 14, color: "#7ABA4C" }}>تاريخ الإرسال</p>
          </div>
        </div>
        {comments?.data?.length ? (
          comments?.data?.map((item, index) => {
            return (
              <CommentsCard
                id={item?.id}
                key={index}
                postContent={item?.commentable_title}
                media_type={item?.commentable_type}
                name={item?.user?.name}
                email={item?.user?.email}
                content={item?.content}
                date={moment(item?.created_at).format("DD/MM/YYYY")}
                approved={item?.approved}
              />
            );
          })
        ) : (
          <NoData title={"لا يوجد تعليقات"} />
        )}
      </div>
      {comments?.total && (
        <AppPaginate
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={Math.ceil(comments?.total / comments?.perPage)}
        />
      )}
    </>
  ) : (
    <Loading />
  );
};

export default CommentsList;
