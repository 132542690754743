import { useEffect, useState } from "react";
import React from "react";
import { TraderAccountDetails } from "./traderAccountDetails";
import ListHeader from "../../screenParts/listingViews/listHeader";
import TraderRatings from "./traderRatings";
import { TraderOrdersScreen } from "../orders/traderOrdersScreen";
import { useNavigate } from "react-router-dom";
import { AccountBalance } from "./accountBalance";
import { useDispatch, useSelector } from "react-redux";
import { clearActiveDetailsState } from "../../api/store/reducers/reload";

const TraderDetails = () => {
  const activeDetailsState = useSelector(state => state?.reload?.activeDetails)
  const [active, setActive] = useState(activeDetailsState ?? 1);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const onChange = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSearchKey(value);
  };
  return (
    <>
      <ListHeader
        hasTitle={true}
        title={"تفاصيل الحساب"}
        back
        hasAdd={false}
        hasFilter={false}
        hasSearch={false}
      />
      <ListHeader
        switchType={'details'}
        onChange={onChange}
        pages={[
          { title: "بيانات الحساب", id: 1 },
          { title: "رصيد الحساب", id: 2 },

          { title: "طلبات الشراء", id: 3 },
          { title: "التقييمات", id: 4 },
        ]}
        active={active}
        setActive={setActive}
        hasAdd={false}
        hasFilter={false}
        hasSearch={active === 4}
      />
      {active == 1 && <TraderAccountDetails />}
      {active == 2 && (
        <AccountBalance
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

      {active == 3 && (
        <TraderOrdersScreen
          currentPage={currentPage}
          searchKey={searchKey}
          setCurrentPage={setCurrentPage}
          pageCount={4}
        />
      )}
      {active == 4 && (
        <TraderRatings
          searchKey={searchKey}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default TraderDetails;
