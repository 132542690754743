import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import { ErrorToast } from "../../../api/axios/toastify";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getBonusPoints } from "../../../api/store/actions/saga/settings/bonusPoints";
import { getDajinTypes } from "../../../api/store/actions/saga/settings/dajinTypes";
import {
  deactivateTimeLimitedOffer,
  getCurrentTimeLimitedOffer,
} from "../../../api/store/actions/saga/settings/timerLimtedOffer";
import Button from "../../../reusableComponents/button";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../../reusableComponents/sectionLabel";
import { LabelWIcon } from "../../../screenParts/farms/labelWIcon";
import ListView from "../../../screenParts/listingViews";
import ListHeader from "../../../screenParts/listingViews/listHeader";
import NoData from "../../../screenParts/noData";
import TimeLimtedHistory from "./history";

const TimeLimtedOffer = () => {
  const width = useResponsiveWidth({
    tablet: 50,
    tabletDesktop: 50,
    tabletMobile: 100,
  });
  const content = useResponsiveWidth(
    {
      desktop: "flex-end",
      tablet: "flex-start",
      tabletDesktop: "flex-start",
    },
    "justifyContent"
  );
  console.log(content);
  const [active, setActive] = useState(1);

  const [timeLimtedData, setTimeLimtedData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);
  const location = useLocation();
  const [deactivate, setDeactivate] = useState();

  useEffect(() => {
    resolveRequest(getCurrentTimeLimitedOffer, setTimeLimtedData);
  }, [reload, deactivate]);
  console.log(timeLimtedData);
  return (
    <>
      <ListHeader
        hasFilter={false}
        hasSearch={false}
        hasTitle={false}
        hasAdd={false}
        pages={[
          { id: 1, title: "العرض الفعال حاليا" },
          { id: 2, title: "مرجع العروض" },
        ]}
        responsiveContent={{ ...content }}
        responsiveWidth={{ ...width }}
        active={active}
        setActive={(x) => {
          setActive(x);
        }}
      />
      {active == 1 ? (
        timeLimtedData ? (
          <>
            <Link
              to={
                timeLimtedData?.data?.data == null ? "addTimerLimitedOffer" : ""
              }
              style={{ display: "contents" }}
              state={{
                backgroundLocation: location,
              }}
            >
              <Button
                width={100}
                onClick={() => {
                  if (timeLimtedData?.data?.data != null) {
                    ErrorToast("يوجد عرض فعال حالياً");
                  }
                }}
              >
                اضافة
              </Button>
            </Link>
            {timeLimtedData?.data && timeLimtedData?.data?.data != null ? (
              <div style={{ marginTop: 20 }}>
                <SectionLabel content={"تفاصيل العرض الحالى"} />
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#7ABA4C",
                    padding: 10,
                    justifyContent: "space-between",
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",

                      backgroundColor: "white",
                      padding: 20,
                      borderRadius: 10,
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontSize: 20 }}>عدد النقاط :</p>
                    <p style={{ fontSize: 20 }}>
                      {timeLimtedData?.data?.data?.points}
                    </p>
                  </div>
                </div>
                <p>الوصف</p>
                <p
                  style={{
                    padding: 10,
                    border: "1px dashed #7ABA4C",
                    borderRadius: 10,
                  }}
                >
                  {timeLimtedData?.data?.data?.description}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>بداية الصلاحية : </p>

                    <p>{timeLimtedData?.data?.data?.valid_from}</p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>نهاية الصلاحية :</p>

                    <p>{timeLimtedData?.data?.data?.valid_until}</p>
                  </div>{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Link
                    to={`editTimerLimitedOffer/${timeLimtedData?.data?.data?.id}`}
                    style={{ display: "contents" }}
                    state={{
                      backgroundLocation: location,
                    }}
                  >
                    <Button style={{ margeinLeft: 20, marginRight: 20 }}>
                      {" "}
                      تعديل
                    </Button>
                  </Link>
                  <Button
                    onClick={() => {
                      resolveRequest(
                        deactivateTimeLimitedOffer,
                        setDeactivate,
                        {
                          offer_id: timeLimtedData?.data?.data?.id,
                        }
                      );
                    }}
                    style={{ margeinLeft: 20, marginRight: 20 }}
                    backgroundColor="white"
                    textColor="red"
                    buttonStyles={{ border: "1px solid red" }}
                  >
                    إلغاء
                  </Button>
                </div>
              </div>
            ) : (
              <p style={{ textAlign: "center" }}>لا يوجد عرض حالياً فعال</p>
            )}
          </>
        ) : (
          <Loading />
        )
      ) : active == 2 ? (
        <TimeLimtedHistory />
      ) : (
        <></>
      )}
    </>
  );
};

export default TimeLimtedOffer;
