import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getDajinTypes } from "../../../api/store/actions/saga/settings/dajinTypes";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const DajinTypes = () => {
  const [dajinTypesData, setDajinTypesData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getDajinTypes, setDajinTypesData);
  }, [reload]);
  return dajinTypesData ? (
    <ListView
      objName={"dajinTypes"}
      stateLocation
      links={{
        edit: "updateDajin",
        delete: "delete/dajin",
        show: `dajinTypeBreeds`,
        query: {
          show: { key: "type", prop: "type" },
          delete: "",
          edit: "",
        },
      }}
      data={dajinTypesData}
    ></ListView>
  ) : (
    <Loading />
  );
};

export default DajinTypes;
