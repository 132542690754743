import { useEffect, useState } from "react";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getSupportTypes } from "../../api/store/actions/saga/support";
import ListHeader from "../../screenParts/listingViews/listHeader";
import ContactUs from "./contact/contactUs";
import TechinacalSupport from "./technical/technicalSupport";

const Support = () => {
  // has_reply  contactus filter
  //   support_type_id support filter
  const [active, setActive] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const [filterKey2, setSFilterKey2] = useState(null);

  const [supportTypes, setSupportTypes] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const onSelect = (value) => {
    if (currentPage != 1) setCurrentPage(1);
    setSFilterKey(value);
  };
  const onSelect2 = (value) => {
    if (currentPage != 1) setCurrentPage(1);
    setSFilterKey2(value);
  };
  useEffect(() => {
    resolveRequest(getSupportTypes, setSupportTypes);
  }, []);
  return (
    <>
      {supportTypes && (
        <ListHeader
          hasAdd={false}
          hasSearch={false}
          selectedItem={filterKey}
          /*         hasFilter2={active === 2}
          dataSource2={[
            { name: "تم الرد", id: true },
            { name: "لم يتم الرد", id: false },
          ]}
          schema2={{ name: "name", id: "id" }}
          onSelect2={onSelect2}
          selectedItem2={filterKey2} */
          schema={{ name: active == 1 ? "name" : "type", id: "id" }}
          dataSource={
            active == 1
              ? contactUsFilters
              : [{ type: "الكل", id: null }, ...supportTypes?.data]
          }
          onSelect={onSelect}
          pages={[
            { title: "رسائل التواصل", id: 1 },
            { title: "طلبات الدعم الفني", id: 2 },
          ]}
          active={active}
          setActive={(x) => {
            if (currentPage != 1) setCurrentPage(1);

            setSFilterKey(null);
            setActive(x);
          }}
        />
      )}
      {active == 1 ? (
        <ContactUs
          filterKey={filterKey}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <TechinacalSupport
          filterKey2={filterKey2}
          filterKey={filterKey}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default Support;

const contactUsFilters = [
  { name: "الكل", id: null },
  { name: "تم الرد", id: 1 },
  { name: "لم يتم الرد", id: 0 },
];
