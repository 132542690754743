import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getSupportTypes } from "../../../api/store/actions/saga/settings/supportTypes";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const SupportTypes = () => {
  const [supportTypesData, setSupportTypesData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getSupportTypes, setSupportTypesData);
  }, [reload]);
  return supportTypesData ? (
    <ListView
      objName={"supportTypes"}
      stateLocation
      links={{
        edit: "updateSupportType",
        delete: "delete/support",
        show: ``,
        query: {
          show: "",
          delete: "",
          edit: "",
        },
      }}
      data={supportTypesData}
    ></ListView>
  ) : (
    <Loading />
  );
};

export default SupportTypes;
