import { dynamicProps, filterObjKeys } from "../../alogrithms";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import ListHeader from "./listHeader";
import TableBase from "./tableBase";

const ListView = ({
  data,
  onClick,
  children,
  currentPage = 1,
  setCurrentPage,
  links,
  objName,
  stateLocation,
  actionName = "تعديل",
}) => {
  console.log("ggggggg", data);
  const theadData = filterObjKeys(data?.data[0] ?? {}, objName);
  const tbodyData = dynamicProps(data?.data, objName);
  console.log("tbodyData", tbodyData);
  return (
    <>
      <div style={{ padding: 10, backgroundColor: "white" }}>
        {children}
        <TableBase
          actionName={actionName}
          onClick={onClick}
          objName={objName}
          links={links}
          tableHead={theadData}
          tableData={tbodyData}
          stateLocation={stateLocation}
        />
      </div>
      {setCurrentPage && data?.total != 0 && (
        <AppPaginate
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={Math.ceil(data?.total / data?.perPage)}
        />
      )}
      {/* <Loading /> */}
    </>
  );
};

export default ListView;
