import React from "react";

export function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        fill="rgba(254,100,111,0.1)"
        d="M26 32H6a6 6 0 01-6-6V6a6 6 0 016-6h20a6 6 0 016 6v20a6 6 0 01-6 6z"
        data-name="Path 4117"
      ></path>
      <path
        fill="#fe646f"
        d="M10 14v8.167A1.832 1.832 0 0011.833 24h8.333A1.832 1.832 0 0022 22.167V14zm4 6.667a.667.667 0 01-1.334 0v-4a.667.667 0 011.334 0zm2.667 0a.667.667 0 11-1.334 0v-4a.667.667 0 011.334 0zm2.666 0a.667.667 0 01-1.334 0v-4a.667.667 0 011.335 0v4zM14 9.333h4V10h1.333v-.667A1.337 1.337 0 0018 8h-4a1.337 1.337 0 00-1.333 1.333V10H14z"
        data-name="Path 4118"
      ></path>
      <path
        fill="#fe646f"
        d="M23.333 11.833V13.5a.5.5 0 01-.5.5H9.167a.5.5 0 01-.5-.5v-1.667A1.832 1.832 0 0110.5 10h11a1.832 1.832 0 011.833 1.833z"
        data-name="Path 4119"
        opacity="0.5"
      ></path>
    </svg>
  );
}

export function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        fill="rgba(122,186,76,0.1)"
        d="M26 32H6a6 6 0 01-6-6V6a6 6 0 016-6h20a6 6 0 016 6v20a6 6 0 01-6 6z"
        data-name="Path 4113"
      ></path>
      <path
        fill="#7aba4c"
        d="M23.5 22.694h-8.667a.667.667 0 010-1.334H23.5a.667.667 0 010 1.334z"
        data-name="Path 4114"
        opacity="0.5"
      ></path>
      <path
        fill="#7aba4c"
        d="M21.678 10.792l-.943-.943a1.364 1.364 0 00-1.885 0l-1.379 1.379 2.829 2.828 1.379-1.379a1.331 1.331 0 000-1.885z"
        data-name="Path 4115"
        opacity="0.5"
      ></path>
      <path
        fill="#7aba4c"
        d="M16.529 12.171l-6.871 6.871a.332.332 0 00-.089.159l-.727 3.084a.334.334 0 00.325.41.329.329 0 00.076-.009l3.084-.727a.327.327 0 00.159-.089L19.357 15z"
        data-name="Path 4116"
      ></path>
    </svg>
  );
}

export function ShowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        fill="rgba(99,164,249,0.1)"
        d="M26 32H6a6 6 0 01-6-6V6a6 6 0 016-6h20a6 6 0 016 6v20a6 6 0 01-6 6zm0 0"
        data-name="Path 4123"
      ></path>
      <g
        fill="#63a4f9"
        data-name="Group 36117"
        opacity="0.5"
        transform="translate(8.001 9.332)"
      >
        <path
          d="M128.683 153a.667.667 0 01-.667-.667v-1a2 2 0 012-2h1a.667.667 0 010 1.334h-1a.668.668 0 00-.667.667v1a.666.666 0 01-.666.666zm0 0"
          data-name="Path 4124"
          transform="translate(-128.016 -149.327)"
        ></path>
        <path
          d="M328.344 152.98a.667.667 0 01-.667-.667v-1a.668.668 0 00-.667-.667h-1a.667.667 0 010-1.334h1a2 2 0 012 2v1a.666.666 0 01-.666.668zm0 0"
          data-name="Path 4125"
          transform="translate(-313.01 -149.312)"
        ></path>
        <path
          d="M131.016 307.667h-1a2 2 0 01-2-2v-1a.667.667 0 111.334 0v1a.667.667 0 00.667.667h1a.667.667 0 110 1.333zm0 0"
          data-name="Path 4126"
          transform="translate(-128.016 -294.332)"
        ></path>
        <path
          d="M327.011 307.652h-1a.667.667 0 010-1.334h1a.668.668 0 00.667-.667v-1a.667.667 0 111.334 0v1a2 2 0 01-2.001 2.001zm0 0"
          data-name="Path 4127"
          transform="translate(-313.011 -294.317)"
        ></path>
      </g>
      <path
        fill="#63a4f9"
        d="M175.974 191.246a5.349 5.349 0 00-5.32 3.927.487.487 0 000 .24 5.567 5.567 0 0010.64 0 .488.488 0 000-.24 5.349 5.349 0 00-5.32-3.927zm0 6.047a2 2 0 112-2 2.006 2.006 0 01-2 2zm0 0"
        data-name="Path 4128"
        transform="translate(-159.974 -179.293)"
      ></path>
    </svg>
  );
}
