import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getSalesDetails } from "../../api/store/actions/saga/sales_transactions";
import SectionLabel from "../../reusableComponents/sectionLabel";
import Rating from "@mui/material/Rating";
import PhoneIcon from "../../reusableComponents/iconsSvg/phoneIcon";
import WhatsAppIcon from "../../reusableComponents/iconsSvg/whatsappIcon";
import FacebookIcon from "../../reusableComponents/iconsSvg/facebookIcon";
import LocationIcon from "../../reusableComponents/iconsSvg/locationIcon";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import { LabelWIcon } from "../../screenParts/farms/labelWIcon";
import { useMediaQuery } from "react-responsive";
import ListHeader from "../../screenParts/listingViews/listHeader";
import TimeIcon from "../../reusableComponents/iconsSvg/timeIcon";
import Loading from "../../reusableComponents/loadingScreen/loading";
import Button from "../../reusableComponents/button";
import {
  acceptDispute,
  getDisputeDetails,
  rejectDispute,
} from "../../api/store/actions/saga/dispute";
import moment from "moment";
import { useSelector } from "react-redux";
export const useTablet = () => useMediaQuery({ maxWidth: 1100 });

export const DisputeDetails = () => {
  const isTablet = useTablet();
  const { dispute_id } = useParams();
  const [disputeDetails, setDisputeDetails] = useState();
  const [acceptDisputes, setAcceptDispute] = useState();
  const [rejectDisputes, setRejectDispute] = useState();

  const reload = useSelector((state) => state.reload.reload);

  console.log(dispute_id);
  useEffect(() => {
    resolveRequest(getDisputeDetails, setDisputeDetails, {
      dispute_id: dispute_id,
    });
  }, [reload, acceptDisputes, rejectDisputes]);
  useEffect(() => {
    console.log("salesDetails", disputeDetails);
  }, [disputeDetails]);
  const halfWidth = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  return (
    <>
      {disputeDetails ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListHeader
              hasTitle={true}
              title={"تفاصيل العملية"}
              back
              hasAdd={false}
              hasFilter={false}
              hasSearch={false}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p> الحالة:</p>
                <p
                  style={{
                    color:
                      disputeDetails?.data?.status?.id == 7
                        ? "#B1B1B1"
                        : disputeDetails?.data?.status?.id == 8
                        ? "#7ABA4C"
                        : "#FE646F",
                    marginRight: 10,
                  }}
                >
                  {disputeDetails?.data?.status?.status}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <TimeIcon />
                <p style={{ color: "#535353", marginRight: 10 }}>
                  {moment(disputeDetails?.data?.created_at).format(
                    "YYYY-MM-DD"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <SectionLabel content={"تفاصيل الطلب"} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <LabelWIcon
                    icon={<ChickenIcon />}
                    text={` العدد: ${disputeDetails?.data?.order_request?.amount}`}
                  />
                  {disputeDetails?.data?.order_request?.dajin_health ? (
                    <LabelWIcon
                      icon={<ChickenIcon />}
                      text={` الحالة الصحية: ${disputeDetails?.data?.order_request?.dajin_health}`}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                {disputeDetails?.data?.order_request?.average_weight ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 20,
                    }}
                  >
                    <LabelWIcon
                      icon={<ChickenIcon />}
                      text={` متوسط الوزن: ${disputeDetails?.data?.order_request?.average_weight}`}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 15,
                  }}
                >
                  <LabelWIcon
                    icon={<ChickenIcon />}
                    text={` السعر النهائى: ${disputeDetails?.data?.order_request?.final_price}`}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: isTablet ? "column" : "row",
              }}
            >
              <div
                style={{
                  ...halfWidth,
                }}
              >
                <SectionLabel content={"حالة التاجر"} />

                <p
                  style={{
                    backgroundColor: "#B1B1B1",
                    width: 200,
                    textAlign: "center",
                    borderRadius: 10,
                    color: "white",
                    padding: 10,
                  }}
                >
                  {
                    disputeDetails?.data?.order_request?.status
                      ?.status_for_trader
                  }
                </p>
              </div>
              <div
                style={{
                  ...halfWidth,
                }}
              >
                <SectionLabel content={"حالة المربى"} />

                <p
                  style={{
                    backgroundColor: "#B1B1B1",
                    width: 200,
                    textAlign: "center",
                    borderRadius: 10,
                    color: "white",
                    padding: 10,
                  }}
                >
                  {
                    disputeDetails?.data?.order_request?.status
                      ?.status_for_farmer
                  }
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: isTablet ? "column" : "row",
              }}
            >
              {disputeDetails?.data?.comment && (
                <div
                  style={{
                    ...halfWidth,
                  }}
                >
                  <SectionLabel content={"التعليق"} />

                  <p
                    style={{
                      borderRadius: 10,
                      border: ".5px solid black",
                      padding: 20,
                      fontSize: 10,
                      margin: 10,
                    }}
                  >
                    {disputeDetails?.data?.comment}
                  </p>
                </div>
              )}

              {disputeDetails?.data?.reason && (
                <div
                  style={{
                    ...halfWidth,
                  }}
                >
                  <SectionLabel content={"سبب النزاع"} />

                  <p
                    style={{
                      margin: 10,
                      borderRadius: 10,
                      border: ".5px solid black",
                      padding: 20,
                      fontSize: 10,
                    }}
                  >
                    {disputeDetails?.data?.reason?.reason}
                  </p>
                </div>
              )}
            </div>

            {disputeDetails?.data?.status?.id == 7 && (
              <div style={{ display: "flex", width: "50%" }}>
                <Button
                  style={{ margin: 10 }}
                  onClick={() => {
                    resolveRequest(acceptDispute, setAcceptDispute, {
                      dispute_id: dispute_id,
                    });
                  }}
                >
                  قبول
                </Button>
                <Button
                  backgroundColor="white"
                  textColor="red"
                  buttonStyles={{ border: "1px solid red" }}
                  style={{ margin: 10 }}
                  onClick={() => {
                    resolveRequest(rejectDispute, setRejectDispute, {
                      dispute_id: dispute_id,
                    });
                  }}
                >
                  رفض
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
