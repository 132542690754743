import React, { useEffect } from "react";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import Button from "../../../reusableComponents/button";
import BinIcon from "../../../reusableComponents/iconsSvg/binIcon";
import CartIcon from "../../../reusableComponents/iconsSvg/cartIcon";
import ChickenIcon from "../../../reusableComponents/iconsSvg/chickenIcon";
import CostIcon from "../../../reusableComponents/iconsSvg/costIcon";
import LargeChickenIcon from "../../../reusableComponents/iconsSvg/largeChickenIcon";
import LocationIcon from "../../../reusableComponents/iconsSvg/locationIcon";
import { EditIcon } from "../../../reusableComponents/iconsSvg/tableI/tableIcons";
export const PriceCard = ({ price, setPrice, min, max, final }) => {
  //useResponsiveWidth();
  console.log("min", min);
  console.log("price", price);
  console.log("max", max);

  return (
    <div
      style={{
        marginTop: 10,
        marginBottom: 10,
        ...useResponsiveWidth({
          desktop: 100,
          tablet: 100,
          mobile: 100,
          defaults: 100,
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Button
        backgroundColor={price >= max || final ? "lightgray" : "#7ABA4C"}
        width="20%"
        onClick={
          final
            ? () => {}
            : () => {
                if (price <= max - 1) {
                  setPrice(price + 1);
                }
              }
        }
      >
        +
      </Button>{" "}
      <p
        style={{
          ...useResponsiveWidth({
            desktop: 55,
            tablet: 55,
            mobile: 55,
            defaults: 55,
          }),
          marginRight: 10,
          marginLeft: 10,
          height: 50,
          backgroundColor: "white",
          border: "1px solid #EDEDED",
          borderRadius: 10,
          fontSize: 24,
          textAlign: "center",
          paddingTop: 5,
        }}
      >
        {price}
      </p>
      <Button
        width="20%"
        backgroundColor={price < min + 1 || final ? "lightgray" : "#7ABA4C"}
        onClick={
          final
            ? () => {}
            : () => {
                if (price >= min + 1) {
                  setPrice(price - 1);
                }
              }
        }
      >
        -
      </Button>
    </div>
  );
};
