import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  convertToRaw,
  ContentState,
  EditorState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

export const MyEditor = ({ placeholder, content, onChange }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const blocksFromHTML = convertFromHTML(content?.trim());

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )
      )
    );
  }, [content]);

  return (
    <div style={{ width: "100%" }}>
      <Editor
        wrapperStyle={{ border: "1px solid #E8E8E8", padding: "10px" }}
        editorStyle={{ border: "1px solid #E8E8E8", padding: "10px" }}
        toolbar={[
          ["style", ["bold", "italic", "underline", "clear"]],
          ["font", ["strikethrough", "superscript", "subscript"]],
          ["fontsize", ["fontsize"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["height", ["height"]],
        ]}
        placeholder={placeholder}
        editorState={editorState}
        onEditorStateChange={(state) => {
          setEditorState(state);
          if (onChange)
            onChange(draftToHtml(convertToRaw(state.getCurrentContent())));
        }}
      />
    </div>
  );
};
