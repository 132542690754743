import React from "react";

function NotifyHeader() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.5"
      height="24.5"
      viewBox="0 0 24.5 24.5"
    >
      <g transform="translate(-1.75 -1.75)">
        <g data-name="Layer 2">
          <path
            fill="#7aba4c"
            d="M21 14.088V11.3a8.68 8.68 0 00-7.875-8.631V.875a.875.875 0 00-1.75 0v1.79A8.68 8.68 0 003.5 11.3v2.792A4.382 4.382 0 000 18.375 2.628 2.628 0 002.625 21h5.338a4.375 4.375 0 008.573 0h5.338a2.628 2.628 0 002.626-2.625 4.382 4.382 0 00-3.5-4.287zM5.25 11.3a6.93 6.93 0 016.921-6.921h.158A6.93 6.93 0 0119.25 11.3V14h-14zm7 11.454A2.621 2.621 0 019.786 21h4.927a2.621 2.621 0 01-2.464 1.75zm9.625-3.5H2.625a.876.876 0 01-.875-.875 2.628 2.628 0 012.625-2.629h15.75a2.628 2.628 0 012.625 2.625.876.876 0 01-.875.875z"
            transform="translate(1.75 1.75)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default NotifyHeader;

export const NewNotifyHeader = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.5}
    height={24.5}
    viewBox="0 0 58 58"
    {...props}
  >
    <g id="Group_39564" data-name="Group 39564" transform="translate(0 2)">
      <g
        id="Group_36156"
        data-name="Group 36156"
        transform="translate(-1402 -32)"
      >
        {/* <rect
          id="background"
          width={56}
          height={56}
          rx={20}
          transform="translate(1402 32)"
          fill="rgba(122,186,76,0.1)"
        /> */}
        <g id="ic_bell" transform="translate(1416 46)">
          <g id="Layer_2" data-name="Layer 2">
            <path
              id="Vector"
              d="M21,14.088V11.3a8.68,8.68,0,0,0-7.875-8.631V.875a.875.875,0,0,0-1.75,0v1.79A8.68,8.68,0,0,0,3.5,11.3v2.792A4.382,4.382,0,0,0,0,18.375,2.628,2.628,0,0,0,2.625,21H7.963a4.375,4.375,0,0,0,8.573,0h5.338A2.628,2.628,0,0,0,24.5,18.375,4.382,4.382,0,0,0,21,14.088ZM5.25,11.3a6.93,6.93,0,0,1,6.921-6.921h.158A6.93,6.93,0,0,1,19.25,11.3V14h-14Zm7,11.454A2.621,2.621,0,0,1,9.786,21h4.927a2.621,2.621,0,0,1-2.464,1.75Zm9.625-3.5H2.625a.876.876,0,0,1-.875-.875A2.628,2.628,0,0,1,4.375,15.75h15.75a2.628,2.628,0,0,1,2.625,2.625.876.876,0,0,1-.875.875Z"
              transform="translate(1.75 1.75)"
              fill="#7aba4c"
            />
          </g>
        </g>
      </g>
      <g id="dotCounter" transform="translate(-1402 -32)">
        <g
          id="background-2"
          data-name="background"
          transform="translate(1442 34)"
          fill="#fe646f"
          stroke="#fbfbfb"
          strokeWidth={4}
        >
          <circle cx={7} cy={7} r={7} stroke="none" />
          <circle cx={7} cy={7} r={9} fill="none" />
        </g>
      </g>
    </g>
  </svg>
);
