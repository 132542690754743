import { useEffect, useState } from "react";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  createArticles,
  getArticles,
} from "../../api/store/actions/saga/articles";
import NoData from "../noData";
import "./listStyles.css";
import TableHeadItem from "./tableHeadItem";
import TableRow from "./tableRow";

const TableBase = ({
  tableHead = [],
  tableData = [],
  links = {},
  objName,
  stateLocation,
  actionName = "تعديل",
  onClick,
}) => {
  console.log(tableHead, tableData);
  return (
    <>
      {tableData?.length ? (
        <table>
          <thead>
            <tr>
              {tableHead?.map((title) => {
                return (
                  <TableHeadItem
                    onClick={onClick}
                    objName={objName}
                    key={title}
                    item={title}
                  />
                );
              })}
              {
                <th scope="col" title={actionName}>
                  {actionName}
                </th>
              }
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item) => {
              return (
                <TableRow
                  objName={objName}
                  links={links}
                  id={item.id}
                  key={item.id}
                  data={item.items}
                  stateLocation={stateLocation}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default TableBase;
