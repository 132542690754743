import { useSelector } from "react-redux";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

import AppRoutes from "./AppRoutes";

const AppRouting = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default AppRouting;
