const CheckBox = ({
  label,
  id,
  setToggleCheckBox,
  toggleCheckBox = {},
  disabled,
}) => {
  const isChecked = toggleCheckBox[id];

  return (
    <div
      style={{
        width: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        paddingRight: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        <p style={{ marginRight: 15, marginLeft: 2 }}> {label} </p>
        <input
          disabled={disabled}
          id={id}
          checked={isChecked ?? false}
          type="checkbox"
          name={label}
          value={toggleCheckBox[id] === true}
          onChange={() => {
            setToggleCheckBox({ ...toggleCheckBox, [id]: !isChecked });
          }}
        />
      </div>
    </div>
  );
};
export default CheckBox;
