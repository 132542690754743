import { useEffect, useState } from "react";
import { resolveRequest } from "./api/middlewares/resolverRequests";
import { initReq } from "./api/store/actions/saga/news";
import "./App.css";
import AppRouting from "./router";
import Loading from "./reusableComponents/loadingScreen/loading";
import { settingsData } from "./api/store/actions/saga/settings";
import { useDispatch } from "react-redux";

function App() {
  const [CSRF, setUCSRF] = useState(null);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(settingsData())
    resolveRequest(initReq, setUCSRF);
  }, []);
  console.log("CSRF", CSRF);

  if (CSRF) return CSRF?.status == 419 ? <>ERROR</> : <AppRouting />;
  return <Loading width={"100%"} height={"100%"} />;
}

//
export default App;
