import "./modalStyles.css";
import Button from "../../reusableComponents/button";
import { useNavigate } from "react-router";
import { useEffect } from "react";
const AppModal = ({
  onClick,
  buttonTitle,
  children,
  modalTitle,
  buttonTitleReject,
  onClickReject,
  width = "80%",
}) => {
  useEffect(() => {
    document.body.classList.add("overFlow");
    return () => {
      document.body.classList.remove("overFlow");
    };
  }, []);
  const navigate = useNavigate();
  return (
    <div className="appmodal">
      <div className="appmodal-main" style={{ width: width }}>
        <div style={{ width: "100%" }}>
          <p
            style={{
              color: "#7ABA4C",
              textAlign: "center",
              paddingBottom: 15,
              fontFamily: "boldKufi",
            }}
          >
            {modalTitle ?? "Modal Title"}
          </p>
          <div style={{ marginBottom: 10, marginTop: 10 }}>
            {children ?? <p>HII MODAL</p>}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent:
              onClickReject && buttonTitleReject ? "space-between" : "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {onClickReject && buttonTitleReject && (
            <Button
              backgroundColor="white"
              textColor="#FE646F"
              buttonStyles={{ border: "1px solid #FE646F" }}
              onClick={onClickReject}
              textAlign={"center"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              width={"40%"}
            >
              {buttonTitleReject ?? "Reject"}
            </Button>
          )}
          {onClick && (
            <Button
              onClick={onClick}
              textAlign={"center"}
              type={"submit"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              width={"40%"}
            >
              {buttonTitle ?? "Add"}
            </Button>
          )}
        </div>
        <div
          onClick={() => {
            navigate(-1, { replace: true });
          }}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 12,
            top: -12,
            backgroundColor: "white",
            borderRadius: "50%",
            width: 25,
            height: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#797979",
          }}
        >
          X
        </div>
      </div>
    </div>
  );
};

export default AppModal;
