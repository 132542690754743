import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { deleteTicket } from "../../../api/store/actions/saga/support";
import AppModal from "../../../screenParts/modal/customModal";

const DeleteTicket = () => {
  const { id } = useParams();
  const submit = () => {
    deleteTicket({ id: id });
  };
  return (
    <AppModal
      buttonTitle={"حذف"}
      modalTitle={"هل انت متاكد من الحذف ؟"}
      onClick={submit}
      children={" "}
    ></AppModal>
  );
};

export default DeleteTicket;
