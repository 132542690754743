import { useEffect, useState } from "react";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getInstructors } from "../../api/store/actions/saga/instructors";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";

const InstructorsList = ({ searchKey, active }) => {
  const [instructorData, setInstructorData] = useState(null);

  useEffect(() => {
    if (active == 3) {
      setInstructorData(null);
      resolveRequest(getInstructors, setInstructorData, {
        params: { name: searchKey },
      });
    }
  }, [searchKey]);

  return (
    <>
      {instructorData ? (
        <ListView
          objName={"instructors"}
          links={{
            edit: "editInstructor",
            delete: "",
            show: "",
            query: { show: "", delete: "", edit: "" },
          }}
          data={instructorData}
        ></ListView>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default InstructorsList;
