import { useNavigate, useParams } from "react-router";
import { deleteBreeds } from "../../api/store/actions/saga/settings/breeds";
import { deleteCompany } from "../../api/store/actions/saga/settings/companies";
import { deleteDajinType } from "../../api/store/actions/saga/settings/dajinTypes";
import { deleteFacility } from "../../api/store/actions/saga/settings/facilities";
import { deleteFarmingType } from "../../api/store/actions/saga/settings/farmingTypes";
import { deleteGift } from "../../api/store/actions/saga/settings/gifts";
import { deleteRating } from "../../api/store/actions/saga/settings/rating";
import { deleteSupportType } from "../../api/store/actions/saga/settings/supportTypes";
import { deleteTraderLevel } from "../../api/store/actions/saga/settings/traderLevels";
import AppModal from "../../screenParts/modal/customModal";
const DeleteSetting = () => {
  const navigate = useNavigate();

  const { id, type, dajinId } = useParams();
  const submit = () => {
    type == "levels"
      ? deleteTraderLevel({ id: id })
      : type == "gift"
      ? deleteGift({ gift_id: id })
      : type == "trader"
      ? deleteRating({ id: id, type: type })
      : type == "farm"
      ? deleteRating({ id: id, type: type })
      : type == "dajin"
      ? deleteDajinType({ id: id })
      : type == "facility"
      ? deleteFacility({ id: id })
      : type == "company"
      ? deleteCompany({ id: id })
      : type == "farming"
      ? deleteFarmingType({ id: id })
      : type == "support"
      ? deleteSupportType({ id: id })
      : type == "breed"
      ? deleteBreeds({ id: id, dajinId: dajinId })
      : (() => {})();
  };
  return (
    <AppModal
      buttonTitle={"حذف"}
      modalTitle={"هل انت متاكد من الحذف ؟"}
      onClick={submit}
      buttonTitleReject="الغاء"
      onClickReject={() => {
        navigate(-1, { replace: true });
      }}
      children={" "}
    ></AppModal>
  );
};

export default DeleteSetting;
