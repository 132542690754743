import SectionLabel from "../../reusableComponents/sectionLabel";

import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { useEffect, useState } from "react";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  getCommentsDetails,
  updateComment,
} from "../../api/store/actions/saga/comments";
import { useParams } from "react-router";
import TextInput from "../../reusableComponents/textInput";
import TimeInput from "../../reusableComponents/timeInput";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import { useNavigate } from "react-router-dom";

function CommentForm(isEdit = true) {
  const width = useResponsiveWidth({});
  const navigate = useNavigate();
  const { commentId } = useParams();
  const [CommentsDetails, setCommentsDetails] = useState();
  const options = [
    { id: 1, name: " نعم" },
    { id: 0, name: "لا" },
  ];
  useEffect(() => {
    if (isEdit) {
      resolveRequest(getCommentsDetails, setCommentsDetails, {
        id: commentId,
      });
    }
  }, []);

  const submit = (values) => {
    const body = {
      name: values?.name,
      email: values?.email,

      content: values?.comment,
      approved: parseInt(values?.approved, 10),
      // image: values?.image,
      /*  allow_comments: parseInt(values?.commentApproved, 10),
      publish_at: checked
        ? dateToIso(new Date())
        : dateToIso(new Date(values?.date + " " + values?.time)),
    }; */
    };
    updateComment({
      body: body,
      id: commentId,
    });
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      !isEdit ? undefined : CommentsDetails?.data?.data,
      validationSchema,
      "commentsScreen",
      submit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
  } = formik;
  console.log(values);
  const isValid = formikFieldValidation(touched, errors);
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: 10,
        // backgroundImage: `url(${BG})`,
      }}
    >
      <p style={{ marginBottom: 10, marginTop: 10 }}>تعديل التعليق </p>

      <form onSubmit={handleSubmit}>
        <SectionLabel content={"الكاتب"} />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ marginBottom: 10, marginTop: 10 }}> الاسم </p>
            <TextInput
              readOnly
              value={values?.name}
              placeholder={"اكتب الاسم"}
              onChange={handleChange("name")}
              errorMessage={isValid("name")}
            />
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ marginBottom: 10, marginTop: 10 }}>
              {" "}
              البريد الإلكترونى{" "}
            </p>
            <TextInput
              readOnly
              value={values?.email}
              placeholder={"اكتب البريد الالكترونى"}
              onChange={handleChange("email")}
              errorMessage={isValid("email")}
            />
          </div>
        </div>
        <SectionLabel content={"التعليق"} />
        <div>
          <p style={{ marginBottom: 10, marginTop: 10 }}> التعليق </p>
          <textarea
            rows="5"
            style={{
              width: "100%",
              height: 80,
              border: 0,
              borderRadius: 10,
              backgroundColor: "#FBFBFB",
              color: "black",
              paddingRight: 20,
            }}
            value={values["comment"]}
            onChange={handleChange("comment")}
            dir={"rtl"}
            placeholder={"اكتب التعليق"}
            name="comment"
          ></textarea>
          <p style={{ color: "red" }}>{isValid("comment")}</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "50%",
              paddingTop: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SectionLabel content={"النشر"} />
            <p style={{ marginBottom: 10, marginTop: 10 }}>تاريخ النشر</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <input
                style={{
                  width: "50%",
                  height: 54,
                  border: 0,
                  borderRadius: 10,
                  backgroundColor: "#FBFBFB",
                  color: "#7ABA4C",
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
                value={values["date"]}
                onChange={handleChange("date")}
                dir="rtl"
                type="date"
                name="date"
              />
              <TimeInput
                responsiveWidth={{ ...width }}
                value={values.time}
                name={"time"}
                onChange={handleChange("time")}
              />
            </div>
          </div>
          <div
            style={{
              width: "50%",
              paddingTop: 20,
              paddingRight: 20,
              paddingLeft: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SectionLabel content={"حالة التعليق"} />
            <p style={{ marginBottom: 10, marginTop: 10 }}>حالة التعليق</p>
            <SelectDropdown
              schema={{ id: "id", name: "name" }}
              placeholder={"اختر"}
              responsiveWidth={{ ...width }}
              selectedItem={values.approved}
              dataSource={options}
              onSelect={(e) => {
                console.log("e", e);
                setFieldValue("approved", e);
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", marginTop: 20 }}>
          <Button type={"submit"} style={{ width: "20%" }}>
            {!isEdit ? "إضافة" : "تعديل"}
          </Button>

          <Button
            onClick={() => {
              setCommentsDetails(undefined);
              navigate(-1);
            }}
            type={"reset"}
            backgroundColor="#F4F4F4"
            textColor="#9F9F9F"
            style={{ marginRight: 20, width: "20%" }}
          >
            إلغاء
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CommentForm;
