const initialState = {
  reload: false,
};
export default function reload(state = initialState, action) {
  switch (action.type) {
    case "RELOAD":
      return {
        ...state, reload: !state.reload,
      };
    case "ACTIVE_STATE":
      return {
        ...state, active: action.payload ?? 1,
      };
    case "ACTIVE_DETAILS_STATE":
      return {
        ...state, activeDetails: action.payload ?? 1,
      };
    default:
      return state;
  }
}


export const setActiveDetailsState = (value) => {
  return { type: "ACTIVE_DETAILS_STATE", payload: value }
}
export const clearActiveDetailsState = () => {
  return { type: "ACTIVE_DETAILS_STATE", payload: 1 }
}
export const setActiveState = (value) => {
  return { type: "ACTIVE_STATE", payload: value }
}
export const clearActiveState = () => {
  return { type: "ACTIVE_STATE", payload: 1 }
}