import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { arToEn } from "../../../alogrithms";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  addTimeLimitedOffer,
  editTimeLimitedOffer,
  showTimeLimitedOffer,
} from "../../../api/store/actions/saga/settings/timerLimtedOffer";
import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";
import { useTablet } from "../../salesTransactions/salesDetails";

const AddTimeLimtedOffer = ({ isEdit }) => {
  const { offer_id } = useParams();
  const [offerDetails, setOfferDetails] = useState();
  useEffect(() => {
    if (isEdit)
      resolveRequest(showTimeLimitedOffer, setOfferDetails, {
        offer_id: offer_id,
      });
  }, []);
  const submit = async (values) => {
    const body = {
      points: arToEn(values?.points),
      valid_from: values?.valid_from,
      valid_until: values?.valid_until,
      description: values?.description,
    };
    const res = await addTimeLimitedOffer({ body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };
  const submitEdit = async (values) => {
    const body = {
      points: arToEn(values?.points),
      valid_until: values?.valid_until,
      valid_from: values?.valid_from,

      description: values?.description,
    };
    const res = await editTimeLimitedOffer({ body: body, offer_id: offer_id });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? offerDetails?.data : undefined,

      validationSchema,
      "AddTimeLimtedOffer",
      !isEdit ? submit : submitEdit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log("balues", values);
  const isValid = formikFieldValidation(touched, errors);
  const width = useResponsiveWidth({
    desktop: 100,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const halfWidth = useResponsiveWidth({
    desktop: 48,
    tablet: 100,
    tabletDesktop: 100,
    tabletMobile: 100,
  });
  const isTablet = useTablet();
  return (
    <AppModal
      buttonTitle={isEdit ? "تعديل" : "إضافة"}
      modalTitle={isEdit ? "تعديل عرض لفترة محدودة" : "إضافة عرض لفترة محدودة"}
      onClick={handleSubmit}
    >
      <TextInput
        require
        // defaultValue={isEdit ? category?.data?.name : ""}
        label={"النقاط"}
        placeholder={"0000"}
        onChange={handleChange("points")}
        errorMessage={isValid("points")}
        value={values["points"]}
      />
      <div
        style={{
          marginTop: 10,
          flexDirection: isTablet ? "column" : "row",

          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ ...halfWidth }}>
          <p>بداية الصلاحية *</p>
          <input
            style={{
              width: "100%",

              height: 54,
              border: 0,
              borderRadius: 10,
              backgroundColor: "#FBFBFB",
              color: "#7ABA4C",
              paddingRight: 20,
              paddingLeft: 20,
            }}
            value={values["valid_from"]}
            onChange={handleChange("valid_from")}
            dir="rtl"
            type="date"
            name="date"
          />
          <p style={{ color: "red" }}>{isValid("valid_from")}</p>
        </div>

        <div
          style={{
            ...halfWidth,
          }}
        >
          <p>نهايةالصلاحية *</p>
          <input
            style={{
              width: "100%",
              height: 54,
              border: 0,
              borderRadius: 10,
              backgroundColor: "#FBFBFB",
              color: "#7ABA4C",
              paddingRight: 20,
              paddingLeft: 20,
            }}
            value={values["valid_until"]}
            onChange={handleChange("valid_until")}
            dir="rtl"
            type="date"
            name="date"
          />
          <p style={{ color: "red" }}>{isValid("valid_until")}</p>
        </div>
      </div>
      <TextInput
        require
        // defaultValue={isEdit ? category?.data?.name : ""}
        onChange={handleChange("description")}
        label={"الوصف"}
        placeholder={"اكتب الوصف"}
        errorMessage={isValid("description")}
        value={values["description"]}
      />
    </AppModal>
  );
};

export default AddTimeLimtedOffer;
