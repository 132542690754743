import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = "/admin/v1/notifications";
const apiUrlPolling = "/admin/v1/notifications/poll";

export const getNotifications = async ({ currentPage = 1 }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}?page=${currentPage}`);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const checkReadNotifications = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}/read/check`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const pollingNotifications = async ({ timeStamp }) => {
  try {
    console.log("timeStamp poll", timeStamp);
    const data = await axiosCall.get(`${apiUrlPolling}/${timeStamp}`);
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const markAsReadNotify = async ({ notificationId }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/read/${notificationId}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
