import React from "react";

function LocationIcon({ width = "11.084", height = "16.01" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11.084 16.01"
    >
      <path
        fill="#7aba4c"
        d="M13.417 3.375a5.356 5.356 0 00-5.542 5.146c0 4 5.542 10.864 5.542 10.864s5.542-6.862 5.542-10.864a5.356 5.356 0 00-5.542-5.146zm0 7.347a1.8 1.8 0 111.8-1.8 1.805 1.805 0 01-1.8 1.8z"
        data-name="Icon ionic-ios-pin"
        transform="translate(-7.875 -3.375)"
      ></path>
    </svg>
  );
}

export default LocationIcon;
