import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  createCompany,
  getCompanyDetails,
  updateCompany,
} from "../../../api/store/actions/saga/settings/companies";
import {
  createFacility,
  getFacilityDetails,
  updateFacility,
} from "../../../api/store/actions/saga/settings/facilities";

import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";

const CompaniesForm = ({ isEdit }) => {
  const { companyId } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);
  useEffect(() => {
    if (isEdit)
      resolveRequest(getCompanyDetails, setCompanyDetails, {
        id: companyId,
      });
  }, []);
  const submit = async (data) => {
    if (isEdit) {
      const res = await updateCompany({ id: companyId, body: data });
      setBackEndErrors(res?.errors, setFieldError);
    } else {
      const res = await createCompany({ body: data });
      setBackEndErrors(res?.errors, setFieldError);
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? companyDetails?.data : {},
      validationSchema,
      "company",
      submit
    )
  );
  const { values, handleSubmit, handleChange, touched, errors, setFieldError } =
    formik;
  console.log(errors, values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <div>
      <AppModal
        buttonTitleReject={false}
        onClickReject={false}
        buttonTitle={isEdit ? "تعديل" : "إضافة"}
        modalTitle={isEdit ? "تعديل  شركة " : "إضافة شركة"}
        onClick={handleSubmit}
      >
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={"النوع"}
          value={values.type}
          defaultValue={values.type}
          name={"type"}
          errorMessage={isValid("type")}
          onChange={handleChange("type")}
        />
      </AppModal>
    </div>
  );
};

export default CompaniesForm;
