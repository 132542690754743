import moment from "moment";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "./dateInputStyles.css";
const CustomDatePicker = ({ onChange, value }) => {
  console.log(value == false);
  const newValue = value ? moment(value).toDate() : "";
  return (
    <DatePicker
      className={"outer-date-input"}
      // defaultValue={new Date()}
      clearIcon={false}
      format={"yyyy/M/d"}
      closeCalendar={false}
      onChange={(e) => {
        if (onChange) onChange(moment(e).format("yyyy-MM-DD"));
      }}
      value={newValue}
    />
  );
};

export default CustomDatePicker;
