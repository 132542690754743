import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  getContactUsRecords,
  getTechinacalSupportRecords,
} from "../../../api/store/actions/saga/support";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const TechinacalSupport = ({
  filterKey,
  currentPage,
  setCurrentPage,
  filterKey2,
}) => {
  const [technicalSupportData, setTechnicalSupportData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    setTechnicalSupportData(null);
    resolveRequest(getTechinacalSupportRecords, setTechnicalSupportData, {
      pageNumber: currentPage,
      params: {
        support_type_id: filterKey,
        closed: filterKey2,
      },
    });
  }, [filterKey, currentPage, filterKey2, reload]);
  console.log(filterKey);

  return (
    <>
      {technicalSupportData ? (
        <ListView
          links={{
            edit: "",
            delete: "deleteSupportTicket",
            show: `SupportTicket`,
            query: {
              show: "",
              delete: "",
              edit: "",
            },
          }}
          objName={"support"}
          data={technicalSupportData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};
export default TechinacalSupport;
