import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getArticles } from "../../api/store/actions/saga/articles";
import { getCourses } from "../../api/store/actions/saga/courses";
import { getAllDisputes } from "../../api/store/actions/saga/dispute";
import { getAllFarmers } from "../../api/store/actions/saga/farmer";
import { getAllSales } from "../../api/store/actions/saga/sales_transactions";
import { getAllSettings } from "../../api/store/actions/saga/settings";
import { getAllTraders } from "../../api/store/actions/saga/traders";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";

const DisputesList = () => {
  const [disputeData, setDisputeData] = useState(null);

  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const settingsData = useSelector(state => state?.settings?.setting)


  useEffect(() => {
    // setCourseData(null);
    resolveRequest(getAllDisputes, setDisputeData, {
      pageNumber: currentPage,
      pageCount: 7,
      // params: { name: searchKey, city_id: filterKey },
    });
  }, [currentPage, searchKey, filterKey]);
  console.log("filterKey", filterKey);
  console.log("disputeData", disputeData);
  const onChange = (value) => {
    setSearchKey(value);
  };
  console.log("settings", settingsData);
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
    console.log(value, "select");
  };
  return (
    <>
      {settingsData && (
        <ListHeader
          onSelect={onSelect}
          onChange={onChange}
          dataSource={
            settingsData
              ? [
                { name: "الكل", id: null },
                ...settingsData?.data?.data?.cities,
              ]
              : []
          }
          hasFilter={false}
          hasSearch={false}
          hasAdd={false}
        />
      )}
      {disputeData ? (
        <ListView
          objName={"disputes"}
          links={{
            show: "disputeDetails",
            edit: "",
            query: { show: "", edit: "", delete: "" },
          }}
          data={disputeData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default DisputesList;
