import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getArticles } from "../../api/store/actions/saga/articles";
import { getCourses } from "../../api/store/actions/saga/courses";
import { getAllFarmers } from "../../api/store/actions/saga/farmer";
import { getAllSales } from "../../api/store/actions/saga/sales_transactions";
import { getAllSettings } from "../../api/store/actions/saga/settings";
import { getAllTraders } from "../../api/store/actions/saga/traders";
import { clearActiveDetailsState } from "../../api/store/reducers/reload";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";

const FarmersList = () => {
  const dispatch = useDispatch();
  const [farmersData, setFarmersData] = useState(null);

  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const settingsData = useSelector((state) => state?.settings?.setting);
  const [sort, setSort] = useState("");
  useEffect(() => {
    dispatch(clearActiveDetailsState());
  }, []);
  useEffect(() => {
    // setCourseData(null);
    resolveRequest(getAllFarmers, setFarmersData, {
      pageNumber: currentPage,
      pageCount: 7,
      params: { name: searchKey, status_id: filterKey },
      sort: sort,
    });
  }, [currentPage, searchKey, filterKey, sort]);
  console.log("filterKey", filterKey);
  useEffect(() => {
    // setCourseData(null);
    console.log("FarmersData", farmersData);
  }, [farmersData]);
  const onChange = (value) => {
    setSearchKey(value);
  };
  console.log("settings", settingsData);
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
    console.log(value, "select");
  };
  return (
    <>
      {settingsData && (
        <ListHeader
          onSelect={onSelect}
          onChange={onChange}
          schema={{ name: "status", id: "id" }}
          dataSource={
            settingsData
              ? [
                  { status: "الكل", id: null },
                  ...settingsData?.data?.data?.user?.account_statuses,
                ]
              : []
          }
          hasFilter={true}
          hasSearch={true}
          hasAdd={true}
          addLink={"addFarmer"}
          linkType={undefined}
        />
      )}
      {farmersData ? (
        <ListView
          onClick={() => {
            setSort(sort == "total_rating" ? "-total_rating" : "total_rating");
          }}
          objName={"farmers"}
          links={{
            show: "farmerDetails",
            edit: "editFarmer",
            query: { show: "", edit: "", delete: "" },
          }}
          data={farmersData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FarmersList;
