import { NotifyIcon } from "../../../reusableComponents/iconsSvg/sideBar/icons";
import AddCategory from "../../../screens/categories/addCategoryForm";
import CreateOffer from "../../../screens/farms/createOffer";
import DeleteCategory from "../../../screens/categories/deleteCategory";
import HouseFarm from "../../../screens/farms/houseFarm";
import NewsList from "../../../screens/news";
import AddBalance from "../../../screens/traders/addBalance";
import AddGift from "../../../screens/setting/gifts/addGift";
import AddPoints from "../../../screens/traders/addPrize";
import TraderForm from "../../../screens/setting/tarderLevels/traderLevelsForm";
import RatingForm from "../../../screens/setting/rating/ratingForm";
import DeleteSetting from "../../../screens/setting/deleteSetting";
import DajinTypesForm from "../../../screens/setting/dajinTypes/dajinTypesForm";
import FarmingTypeForm from "../../../screens/setting/farmingTypes/farmingTypeForm";
import FacilityTypeForm from "../../../screens/setting/facilitiesTypes/facilityForm";
import SupportTypeForm from "../../../screens/setting/suppotrTypes/supportTypesForm";
import BreedsForm from "../../../screens/setting/breeds/breedsForm";
import UpdateBonusPoints from "../../../screens/setting/bonusPoints/updateBonusPoints";
import AddTimeLimtedOffer from "../../../screens/setting/timeLimtedOffer/addTimeLimtedOffer";
import DeleteTicket from "../../../screens/support/technical/deleteTicket";
import DeleteComment from "../../../screens/comments/deleteComment";
import DeleteFarm from "../../farms/deleteFarm";
import MapScreen from "../../farms/mapScreen";
import CompaniesForm from "../../../screens/setting/companies/companiesForm";
import EditTawreedSettings from "../../../screens/setting/tawreedSettings/editTawreedSettings";

export const appModalsRoutes = [
  {
    id: "addNewsCategory",
    name: "addNewsCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddCategory />,
    link: "/news/addCategory/:type",
  },
  {
    id: "addBalance",
    name: "addBalance",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddBalance />,
    link: "/traders/traderDetails/:trader_id/addBalance",
  },
  {
    id: "addBalanceAccounts",
    name: "addBalanceAccounts",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddBalance />,
    link: "/accounts/balances/accountBalanceDetails/:trader_id/addBalance",
  },
  {
    id: "addPrize",
    name: "addPrize",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddPoints />,
    link: "/traders/traderDetails/:trader_id/addPrize",
  },
  {
    id: "addPrizeAccounts",
    name: "addPrizeAccounts",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddPoints />,
    link: "/accounts/balances/accountBalanceDetails/:trader_id/addPrize",
  },
  {
    id: "editNewsCategory",
    name: "editNewsCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddCategory isEdit />,
    link: "/news/editCategory/:type/:categoryId",
  },
  {
    id: "deleteNewsCategory",
    name: "deleteNewsCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DeleteCategory />,
    link: "/news/deleteCategory/:type/:categoryId",
  },
  {
    id: "deleteSupportTicket",
    name: "deleteSupportTicket",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DeleteTicket />,
    link: "/support/deleteSupportTicket/:id",
  },
  {
    id: "deleteFarm",
    name: "deleteFarm",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DeleteFarm />,
    link: "/farmers/farmerDetails/deleteFarm/:id",
  },
  {
    id: "mapScreen",
    name: "mapScreen",
    icon: (color) => <NotifyIcon color={color} />,
    component: <MapScreen />,
    link: "/farmers/farmerDetails/mapScreen",
  },
  {
    id: "deleteComment",
    name: "deleteComment",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DeleteComment />,
    link: "/comments/deleteComment/:id",
  },

  {
    id: "housing",
    name: "housing",
    icon: (color) => <NotifyIcon color={color} />,
    component: <HouseFarm />,
    link: "/farmers/farmDetails/:farm_id/housing",
  },
  {
    id: "createOffer",
    name: "createOffer",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateOffer />,
    link: "/farmers/farmDetails/:farm_id/createOffer/:dajin_id",
  },

  {
    id: "editOffer",
    name: "editOffer",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CreateOffer isEdit />,
    link: "/farmers/farmDetails/editOffer/:dajin_id/:farm_id/:offer_id",
  },
  {
    id: "addArticlesCategory",
    name: "addArticlesCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddCategory />,
    link: "/articles/addCategory/:type",
  },
  {
    id: "editArticlesCategory",
    name: "editArticlesCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddCategory isEdit />,
    link: "/articles/editCategory/:type/:categoryId",
  },
  {
    id: "deleteArticlesCategory",
    name: "deleteArticlesCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DeleteCategory />,
    link: "/articles/deleteCategory/:type/:categoryId",
  },
  {
    id: "addCoursesCategory",
    name: "addCoursesCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddCategory />,
    link: "/courses/addCategory/:type",
  },

  {
    id: "editCoursesCategory",
    name: "editCoursesCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddCategory isEdit />,
    link: "/courses/editCategory/:type/:categoryId",
  },
  {
    id: "deleteCoursesCategory",
    name: "deleteCoursesCategory",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DeleteCategory />,
    link: "/courses/deleteCategory/:type/:categoryId",
  },

  {
    id: "delete",
    name: "delete",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DeleteSetting />,
    link: "/setting/delete/:type/:id",
  },
  {
    id: "deleteBreed",
    name: "deleteBreed",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DeleteSetting />,
    link: "/setting/dajinTypeBreeds/:dajinId/delete/deleteBreed/:type/:id",
  },
  {
    id: "addGift",
    name: "addGift",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddGift />,
    link: "/setting/addGift",
  },
  {
    id: "addTimerLimitedOffer",
    name: "addTimerLimitedOffer",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddTimeLimtedOffer />,
    link: "/setting/addTimerLimitedOffer",
  },
  {
    id: "editTimerLimitedOffer",
    name: "editTimerLimitedOffer",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddTimeLimtedOffer isEdit />,
    link: "/setting/editTimerLimitedOffer/:offer_id",
  },
  {
    id: "editGift",
    name: "editGift",
    icon: (color) => <NotifyIcon color={color} />,
    component: <AddGift isEdit />,
    link: "/setting/editGift/:gift_id",
  },
  {
    id: "updateTawreedSettings",
    name: "updateTawreedSettings",
    icon: (color) => <NotifyIcon color={color} />,
    component: <EditTawreedSettings />,
    link: "/setting/updateTawreedSettings",
  },

  {
    id: "updateTraderLevel",
    name: "updateTraderLevel",
    icon: (color) => <NotifyIcon color={color} />,
    component: <TraderForm isEdit />,
    link: "/setting/updateTraderLevel/:levelId",
  },
  {
    id: "createTraderLevel",
    name: "createTraderLevel",
    icon: (color) => <NotifyIcon color={color} />,
    component: <TraderForm />,
    link: "/setting/createTraderLevel",
  },

  {
    id: "updateRating",
    name: "updateRating",
    icon: (color) => <NotifyIcon color={color} />,
    component: <RatingForm isEdit />,
    link: "/setting/updateRating/:type/:ratingId",
  },
  {
    id: "createRating",
    name: "createRating",
    icon: (color) => <NotifyIcon color={color} />,
    component: <RatingForm />,
    link: "/setting/createRating/:type",
  },

  {
    id: "updateDajin",
    name: "updateDajin",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DajinTypesForm isEdit />,
    link: "/setting/updateDajin/:dajinId",
  },
  {
    id: "createDajin",
    name: "createDajin",
    icon: (color) => <NotifyIcon color={color} />,
    component: <DajinTypesForm />,
    link: "/setting/createDajin",
  },

  {
    id: "updateFarmingType",
    name: "updateFarmingType",
    icon: (color) => <NotifyIcon color={color} />,
    component: <FarmingTypeForm isEdit />,
    link: "/setting/updateFarmingType/:farmingId",
  },
  {
    id: "createFarmingType",
    name: "createFarmingType",
    icon: (color) => <NotifyIcon color={color} />,
    component: <FarmingTypeForm />,
    link: "/setting/createFarmingType",
  },

  {
    id: "updateFacility",
    name: "updateFacility",
    icon: (color) => <NotifyIcon color={color} />,
    component: <FacilityTypeForm isEdit />,
    link: "/setting/updateFacility/:facilityId",
  },
  {
    id: "updateCompany",
    name: "updateCompany",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CompaniesForm isEdit />,
    link: "/setting/updateCompany/:companyId",
  },
  {
    id: "createFacility",
    name: "createFacility",
    icon: (color) => <NotifyIcon color={color} />,
    component: <FacilityTypeForm />,
    link: "/setting/createFacility",
  },
  {
    id: "createCompany",
    name: "createCompany",
    icon: (color) => <NotifyIcon color={color} />,
    component: <CompaniesForm />,
    link: "/setting/createCompany",
  },

  {
    id: "updateSupportType",
    name: "updateSupportType",
    icon: (color) => <NotifyIcon color={color} />,
    component: <SupportTypeForm isEdit />,
    link: "/setting/updateSupportType/:supportId",
  },
  {
    id: "createSupportType",
    name: "createSupportType",
    icon: (color) => <NotifyIcon color={color} />,
    component: <SupportTypeForm />,
    link: "/setting/createSupportType",
  },

  {
    id: "updateBreed",
    name: "updateBreed",
    icon: (color) => <NotifyIcon color={color} />,
    component: <BreedsForm isEdit />,
    link: "/setting/dajinTypeBreeds/:dajinId/updateBreed/:breedId",
  },
  {
    id: "createBreed",
    name: "createBreed",
    icon: (color) => <NotifyIcon color={color} />,
    component: <BreedsForm />,
    link: "/setting/dajinTypeBreeds/:dajinId/createBreed",
  },
  {
    id: "updateBonusPoints",
    name: "updateBonusPoints",
    icon: (color) => <NotifyIcon color={color} />,
    component: <UpdateBonusPoints isEdit />,
    link: "/setting/updateBonusPoints/:bonus_id",
  },
];
