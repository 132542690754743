import { toast } from "react-toastify";
import { loginTypes } from "../../utlity/actionTypes";
import { ErrorToast } from "./toastify";

export const interceptorsErrorsResponse = (error, dispatch, navigate) => {
  switch (error.response.status) {
    case 422:
      return ErrorToast(error.response.data.message);
    case 409:
      return ErrorToast(error.response.data.message);
    case 400:
      return ErrorToast(error.response.data.message);
    case 401:
      return dispatch({ type: loginTypes.LOGIN_FAILED });
    case 500:
      return ErrorToast(error.response.data.message);
    case 502:
      return console.log(error.response.status);
    case 403: {
      toast.warning("ليس لديك صلاحية");
      return navigate(-1);
    }
    default:
      ErrorToast("Something Went Wrong");
  }
};
