import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getPriceByRequest } from "../../api/store/actions/saga/settings";
import { getTraderOfferDetails } from "../../api/store/actions/saga/traders";
import Button from "../../reusableComponents/button";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import FacebookIcon from "../../reusableComponents/iconsSvg/facebookIcon";
import FeaturedIcon from "../../reusableComponents/iconsSvg/featuredIcon";
import LocationIcon from "../../reusableComponents/iconsSvg/locationIcon";
import PhoneIcon from "../../reusableComponents/iconsSvg/phoneIcon";
import StarIcon from "../../reusableComponents/iconsSvg/starIcon";
import WhatsAppIcon from "../../reusableComponents/iconsSvg/whatsappIcon";
import Loading from "../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { CardsCommonItem } from "../../screenParts/cardsCommonItem";
import { LabelWIcon } from "../../screenParts/farms/labelWIcon";
import ListHeader from "../../screenParts/listingViews/listHeader";
import { useTablet } from "../salesTransactions/salesDetails";

export const OrderRequestDetailsScreen = () => {
  const { offer_id } = useParams();
  const [requestDetails, setRequestDetails] = useState();
  const [dajin_price, setDajinPrice] = useState();
  useEffect(() => {
    resolveRequest(getTraderOfferDetails, setRequestDetails, {
      offer_id: offer_id,
    });
  }, []);
  useEffect(() => {
    resolveRequest(getPriceByRequest, setDajinPrice, {
      request_id: offer_id,
    });
  }, [offer_id]);
  console.log("requestDetails", requestDetails);
  const halfWidthFeedback = useResponsiveWidth({
    desktop: requestDetails?.data?.cancelFeedback ? 50 : 100,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const halfWidth = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const isTablet = useTablet();
  return (
    <>
      {requestDetails ? (
        <div>
          <ListHeader
            hasTitle={true}
            title={"تفاصيل الطلب"}
            back
            hasAdd={false}
            hasFilter={false}
            hasSearch={false}
          />
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "column" : "row",
            }}
          >
            <div
              style={{
                ...halfWidth,
              }}
            >
              <SectionLabel content={"تفاصيل العنبر"} />
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <img
                    style={{ width: 140, height: 140, borderRadius: 10 }}
                    src={
                      requestDetails?.data?.farm?.photo ??
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                    }
                  />
                </div>
                <div style={{ flex: 3, marginRight: 10, marginBottom: 10 }}>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginLeft: 5 }}>
                        {requestDetails?.data?.farm?.name}
                      </p>
                      {requestDetails?.data?.farm?.trusted && <FeaturedIcon />}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 20,
                      }}
                    >
                      <StarIcon />

                      <p style={{ marginRight: 10 }}>
                        {requestDetails?.data?.farm?.rating}
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LocationIcon width="30" height="30" />
                    <p style={{ marginTop: 10, marginBottom: 10 }}>
                      {" "}
                      {requestDetails?.data?.farm?.city?.name +
                        ", " +
                        requestDetails?.data?.farm?.city?.governorate?.name}
                    </p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <PhoneIcon />
                    <p
                      style={{
                        marginRight: 15,
                        marginLeft: 15,
                        color: "#646D82",
                      }}
                    >
                      {requestDetails?.data?.farm?.farmer?.mobile_number}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      marginBottom: 10,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <WhatsAppIcon />
                      <p
                        style={{
                          marginRight: 15,
                          marginLeft: 15,
                          color: "#646D82",
                        }}
                      >
                        {requestDetails?.data?.farm?.farmer?.whatsapp_number}
                      </p>
                    </div>
                    <div style={{ marginRight: 10, marginLeft: 10 }}>
                      <Button
                        backgroundColor="transparent"
                        onClick={() => {
                          window.open(
                            requestDetails?.data?.farm?.farmer
                              ?.facebook_account ?? ""
                          );
                        }}
                      >
                        <FacebookIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                ...halfWidth,
              }}
            >
              <SectionLabel content={"بيانات العنبر"} />
              <CardsCommonItem
                style={{ marginTop: 5 }}
                icon={<ChickenIcon />}
                text={`العدد  : ${requestDetails?.data?.farm_offer?.count}`}
              />
              <CardsCommonItem
                style={{ marginTop: 15 }}
                icon={<ChickenIcon />}
                text={`الحالة الصحية  : ${requestDetails?.data?.farm_offer?.health_status?.status}`}
              />
              <CardsCommonItem
                style={{ marginTop: 15 }}
                icon={<ChickenIcon />}
                text={`متوسط الوزن  : ${requestDetails?.data?.farm_offer?.average_weight}`}
              />
            </div>
          </div>
          <div>
            <SectionLabel content={"بيانات الطلب"} />

            <div
              style={{
                display: "flex",
                flexDirection: isTablet ? "column" : "row",
              }}
            >
              <div style={{ ...halfWidth }}>
                <CardsCommonItem
                  style={{ marginTop: 5 }}
                  icon={<ChickenIcon />}
                  text={`العدد  : ${requestDetails?.data?.order_details?.amount}`}
                />

                <div
                  style={{
                    marginTop: 15,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <LabelWIcon
                      icon={<ChickenIcon />}
                      text={` نطاق الشراء: `}
                    />
                  </div>
                  <div
                    style={{ display: "flex", marginTop: 5, flexWrap: "wrap" }}
                  >
                    {requestDetails?.data?.order_details?.governorates?.map(
                      (item) => (
                        <p
                          style={{
                            fontSize: 10,
                            padding: 5,
                            borderRadius: 10,
                            marginRight: 10,
                            backgroundColor: "lightgray",
                            textAlign: "center",
                          }}
                        >
                          {item?.name}
                        </p>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div style={{ ...halfWidth }}>
                <CardsCommonItem
                  style={{ marginTop: 5 }}
                  icon={<ChickenIcon />}
                  text={`النوع  : ${requestDetails?.data?.order_details?.dajin_type?.type}`}
                />
                <CardsCommonItem
                  style={{ marginTop: 15 }}
                  icon={<ChickenIcon />}
                  text={`متوسط الوزن  : ${requestDetails?.data?.order_details?.average_weight}`}
                />
                <CardsCommonItem
                  style={{ marginTop: 15 }}
                  icon={<ChickenIcon />}
                  text={`سعر الشراء  : ${requestDetails?.data?.order_details?.price}`}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "column" : "row",
            }}
          >
            <div style={{ ...halfWidthFeedback }}>
              <SectionLabel content={"حالة الطلب"} />
              <p
                style={{
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "white",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,

                  width: 200,
                  textAlign: "center",
                }}
              >
                {requestDetails?.data?.status?.status_for_farmer}
              </p>
            </div>
            {requestDetails?.data?.cancelFeedback && (
              <div style={{ ...halfWidthFeedback }}>
                <SectionLabel content={"سبب عدم اتمام الشراء"} />
                <p
                  style={{
                    borderRadius: 10,
                    border: ".5px solid black",
                    padding: 20,
                    fontSize: 10,
                  }}
                >
                  {requestDetails?.data?.cancelFeedback?.comment}
                </p>
              </div>
            )}
          </div>

          <div>
            <SectionLabel
              content={`السعر النهائى لل${requestDetails?.data?.order_details?.dajin_type?.type}`}
            />
            <p
              style={{
                borderRadius: 10,
                border: ".5px solid black",
                padding: 20,
                fontSize: 15,
              }}
            >
              {dajin_price?.data?.price?.final_price != null
                ? dajin_price?.data?.price?.final_price + " ج.م"
                : "لم يتم حساب السعر النهائى حتى الآن"}
            </p>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
