import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getRoles } from "../../api/store/actions/saga/roles";
import TextInput from "../../reusableComponents/textInput";
import TitleAndBack from "../../screenParts/listingViews/titleAndBack";
import { RotatingLines } from "react-loader-spinner";
import Button from "../../reusableComponents/button";
import MultiSelectDropdown from "../../reusableComponents/dropdown/dropdownMultiSelect";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import {
  addUser,
  getUser,
  updateUser,
} from "../../api/store/actions/saga/users";
import { objToFormData } from "../../alogrithms/requests";
import { useParams } from "react-router";
import { arToEn } from "../../alogrithms";
const UsersForm = ({ isEdit }) => {
  console.log("isEdit", isEdit);
  const { userId } = useParams();
  const width = useResponsiveWidth({});
  const [roles, setRoles] = useState(null);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    if (isEdit) resolveRequest(getUser, setUserData, { id: userId });
    resolveRequest(getRoles, setRoles);
  }, []);
  const submit = async (data) => {
    data.mobile_number = `0${arToEn(data?.mobile_number)}`;

    if (isEdit) {
      const res = await updateUser({
        id: userId,
        body: { roles: values?.roles },
      });
      setBackEndErrors(res?.errors, setFieldError);
    } else {
      const res = await addUser({ body: objToFormData(data) });
      setBackEndErrors(res?.errors, setFieldError);
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? { ...userData?.data } : {},
      validationSchema,
      isEdit ? "editUsers" : "users",
      submit
    )
  );
  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    touched,
    setFieldError,
  } = formik;
  console.log(userData, values);
  const errorMessage = formikFieldValidation(touched, errors);
  console.log("values.roles", values.roles);
  return (
    <div style={{ backgroundColor: "white", borderRadius: 8 }}>
      <TitleAndBack title={isEdit ? "تعديل المستخدم" : "إضافة مستخدم"} />
      <form onSubmit={handleSubmit}>
        {roles ? (
          <div style={{ width: "50%" }}>
            {!isEdit ? (
              <>
                <TextInput
                  require
                  name={"name"}
                  defaultValue={values.name}
                  values={values.name}
                  errorMessage={errorMessage("name")}
                  onChange={handleChange}
                  label={"الاسم"}
                  styles={{ marginTop: 10, marginBottom: 10 }}
                />
                <TextInput
                  require
                  name={"email"}
                  defaultValue={values.email}
                  values={values.email}
                  errorMessage={errorMessage("email")}
                  onChange={handleChange}
                  label={"البريد الالكتروني"}
                  styles={{ marginTop: 10, marginBottom: 10 }}
                />
                <TextInput
                  require
                  name={"mobile_number"}
                  defaultValue={values.mobile_number}
                  values={values.mobile_number}
                  errorMessage={errorMessage("mobile_number")}
                  onChange={handleChange}
                  label={"الموبايل"}
                  styles={{ marginTop: 10, marginBottom: 10 }}
                />
              </>
            ) : (
              <></>
            )}
            <MultiSelectDropdown
              require
              errorMessage={errorMessage("roles")}
              maxSelect={20}
              label={"الصلاحيات"}
              responsiveWidth={width}
              selectedItems={values.roles}
              dataSource={roles?.data}
              placeholder={"اختر"}
              onSelect={(item) => {
                setFieldValue("roles", item);
              }}
            />
            {!isEdit ? (
              <>
                <TextInput
                  require
                  name={"password"}
                  values={values.password}
                  errorMessage={errorMessage("password")}
                  onChange={handleChange}
                  label={"كلمة المرور"}
                  styles={{ marginTop: 10, marginBottom: 10 }}
                  password
                />
                <TextInput
                  require
                  name={"password_confirmation"}
                  values={values.password_confirmation}
                  errorMessage={errorMessage("password_confirmation")}
                  onChange={handleChange}
                  label={"تأكيد كلمة المرور"}
                  styles={{ marginTop: 10, marginBottom: 10 }}
                  password
                />
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <RotatingLines />
        )}
        <Button
          type={"submit"}
          width={"30%"}
          style={{ marginTop: 40, marginBottom: 10 }}
        >
          {isEdit ? "تعديل" : "إضافة"}
        </Button>
      </form>
    </div>
  );
};

export default UsersForm;
