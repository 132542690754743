import moment from "moment";

export const dateToIso = (date) => {
  var tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(date?.getTime() - tzoffset)
    .toISOString()
    .slice(0, -1);
  return localISOTime;
};
export const dateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
export const formDate = ({ date_now, creation_date }) => {
  var delta = Math.abs(date_now - creation_date) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60;
  return days != 0 && hours != 0
    ? `${days}يوم, ${hours}س `
    : days != 0 && hours == 0
    ? `${days}يوم`
    : days == 0 && hours != 0 && minutes != 0 && seconds != 0
    ? `${hours}س,${minutes}د ,${Math.floor(seconds)}ثانية`
    : `${hours}س,${minutes}د ,${Math.floor(seconds)}ثانية`;
};
