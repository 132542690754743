import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getAllAccounts } from "../../api/store/actions/saga/accounts";

import { getAllSettings } from "../../api/store/actions/saga/settings";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";

import ListHeader from "../../screenParts/listingViews/listHeader";

export const AccountsList = () => {
  const [accountsData, setAccountsData] = useState(null);

  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const settingsData = useSelector(state => state?.settings?.setting)

  useEffect(() => {
    // setCourseData(null);
    resolveRequest(getAllAccounts, setAccountsData, {
      pageNumber: currentPage,
      pageCount: 7,
      //   params: { name: searchKey, city_id: filterKey },
    });
  }, [currentPage, searchKey, filterKey]);
  console.log("filterKey", filterKey);

  const onChange = (value) => {
    setSearchKey(value);
  };
  console.log("accountsData", accountsData);
  console.log("settings", settingsData);
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
    console.log(value, "select");
  };
  return (
    <>
      {settingsData && (
        <ListHeader
          /*  onSelect={onSelect}
          onChange={onChange}
          dataSource={
            settingsData
              ? [
                  { name: "الكل", id: null },
                  ...settingsData?.data?.data?.cities,
                ]
              : []
          } */
          hasFilter={false}
          hasSearch={false}
          hasAdd={false}
          hasTitle={true}
          title="الحسابات و الأرصدة"
        //addLink={"addFarmer"}
        //linkType={undefined}
        />
      )}
      {accountsData ? (
        <ListView
          objName={"accounts"}
          links={{
            show: `accountBalanceDetails`,
            query: {
              show: { key: "total_transactions", prop: "total_transactions" },
              edit: "",
              delete: "",
            },
          }}
          data={accountsData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};
