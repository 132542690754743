import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = "/admin/v1/admins/roles";
const premissionApi = "/admin/v1/admins/permissions";
// export const createInstructor = async ({ body }) => {
//     try {
//       const data = await axiosCall.post(apiUrl, objToFormData(body), {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       return dataFormat(data);
//     } catch (e) {
//       return catchFromat(e.response);
//     }
//   };
export const getRoles = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}?include=permissions`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const addRole = async ({ body }) => {
  try {
    const data = await axiosCall.post(`${apiUrl}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateRole = async ({ id, body }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getRole = async ({ id }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}/${id}?include=permissions`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getPremission = async () => {
  try {
    const data = await axiosCall.get(`${premissionApi}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

//   export const updateInstructor = async ({ body, id }) => {
//     try {
//       const data = await axiosCall.post(`${apiUrl}/${id}`, objToFormData(body), {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       return dataFormat(data);
//     } catch (e) {
//       return catchFromat(e.response);
//     }
//   };
//   export const getInstructorDetails = async ({ id }) => {
//     try {
//       const data = await axiosCall.get(`${apiUrl}/${id}`);
//       return dataFormat(data);
//     } catch (e) {
//       return catchFromat(e.response);
//     }
//   };
//   export const deleteInstructor = async ({ id }) => {
//     try {
//       const data = await axiosCall.delete(`${apiUrl}/${id}`);
//       return dataFormat(data);
//     } catch (e) {
//       return catchFromat(e.response);
//     }
//   };
