import React from "react";

function HeaderLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210.118"
      height="56.907"
      viewBox="0 0 210.118 56.907"
    >
      <g data-name="Group 7" transform="translate(-610 -445.208)">
        <g
          fill="#143727"
          data-name="Group 4"
          transform="translate(610 465.644)"
        >
          <g data-name="Group 2">
            <g data-name="Group 1" transform="translate(0 3.284)">
              <path
                d="M636.233 529.015a2.346 2.346 0 001.712-.734 2.287 2.287 0 00.7-1.677 2.261 2.261 0 00-.716-1.677 2.345 2.345 0 00-1.694-.7 2.386 2.386 0 00-2.376 2.376 2.326 2.326 0 00.7 1.712 2.285 2.285 0 001.674.7z"
                data-name="Path 1"
                transform="translate(-626.696 -524.229)"
              ></path>
              <path
                d="M724.822 609.3a1.726 1.726 0 00-1.293.542 1.9 1.9 0 000 2.62 1.729 1.729 0 001.293.541 1.877 1.877 0 001.327-.524 1.815 1.815 0 00.559-1.328 1.756 1.756 0 00-.559-1.31 1.842 1.842 0 00-1.327-.541z"
                data-name="Path 2"
                transform="translate(-689.085 -583.767)"
              ></path>
              <path
                d="M709.285 611.155a1.756 1.756 0 00-.559-1.31 1.884 1.884 0 00-1.362-.542 1.662 1.662 0 00-1.257.559 1.7 1.7 0 00-.559 1.292 1.785 1.785 0 00.542 1.31 1.745 1.745 0 001.275.541 1.887 1.887 0 001.362-.541 1.759 1.759 0 00.558-1.309z"
                data-name="Path 3"
                transform="translate(-676.867 -583.767)"
              ></path>
              <path
                d="M716.535 623.619a1.68 1.68 0 101.677 1.677 1.59 1.59 0 00-.507-1.188 1.634 1.634 0 00-1.17-.489z"
                data-name="Path 4"
                transform="translate(-683.383 -593.785)"
              ></path>
              <path
                d="M653.318 557.09a10.852 10.852 0 00-3.284-8.07 11.192 11.192 0 00-8.1-3.144h-7.476v4.052h7.266a7.476 7.476 0 015.45 1.974 7.254 7.254 0 012.061 5.362l.035 2.026h-17.785q-2.411 0-2.411-3.074v-10.34h-4.087v15.266a6.332 6.332 0 01-1.554 4.454 5.325 5.325 0 01-7.791 0 6.329 6.329 0 01-1.554-4.454v-11.773H610v11.773a10.153 10.153 0 002.76 7.319 9.4 9.4 0 0013.17.367 9.9 9.9 0 003.07-6.533 3.048 3.048 0 002.481 1.048h21.868l-.035-4.052z"
                data-name="Path 5"
                transform="translate(-610 -539.378)"
              ></path>
            </g>
            <path
              d="M0 0H4.087V27.249H0z"
              data-name="Rectangle 1"
              transform="translate(46.748)"
            ></path>
            <path
              d="M797.059 545.876a8.534 8.534 0 00-4.716 1.4l1.642 3.563a5.874 5.874 0 013.074-.908 4.308 4.308 0 013.353 1.432 5.419 5.419 0 011.293 3.773v4.157h-11.039v4.052h15.126v-8.21a9.235 9.235 0 00-2.5-6.637 8.261 8.261 0 00-6.233-2.622z"
              data-name="Path 6"
              transform="translate(-736.436 -536.094)"
            ></path>
          </g>
          <g data-name="Group 3" transform="translate(76.147 3.214)">
            <path
              d="M899.621 529.306a2.247 2.247 0 001.624-.664 2.31 2.31 0 10-3.266-3.266 2.3 2.3 0 001.642 3.93z"
              data-name="Path 7"
              transform="translate(-887.219 -524.485)"
            ></path>
            <path
              d="M878.9 529.306a2.29 2.29 0 001.659-.664 2.3 2.3 0 000-3.266 2.259 2.259 0 00-1.659-.681 2.31 2.31 0 00-1.625 3.948 2.247 2.247 0 001.625.663z"
              data-name="Path 8"
              transform="translate(-872.722 -524.485)"
            ></path>
            <path
              d="M1033.1 528.817a2.386 2.386 0 10-1.712-.7 2.327 2.327 0 001.712.7z"
              data-name="Path 9"
              transform="translate(-980.558 -523.996)"
            ></path>
            <path
              d="M938.178 548.444a8.734 8.734 0 00-14.9 6.2 5.482 5.482 0 002.306 4.646h-4.89a2.187 2.187 0 01-1.851-.821 3.621 3.621 0 01-.629-2.253v-10.34h-4.052v10.341a3.622 3.622 0 01-.629 2.253 2.151 2.151 0 01-1.817.821h-1.327q-2.271 0-2.271-3v-1.223a9.4 9.4 0 00-2.358-6.62 7.9 7.9 0 00-6.1-2.568q-4.472 0-8.873 4.646v-4.646h-4.052v13.415h-3.493q-2.446 0-2.445-3.074v-10.345h-8.384a8.38 8.38 0 00-6.148 2.55 8.479 8.479 0 00-2.585 6.218 8.707 8.707 0 008.734 8.7 7 7 0 006.044-2.97 4.8 4.8 0 004.786 2.97h17.991a5.537 5.537 0 004.786-2.411 4.739 4.739 0 004.367 2.411h1.327a5.624 5.624 0 018.978 0h11.318a8.72 8.72 0 006.166-14.9zm-61.466 6.166a4.728 4.728 0 01-1.223 3.39 4.053 4.053 0 01-3.074 1.293 4.447 4.447 0 01-3.284-1.38 4.681 4.681 0 010-6.6 4.448 4.448 0 013.284-1.38h4.3zm27.354 1.362a3.466 3.466 0 01-.8 2.463 2.66 2.66 0 01-2.026.856h-10.45v-3.983q4.716-5.379 8.419-5.38a4.585 4.585 0 013.563 1.415 5.406 5.406 0 011.292 3.79zm31.23 1.939a4.655 4.655 0 01-7.93-3.3 4.533 4.533 0 011.363-3.3 4.6 4.6 0 016.568 0 4.68 4.68 0 010 6.6z"
              data-name="Path 10"
              transform="translate(-863.681 -539.308)"
            ></path>
          </g>
        </g>
        <g data-name="Group 6" transform="translate(763.211 445.208)">
          <g data-name="Group 5">
            <path
              fill="#143727"
              d="M1174.477 469.427a21.971 21.971 0 00-10.285-9.262v-14.957H1162a15.305 15.305 0 00-10.943 4.6v-4.6h-15.321a15.165 15.165 0 00-2.189.156 15.327 15.327 0 00-12.976 12.976 15.122 15.122 0 00-.156 2.189v2.189h13.132v39.4h21.886a21.893 21.893 0 0019.041-32.689zm-14.664-19.627v8.536h-8.534a10.978 10.978 0 018.534-8.536zm-34.8 8.536a10.964 10.964 0 0110.725-8.756h10.943v8.756zm30.424 39.4h-4.378v-8.754h4.376a8.755 8.755 0 100-17.51h-8.754v26.264h-8.754v-35.018h17.51a17.509 17.509 0 010 35.019zm-4.378-13.132v-8.754h4.378a4.377 4.377 0 010 8.754z"
              data-name="Path 11"
              transform="translate(-1120.417 -445.208)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeaderLogo;
