import { useEffect, useState } from "react";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getAllTraders } from "../../api/store/actions/saga/traders";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";

const BlockedAccountsScreen = ({ searchKey, filterKey }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tradersData, setTradersData] = useState();

  useEffect(() => {
    // setCourseData(null);
    resolveRequest(getAllTraders, setTradersData, {
      pageNumber: currentPage,
      params: { name: searchKey, status_id: 3, work_areas: filterKey },
    });
  }, [currentPage, searchKey, filterKey]);
  console.log("TraderssData", tradersData);

  return (
    <>
      {tradersData ? (
        <ListView
          objName={"traders"}
          links={{
            // edit: "editNews",
            // delete: "",
            show: "traderDetails",
            edit: "editTrader",

            query: { show: "", delete: "", edit: "" },
          }}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={tradersData}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default BlockedAccountsScreen;
