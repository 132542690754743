import { Rating } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  farmerChangeAccountStatus,
  getFarmerDetails,
} from "../../api/store/actions/saga/farmer";
import { getAllSettings } from "../../api/store/actions/saga/settings";
import {
  traderAccountDetails,
  traderChangeAccountStatus,
  traderFeatured,
} from "../../api/store/actions/saga/traders";
import Button from "../../reusableComponents/button";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import FacebookIcon from "../../reusableComponents/iconsSvg/facebookIcon";
import FeaturedIcon from "../../reusableComponents/iconsSvg/featuredIcon";
import PhoneIcon from "../../reusableComponents/iconsSvg/phoneIcon";
import StarIcon from "../../reusableComponents/iconsSvg/starIcon";
import WhatsAppIcon from "../../reusableComponents/iconsSvg/whatsappIcon";
import Loading from "../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { useTablet } from "../salesTransactions/salesDetails";
export const FarmerAccountDetails = () => {
  const { farmer_id } = useParams();
  const width = useResponsiveWidth({});

  const [accountDetails, setAccountDetails] = useState();
  const settings = useSelector((state) => state?.settings?.setting);
  const isTablet = useTablet();
  useEffect(() => {
    resolveRequest(getFarmerDetails, setAccountDetails, {
      farmer_id: farmer_id,
    });
  }, []);
  console.log("accountDetails", accountDetails);
  console.log("settings", settings);

  console.log(farmer_id);
  const submit = (values) => {
    const bodyStatus = {
      status_id: values?.status_id,
    };

    farmerChangeAccountStatus({
      body: bodyStatus,
      farmer_id: farmer_id,
    });
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      accountDetails?.data,
      validationSchema,
      "accountDetails",
      submit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
  } = formik;
  console.log(values);
  const isValid = formikFieldValidation(touched, errors);
  const halfWidth = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  return (
    <>
      {accountDetails && settings ? (
        <div
          style={{
            display: "flex",
            flexDirection: isTablet ? "column" : "row",
          }}
        >
          <div
            style={{
              ...halfWidth,
            }}
          >
            <SectionLabel content={"بيانات المربى"} />
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <img
                  style={{ width: 140, height: 140, borderRadius: 10 }}
                  src={
                    accountDetails?.data?.avatar ??
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                  }
                />
              </div>
              <div style={{ flex: 3, marginRight: 10, marginBottom: 10 }}>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginLeft: 5 }}>
                      {accountDetails?.data?.name}
                    </p>
                    {accountDetails?.data?.data?.featured && <FeaturedIcon />}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 20,
                    }}
                  >
                    <StarIcon />

                    <p style={{ marginRight: 10 }}>
                      {accountDetails?.data?.total_rating == 0 ||
                      accountDetails?.data?.total_rating == null
                        ? "لا يوجد تقييم"
                        : accountDetails?.data?.total_rating?.toFixed(2)}
                    </p>
                  </div>
                </div>

                <p style={{ marginTop: 10, marginBottom: 10 }}>
                  {accountDetails?.data?.city?.governorate?.name} ,
                  {accountDetails?.data?.city?.name}
                </p>
                <p style={{ marginTop: 10, marginBottom: 10 }}>
                  {accountDetails?.data?.email}
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <PhoneIcon />
                  <p
                    style={{
                      marginRight: 15,
                      marginLeft: 15,
                      color: "#646D82",
                    }}
                  >
                    {accountDetails?.data?.mobile_number}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    marginBottom: 10,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <WhatsAppIcon />
                    <p
                      style={{
                        marginRight: 15,
                        marginLeft: 15,
                        color: "#646D82",
                      }}
                    >
                      {accountDetails?.data?.whatsapp_number}
                    </p>
                  </div>
                  <div style={{ marginRight: 10, marginLeft: 10 }}>
                    <Button
                      backgroundColor="transparent"
                      onClick={() => {
                        window.open(
                          accountDetails?.data?.facebook_account ?? ""
                        );
                      }}
                    >
                      <FacebookIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <SectionLabel content={"معلومات النشاط"} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <p
                style={{
                  color: "#114634",
                  fontWeight: "bold",
                }}
              >
                نوع النشاط
              </p>
              <p style={{ marginRight: 20, marginLeft: 20 }}>
                {accountDetails?.data?.activity_type?.name}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <p
                style={{
                  color: "#114634",
                  fontWeight: "bold",
                }}
              >
                حجم التربية التقريبي
              </p>
              <p style={{ marginRight: 20, marginLeft: 20 }}>
                {accountDetails?.data?.farms_capacity?.name}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <p
                style={{
                  color: "#114634",
                  fontWeight: "bold",
                }}
              >
                عدد عنابر التربية
              </p>
              <p style={{ marginRight: 20, marginLeft: 20 }}>
                {accountDetails?.data?.farms_count}
              </p>
            </div>
          </div>
          <div
            style={{
              ...halfWidth,
            }}
          >
            {accountDetails?.data?.interests != undefined &&
              accountDetails?.data?.interests?.length != 0 && (
                <div>
                  <p
                    style={{
                      color: "#7ABA4C",
                      fontWeight: "bold",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    الاهتمامات
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {accountDetails?.data?.interests?.map((item, index) => {
                      return (
                        <p
                          key={index}
                          style={{
                            backgroundColor: "#F8FAFA",
                            border: "1px solid #EDEDED",
                            padding: 10,
                            borderRadius: 10,
                            marginLeft: 5,
                            marginRight: index != 0 ? 5 : 0,
                          }}
                        >
                          {item?.name}
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
            <SectionLabel content={"التفعيل"} />
            <form onSubmit={handleSubmit}>
              <SelectDropdown
                placeholder={"اختر"}
                schema={{ id: "id", name: "status" }}
                errorMessage={isValid("status_id")}
                responsiveWidth={{ width }}
                selectedItem={values?.status_id}
                dataSource={settings?.data?.data?.user?.account_statuses}
                onSelect={(e) => {
                  setFieldValue("status_id", e);
                }}
              />

              <Button style={{ marginTop: 10 }} type={"submit"}>
                {" "}
                تحديث
              </Button>
            </form>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
