import React from "react";
import StarIcon from "../reusableComponents/iconsSvg/starIcon";
import { useTablet } from "../screens/salesTransactions/salesDetails";
export const TraderRatingCard = ({ label, rate }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: 2,
        marginRight: 2,

        width: "100%",
      }}
    >
      <p style={{ fontSize: 8 }}>{label}</p>
      <StarIcon />
      <p style={{ color: "#B7B7B7", fontSize: 12 }}>{rate}</p>
    </div>
  );
};
