import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getGifts } from "../../../api/store/actions/saga/settings/gifts";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const GiftsList = () => {
  const [giftsData, setGiftsData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getGifts, setGiftsData);
  }, [reload]);
  return giftsData ? (
    <ListView
      stateLocation
      objName={"gifts"}
      links={{
        edit: "editGift",
        delete: `delete/gift`,
        show: ``,
        query: {
          show: "",
          delete: "",
          edit: "",
        },
      }}
      data={giftsData}
    ></ListView>
  ) : (
    <Loading />
  );
};

export default GiftsList;
