import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { arToEn } from "../../../alogrithms";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  createTraderLevel,
  getTraderLevelDetails,
  updateTraderLevel,
} from "../../../api/store/actions/saga/settings/traderLevels";
import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";

const TraderForm = ({ isEdit }) => {
  const { levelId } = useParams();
  const [traderLevelDetails, setTraderLevelDetails] = useState(null);
  useEffect(() => {
    if (isEdit)
      resolveRequest(getTraderLevelDetails, setTraderLevelDetails, {
        id: levelId,
      });
  }, []);
  const submit = async (data) => {
    data.to_number = arToEn(data?.to_number);
    data.from_number = arToEn(data?.from_number);
    data.discount = arToEn(data?.discount);

    if (isEdit) {
      const res = await updateTraderLevel({ id: levelId, body: data });
      setBackEndErrors(res?.errors, setFieldError);
    } else {
      const res = await createTraderLevel({ body: data });
      setBackEndErrors(res?.errors, setFieldError);
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? traderLevelDetails?.data : {},
      validationSchema,
      "traderLevels",
      submit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log(errors, values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <div>
      <AppModal
        buttonTitleReject={false}
        onClickReject={false}
        buttonTitle={isEdit ? "تعديل" : "إضافة"}
        modalTitle={isEdit ? "تعديل شريحة" : "إضافة  شريحة"}
        onClick={handleSubmit}
      >
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={"الاسم"}
          value={values.name}
          defaultValue={values.name}
          name={"name"}
          errorMessage={isValid("name")}
          onChange={handleChange("name")}
        />
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          type={"number"}
          label={"من العدد"}
          value={values.from_number}
          defaultValue={values.from_number}
          name="from_number"
          errorMessage={isValid("from_number")}
          onChange={handleChange("from_number")}
        />
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          type={"number"}
          label={"إلى عدد"}
          defaultValue={values.to_number}
          value={values.to_number}
          name="to_number"
          errorMessage={isValid("to_number")}
          onChange={handleChange("to_number")}
        />
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          type={"number"}
          label={"نسبة الخصم"}
          defaultValue={values.discount}
          value={values.discount}
          name="discount"
          errorMessage={isValid("discount")}
          onChange={handleChange("discount")}
        />
      </AppModal>
    </div>
  );
};

export default TraderForm;
