import { put, takeEvery } from "@redux-saga/core/effects";
import { settingTypes } from "../../../../utlity/actionTypes";
import axiosCall from "../../../axios/axiosConfig";
import { dataFormat } from "../../../middlewares/resolverRequests";

const settingsAPI = "/api/v1/settings";
export const getAllSettings = async () => {
  try {
    return await axiosCall.get(settingsAPI);
  } catch (e) {
    throw e;
  }
};

export function settingsData(body) {
  return {
    type: settingTypes.SETTING_REQUESTED,
    payload: body,
  };
}
function* fetchSettings(action) {
  console.log("ACTION", action);
  try {
    const setting = yield getAllSettings();
    console.log("setting SAGA", setting);
    yield put({ type: settingTypes.SETTING_SUCCESS, setting: setting });
  } catch (e) {
    console.log("MESSAGE", e);
    yield put({ type: settingTypes.SETTING_FAILED, message: e.message });
  }
}

export function* getSettingsSaga() {
  yield takeEvery(settingTypes.SETTING_REQUESTED, fetchSettings);
}

const priceAPI = "/admin/v1/prices";
export const getPriceByDajin = async ({ dajin_id }) => {
  try {
    const data = await axiosCall.get(`${priceAPI}/dajin/${dajin_id}`);
    return dataFormat(data);
  } catch (e) {
    throw e;
  }
};
export const getPriceByRequest = async ({ request_id }) => {
  try {
    const data = await axiosCall.get(`${priceAPI}/order/request/${request_id}`);
    return dataFormat(data);
  } catch (e) {
    throw e;
  }
};
export const getPriceByDajinFinal = async ({ dajin_id }) => {
  try {
    const data = await axiosCall.get(`${priceAPI}/final/${dajin_id}`);
    return data;
  } catch (e) {
    throw e;
  }
};
export const moneyForPoint = async () => {
  try {
    const data = await axiosCall.get(settingsAPI + "/money/for/point");
    return dataFormat(data);
  } catch (e) {
    throw e;
  }
};
