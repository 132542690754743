import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { deleteCategories } from "../../api/store/actions/saga/categories";
import AppModal from "../../screenParts/modal/customModal";

const DeleteCategory = () => {
  const { type, categoryId } = useParams();
  const submit = () => {
    deleteCategories({ type: type, id: categoryId });
  };
  return (
    <AppModal
      buttonTitle={"حذف"}
      modalTitle={"هل انت متاكد من الحذف ؟"}
      onClick={submit}
      children={" "}
    ></AppModal>
  );
};

export default DeleteCategory;
