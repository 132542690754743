import React from "react";
import Switch from "react-switch";

export const CustomSwitch = ({ checked, setChecked }) => {
  const handleChange = () => {
    setChecked((prevState) => !prevState);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        تذكرنى
      </p>
      <Switch
        onColor={"#4BD964"}
        uncheckedIcon={false}
        checkedIcon={false}
        onChange={handleChange}
        checked={checked}
      />
    </div>
  );
};
