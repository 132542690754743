import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getRating } from "../../../api/store/actions/saga/settings/rating";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";
import ListHeader from "../../../screenParts/listingViews/listHeader";

const SettingRating = () => {
  const [ratingActive, setRatingActive] = useState(1);
  const [ratingData, setRatingData] = useState(null);
  const [type, setType] = useState("trader");
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    if (ratingData) setRatingData(null);
    resolveRequest(getRating, setRatingData, { type: type });
  }, [type, reload]);
  console.log(ratingData);
  return (
    <>
      <ListHeader
        hasFilter={false}
        hasSearch={false}
        hasTitle={false}
        addLink={`createRating/${type}`}
        pages={[
          { id: 1, title: "تقييم التاجر" },
          { id: 2, title: "تقييم المربي" },
        ]}
        linkType={"modal"}
        active={ratingActive}
        setActive={(x) => {
          setType(type == "trader" ? "farm" : "trader");
          setRatingActive(x);
        }}
      />
      <>
        {ratingData ? (
          <>
            <ListView
              objName={"rating"}
              stateLocation
              links={{
                edit: `updateRating/${type}`,
                delete: `delete/${type}`,
                show: ``,
                query: {
                  show: "",
                  delete: "",
                  edit: "",
                },
              }}
              data={ratingData}
            ></ListView>
          </>
        ) : (
          <Loading />
        )}
      </>
    </>
  );
};

export default SettingRating;
