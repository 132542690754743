const SideBarItem = ({ active, children, title, color, backgroundColor }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 8,
        marginBottom: 8,
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 60,
          height: 60,
          backgroundColor: backgroundColor,
          borderRadius: 15,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {children}
      </div>
      <p style={{ color: color, marginRight: 15, marginLeft: 15 }}>{title}</p>
    </div>
  );
};

export default SideBarItem;
