import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  formikSetup,
  initialValues,
  validationSchema,
} from "../alogrithms/formikValidation";
import { userLogin } from "../api/store/actions/saga/login";
import Button from "../reusableComponents/button";
import { CustomSwitch } from "../reusableComponents/customSwitch";
import BG from "../reusableComponents/iconsSvg/BG.svg";
import LogoLogin from "../reusableComponents/iconsSvg/logoLogin";
import { NextIcon } from "../reusableComponents/iconsSvg/nextIcon";

export const LoginScreen = () => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(0);

  const submit = async (values) => {
    values.remember = checked;
    // token_name: "test",
    dispatch(userLogin(values));
  };

  const formik = useFormik(
    formikSetup(initialValues, undefined, validationSchema, "login", submit)
  );
  const { handleChange, handleSubmit, values } = formik;
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${BG})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LogoLogin />
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <input
            style={{
              marginTop: 20,

              width: 451,
              height: 54,
              border: 0,
              borderRadius: 10,
              backgroundColor: "rgba(180, 180, 180, 0.3)",
              color: "black",
              paddingRight: 20,
            }}
            value={values["email"]}
            onChange={handleChange("email")}
            type="email"
            name="email"
            placeholder="البريد الإلكترونى"
            dir="rtl"
          />
          <input
            style={{
              marginTop: 20,

              width: 451,
              height: 54,
              border: 0,
              borderRadius: 10,
              backgroundColor: "rgba(180, 180, 180, 0.3)",
              color: "black",
              paddingRight: 20,
            }}
            value={values["password"]}
            onChange={handleChange("password")}
            type="password"
            name="email"
            placeholder="الرقم السرى"
          />
          <div
            style={{ marginBottom: 10, marginTop: 10, alignSelf: "flex-start" }}
          >
            <CustomSwitch checked={!!checked} setChecked={setChecked} />
          </div>
          <Button
            style={{ marginTop: 20 }}
            width={"70%"}
            icon={<NextIcon />}
            type="submit"
          >
            تسجيل الدخول
          </Button>
        </form>
      </div>
      <div style={{ position: "absolute", bottom: "5%" }}>
        <p>جميع الحقوق محفوظة لمنصة داجن © 2022</p>
      </div>
    </div>
  );
};
