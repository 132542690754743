const initialState = {
    reload: false,
};
export default function loading(state = initialState, action) {
    switch (action.type) {
        case "BTN_LOADING":
            return {
                loading: action.payload ?? !state.reload,
            };
        default:
            return state;
    }
}
