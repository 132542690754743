import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getFarmingTypes } from "../../../api/store/actions/saga/settings/farmingTypes";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const FarmingTypes = () => {
  const [farmingTypesData, setFarmingTypesData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getFarmingTypes, setFarmingTypesData);
  }, [reload]);
  return farmingTypesData ? (
    <ListView
      objName={"farminTypes"}
      stateLocation
      links={{
        edit: "updateFarmingType",
        delete: "delete/farming",
        show: ``,
        query: {
          show: "",
          delete: "",
          edit: "",
        },
      }}
      data={farmingTypesData}
    ></ListView>
  ) : (
    <Loading />
  );
};

export default FarmingTypes;
