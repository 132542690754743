import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getFarmerFarms } from "../../api/store/actions/saga/farms";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { FarmCard } from "../../screenParts/farms/farmCard";
import NoData from "../../screenParts/noData";
export const FarmsScreen = ({
  currentPage,
  setCurrentPage,
  searchKey,
  filterKey,
}) => {
  const { farmer_id } = useParams();
  const [farmerFarms, setFarmerFarms] = useState();
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getFarmerFarms, setFarmerFarms, {
      farmer_id: farmer_id,
      pageNumber: currentPage,
      params: { farm_name: searchKey, status_id: filterKey },
      pageCount: 9,
    });
  }, [searchKey, currentPage, filterKey, reload]);

  useEffect(() => {
    console.log("farmData", farmerFarms);
  }, [farmerFarms]);
  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      <SectionLabel content={"عنابر"} />
      {farmerFarms ? (
        farmerFarms?.data?.length != 0 ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {farmerFarms?.data?.map((item) => {
              return (
                <FarmCard
                  deleteFarmLink={`/farmers/farmerDetails/deleteFarm/${item?.id}`}
                  farmManagementLink={`/farmers/farmDetails/${item?.id}`}
                  logLink={`/farmers/farms/farmLog/${item?.id}`}
                  key={item?.id}
                  name={item?.name}
                  location={`${item?.city?.governorate?.name ?? ""}, ${
                    item?.city?.name ?? ""
                  }`}
                  status={item?.status}
                  requested_price={item?.requested_price ?? "0000"}
                  dajin={item?.breed?.dajin?.type}
                  total_count={item?.total_count ?? "0000"}
                  image={item?.photo}
                />
              );
            })}
            {setCurrentPage && (
              <AppPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={Math.ceil(farmerFarms?.total / 9)}
              />
            )}
          </div>
        ) : (
          <NoData title="لا يوجد بيانات حالياً" />
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};
