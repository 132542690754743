import axiosCall from "../../../../axios/axiosConfig";
import {
  catchFromat,
  dataFormat,
} from "../../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/settings/trader/tawreed/orders`;
export const getTawreedLevels = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const editTawreedSettings = async ({ body }) => {
  try {
    const data = await axiosCall.patch(`${apiUrl}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
