import React from "react";
export const CardsCommonItem = ({ icon, text, style, marginTop = 0 }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "50%",
        marginTop: marginTop,
        ...style,
      }}
    >
      {icon}
      <p style={{ fontSize: 14, marginRight: 5 }}>{text}</p>
    </div>
  );
};
