import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.315"
      height="11.395"
      viewBox="0 0 13.315 11.395"
    >
      <path
        fill="#f8fafa"
        d="M22.271 22.51h-7.526a.575.575 0 110-1.15h7.526a.575.575 0 110 1.15z"
        data-name="Path 4114"
        opacity="0.5"
        transform="translate(-9.535 -11.115)"
      ></path>
      <path
        fill="#f8fafa"
        d="M21.124 10.609l-.819-.809a1.191 1.191 0 00-1.637 0l-1.2 1.188 2.456 2.437 1.2-1.188a1.141 1.141 0 000-1.624z"
        data-name="Path 4115"
        opacity="0.5"
        transform="translate(-9.97 -9.471)"
      ></path>
      <path
        fill="#f8fafa"
        d="M15.516 12.171l-5.967 5.921a.285.285 0 00-.077.137l-.631 2.657a.286.286 0 00.077.269.291.291 0 00.2.084.288.288 0 00.066-.008l2.678-.626a.285.285 0 00.138-.077l5.966-5.921z"
        data-name="Path 4116"
        transform="translate(-8.833 -9.844)"
      ></path>
    </svg>
  );
}

export default EditIcon;
