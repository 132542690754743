import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  addNotify,
  getNotify,
  getTargets,
  updateNotify,
} from "../../api/store/actions/saga/notification";
import CheckBox from "../../reusableComponents/checkBox";
import CustomDatePicker from "../../reusableComponents/dateInput/dateInput";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import Loading from "../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../reusableComponents/sectionLabel";
import TextArea from "../../reusableComponents/textArea";
import TextInput from "../../reusableComponents/textInput";
import TimeInput from "../../reusableComponents/timeInput";
import TitleAndBack from "../../screenParts/listingViews/titleAndBack";
import Button from "../../reusableComponents/button";
import { dateToIso } from "../../alogrithms/dateAlgo";
const NotificationForm = ({ isEdit }) => {
  const { notifyId } = useParams();
  const [targetsData, setTargetsData] = useState(null);
  const [notifyData, setNotifyData] = useState(null);
  useEffect(() => {
    resolveRequest(getTargets, setTargetsData);
    if (isEdit) {
      resolveRequest(getNotify, setNotifyData, { id: notifyId });
    }
  }, []);
  const submit = async (data) => {
    data.publish_at = dateToIso(new Date(data.publishDate + " " + data.publishTime));
    if (isEdit) {
      const res = await updateNotify({ id: notifyId, body: data });
      setBackEndErrors(res?.errors, setFieldError);

    } else {
      const res = await addNotify({ body: data });
      setBackEndErrors(res?.errors, setFieldError);
      console.log('res', res.errors)
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? notifyData?.data : {},
      validationSchema,
      "notification",
      submit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldError,
  } = formik;
  const isValid = formikFieldValidation(touched, errors);
  console.log('formikFieldValidation(true, errors)("publish_at")', errors.publish_at)
  return targetsData ? (
    <div style={{ backgroundColor: "white", borderRadius: 10 }}>
      <TitleAndBack title={isEdit ? "تعديل إشعار" : "إضافة إشعار"} />
      <SectionLabel content={"الاشعار"} />
      <TextInput
        require
        label={"العنوان"}
        styles={{ marginBottom: 10 }}
        name={"title"}
        value={values.title}
        onChange={handleChange}
        errorMessage={isValid("title")}
      />
      <TextArea
        require
        label={"تفاصيل الاشعار"}
        name={"description"}
        value={values.description}
        onChange={handleChange}
        errorMessage={isValid("description")}
      />
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", padding: 10 }}>
          <SectionLabel content={"موجهة الى"} />
          <label style={{ marginBottom: 10 }}> الجهة *</label>
          <SelectDropdown
            selectedItem={values.target}
            errorMessage={isValid("target")}
            dataSource={targetsData?.data?.map((item) => {
              return { name: item, id: item };
            })}
            onSelect={(e) => {
              setFieldValue("target", e);
            }}
            placeholder={"اختر"}
            width={"auto"}
          />
        </div>
        <div style={{ width: "50%", padding: 10 }}>
          <SectionLabel content={"النشر"} />
          <label style={{ marginBottom: 10 }}>تاريخ النشر *</label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 5, marginLeft: 5 }}>
              <CustomDatePicker
                value={values.publishDate ?? new Date()}
                onChange={(e) => {
                  setFieldValue("publishDate", e);
                }}
              />
              <p style={{ color: "red" }}>{isValid("publishDate")}</p>
            </div>
            <div style={{ marginRight: 5, marginLeft: 5 }}>
              <TimeInput
                value={values.publishTime ?? new Date()}
                name={"publishTime"}
                errorMessage={isValid("publishTime")}
                onChange={handleChange("publishTime")}
              />
            </div>

            {/* <div style={{ display: "flex", marginRight: 5, marginLeft: 5 }}>
              <CheckBox  /> الآن
            </div> */}
          </div>
          <p style={{ color: "red" }}>{errors.publish_at}</p>
        </div>
      </div>
      <Button
        style={{ marginTop: 10, marginBottom: 10 }}
        onClick={handleSubmit}
        type={"submit"}
        width={"20%"}
      >
        {isEdit ? "تعديل" : "إضافة"}
      </Button>
    </div>
  ) : (
    <Loading />
  );
};

export default NotificationForm;
