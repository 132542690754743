import axiosCall from "../../../axios/axiosConfig";
import { catchFromat, dataFormat } from "../../../middlewares/resolverRequests";
const categoriesUrl = (type) => `/admin/v1/${type}/categories`;

export const getCategories = async (type) => {
  try {
    let res = await axiosCall.get(categoriesUrl(type));
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const addCategories = async ({ type, body }) => {
  try {
    let res = await axiosCall.post(categoriesUrl(type), body);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateCategories = async ({ type, body, id }) => {
  try {
    let res = await axiosCall.put(categoriesUrl(type) + "/" + id, body);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const categoriesDetails = async ({ type, body, id }) => {
  try {
    let res = await axiosCall.get(categoriesUrl(type) + "/" + id, body);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const deleteCategories = async ({ type, id }) => {
  try {
    let res = await axiosCall.delete(categoriesUrl(type) + "/" + id);
    return dataFormat(res);
  } catch (e) {
    return catchFromat(e.response);
  }
};
