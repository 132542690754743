import "./loading.css";
const Loading = ({ width = "80%", height = "50%" }) => {
  return (
    <div
      className="loading"
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: width,
        display: "flex",
        flexDirection: "column",
        height: height,
        position: "fixed",
      }}
    >
      <h2 style={{ fontFamily: "boldKufi" }}>جاري التحميل </h2>
      <div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Loading;
