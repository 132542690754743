export const newsTypes = {
  GET_NEWS_REQUESTED: "GET_NEWS_REQUESTED",
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILED: "GET_NEWS_FAILED",
};

export const loginTypes = {
  LOGIN_REQUESTED: "LOGIN_REQUESTED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGIN_TOKEN: "LOGIN_TOKEN",
  LOGIN_STATUS: "LOGIN_STATUS",
};

export const settingTypes = {
  SETTING_REQUESTED: "SETTING_REQUESTED",
  SETTING_SUCCESS: "SETTING_SUCCESS",
  SETTING_FAILED: "SETTING_FAILED"
};
