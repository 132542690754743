import moment from "moment";
import { nestedPropsArr, propsArr } from "../utlity/GeneralProps";

export const filterCheckBoxObj = (obj) => {
  let result = [];
  for (let [key, value] of Object.entries(obj)) {
    if (value === true) result.push(key);
  }
  return result;
};

export const isEmpty = (obj) => {
  return !!Object.keys(obj).length;
};
export const isObject = (obj) => {
  return obj instanceof Object && obj !== null;
};

// export const getObjectValuesOnly = (obj) => {
//   let result = [];

//   for (let [key, value] of Object.entries(obj)) {
//     if (value === isObject(value))
//      result.push(key);
//   }
// };
export const dynamicProps = (arr, objName) => {
  return arr?.map((item) => {
    return { id: item?.id, items: [...filterObj(item, objName), []] };
  });
};

export const filterObjKeys = (obj, objName) => {
  let newKeys = [];

  for (let key of Object.keys(obj)) {
    console.log(propsArr[objName]);
    if (propsArr[objName][key]?.name == key) {
      if (key == "image_url") newKeys.unshift(key);
      else newKeys.push(key);
    }
  }
  return newKeys;
};

export const getStringPart = (string, numOfChars = 100) => {
  return string?.length > numOfChars
    ? string.substr(0, numOfChars) + " ..."
    : string;
};
const filterObj = (obj, objName) => {
  let newArr = [];

  for (let [key, value] of Object.entries(obj)) {
    console.log(propsArr[objName][key]?.name);
    if (propsArr[objName][key]?.name === key) {
      // if (value?.constructor?.name === "Object") {
      //   // for (let [keys, values] of Object.entries(value)) {
      //   // if (nestedPropsArr[key].includes(keys))
      //   newArr.push([
      //     key,
      //     value,
      //     propsArr[objName][key]?.special,
      //     propsArr[objName][key]?.type,
      //     propsArr[objName][key]?.mapProp,
      //   ]);
      //   // }
      // } else

      if (key == "image_url")
        newArr.unshift([
          key,
          key == "publish_at" ||
          key == "created_at" ||
          key == "created" ||
          key == "updated_at"
            ? moment(value).format("DD/MM/YYYY")
            : value,
          propsArr[objName][key]?.special,
          propsArr[objName][key]?.type,
          propsArr[objName][key]?.mapProp,
          propsArr[objName][key]?.nestadProps,
        ]);
      else
        newArr.push([
          key,
          key == "publish_at" ||
          key == "created_at" ||
          key == "created" ||
          key == "updated_at"
            ? objName == "disputes"
              ? value
              : moment(value).format("DD/MM/YYYY")
            : value,
          propsArr[objName][key]?.special,
          propsArr[objName][key]?.type,
          propsArr[objName][key]?.mapProp,
          propsArr[objName][key]?.nestadProps,
        ]);
    }
  }
  return newArr;
};

const arabicNumbers = [
  /٠/g,
  /١/g,
  /٢/g,
  /٣/g,
  /٤/g,
  /٥/g,
  /٦/g,
  /٧/g,
  /٨/g,
  /٩/g,
];
export const arToEn = (str) => {
  if (typeof str === "string")
    for (var i = 0; i < 10; i++) str = str.trim().replace(arabicNumbers[i], i);
  return Number(str);
};
