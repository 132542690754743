import React from "react";
import { useSelector } from "react-redux";
const Button = ({
  children,
  type,
  onClick,
  width = "100%",
  height = 47,
  backgroundColor = "#7ABA4C",
  icon,
  textColor = "white",
  fontSize = 15,
  textAlign = "center",
  style,
  buttonStyles,
}) => {
  const loading = useSelector((state) => state.loading.loading);

  return (
    <div
      style={{
        width: width,
        height: height,
        borderRadius: 10,
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "flex-start",
        display: "flex",
        ...style,
      }}
    >
      <button
        disabled={loading}
        className="btn"
        style={{
          width: "100%",
          height: height,
          border: 0,
          cursor: "pointer",
          backgroundColor: backgroundColor,
          ...buttonStyles,
        }}
        onClick={onClick}
        type={type}
      >
        <p
          style={{
            color: textColor,
            textAlign: textAlign,
            paddingRight: icon ? 20 : 0,
            fontSize: fontSize,
          }}
        >
          {children}
        </p>
      </button>
      {icon && <div style={{ position: "absolute", right: "20%" }}>{icon}</div>}
    </div>
  );
};

export default Button;
