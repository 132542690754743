import { Checkbox } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { getStringPart, isEmpty } from "../../alogrithms";
import {
  DeleteIcon,
  EditIcon,
  ShowIcon,
} from "../../reusableComponents/iconsSvg/tableI/tableIcons";
import { useTablet } from "../../screens/salesTransactions/salesDetails";
import { nestedPropsArr, propsMapper } from "../../utlity/GeneralProps";
import { StatusContent } from "../statusContent";
import { TraderRatingCard } from "../traderRatingCard";
import ArrayFormat from "./arrayFormat";

const TableRow = ({ data, id, links = {}, objName, stateLocation }) => {
  let showQuery;
  const location = useLocation();
  const useTablet = () => useMediaQuery({ maxWidth: 1300 });
  const isTablet = useTablet();
  return (
    <tr>
      {data?.map((item, index) => {
        // if (item[0] != "id")
        if (index != data.length - 1) {
          if (links?.query?.show?.prop == item[0]) showQuery = item[1];
          return (
            <td
              className={index == 0 ? "firsttd" : ""}
              data-label={propsMapper[objName][item[0]]}
              key={index}
              style={{ paddingLeft: 10 }}
              title={typeof item[1] == "object" ? "" : item[1]}
            >
              {item[0] == "image_url" ? (
                <img
                  alt={item[1]}
                  src={item[1]}
                  style={{ height: 50, width: 50, borderRadius: 10 }}
                />
              ) : Array.isArray(item[1]) ? (
                item[0] == "values" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isTablet ? "column" : "row",
                      width: "100%",
                    }}
                  >
                    {item[1]?.map((item) => {
                      console.log("rating values", item);
                      return (
                        <TraderRatingCard
                          label={item?.dimension?.dimension}
                          rate={item?.value?.toFixed(2)}
                        />
                      );
                      //   item?.value;
                    })}
                  </div>
                ) : (
                  <ArrayFormat item={item} />
                )
              ) : item[1] instanceof Object ? (
                item[5]?.map((i) => {
                  return item[1][i];
                })
              ) : // nestedPropsArr[item[0]].map((prop) => {
              //   if (prop == "status_for_farmer") {
              //     if (item[1][prop] == "جديد") {
              //       return (
              //         <div style={{ display: "contents" }}>
              //           <StatusContent
              //             color={"#B1B1B1"}
              //             text={item[1][prop]?.toString()}
              //           />
              //         </div>
              //       );
              //     } else if (item[1][prop] == "لم يتم البيع") {
              //       return (
              //         <StatusContent
              //           color={"red"}
              //           text={item[1][prop]?.toString()}
              //         />
              //       );
              //     } else if (item[1][prop] == "تم البيع") {
              //       return (
              //         <StatusContent
              //           color={"#7ABA4C"}
              //           text={item[1][prop]?.toString()}
              //         />
              //       );
              //     } else if (item[1][prop] == "مقبول") {
              //       return (
              //         <StatusContent
              //           color={"#2196F3"}
              //           text={item[1][prop]?.toString()}
              //         />
              //       );
              //     }
              //   }
              //   if (prop == "status") {
              //     if (item[1][prop] == "موقوف") {
              //       return (
              //         <StatusContent
              //           color={"#B1B1B1"}
              //           text={item[1][prop]?.toString()}
              //         />
              //       );
              //     } else if (item[1][prop] == "مفعل") {
              //       return (
              //         <StatusContent
              //           color={"#7ABA4C"}
              //           text={item[1][prop]?.toString()}
              //         />
              //       );
              //     } else if (item[1][prop] == "غير مفعل") {
              //       return (
              //         <StatusContent
              //           color={"#2196F3"}
              //           text={item[1][prop]?.toString()}
              //         />
              //       );
              //     }
              //   }

              //   return (
              //     <p style={{ display: "contents", fontSize: "unset" }}>
              //       {" "}
              //       {item[1][prop]?.toString()}
              //     </p>
              //   );
              // })
              item[1] === true ? (
                <Checkbox color="success" checked={true} readOnly={true} />
              ) : item[1] === false ? (
                <Checkbox readOnly={true} checked={false} />
              ) : (
                //  item[1] === 1 ? (
                //   "نعم"
                // ) : item[1] === 0 ? (
                //   "لا"
                // ) :

                getStringPart(item[1], 50)
              )}
            </td>
          );
        } else {
          return (
            <td
              style={{ cursor: "pointer" }}
              className={"lasttd"}
              data-label={"تعديل"}
              key={item[1] + index}
            >
              {!isEmpty(links) ? (
                "لا يمكن التعديل حاليا"
              ) : (
                <>
                  {links?.show && (
                    <Link
                      to={`${links?.show}/${id}${
                        links?.query?.show
                          ? "?" + links?.query?.show?.key + "=" + showQuery
                          : ""
                      }`}
                      style={{ display: "contents" }}
                    >
                      <ShowIcon />
                    </Link>
                  )}
                  &nbsp;
                  {links?.edit && (
                    <Link
                      to={`${links.edit}/${id}`}
                      style={{ display: "contents" }}
                      state={{
                        backgroundLocation: stateLocation ? location : null,
                      }}
                    >
                      <EditIcon />
                    </Link>
                  )}
                  &nbsp;
                  {links?.delete && (
                    <Link
                      to={`${links.delete}/${id}`}
                      style={{ display: "contents" }}
                      state={{
                        backgroundLocation: location,
                      }}
                    >
                      <DeleteIcon />
                    </Link>
                  )}
                  &nbsp;
                </>
              )}
            </td>
          );
        }
      })}
    </tr>
  );
};

export default TableRow;
