import React from "react";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import Button from "../../reusableComponents/button";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import LocationIcon from "../../reusableComponents/iconsSvg/locationIcon";
import StarIcon from "../../reusableComponents/iconsSvg/starIcon";
import { CardsCommonItem } from "../cardsCommonItem";

export const OrdersRequestsDetailsCard = ({
  location,
  farmName,
  amount,
  averageWeight,
  dajinHealth,
  requestStatus,
  onPress,
  image,
}) => {
  return (
    <div
      style={{
        ...useResponsiveWidth({
          desktop: 46,
          tablet: 100,
          mobile: 100,
          defaults: 100,
        }),
        marginRight: "2%",
        marginLeft: "2%",
        marginTop: "2%",

        marginBottom: "2%",

        padding: 20,

        border: "1px solid #EDEDED",
        borderRadius: 10,
        backgroundColor: "white",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            ...useResponsiveWidth({
              desktop: 100,
              tablet: 100,
              mobile: 100,
              defaults: 100,
            }),
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={
              image ??
              "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
            }
            style={{
              borderRadius: 10,
              ...useResponsiveWidth({
                desktop: 100,
                tablet: 100,
                mobile: 100,
                defaults: 100,
              }),
            }}
            height={120}
          />
        </div>
        <div style={{ flex: 3, marginRight: 20 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: 20 }}> {farmName}</p>
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <StarIcon />
              <p style={{ fontSize: 14, color: "#B7B7B7", marginRight: 5 }}>
                5.0
              </p>
              <p
                style={{
                  fontSize: 14,
                  color: "white",
                  marginRight: 5,
                  backgroundColor: "grey",
                  paddingRight: 20,
                  paddingLeft: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 10,
                }}
              >
                {requestStatus}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <CardsCommonItem icon={<LocationIcon />} text={location} />
            <CardsCommonItem
              icon={<ChickenIcon />}
              text={` العدد المتاح : ${amount}`}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <CardsCommonItem
              icon={<ChickenIcon />}
              text={`متوسط الوزن : ${averageWeight}`}
            />
            <CardsCommonItem
              icon={<ChickenIcon />}
              text={` الحالة الصحية :${dajinHealth} `}
            />
          </div>
        </div>
      </div>
      <Button onClick={onPress} style={{ marginTop: 20 }} height={38}>
        تفاصيل الطلب
      </Button>
    </div>
  );
};
