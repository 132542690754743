export const propsArr = {
  users: {
    name: { name: "name", special: 0, type: "string" },
    id: { name: "id", special: 0, type: "string" },
    email: { name: "email", special: 0, type: "string" },
    mobile_number: { name: "mobile_number", special: 0, type: "string" },
    roles: {
      name: "roles",
      special: 1,
      type: "array",
      mapProp: "name",
      nestadProps: [],
    },
  },
  traderRating: {
    created_at: { name: "created_at", special: 0, type: "string" },
    rater_name: { name: "rater_name", special: 0, type: "string" },
    values: {
      name: "values",
      special: 1,
      type: "array",
      mapProp: "value",
      nestadProps: [],
    },
    comment: { name: "comment", special: 0, type: "string" },
  },
  timeLimitOffer: {
    active: { name: "active", special: 0, type: "boolean" },
    valid_until: { name: "valid_until", special: 0, type: "string" },
    valid_from: { name: "valid_from", special: 0, type: "string" },
    description: { name: "description", special: 0, type: "string" },

    points: { name: "points", special: 0, type: "number" },
  },
  roles: {
    name: { name: "name", special: 0, type: "string" },
    permissions: {
      name: "permissions",
      special: 1,
      type: "array",
      mapProp: "name",
      nestadProps: [],
    },
  },
  news: {
    publish_at: { name: "publish_at", special: 0, type: "string" },
    image_url: { name: "image_url", special: 0, type: "string" },
    category_name: { name: "category_name", special: 0, type: "string" },
    title: { name: "title", special: 0, type: "string" },
  },
  accounts: {
    name: { name: "name", special: 0, type: "string" },
    total_balance: { name: "total_balance", special: 0, type: "string" },
    total_commision: { name: "total_commision", special: 0, type: "string" },
    total_points: { name: "total_points", special: 0, type: "string" },

    total_transactions: {
      name: "total_transactions",
      special: 0,
      type: "string",
    },
  },
  articles: {
    publish_at: { name: "publish_at", special: 0, type: "string" },
    image_url: { name: "image_url", special: 0, type: "string" },
    category_name: { name: "category_name", special: 0, type: "string" },
    title: { name: "title", special: 0, type: "string" },
  },
  courses: {
    publish_at: { name: "publish_at", special: 0, type: "string" },
    image_url: { name: "image_url", special: 0, type: "string" },
    category_name: { name: "category_name", special: 0, type: "string" },
    title: { name: "title", special: 0, type: "string" },
    instructor_name: { name: "instructor_name", special: 0, type: "string" },
  },
  lecture: {
    publish_at: { name: "publish_at", special: 0, type: "string" },
    image_url: { name: "image_url", special: 0, type: "string" },
    title: { name: "title", special: 0, type: "string" },
    views: { name: "views", special: 0, type: "string" },
  },
  farmers: {
    name: { name: "name", special: 0, type: "string" },
    city: {
      name: "city",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["name"],
    },
    total_rating: { name: "total_rating", special: 0, type: "string" },
    email: { name: "email", special: 0, type: "string" },
    mobile_number: { name: "mobile_number", special: 0, type: "string" },
    status: {
      name: "status",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["status"],
    },
    // user: "الاسم",
  },
  traders: {
    name: { name: "name", special: 0, type: "string" },
    city: {
      name: "city",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["name"],
    },
    total_rating: { name: "total_rating", special: 0, type: "string" },

    email: { name: "email", special: 0, type: "string" },
    mobile_number: { name: "mobile_number", special: 0, type: "string" },
    status: {
      name: "status",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["status"],
    },
    featured: { name: "featured", special: 0, type: "boolean" },
    tawreed: { name: "tawreed", special: 0, type: "boolean" },

    // user: "الاسم",
  },
  salesTransactions: {
    trader: {
      name: "trader",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["name"],
    },
    farm: {
      name: "farm",
      special: 0,
      type: "string",
    },
    dajin: {
      name: "dajin",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["type"],
    },
    created_at: {
      name: "created_at",
      special: 0,
      type: "string",
    },
    updated_at: {
      name: "updated_at",
      special: 0,
      type: "string",
    },
    status: {
      name: "status",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["status_for_farmer"],
    },
  },
  instructors: {
    description: { name: "description", special: 0, type: "string" },
    image_url: { name: "image_url", special: 0, type: "string" },
    name: { name: "name", special: 0, type: "string" },
    certified: { name: "certified", special: 0, type: "string" },
  },
  contactUs: {
    message: { name: "message", special: 0, type: "string" },
    sender_name: { name: "sender_name", special: 0, type: "string" },
    sender_email: { name: "sender_email", special: 0, type: "string" },
    created_at: { name: "created_at", special: 0, type: "string" },
    has_reply: { name: "has_reply", special: 1, type: "boolean" },
  },

  support: {
    created_at: { name: "created_at", special: 0, type: "string" },
    description: { name: "description", special: 0, type: "string" },
    support_type: {
      name: "support_type",
      special: 0,
      type: "string",
    },
    user_name: {
      name: "user_name",
      special: 0,
      type: "string",
    },
    closed: {
      name: "closed",
      special: 0,
      type: "boolean",
    },

    // user: {
    //   name: "user",
    //   special: 0,
    //   type: "object",
    //   mapProp: "name",
    //   nestadProps: [],
    // },
  },
  rating: {
    dimension: { name: "dimension", special: 0, type: "string" },
    id: { name: "id", special: 0, type: "string" },
  },

  farminTypes: {
    type: { name: "type", special: 0, type: "string" },
    id: { name: "id", special: 0, type: "string" },
  },
  facility: {
    facility: { name: "facility", special: 0, type: "string" },
    id: { name: "id", special: 0, type: "string" },
  },
  companies: {
    type: { name: "type", special: 0, type: "string" },
    id: { name: "id", special: 0, type: "string" },
  },
  dajinTypes: {
    type: { name: "type", special: 0, type: "string" },
    trader_commission: {
      name: "trader_commission",
      special: 0,
      type: "string",
    },
    farmer_commission: {
      name: "farmer_commission",
      special: 0,
      type: "string",
    },
    image_url: { name: "image_url", special: 0, type: "string" },
  },
  supportTypes: {
    type: { name: "type", special: 0, type: "string" },
    id: { name: "id", special: 0, type: "string" },
  },
  gifts: {
    description: { name: "description", special: 0, type: "string" },
    image_url: { name: "image_url", special: 0, type: "string" },
    no_available: { name: "no_available", special: 0, type: "string" },
    points: { name: "points", special: 0, type: "string" },
    created_at: { name: "created_at", special: 0, type: "string" },
  },
  traderLevels: {
    name: { name: "name", special: 0, type: "string" },
    from_number: { name: "from_number", special: 0, type: "string" },
    to_number: { name: "to_number", special: 0, type: "string" },
    discount: { name: "discount", special: 0, type: "string" },
  },
  tawreedSettings: {
    featured_price: { name: "featured_price", special: 0, type: "number" },
    normal_price: { name: "normal_price", special: 0, type: "number" },
    expiration_in_days: {
      name: "expiration_in_days",
      special: 0,
      type: "number",
    },
  },

  disputes: {
    disputed_reason: {
      name: "disputed_reason",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["reason"],
    },
    created_at: { name: "created_at", special: 0, type: "string" },
    dispute_status: {
      name: "dispute_status",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["status"],
    },
  },
  breeds: {
    breed_type: { name: "breed_type", special: 0, type: "string" },
    poultry_farming_average: {
      name: "poultry_farming_average",
      special: 0,
      type: "string",
    },
    poultry_farming_maximum: {
      name: "poultry_farming_maximum",
      special: 0,
      type: "string",
    },
  },
  notification: {
    title: { name: "title", special: 0, type: "string" },
    description: { name: "description", special: 0, type: "string" },
    target: { name: "target", special: 0, type: "string" },
    publish_at: { name: "publish_at", special: 0, type: "string" },
  },
  bonusPoints: {
    active: { name: "active", special: 0, type: "boolean" },
    bonus_points_criteria: {
      name: "bonus_points_criteria",
      special: 0,
      type: "object",
      mapProp: "",
      nestadProps: ["description"],
    },
    rating_value: { name: "rating_value", special: 0, type: "number" },
    points: { name: "points", special: 0, type: "number" },

    // user: "الاسم",
  },
  //  "id": 1,
  //  "type": "فراخ ساسو",
  //  "trader_commission": 0.01,
  //  "farmer_commission": 0.01,
  //  "image_url": "https://via.placeholder.com/640x480.png/005588?text=image"

  // roles: [
  //   "name",
  //   "permissions",
  // "image_url",
  // "category_name",
  // "title",
  // "publish_at",
  // "instructor_id",
  // "lectures",
  // "farm",
  // "trader",
  // "dajin",
  // "created_at",
  // "updated_at",
  // "status",
  // "name",
  // "city",
  // "featured",
  // "email",
  // "mobile_number",
  // "description",
  // "certified",
  // "sender_name",
  // "sender_email",
  // "has_reply",
  // "message",
  // ],
};
export const specialProps = ["image_url", "publish_at", "created_at"];

export const propsMapper = {
  accounts: {
    name: "اسم التاجر",
    total_balance: "اجمالى الرصيد",
    total_transactions: "اجمالى العمليات",
    total_points: "اجمالى النقاط",
    total_commision: "اجمالى العمولات",
  },
  traderRating: {
    created_at: "التاريخ",
    rater_name: "التاجر",
    comment: "التعليق",
    values: "التقييم",
  },
  salesTransactions: {
    trader: "اسم التاجر",
    farm: "اسم العنبر",
    dajin: "نوع الداجن",
    created_at: "تاريخ الاضافة",
    updated_at: "تاريخ التعديل",
    status: "حالة المربى",
  },
  users: {
    email: "البريد الالكترونى",
    mobile_number: "الهاتف",
    name: "الاسم",
    id: "ID",
    roles: "الصلاحيات",
  },
  roles: {
    name: "الاسم",
    permissions: "الأذونات",
  },
  news: {
    publish_at: "تاريخ النشر",
    image_url: "الصورة",
    category_name: "التصنيف",
    title: "العنوان",
  },
  articles: {
    publish_at: "تاريخ النشر",
    image_url: "الصورة",
    category_name: "التصنيف",
    title: "العنوان",
  },
  courses: {
    publish_at: "تاريخ النشر",
    image_url: "الصورة",
    category_name: "التنصيف",
    title: "العنوان",
    instructor_name: "المدرب",
  },
  lecture: {
    publish_at: "تاريخ النشر",
    image_url: "الصورة",
    title: "العنوان",
    views: "المشاهدات",
  },
  instructors: {
    description: "الوصف",
    image_url: "الصورة",
    name: "الاسم",
    certified: "معتمد",
  },
  contactUs: {
    sender_name: "اسم ",
    sender_email: "البريد الالكتروني",
    has_reply: "تم الرد ",
    message: "الرسالة",
    created_at: "التاريخ",
  },
  support: {
    created_at: "التاريخ",
    description: "الوصف",
    support_type: "مجال الدعم",
    user_name: "اسم المرسل",
    closed: "تم الرد",
  },
  timeLimitOffer: {
    active: "مفعل",
    valid_until: "نهاية الصلاحية",
    valid_from: "بداية الصلاحية",
    description: "الوصف",
    points: "النقاط",
  },
  farmers: {
    name: "المربى",
    city: "المدينة",
    email: "البريد الإلكترونى",
    mobile_number: "الهاتف",
    status: "الحالة",
    total_rating: "التقييم",
  },
  traders: {
    name: "التاجر",
    tawreed: "مورد",
    city: "المدينة",
    email: "البريد الإلكترونى",
    mobile_number: "الهاتف",
    status: "الحالة",
    featured: "التوثيق",
    total_rating: "التقييم",

    // user: "الاسم",
  },
  rating: {
    dimension: "الاسم",
    id: "ID",
  },
  farminTypes: {
    id: "ID",
    type: "الاسم",
  },
  facility: {
    id: "ID",
    facility: "الاسم",
  },
  companies: {
    id: "ID",
    type: "النوع",
  },
  dajinTypes: {
    type: "الاسم",
    trader_commission: "العمولة على التاجر",
    farmer_commission: "العمولة على المربي",
    image_url: "الصورة",
  },
  supportTypes: {
    id: "ID",
    type: "الاسم",
  },
  gifts: {
    created_at: "تاريخ الاضافة",
    image_url: "الصورة",
    description: "الوصف",
    no_available: " النقاط المتاحة",
    points: "النقاط",
  },
  traderLevels: {
    name: "الاسم",
    from_number: "من العدد",
    to_number: "إلى عدد",
    discount: "(%)نسبة الخصم",
  },
  tawreedSettings: {
    featured_price: "السعر المميز",
    normal_price: "السعر العادى",
    expiration_in_days: "انتهاء الصلاحية (ايام)",
  },
  breeds: {
    breed_type: "الاسم",
    poultry_farming_maximum: "أقصى مدة للتربيه",
    poultry_farming_average: "متوسط مدة التربية",
  },
  notification: {
    title: "العنوان",
    description: "الاشعار",
    target: "موجهة الى",
    publish_at: "التاريخ",
  },
  bonusPoints: {
    active: "التفعيل",
    bonus_points_criteria: "على أساس",
    rating_value: "قيمة التقييم",
    points: "النقاط",
  },
  disputes: {
    disputed_reason: "السبب",
    created_at: "تاريخ الاضافة",
    dispute_status: "الحالة",
  },
  image_url: "الصورة",
  category_name: "القسم",
  title: "العنوان",
  publish_at: "تاريخ النشر",
  category: "القسم",
  instructor: "المدرب",
  instructor_id: "المدرب",
  lectures: "عدد المحاضرات",
  dajin: "نوع الداجن",
  farm: "العنبر",
  trader: "التاجر",
  created_at: "تاريخ الإضافة",
  updated_at: "تاريخ التعديل",
  status: "الحالة",
  name: "الاسم",
  description: "الوصف",
  certified: "معتمد",
  city: "المدينة",
  governorate: "المحافظه",
  featured: "التوثيق",
  email: "البريد الالكترونى",
};
export const nestedPropsArr = {
  trader: ["name"],
  dajin: ["type"],
  support_type: ["type"],
  status: ["status_for_farmer", "status"],
  //tatus: [],
  city: ["name"],
};
