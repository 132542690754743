import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getBonusPoints } from "../../../api/store/actions/saga/settings/bonusPoints";
import { getDajinTypes } from "../../../api/store/actions/saga/settings/dajinTypes";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const BonusPoints = () => {
  const [bonusPointsData, setBonusPointsData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getBonusPoints, setBonusPointsData);
  }, [reload]);
  console.log(bonusPointsData);
  return bonusPointsData ? (
    <ListView
      objName={"bonusPoints"}
      stateLocation
      links={{
        edit: "updateBonusPoints",
        delete: "",
        show: ``,
        query: {
          show: "",
          delete: "",
          edit: "",
        },
      }}
      data={bonusPointsData}
    ></ListView>
  ) : (
    <Loading />
  );
};

export default BonusPoints;
