import { Link, useLocation } from "react-router-dom";
import {
  approveComment,
  deleteComment,
  disapproveComment,
} from "../../api/store/actions/saga/comments";

const CommentsCard = ({
  date,
  name,
  email,
  content,
  postContent,
  id,
  approved,
  media_type,
}) => {
  const location = useLocation();

  return (
    <div
      style={{
        paddingTop: 15,
        paddingBottom: 15,
        border: "solid 1px #C2C2C2",
        borderRadius: 10,
        color: "#010A14",
        marginBottom: 10,
        marginTop: 10,
      }}
    >
      <div
        style={{
          display: "flex",

          flexWrap: "wrap",
        }}
      >
        <div style={{ width: "15%", paddingRight: 10, paddingLeft: 10 }}>
          <p style={{ fontSize: 14 }}>{name} </p>
          <p style={{ fontSize: 12 }}>{email}</p>
          <p style={{ fontSize: 12, color: "#7ABA4C" }}>
            {" "}
            تعليق على :{media_type}
          </p>
        </div>

        <div
          style={{
            width: "40%",
            paddingRight: 10,
            paddingLeft: 10,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: 12 }}>{content}</p>
        </div>

        <div
          style={{
            width: "30%",
            paddingRight: 10,
            paddingLeft: 10,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: 12 }}>{postContent}</p>
          {/* <p style={{ color: "#7ABA4C", fontSize: 14 }}>مشاهدة الخبر</p> */}
        </div>

        <div style={{ width: "15%", paddingRight: 10, paddingLeft: 10 }}>
          <p style={{ fontSize: 14 }}> {date}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",

          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          paddingLeft: 10,
        }}
      >
        {approved ? (
          <p
            style={{ color: "#98000B", fontSize: 12, cursor: "pointer" }}
            onClick={() => {
              disapproveComment({ id: id });
            }}
          >
            إلغاء الموافقة
          </p>
        ) : (
          <p
            style={{ color: "#7ABA4C", fontSize: 12, cursor: "pointer" }}
            onClick={() => {
              approveComment({ id: id });
            }}
          >
            الموافقة
          </p>
        )}
        &nbsp; | &nbsp;
        <Link style={{ textDecoration: "none" }} to={`editComment/${id}`}>
          <p
            style={{
              color: "#005FC8",
              fontSize: 12,
              cursor: "pointer",
            }}
          >
            تحرير
          </p>
        </Link>
        &nbsp;| &nbsp;
        <Link
          style={{ textDecoration: "none", display: "contents" }}
          state={{
            backgroundLocation: location,
          }}
          to={`deleteComment/${id}`}
        >
          <p style={{ color: "#FE646F", fontSize: 12, cursor: "pointer" }}>
            حذف
          </p>
        </Link>
      </div>
    </div>
  );
};

export default CommentsCard;
