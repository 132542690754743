import { useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveDetailsState, setActiveState } from "../api/store/reducers/reload";

const TabsSwitch = ({ active = 1, setActive, pages, switchType }) => {
  const dispatch = useDispatch()
  return (
    <>
      <div
        style={{
          width: "auto",
          borderRadius: 10,
          backgroundColor: "#F3F3F3",
          display: "flex",
          flexWrap: "wrap",
          padding: 3,
          alignItems: "center",
          height: "auto",
          cursor: "pointer",
          overflowX: "auto",
        }}
      >
        {pages?.map((item, index) => {
          return (
            <div
              key={item.id}
              style={{
                backgroundColor: active == item?.id ? "#7ABA4C" : "transparent",
                width: "auto",
                borderRadius: 5,
                textAlign: "center",
                paddingRight: 10,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                if (active !== item?.id) {
                  setActive(item?.id);
                  if (switchType == 'details')
                    dispatch(setActiveDetailsState(item?.id))
                  else
                    dispatch(setActiveState(item?.id))
                }

              }}
            >
              <p style={{ color: active == item?.id ? "white" : "black" }}>
                {item?.title}
              </p>
            </div>
          );
        })}

        {/* <div
          style={{
            backgroundColor: active == 2 ? "#7ABA4C" : "transparent",
            width: "auto",
            borderRadius: 5,
            textAlign: "center",
            paddingRight: 10,
            paddingLeft: 10,
            color: active == 2 ? "white" : "black",
            paddingTop: 5,
            paddingBottom: 5,
          }}
          onClick={() => {
            if (active !== 2) setActive(2);
          }}
        >
          <p style={{ color: active == 2 ? "white" : "black" }}>{titleTwo}</p>
        </div> */}
      </div>
    </>
  );
};

export default TabsSwitch;
