import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import avatar from "../../../assets/avatar.jpg";

import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import {
  createGift,
  showGift,
  updateGift,
} from "../../../api/store/actions/saga/settings/gifts";
import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";
import { update } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { arToEn } from "../../../alogrithms";

const AddGift = ({ isEdit }) => {
  const [image, setImage] = useState();
  const [giftDetails, setGiftDetails] = useState();
  const [imageChanged, setImageChanged] = useState(false);

  const { gift_id } = useParams();
  useEffect(() => {
    if (isEdit) {
      resolveRequest(showGift, setGiftDetails, { gift_id: gift_id });
    }
  }, []);
  const submit = async (values) => {
    const body = {
      points: arToEn(values?.points),
      description: values?.description,
      no_available: arToEn(values?.no_available),
      image: values?.image,
    };
    const res = await createGift({ body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };
  const submitEdit = async (values) => {
    let body = {};
    if (!imageChanged) {
      body = {
        points: arToEn(values?.points),
        description: values?.description,
        no_available: arToEn(values?.no_available),
        _method: "put",
      };
    } else {
      body = {
        points: arToEn(values?.points),
        description: values?.description,
        no_available: arToEn(values?.no_available),
        image: values?.image,
        _method: "put",
      };
    }
    if (body?.description == giftDetails?.data?.description) {
      delete body?.description;
    }
    const res = await updateGift({ gift_id: gift_id, body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? giftDetails?.data : undefined,
      validationSchema,
      "addGift",
      isEdit ? submitEdit : submit
    )
  );
  console.log(giftDetails);
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log("balues", values);
  const isValid = formikFieldValidation(touched, errors);
  const width = useResponsiveWidth({
    desktop: 100,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  return (
    <AppModal
      buttonTitle={isEdit ? "تعديل" : "إضافة"}
      modalTitle={isEdit ? "تعديل الجائزة" : "إضافة الجائزة"}
      onClick={handleSubmit}
    >
      {
        <div
          style={{
            borderRadius: 10,
            backgroundColor: "#7ABA4C0D",
            border: "1px dashed #7ABA4C99",
            height: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            width={100}
            height={100}
            style={{ borderRadius: "50%" }}
            src={
              isEdit && image != undefined
                ? image
                : isEdit
                  ? giftDetails?.data?.image_url
                  : isEdit && image == undefined
                    ? avatar
                    : !isEdit && image == undefined
                      ? avatar
                      : image
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <label
              style={{
                color: "#7ABA4C",
                marginRight: 10,
              }}
            >
              <input
                type="file"
                name="file"
                onChange={(e) => {
                  setFieldValue("image", e.target.files[0]);
                  setImage(URL.createObjectURL(e.target.files[0]));

                  setImageChanged(true);
                }}
                style={{ display: "none" }}
              />
              استعرض
            </label>
          </div>
          <p style={{ color: "red" }}>{isValid("image")}</p>
        </div>
      }
      <TextInput
        require
        label={"النقاط"}
        placeholder={"0000"}
        onChange={handleChange("points")}
        errorMessage={isValid("points")}
        value={values["points"]}
      />
      <TextInput
        require
        label={" المتاح"}
        placeholder={"0000"}
        onChange={handleChange("no_available")}
        errorMessage={isValid("no_available")}
        value={values["no_available"]}
      />
      <TextInput
        require
        // defaultValue={isEdit ? category?.data?.name : ""}
        onChange={handleChange("description")}
        label={"الوصف"}
        placeholder={"اكتب الوصف"}
        errorMessage={isValid("description")}
        value={values["description"]}
      />
    </AppModal>
  );
};

export default AddGift;
