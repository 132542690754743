import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { logout } from "../../api/store/actions/saga/login";
import {
  checkReadNotifications,
  getNotifications,
  pollingNotifications,
} from "../../api/store/actions/saga/upcomingNotification";
import HeaderLogo from "../../reusableComponents/iconsSvg/header/logoHeader";
import LogoutIcon from "../../reusableComponents/iconsSvg/header/logout";
import MenuHeader from "../../reusableComponents/iconsSvg/header/menuHeader";
import NotifyHeader, {
  NewNotifyHeader,
} from "../../reusableComponents/iconsSvg/header/notifyHeader";
import NotificationList from "../../screens/upcomingNotification";
import "./dropdownMenu/dropdownMenuStyles.css";
import axiosCall from "../../api/axios/axiosConfig";
import axios, { Axios } from "axios";
const Header = () => {
  const [notification, setNotification] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [flag, setFlag] = useState(0);
  const [notificationsData, setNotificationsData] = useState([]);
  const [checkRead, setCheckRead] = useState();

  const userData = useSelector((state) => state.login.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let timeOut;
    if (notification && flag) {
      timeOut = setTimeout(() => {
        resolveRequest(pollingNotifications, setNotification, {
          timeStamp: notification?.timestamp,
        });
      }, notification?.poll_interval);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [notification]);
  useEffect(() => {
    if (notification?.data?.length) {
      setNotificationsData([...notificationsData, ...notification?.data]);
    }
  }, [notification]);
  useEffect(() => {
    resolveRequest(getNotifications, setNotification, {
      currentPage: currentPage,
    });
    setFlag(1);
  }, [currentPage]);
  useEffect(() => {
    resolveRequest(checkReadNotifications, setCheckRead, {});
  }, [location?.pathname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "white",
        padding: 10,
      }}
    >
      <div
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          ...useResponsiveWidth({
            desktop: "auto",
            tablet: "auto",
            mobile: 50,
            defaults: "auto",
          }),
        }}
      >
        <HeaderLogo />
      </div>
      <div
        style={{
          height: "inherit",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            ...useResponsiveWidth({
              desktop: 30,
              tablet: 90,
              mobile: 0,
              defaults: "auto",
              tabletMobile: 0,
            }),
          }}
        >
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <MenuHeader />
          </div>
          <div>
            <p
              style={{
                fontFamily: "boldKufi",
                fontSize: 20,
                width: "max-content",
                alignItems: "center",
              }}
            >
              , مرحبا {userData?.name}
            </p>
            <p
              style={{
                color: "#8F8F8F",
                ...useResponsiveWidth({
                  desktop: "auto",
                  tablet: "max-content",
                  mobile: 0,
                  defaults: 0,
                  tabletDesktop: "max-content",
                  tabletMobile: "max-content",
                }),
              }}
            >
              انت تقوم بعمل جيد جدا
            </p>
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            ...useResponsiveWidth({
              desktop: 70,
              tablet: 70,
              mobile: 0,
              defaults: 70,
              tabletMobile: 100,
              tabletDesktop: 70,
            }),
            justifyContent: "flex-end",
          }}
        >
          {notification ? (
            <>
              <div
                className="dropdown-parent-hover"
                style={{
                  backgroundColor: "#7ABA4C1A",
                  padding: 10,
                  borderRadius: 10,
                  marginLeft: 10,
                  marginRight: 10,
                  width: "auto",
                }}
              >
                <div style={{ position: "relative" }}>
                  <NotifyHeader />
                  {checkRead?.data?.has_null_read_at ? (
                    <p
                      style={{
                        position: "absolute",
                        padding: 5,
                        backgroundColor: "red",
                        fontSize: 10,
                        color: "white",
                        borderRadius: 50,
                        bottom: -15,
                        right: -20,
                      }}
                    >
                      {checkRead?.data?.null_read_at_count > 99
                        ? "99+"
                        : checkRead?.data?.null_read_at_count}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="notification">
                <NotificationList
                  data={notificationsData}
                  totalPages={Math.ceil(
                    notification?.total / notification?.perPage
                  )}
                  currentPage={currentPage}
                  onSeeMore={() => {
                    if (
                      Math.ceil(notification?.total / notification?.perPage) >
                      currentPage
                    )
                      setCurrentPage((prevState) => prevState + 1);
                  }}
                ></NotificationList>
              </div>
            </>
          ) : (
            <RotatingLines width={20} />
          )}
          <div
            onClick={async () => {
              const res = await axios.delete(
                "https://api.dajin-platform.com/admin/v1/logout",
                { withCredentials: true }
              );
              if (res?.status <= 206) {
                navigate(-1);
              }
            }}
            style={{
              cursor: "pointer",
              ...useResponsiveWidth({
                desktop: "auto",
                tablet: "max-content",
                mobile: "max-content",
                defaults: 0,
                tabletMobile: "auto",
                tabletDesktop: "auto",
              }),
            }}
          >
            <LogoutIcon />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              marginRight: 10,
              marginLeft: 10,
              alignItems: "center",
              ...useResponsiveWidth({
                desktop: "auto",
                tablet: "max-content",
                mobile: "max-content",
                defaults: 0,
                tabletMobile: "auto",
                tabletDesktop: "auto",
              }),
            }}
          >
            <div
              style={{
                backgroundColor: "#7ABA4C1A",

                borderRadius: 10,
                marginRight: 10,
                marginLeft: 10,
              }}
            >
              <img
                src={"/images/Group36154.png"}
                style={{ width: 40, height: 40 }}
              />
            </div>
            <div>
              <p style={{ fontFamily: "boldKufi" }}> {userData?.name}</p>
              <p style={{ color: "#8F8F8F", fontSize: 12 }}>مدير المنصة</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
