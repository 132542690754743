import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import { getGifts } from "../../../api/store/actions/saga/settings/gifts";
import { getHistoryTimeLimitedOffer } from "../../../api/store/actions/saga/settings/timerLimtedOffer";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import ListView from "../../../screenParts/listingViews";

const TimeLimtedHistory = () => {
  const [historyData, setHistoryData] = useState(null);
  const reload = useSelector((state) => state.reload.reload);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    resolveRequest(getHistoryTimeLimitedOffer, setHistoryData, {
      pageNumber: currentPage,
    });
  }, [reload, currentPage]);
  console.log("historyData", historyData);
  return historyData ? (
    <ListView
      objName={"timeLimitOffer"}
      data={historyData}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    ></ListView>
  ) : (
    <Loading />
  );
};

export default TimeLimtedHistory;
