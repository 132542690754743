import React from "react";

function MapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <g data-name="03-google map">
        <circle
          cx="4"
          cy="4"
          r="4"
          fill="#a83229"
          data-name="Ellipse 1609"
          transform="translate(23 7)"
        ></circle>
        <path
          fill="#4a9bf6"
          d="M27.839 56.419H7L17.419 46z"
          data-name="Path 39454"
          transform="translate(-3.323 -18.419)"
        ></path>
        <path
          fill="#d3d9f2"
          d="M50.226 7v30.645a3.677 3.677 0 01-3.677 3.677H44.1L30 27.226l4.664-4.664a63.058 63.058 0 004.529 5.277s8.581-8.746 8.581-13.484a8.532 8.532 0 00-.962-3.941z"
          data-name="Path 39455"
          transform="translate(-12.226 -3.323)"
        ></path>
        <path
          fill="#ffdf64"
          d="M58.521 4.677l-3.414 3.414a8.524 8.524 0 00-3.677-3.677L54.844 1a3.677 3.677 0 013.677 3.677z"
          data-name="Path 39456"
          transform="translate(-20.521 -1)"
        ></path>
        <path
          fill="#f7f7ff"
          d="M41.774 54.1h-7.355L24 43.677 27.677 40z"
          data-name="Path 39457"
          transform="translate(-9.903 -16.097)"
        ></path>
        <path
          fill="#ffdf64"
          d="M23.438 29.572l-4.664 4.664-3.674 3.678L4.677 48.333A3.677 3.677 0 011 44.655L20.545 25.11a31.045 31.045 0 002.893 4.462z"
          data-name="Path 39458"
          transform="translate(-1 -10.333)"
        ></path>
        <path
          fill="#28be77"
          d="M35.323 1l-3.414 3.414a8.575 8.575 0 00-12.522 7.618 8.672 8.672 0 001.158 3.745L1 35.323V4.677A3.677 3.677 0 014.677 1z"
          data-name="Path 39459"
          transform="translate(-1 -1)"
        ></path>
        <path
          fill="#d73d3f"
          d="M47.2 9.644a8.532 8.532 0 01.962 3.941c0 4.738-8.581 13.484-8.581 13.484a63.055 63.055 0 01-4.529-5.277 31.045 31.045 0 01-2.893-4.462A8.672 8.672 0 0131 13.585a8.581 8.581 0 0116.2-3.941zm-3.941 3.941a3.677 3.677 0 10-3.677 3.677 3.677 3.677 0 003.676-3.677z"
          data-name="Path 39460"
          transform="translate(-12.613 -2.552)"
        ></path>
        <path
          fill="#3b7cc4"
          d="M9.452 59L7 61.452h20.839L25.387 59z"
          data-name="Path 39461"
          transform="translate(-3.323 -23.452)"
        ></path>
        <path
          fill="#aeb2d4"
          d="M53.9 56.677H49l2.452 2.452H53.9a3.677 3.677 0 003.677-3.677V53a3.677 3.677 0 01-3.677 3.677z"
          data-name="Path 39462"
          transform="translate(-19.581 -21.129)"
        ></path>
        <path
          fill="#b8bce0"
          d="M62.117 7.541a3.644 3.644 0 01.541 1.91V7z"
          data-name="Path 39463"
          transform="translate(-24.658 -3.323)"
        ></path>
        <path
          fill="#ffb844"
          d="M55.452 3.452a3.673 3.673 0 013.136 1.767l.541-.541A3.677 3.677 0 0055.452 1L53 3.452z"
          data-name="Path 39464"
          transform="translate(-21.129 -1)"
        ></path>
        <path
          fill="#cacfe8"
          d="M37 59l2.452 2.452h7.355L44.355 59z"
          data-name="Path 39465"
          transform="translate(-14.935 -23.452)"
        ></path>
        <path
          fill="#ffb844"
          d="M4.677 57.884a3.673 3.673 0 01-3.136-1.767L1 56.658a3.677 3.677 0 003.677 3.677l2.452-2.452z"
          data-name="Path 39466"
          transform="translate(-1 -22.336)"
        ></path>
        <path
          fill="#1fa463"
          d="M1 53v2.452l.541-.541A3.644 3.644 0 011 53z"
          data-name="Path 39467"
          transform="translate(-1 -21.129)"
        ></path>
        <path
          fill="#1fa463"
          d="M4.677 3.452h28.194L35.323 1H4.677A3.677 3.677 0 001 4.677v2.452a3.677 3.677 0 013.677-3.677z"
          data-name="Path 39468"
          transform="translate(-1 -1)"
        ></path>
        <path
          fill="#f7f7ff"
          d="M9.516 15.032a5.516 5.516 0 114.306-8.964L11.906 7.6a3.065 3.065 0 10.419 3.141H9.516V8.29h4.29a1.226 1.226 0 011.226 1.226 5.523 5.523 0 01-5.516 5.516z"
          data-name="Path 39469"
          transform="translate(-2.161 -2.161)"
        ></path>
        <path
          fill="#91323c"
          d="M42.677 15.444A3.677 3.677 0 0146.14 17.9a3.677 3.677 0 10-6.926 0 3.677 3.677 0 013.463-2.456z"
          data-name="Path 39470"
          transform="translate(-15.71 -5.638)"
        ></path>
        <path
          fill="#1fa463"
          d="M31.123 20.71a8.61 8.61 0 00-.123 1.4 6.281 6.281 0 00.409 2.042l.749-.749a11.006 11.006 0 01-1.036-2.7z"
          data-name="Path 39471"
          transform="translate(-12.613 -8.63)"
        ></path>
        <path
          fill="#aeb2d4"
          d="M49.943 20.735c-1.049 4.87-8.454 12.42-8.454 12.42a63.056 63.056 0 01-4.529-5.277l-1.03 1.03c.336.482.679.96 1.03 1.422a63.057 63.057 0 004.529 5.277s8.581-8.746 8.581-13.484a8.484 8.484 0 00-.127-1.388z"
          data-name="Path 39472"
          transform="translate(-14.521 -8.639)"
        ></path>
        <path
          fill="#ffb844"
          d="M32.417 25.11l-.749.749a13.258 13.258 0 00.749 1.7A26.656 26.656 0 0034.28 30.6l1.03-1.03a31.044 31.044 0 01-2.893-4.462z"
          data-name="Path 39473"
          transform="translate(-12.871 -10.333)"
        ></path>
      </g>
    </svg>
  );
}

export default MapIcon;
