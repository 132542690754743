import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
const PublicRoute = ({ children, roles }) => {
  const isAuth = useSelector((state) => state.login.isAuth);
  if (isAuth) {
    return <Navigate to="/notification" /*state={{ from: location }}*/ />;
  }
  return children;
};

export default PublicRoute;
