import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getFarmerFarms, getFarmLog } from "../../api/store/actions/saga/farms";
import BlueBG from "../../reusableComponents/iconsSvg/blueBG";
import GreenBG from "../../reusableComponents/iconsSvg/greenBG";
import OrangeBG from "../../reusableComponents/iconsSvg/orangeBG";
import RedBG from "../../reusableComponents/iconsSvg/redBG";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { FarmCard } from "../../screenParts/farms/farmCard";
import { FarmLogCard } from "../../screenParts/farms/farmLogCard";
import ListHeader from "../../screenParts/listingViews/listHeader";
export const FarmLogScreen = () => {
  const { farm_id } = useParams();
  const [farmLogData, setFarmLogData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    resolveRequest(getFarmLog, setFarmLogData, {
      farm_id: farm_id,
      pageNumber: currentPage,
      pageCount: 7,
    });
  }, [currentPage]);
  console.log("farmLogData", farmLogData);
  return (
    <>
      {farmLogData ? (
        <div style={{ backgroundColor: "white", height: "100%" }}>
          <ListHeader
            hasTitle={true}
            title={"تفاصيل العنبر"}
            back
            hasAdd={false}
            hasFilter={false}
            hasSearch={false}
          />
          <SectionLabel content={"سجل الحركة"} />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {farmLogData?.data.length != 0 &&
              farmLogData?.data.map((item) => {
                return (
                  <FarmLogCard
                    BG={
                      item?.type == 1 ? (
                        <RedBG />
                      ) : item?.type == 2 ? (
                        <BlueBG />
                      ) : (
                        <OrangeBG />
                      )
                    }
                    date={moment(item?.created_at).format("YYYY/MM/DD")}
                    text={item?.description}
                  />
                );
              })}

            {setCurrentPage && (
              <AppPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={Math.ceil(farmLogData?.total / 7)}
              />
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
