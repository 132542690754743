import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getFarmerFarms } from "../../api/store/actions/saga/farms";
import { getTraderOrders } from "../../api/store/actions/saga/traders";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { FarmCard } from "../../screenParts/farms/farmCard";
import NoData from "../../screenParts/noData";
import { OrdersRequestsCard } from "../../screenParts/orders/ordersRequestCard";
export const TraderOrdersScreen = ({
  searchKey,
  currentPage,
  setCurrentPage,
  pageCount,
}) => {
  const { user_id } = useParams();
  const [traderOrders, setTraderOrders] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    resolveRequest(getTraderOrders, setTraderOrders, {
      trader_id: user_id,

      pageNumber: currentPage,
      pageCount: pageCount,
      params: searchKey ? { title: searchKey } : {},
    });
  }, [currentPage, searchKey]);

  useEffect(() => {
    console.log("traderOrders", traderOrders);
  }, [traderOrders]);
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
      }}
    >
      <SectionLabel content={"طلبات الشراء"} />
      {traderOrders ? (
        traderOrders?.data?.length != 0 ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {traderOrders?.data?.map((item, index) => {
              return (
                <OrdersRequestsCard
                  date_now={Date.now()}
                  creation_date={new Date(item?.created_at)}
                  id={item?.id}
                  dajinType={item?.dajin?.type}
                  amount={item?.amount}
                  averageWeight={item?.average_weight}
                  dajinHealth={item?.dajin_health?.status}
                  price={item?.price}
                  onPress={() => {
                    navigate(`/traders/traderDetails/orderOffers/${item?.id}`);
                  }}
                />
              );
            })}
            {setCurrentPage && (
              <AppPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={Math.ceil(traderOrders?.total / pageCount)}
              />
            )}
          </div>
        ) : (
          <NoData title="لا يوجد بيانات حالية" />
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};
