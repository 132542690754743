import React from "react";

function WhatsAppIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g data-name="Group 38712" transform="translate(-823 -350)">
        <circle
          cx="17.5"
          cy="17.5"
          r="17.5"
          fill="#12c279"
          data-name="Ellipse 1608"
          transform="translate(823 350)"
        ></circle>
        <path
          fill="#fff"
          d="M13.012 10.695c-.224-.112-1.314-.645-1.517-.718s-.352-.112-.5.112-.573.717-.7.864-.26.156-.48.056A6.035 6.035 0 018.025 9.9a6.747 6.747 0 01-1.234-1.536c-.129-.223-.014-.346.1-.457s.224-.257.335-.389a1.885 1.885 0 00.221-.369.409.409 0 00-.019-.39c-.056-.112-.5-1.2-.686-1.64s-.362-.379-.5-.379-.276-.011-.425-.011A.838.838 0 005.222 5a2.483 2.483 0 00-.777 1.84 4.351 4.351 0 00.906 2.287 9.916 9.916 0 003.792 3.335c.531.223.944.357 1.267.468a3.077 3.077 0 001.4.09 2.288 2.288 0 001.5-1.06 1.841 1.841 0 00.134-1.06c-.055-.1-.2-.156-.424-.257m-4.05 5.535h-.013a7.439 7.439 0 01-3.757-1.03l-.268-.159-2.789.725L2.889 13l-.178-.279A7.375 7.375 0 0114.2 3.626a7.279 7.279 0 012.163 5.2 7.384 7.384 0 01-7.388 7.351m6.286-13.61A9.029 9.029 0 008.957 0a8.841 8.841 0 00-7.7 13.265L0 17.847l4.711-1.228a8.97 8.97 0 004.246 1.076 8.879 8.879 0 008.886-8.846 8.77 8.77 0 00-2.6-6.255"
          data-name="Icon simple-whatsapp"
          transform="translate(831.562 358.602)"
        ></path>
      </g>
    </svg>
  );
}

export default WhatsAppIcon;
