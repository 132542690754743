import React from "react";
import { formDate } from "../../alogrithms/dateAlgo";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import Button from "../../reusableComponents/button";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";

export const OrdersRequestsCard = ({
  creation_date,
  date_now,
  dajinType,
  id,
  amount,
  averageWeight,
  dajinHealth,
  price,
  onPress,
}) => {
  return (
    <div
      style={{
        ...useResponsiveWidth({
          desktop: 45,
          tablet: 100,
          mobile: 100,
          defaults: 100,
        }),
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        marginRight: "2%",
        marginLeft: "2%",

        border: "1px solid #EDEDED",
        borderRadius: 10,
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: 20 }}>الطلب #{id}</p>
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          <ChickenIcon />
          <p style={{ fontSize: 14, marginRight: 5 }}>
            {formDate({ date_now: date_now, creation_date: creation_date })}
          </p>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          <ChickenIcon />
          <p style={{ fontSize: 14, marginRight: 5 }}>النوع :{dajinType}</p>
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          <ChickenIcon />
          <p style={{ fontSize: 14, marginRight: 5 }}>العدد : {amount}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          <ChickenIcon />
          <p style={{ fontSize: 14, marginRight: 5 }}>سعر الشراء :{price}ج.م</p>
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          <ChickenIcon />
          <p style={{ fontSize: 14, marginRight: 5 }}>
            متوسط الوزن :{averageWeight}
          </p>
        </div>
      </div>

      <Button onClick={onPress} style={{ marginTop: 20 }}>
        تفاصيل الطلب
      </Button>
    </div>
  );
};
