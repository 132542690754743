import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import Button from "../../reusableComponents/button";
import BinIcon from "../../reusableComponents/iconsSvg/binIcon";
import CartIcon from "../../reusableComponents/iconsSvg/cartIcon";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import EditIcon from "../../reusableComponents/iconsSvg/editIcon";
import LocationIcon from "../../reusableComponents/iconsSvg/locationIcon";
export const FarmCard = ({
  name,
  location,
  status,
  dajin,
  total_count,
  requested_price,
  logLink,
  farmManagementLink,
  image,
  deleteFarmLink,
  isFarm,
  farmerLink,
}) => {
  //useResponsiveWidth();
  const locationLink = useLocation();

  return (
    <div
      style={{
        ...useResponsiveWidth({
          desktop: 45,
          tablet: 100,
          mobile: 100,
          defaults: 100,
        }),
        marginTop: 10,
        marginBottom: 10,
        marginRight: "2%",
        marginLeft: "2%",

        display: "flex",
        flexDirection: "row",
        border: "1px solid #EDEDED",
        borderRadius: 10,
        backgroundColor: "white",
      }}
    >
      <div style={{ flex: 3, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 2,
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={
                image ??
                "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
              }
              style={{
                borderRadius: 10,
                ...useResponsiveWidth({
                  desktop: 100,
                  tablet: 100,
                  mobile: 100,
                  defaults: 100,
                }),
              }}
              height={120}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 2,
              paddingRight: 20,
              paddingTop: 5,
              flexDirection: "column",
            }}
          >
            <p style={{ color: "#114634", fontWeight: "bold", fontSize: 18 }}>
              {name}
            </p>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                alignItems: "center",
              }}
            >
              <LocationIcon />
              <p style={{ fontSize: 10, marginRight: 5 }}>{location} </p>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: 18, color: "#114634", fontWeight: "bold" }}>
                الحالة:
              </p>
              <p
                style={{
                  fontSize: 18,
                  marginRight: 5,
                  fontWeight: "bold",
                  color:
                    status?.id === 1
                      ? "#8c8c8c"
                      : status?.id == 2
                      ? "#7ABA4C"
                      : status?.id == 3
                      ? "#1296F3"
                      : "#F75757",
                }}
              >
                {status?.status}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingRight: 20,
            paddingBottom: 20,
            display: "flex",
            flexDirection: "row",
            flex: 1,
          }}
        >
          <Link
            to={farmManagementLink}
            style={{ display: "contents" }}
            state={{
              backgroundLocation: undefined,
            }}
          >
            <Button
              fontSize={10}
              icon={<EditIcon />}
              height={28}
              style={{ marginLeft: 10, flex: 2 }}
            >
              إدارة العنبر
            </Button>
          </Link>
          {status?.id != 1 && status?.id != 5 && status?.id != 2 && (
            <Link
              to={logLink}
              style={{ display: "contents" }}
              state={{
                backgroundLocation: undefined,
              }}
            >
              <Button
                fontSize={10}
                height={28}
                style={{ marginRight: 10, marginLeft: 10, flex: 2 }}
              >
                سجل الحركة
              </Button>
            </Link>
          )}
          <Link
            to={deleteFarmLink}
            style={{ textDecoration: "none", display: "contents" }}
            state={{
              backgroundLocation: locationLink,
            }}
          >
            <Button
              backgroundColor="#FDEDED"
              textColor="#ED4C4C"
              fontSize={10}
              icon={<BinIcon />}
              height={28}
              style={{ marginRight: 10, marginLeft: 10, flex: 1 }}
            >
              حذف
            </Button>
          </Link>
        </div>
        {isFarm ? (
          <div
            style={{
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "row",
              flex: 1,
            }}
          >
            <Link
              to={farmerLink}
              style={{ display: "contents" }}
              state={{
                backgroundLocation: undefined,
              }}
            >
              <Button width={"97%"} height={26} fontSize={10}>
                تفاصيل المربى
              </Button>
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRight: "1px solid #EDEDED",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: 68,
            borderBottom: "1px solid #EDEDED",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChickenIcon />
          <p style={{ color: "#114634", fontSize: 10, marginTop: 10 }}>
            {dajin}
          </p>
        </div>
        <div
          style={{
            height: 68,
            borderBottom: "1px solid #EDEDED",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChickenIcon />
          <p style={{ fontSize: 10, color: "#8C8C8C", fontWeight: "bold" }}>
            {total_count}
          </p>
        </div>
        <div
          style={{
            height: 68,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CartIcon />
          <p style={{ fontSize: 10, color: "#8C8C8C", fontWeight: "bold" }}>
            {requested_price}
          </p>
        </div>
      </div>
    </div>
  );
};
