import { markAsReadNotify } from "../../api/store/actions/saga/upcomingNotification";

export const selectedNotificationRoute = (type, id) => {
  if (type == "ContactMessageNotification") {
    return `/support/contactDetails/${id}`;
  } else if (type == "CommentReviewNotification") {
    return `/comments/editComment/${id}`;
  } else if (type == "FarmReviewNotification") {
    return `/farmers/farmDetails/${id}`;
  } else if (type == "NewTraderActivationNotification") {
    return `/traders/traderDetails/${id}`;
  } else if (type == "OrderDoneByTraderNotification") {
    return `/traders/traderDetails/orderOffers/offerDetails/${id}`;
  } else if (type == "DisputeReviewNotification") {
    return `/salesTransactions/disputeDetails/${id}`;
  } else if (type == "SupportTicketNotification") {
    return `/support/SupportTicket/${id}`;
  } else {
    return `/`;
  }
};
