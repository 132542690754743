import { useLocation } from "react-router-dom";
import AppModal from "../modal/customModal";
import GoogleMapReact from "google-map-react";

const MapScreen = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  return (
    <AppModal modalTitle={"العنوان على الخريطة"}>
      <iframe
        width="100%"
        height="300"
        src={
          "https://maps.google.com/maps?width=100%&height=400&amp;hl=en&coord=" +
          query?.get("lat") +
          ", " +
          query?.get("long") +
          "&q=" +
          query?.get("lat") +
          ", " +
          query?.get("long") +
          "&ie=UTF8&t=&z=15&amp;iwloc=B&output=embed&key=AIzaSyAr5i--0qXIwHrcKqvVOTDZ1qFPhNspDi4"
        }
        frameborder="0"
        style={{ border: 0 }}
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
      <br />
    </AppModal>
  );
};

export default MapScreen;
