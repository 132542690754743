import { color } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  closeTicket,
  getSupportDetails,
  getTechinacalSupportDetails,
} from "../../../api/store/actions/saga/support";
import Button from "../../../reusableComponents/button";
import PhoneIcon from "../../../reusableComponents/iconsSvg/phoneIcon";
import WhatsAppIcon from "../../../reusableComponents/iconsSvg/whatsappIcon";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../../reusableComponents/sectionLabel";
import { useTablet } from "../../salesTransactions/salesDetails";

export const TechnicalSupportDetailsScreen = () => {
  const { id } = useParams();
  const [supportDetails, setSupportDetails] = useState();
  const reload = useSelector((state) => state.reload.reload);

  useEffect(() => {
    resolveRequest(getTechinacalSupportDetails, setSupportDetails, { id: id });
  }, [reload]);

  const halfWidth = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const isTablet = useTablet();
  const dispatch = useDispatch();
  return supportDetails ? (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: isTablet ? "column" : "row" }}
      >
        <div
          style={{
            ...halfWidth,
          }}
        >
          <SectionLabel content={"بيانات المستخدم"} />
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <img
                style={{ width: 140, height: 140, borderRadius: 10 }}
                src={
                  supportDetails?.data?.user?.avatar ??
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
                }
              />
            </div>

            <div style={{ flex: 3, marginRight: 10, marginBottom: 10 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <p style={{ marginLeft: 5 }}>
                    {supportDetails?.data?.user?.name}
                  </p>
                  <p style={{ color: "lightgray" }}>
                    {supportDetails?.data?.user?.type == 2 ? "مربى" : "تاجر"}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <p
                    style={{
                      color: "#7ABA4C",
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    مجال الدعم
                  </p>
                  <p> {supportDetails?.data?.support_type?.type}</p>
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  {" "}
                  <PhoneIcon />
                  <p
                    style={{
                      marginRight: 15,
                      marginLeft: 15,
                      color: "#646D82",
                    }}
                  >
                    {supportDetails?.data?.user?.mobile_number}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <p style={{ color: "#7ABA4C" }}> التاريخ</p>

                  <p
                    style={{
                      marginRight: 15,
                      marginLeft: 15,
                      color: "#646D82",
                    }}
                  >
                    {moment(supportDetails?.data?.created_at).format(
                      "YYYY/MM/DD HH:mm A"
                    )}
                  </p>
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginLeft: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <WhatsAppIcon />
                <p
                  style={{
                    marginRight: 15,
                    marginLeft: 15,
                    color: "#646D82",
                  }}
                >
                  {supportDetails?.data?.user?.whatsapp_number}
                </p>
              </div>
              {supportDetails?.data?.user?.email != null ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      color: "#7ABA4C",
                      marginLeft: 10,
                    }}
                  >
                    البريد الإلكترونى
                  </p>
                  <p> {supportDetails?.data?.user?.email}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            ...halfWidth,
          }}
        >
          <SectionLabel content={"الوصف"} />
          <p
            style={{
              border: "1px solid #7ABA4C",
              padding: 10,
              borderRadius: 10,
            }}
          >
            {supportDetails?.data?.description}
          </p>
        </div>
      </div>
      {supportDetails?.data?.closed == false ? (
        <div
          style={{
            width: "100%",
            marginTop: 20,
          }}
        >
          <Button
            width="30%"
            onClick={async () => {
              await closeTicket({ id: id });
              dispatch({ type: "RELOAD" });
            }}
          >
            تم الرد
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <Loading />
  );
};
