import { Route, Routes, useLocation, useNavigate } from "react-router";
import AppModal from "../screenParts/modal/customModal";
import { appRoutes } from "../screenParts/layout/sideBar/sideBarRoutes";
import { LoginScreen } from "../screens/loginScreen";
import PrivateRoute from "./authenticatedRoutes";
import NoMatch from "./noMatch";
import PublicRoute from "./publicRoutes";
import { appModalsRoutes } from "../screenParts/layout/sideBar/modalRoutes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosInterceptors } from "../api/axios/axiosInterceptors";
import NoPermission from "./noPermission";
import { resolveRequest } from "../api/middlewares/resolverRequests";
import { getAdminInf } from "../api/store/actions/saga/users";

const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector((state) => state.login.user);
  const reload = useSelector((state) => state.reload.reload);
  // const permissionArr = permission?.map((item)=> item?.id)
  const [adminInf, setAdminInf] = useState();

  useEffect(() => {
    resolveRequest(getAdminInf, setAdminInf);
  }, [reload]);
  console.log("adminInf", adminInf);
  useEffect(() => {
    console.log("NJCHFFDSFDFDFFGFGF");
    axiosInterceptors(dispatch, navigate);
  }, []);
  const { state } = location;
  console.log("state", state);

  return (
    <>
      {/* Normal Routes */}

      <Routes location={state?.backgroundLocation || location}>
        <Route
          element={
            <PublicRoute roles={undefined}>
              <LoginScreen />
            </PublicRoute>
          }
          path={"/"}
        ></Route>
        {appRoutes.map((item, index) => {
          /*       let hasPermission = item?.permission?.some((permissionItem) => {
            return (
              adminInf?.data?.permissions
                ?.map((f) => f?.id)
                ?.includes(permissionItem) || permissionItem == 0
            );
          }); */
          /*   if (hasPermission !== false && adminInf) */
          return (
            <Route
              key={index}
              exact={true}
              path={item.link}
              element={<PrivateRoute>{item.component}</PrivateRoute>}
            ></Route>
          );
        })}
        {/* <Route path={'/NoPermission'} element={<NoPermission />}></Route> */}
        {/*         {<Route path={"*"} element={<NoMatch />}></Route>}{" "}
         */}{" "}
      </Routes>
      {/* Modal Routes */}
      {state?.backgroundLocation && (
        <Routes>
          {appModalsRoutes.map((item, index) => {
            return (
              <Route
                key={index}
                exact={true}
                path={item.link}
                element={item.component}
              />
            );
          })}
          <Route path={"*"} element={<NoMatch />}></Route>
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
