import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { arToEn } from "../../alogrithms";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";

import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";

import { houseFarm } from "../../api/store/actions/saga/farms";
import { getAllSettings } from "../../api/store/actions/saga/settings";
import { getBreedDetails } from "../../api/store/actions/saga/settings/breeds";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import Loading from "../../reusableComponents/loadingScreen/loading";
import TextInput from "../../reusableComponents/textInput";
import AppModal from "../../screenParts/modal/customModal";
import { useTablet } from "../salesTransactions/salesDetails";

const HouseFarm = () => {
  const dispatch = useDispatch();
  const { farm_id } = useParams();
  console.log("farm_id", farm_id);
  const navigate = useNavigate();
  const settingsData = useSelector((state) => state?.settings?.setting);

  const submit = async (values) => {
    const body = {
      dajin_breed_id: values?.dajin_breed_id,

      company_id: values?.company_id,

      total_count: arToEn(values?.total_count),
      expected_selling_date: values?.expected_selling_date,
      entry_date: values?.entry_date,
    };

    const res = await houseFarm({ farm_id: farm_id, body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };
  const isTablet = useTablet();

  const formik = useFormik(
    formikSetup(
      initialValues,
      undefined,
      validationSchema,
      "housingFarm",
      submit
    )
  );

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;

  console.log(values);
  const isValid = formikFieldValidation(touched, errors);
  const fullWidth = useResponsiveWidth({
    desktop: 100,
    tablet: 100,
    tabletDesktop: 100,
    tabletMobile: 100,
  });
  const halfWidth = useResponsiveWidth({
    desktop: 48,
    tablet: 100,
    tabletDesktop: 100,
    tabletMobile: 100,
  });
  const [breedInf, setBreedInf] = useState();
  console.log("breedInf", breedInf);
  useEffect(() => {
    if (values?.dajin_breed_id)
      resolveRequest(getBreedDetails, setBreedInf, {
        id: values?.dajin_breed_id,
        dajinId: values?.dajin_id,
      });
  }, [values?.dajin_breed_id]);

  useEffect(() => {
    if (values?.entry_date && values?.dajin_breed_id)
      setFieldValue(
        "expected_selling_date",
        moment(
          new Date(values?.entry_date?.toString()).setDate(
            parseInt(values?.entry_date?.toString()?.split("-")[2]) +
              breedInf?.data?.poultry_farming_average
          )
        ).format("yyyy-MM-DD")
      );
  }, [values?.entry_date, values?.dajin_breed_id]);

  return (
    <AppModal
      buttonTitle={"تسكين"}
      modalTitle={"تسكين العنبر"}
      onClick={handleSubmit}
    >
      {settingsData ? (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: isTablet ? "column" : "row",
            }}
          >
            <div
              style={{
                ...halfWidth,
              }}
            >
              <p>نوع الداجن *</p>
              <SelectDropdown
                dataSource={settingsData?.data?.data?.farms?.dajin_types}
                errorMessage={isValid("dajin_id")}
                onSelect={(e) => {
                  setFieldValue("dajin_id", e);
                }}
                schema={{ id: "id", name: "type" }}
                placeholder={"اختر"}
                responsiveWidth={{ ...fullWidth }}
              />
            </div>
            <div
              style={{
                ...halfWidth,
              }}
            >
              <p>نوع السلالة *</p>
              <SelectDropdown
                dataSource={
                  values?.dajin_id
                    ? settingsData?.data?.data?.farms?.dajin_breed_types
                    : []
                }
                errorMessage={isValid("dajin_breed_id")}
                onSelect={(e) => {
                  setFieldValue("dajin_breed_id", e);
                }}
                schema={{ id: "id", name: "breed_type" }}
                placeholder={"اختر"}
                responsiveWidth={{ ...fullWidth }}
                dependId={values["dajin_id"]}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 10,
              flexDirection: isTablet ? "column" : "row",

              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ ...halfWidth }}>
              <p>تاريخ الإدخال *</p>
              <input
                style={{
                  width: "100%",

                  height: 54,
                  border: 0,
                  borderRadius: 10,
                  backgroundColor: "#FBFBFB",
                  color: "#7ABA4C",
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
                value={values["entry_date"]}
                onChange={handleChange("entry_date")}
                dir="rtl"
                type="date"
                name="date"
              />
              <p style={{ color: "red" }}>{isValid("entry_date")}</p>
            </div>{" "}
            <div
              style={{
                ...halfWidth,
              }}
            >
              <p>تاريخ البيع المتوقع *</p>
              <input
                style={{
                  width: "100%",
                  height: 54,
                  border: 0,
                  borderRadius: 10,
                  backgroundColor: "#FBFBFB",
                  color: "#7ABA4C",
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
                lang="ar"
                value={values["expected_selling_date"]}
                onChange={handleChange("expected_selling_date")}
                dir="rtl"
                type="date"
                name="date"
              />
              <p style={{ color: "red" }}>{isValid("expected_selling_date")}</p>
            </div>
          </div>
          <div
            style={{
              marginTop: 10,

              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                ...halfWidth,
              }}
            >
              <div style={{ display: "flex" }}>
                <p style={{ fontSize: 13 }}>العدد *</p>
                <p style={{ fontSize: 13, marginRight: 10, color: "#B0B0B1" }}>
                  {" "}
                  * يجب ان تتوافق مع مساحة العنبر الكلية
                </p>
              </div>
              <TextInput
                placeholder={"0000"}
                errorMessage={isValid("total_count")}
                onChange={handleChange("total_count")}
              />
            </div>
            <div
              style={{
                ...halfWidth,
              }}
            >
              <p>الشركة *</p>
              <SelectDropdown
                onSelect={(e) => {
                  setFieldValue("company_id", e);
                }}
                errorMessage={isValid("company_id")}
                dataSource={settingsData?.data?.data?.farms?.companies}
                schema={{ id: "id", name: "type" }}
                placeholder={"اختر"}
                responsiveWidth={{ ...fullWidth }}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </AppModal>
  );
};

export default HouseFarm;
