import { width } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getAllAccounts } from "../../api/store/actions/saga/accounts";
import {
  approveRedeem,
  disApproveRedeem,
  redeemtionRequests,
} from "../../api/store/actions/saga/redeemption";

import { getAllSettings } from "../../api/store/actions/saga/settings";
import Button from "../../reusableComponents/button";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import ListView from "../../screenParts/listingViews";

import ListHeader from "../../screenParts/listingViews/listHeader";
import { useTablet } from "../salesTransactions/salesDetails";

export const RedeemsList = () => {
  const [redeemsData, setRedeemsData] = useState(null);
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const reload = useSelector((state) => state.reload.reload);
  useEffect(() => {
    // setCourseData(null);
    resolveRequest(redeemtionRequests, setRedeemsData, {
      pageNumber: currentPage,
      pageCount: 7,
      // params: {status_id: filterKey },
    });
  }, [currentPage /* , searchKey, filterKey, */, reload]);
  /*   console.log("filterKey", filterKey);

  const onChange = (value) => {
    setSearchKey(value);
  }; */
  console.log("accountsData", redeemsData);
  /*   console.log("settings", settingsData);
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
    console.log(value, "select");
  }; */
  const isTablet = useTablet();
  return (
    <>
      <ListHeader
        /*  onSelect={onSelect}
          onChange={onChange}
          dataSource={
            settingsData
              ? [
                  { name: "الكل", id: null },
                  ...settingsData?.data?.data?.cities,
                ]
              : []
          } */
        hasFilter={false}
        hasSearch={false}
        hasAdd={false}
        hasTitle={true}
        title="جوائز قيد المراجعة"
        //addLink={"addFarmer"}
        //linkType={undefined}
      />

      {!isTablet && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <p style={{ color: " #7aba4c", fontSize: 12, width: "22%" }}>الاسم</p>
          <p style={{ color: " #7aba4c", fontSize: 12, width: "10%" }}>
            تاريخ الاضافة
          </p>
          <p style={{ color: " #7aba4c", fontSize: 12, width: "23%" }}>
            الهدية
          </p>
          <p style={{ color: " #7aba4c", fontSize: 12, width: "10%" }}>
            النقاط
          </p>
          <p style={{ color: " #7aba4c", fontSize: 12, width: "15%" }}>
            الحالة
          </p>
          <p style={{ color: " #7aba4c", fontSize: 12 }}>التعديل</p>
        </div>
      )}
      {redeemsData ? (
        <div>
          {redeemsData?.data?.map((item) => {
            return (
              <>
                {!isTablet && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid black",
                      borderRadius: 10,
                      padding: 20,

                      marginTop: 10,
                    }}
                  >
                    <p style={{ fontSize: 12, width: "22%" }}>
                      {item?.user?.name}
                    </p>
                    <p style={{ fontSize: 12, width: "10%" }}>
                      {moment(item?.created_at).format("YYYY/MM/DD")}
                    </p>
                    <p style={{ fontSize: 12, width: "23%" }}>
                      {item?.gift?.description}
                    </p>
                    <p style={{ fontSize: 12, width: "10%" }}>
                      {item?.gift?.points}
                    </p>
                    <p style={{ fontSize: 12, width: "15%" }}>
                      {item?.redemption_request_status?.status}
                    </p>
                    {item?.redemption_request_status?.id == 1 && (
                      <div style={{}}>
                        <Button
                          height={"auto"}
                          buttonStyles={{ border: "1px solid  #7aba4c" }}
                          fontSize={12}
                          onClick={() => {
                            approveRedeem({ id: item?.id });
                            dispatch({ type: "RELOAD" });
                          }}
                        >
                          موافقة
                        </Button>
                        <Button
                          height={"auto"}
                          fontSize={12}
                          style={{ marginTop: 10 }}
                          width={100}
                          buttonStyles={{ border: "1px solid red" }}
                          backgroundColor="white"
                          textColor={"red"}
                          onClick={() => {
                            disApproveRedeem({ id: item?.id });
                            dispatch({ type: "RELOAD" });
                          }}
                        >
                          غير موافق
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {isTablet && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      border: "1px solid black",
                      borderRadius: 10,
                      marginTop: 10,
                      padding: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <p
                        style={{
                          color: " #7aba4c",
                          fontSize: 12,
                        }}
                      >
                        الاسم
                      </p>
                      <p style={{ fontSize: 12 }}>{item?.user?.name}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <p
                        style={{
                          color: " #7aba4c",
                          fontSize: 12,
                        }}
                      >
                        تاريخ الاضافة
                      </p>
                      <p style={{ fontSize: 12 }}>
                        {" "}
                        {moment(item?.created_at).format("YYYY/MM/DD")}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <p
                        style={{
                          color: " #7aba4c",
                          fontSize: 12,
                        }}
                      >
                        الهدية
                      </p>
                      <p style={{ fontSize: 12 }}>{item?.gift?.description}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <p
                        style={{
                          color: " #7aba4c",
                          fontSize: 12,
                        }}
                      >
                        النقاط
                      </p>
                      <p style={{ fontSize: 12 }}>{item?.gift?.points}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <p
                        style={{
                          color: " #7aba4c",
                          fontSize: 12,
                        }}
                      >
                        الحالة
                      </p>
                      <p style={{ fontSize: 12 }}>
                        {" "}
                        {item?.redemption_request_status?.status}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          color: " #7aba4c",
                          fontSize: 12,
                        }}
                      >
                        التعديل
                      </p>
                      {item?.redemption_request_status?.id == 1 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "auto",
                          }}
                        >
                          <Button
                            height={"auto"}
                            buttonStyles={{ border: "1px solid  #7aba4c" }}
                            fontSize={12}
                            onClick={() => {
                              approveRedeem({ id: item?.id });
                              dispatch({ type: "RELOAD" });
                            }}
                          >
                            موافقة
                          </Button>
                          <Button
                            fontSize={12}
                            height={"auto"}
                            style={{ marginRight: 10 }}
                            buttonStyles={{ border: "1px solid red" }}
                            backgroundColor="white"
                            textColor={"red"}
                            onClick={() => {
                              disApproveRedeem({ id: item?.id });
                              dispatch({ type: "RELOAD" });
                            }}
                          >
                            غير موافق
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      ) : (
        <Loading />
      )}
      {setCurrentPage && redeemsData?.total != 0 && (
        <AppPaginate
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={Math.ceil(redeemsData?.total / 7)}
        />
      )}
    </>
  );
};
