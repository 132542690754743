import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const salesAPI = "/admin/v1/sales-transactions";
export const getAllSales = async ({
  pageNumber: pageNumber = 1,
  pageCount: pageCount = 7,
  params: params = {},
}) => {
  try {
    let filters = concatFilters(params);
    let data = await axiosCall.get(
      `${salesAPI}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
    //return dataFormatPagination(data);
  } catch (e) {
    return e.response;
  }
};

export const getSalesDetails = async ({ id }) => {
  try {
    // let filters = concatFilters(params);
    let data = await axiosCall.get(`${salesAPI}/${id}`);
    return data;
  } catch (e) {
    return e.response;
  }
};
