import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
} from "../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/instructors`;
export const createInstructor = async ({ body }) => {
  try {
    const data = await axiosCall.post(apiUrl, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getInstructors = async ({ params: params = {} }) => {
  try {
    let filters = concatFilters(params);
    const data = await axiosCall.get(`${apiUrl}?${filters}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const updateInstructor = async ({ body, id }) => {
  try {
    const data = await axiosCall.post(`${apiUrl}/${id}`, objToFormData(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getInstructorDetails = async ({ id }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const deleteInstructor = async ({ id }) => {
  try {
    const data = await axiosCall.delete(`${apiUrl}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
