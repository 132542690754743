import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getUsers } from "../../api/store/actions/saga/users";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";
import RolesList from "../roles";

const UsersList = () => {
  const activeState = useSelector((state) => state?.reload?.active);
  const [searchKey, setSearchKey] = useState("");
  const [usersData, setUsersData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setFilterKey] = useState(null);
  const [active, setActive] = useState(activeState ?? 1);

  useEffect(() => {
    if (active == 1) {
      setUsersData(null);
      resolveRequest(getUsers, setUsersData, {
        pageNumber: currentPage,
      });
    }
  }, [currentPage, searchKey, filterKey]);
  const onChange = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSearchKey(value);
  };
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setFilterKey(value);
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        paddingBottom: 20,
        paddingTop: 20,
        borderRadius: 10,
      }}
    >
      <ListHeader
        onChange={onChange}
        onSelect={onSelect}
        hasFilter={false}
        hasSearch={active === 1}
        dataSource={
          []
          //   categories ? [{ name: "الكل", id: null }, ...categories?.data] : []
        }
        addLink={active == 1 ? "addUser" : "addRoles"}
        pages={[
          { title: "المستخدمين", id: 1 },
          { title: "الصلاحيات", id: 2 },
        ]}
        // linkType={active == 1 || active == 3 ? undefined : "modal"}
        active={active}
        setActive={setActive}
        searchKey={searchKey}
      />
      {active == 1 ? (
        usersData ? (
          <ListView
            objName={"users"}
            links={{
              edit: "editUser",
              delete: "",
              show: ``,
              query: {
                show: { key: "", prop: "" },
                delete: "",
                edit: "",
              },
            }}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={usersData}
          ></ListView>
        ) : (
          <Loading />
        )
      ) : (
        <RolesList />
      )}
    </div>
  );
};

export default UsersList;
