import SectionLabel from "../../reusableComponents/sectionLabel";
import { MyEditor } from "../../reusableComponents/editor";
import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import avatar from "../../assets/avatar.jpg";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";

import {
  createArticles,
  getArticlesDetails,
  updateArticles,
} from "../../api/store/actions/saga/articles";
import { useNavigate } from "react-router-dom";

import draftToHtml from "draftjs-to-html";
import { dateToIso } from "../../alogrithms/dateAlgo";
import Loading from "../../reusableComponents/loadingScreen/loading";
import { useParams } from "react-router";
import TextInput from "../../reusableComponents/textInput";
import { getCategories } from "../../api/store/actions/saga/categories";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import TimeInput from "../../reusableComponents/timeInput";
import { object } from "yup";
import moment from "moment";

function ArticleCreateScreen({ isEdit }) {
  const width = useResponsiveWidth({});

  const [articleDetails, setArticlesDetails] = useState();
  const [image, setImage] = useState();
  const [imageObj, setImageObj] = useState();

  const [categoriesData, setCategoriesData] = useState();

  const [checked, setChecked] = useState(false);

  const options = [
    { id: 1, name: " نعم" },
    { id: 0, name: "لا" },
  ];
  useEffect(() => {
    resolveRequest(getCategories, setCategoriesData, "articles");
  }, []);
  const checktime = new Date(articleDetails?.data?.publish_at) < new Date();
  useEffect(() => {
    if (checktime) {
      setChecked(true);
    }
  }, [checktime]);
  const [title, setTitle] = useState("");
  const [content, setcontent] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [allow_comments, setallow_comments] = useState("");
  const [date, setdate] = useState(moment(new Date()).format("yyyy-MM-DD"));
  const [time, setTime] = useState(moment(new Date()).format("HH:mm"));
  const [errorImage, setErrorImage] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorContent, setErrorContent] = useState(null);
  const [errorCategoryId, setErrorCategoryId] = useState(null);
  const [errorAllowComments, setErrorAllowComments] = useState(null);

  const [errorPublishAt, setErrorPublishAt] = useState(null);

  console.log("error", errorImage);
  console.log("content", typeof content);
  const handleBackendError = (errors) => {
    if (errors?.image) {
      setErrorImage(errors?.image);
    }
    if (errors?.title) {
      setErrorTitle(errors?.title);
    }
    if (errors?.content) {
      setErrorContent(errors?.content);
    }
    if (errors?.category_id) {
      setErrorCategoryId(errors?.category_id);
    }
    if (errors?.publish_at) {
      setErrorPublishAt(errors?.publish_at);
    }
    if (errors?.allow_comments) {
      setErrorAllowComments(errors?.allow_comments);
    }
  };
  const [submitFlag, setSubmitFlag] = useState(false);

  const submit = async () => {
    setSubmitFlag(false);
    if (errorImage) setErrorImage(null);
    if (errorTitle) setErrorImage(null);
    if (errorContent) setErrorImage(null);
    if (errorCategoryId) setErrorCategoryId(null);
    if (errorPublishAt) setErrorPublishAt(null);
    if (errorAllowComments) setErrorAllowComments(null);

    if (title?.length == 0) {
      setErrorTitle("يلزم ادخال العنوان");
    }
    if (content?.length == 0) {
      setErrorContent("يلزم ادخال محتوى المقال");
    }
    if (content == "<p></p>") {
      setErrorContent("يلزم ادخال محتوى المقال");
    }

    if (typeof imageObj != "object") {
      setErrorImage("يلزم ادخال الصورة بالشكل الصحيح");
    }
    if (typeof category_id != "number" && category_id?.length == 0) {
      setErrorCategoryId("يلزم ادخال القسم");
    }
    if (typeof allow_comments != "number" && allow_comments?.length == 0) {
      setErrorAllowComments("يلزم ادخال الموافقة على التعليق");
    }
    if (
      submitFlag == true &&
      errorImage == null &&
      errorTitle == null &&
      errorContent == null &&
      errorCategoryId == null &&
      errorPublishAt == null
    ) {
      const body = {
        title: title,
        content: content,
        category_id: category_id,
        image: imageObj,
        allow_comments: allow_comments,
        publish_at: checked ? "" : dateToIso(new Date(date + " " + time)),
      };
      const res = await createArticles({ body: body });
      handleBackendError(res?.errors);
    }
    setSubmitFlag(true);
  };

  console.log(articleDetails?.data);
  const navigate = useNavigate();

  return (
    <>
      {categoriesData && (!isEdit || (isEdit && articleDetails?.data)) ? (
        <div
          style={{
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <SectionLabel content={"تفاصيل المقال"} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "55%", padding: 20 }}>
              <p style={{ marginBottom: 10, marginTop: 10 }}>العنوان</p>
              <TextInput
                value={title}
                placeholder={"اكتب العنوان"}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.target.value) {
                    setErrorTitle(null);
                  }
                  if (title?.length == 0) {
                    setErrorTitle("يلزم ادخال العنوان");
                  }
                }}
                errorMessage={errorTitle}
              />
              <p style={{ marginBottom: 10, marginTop: 10 }}>القسم</p>
              <SelectDropdown
                placeholder={"اختر"}
                errorMessage={errorCategoryId}
                responsiveWidth={{ width }}
                selectedItem={category_id}
                dataSource={categoriesData?.data}
                onSelect={(e) => {
                  setcategory_id(e);
                  if (e) {
                    setErrorCategoryId(null);
                  } else if (
                    typeof category_id != "number" &&
                    category_id?.length == 0
                  ) {
                    setErrorCategoryId("يلزم ادخال القسم");
                  }
                }}
              />
            </div>
            <div
              style={{
                width: "45%",
                padding: 20,
              }}
            >
              <p style={{ marginBottom: 10, marginTop: 10 }}>صورة المقال</p>
              <div
                style={{
                  borderRadius: 10,
                  backgroundColor: "#7ABA4C0D",
                  border: "1px dashed #7ABA4C99",
                  height: "80%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  width={100}
                  height={100}
                  style={{ borderRadius: 500 }}
                  src={
                    isEdit && image != undefined
                      ? image
                      : isEdit
                      ? `${articleDetails?.data?.image_url}`
                      : isEdit && image == undefined
                      ? avatar
                      : !isEdit && image == undefined
                      ? avatar
                      : image
                  }
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      color: "#7ABA4C",
                      marginRight: 10,
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => {
                        if (e) {
                          setErrorImage(null);
                        }
                        setImageObj(e.target.files[0]);
                        setImage(URL.createObjectURL(e.target.files[0]));
                      }}
                      style={{ display: "none" }}
                    />
                    استعرض
                  </label>
                </div>
              </div>
              <p style={{ marginBottom: 10, marginTop: 10, color: "red" }}>
                {errorImage ?? ""}
              </p>
            </div>
          </div>
          <SectionLabel content={"المقالة"} />
          <MyEditor
            placeholder={"اكتب المقالة"}
            content={isEdit ? articleDetails?.data?.content?.trim() ?? "" : ""}
            onChange={(e) => {
              if (e) {
                setErrorContent(null);
              }
              if (content == "" || content == "<p></p>") {
                setErrorContent("يلزم ادخال محتوى المقال");
              }
              setcontent(e);
            }}
          />
          <p style={{ color: "red" }}>{errorContent}</p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
                paddingTop: 20,
              }}
            >
              <SectionLabel content={"النشر"} />
              <p style={{ marginBottom: 10, marginTop: 10 }}>تاريخ النشر</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    width: "40%",

                    height: 54,
                    border: 0,
                    borderRadius: 10,
                    backgroundColor: "#FBFBFB",
                    color: "#7ABA4C",
                    paddingRight: 20,
                    paddingLeft: 20,
                  }}
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                  value={date}
                  dir="rtl"
                  type="date"
                  name="date"
                  placeholder="اكتب العنوان"
                />

                <TimeInput
                  responsiveWidth={{ ...width }}
                  value={time}
                  name={"name"}
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                />
                {/* <input
                    style={{
                      width: "40%",
                      height: 54,
                      border: 0,
                      borderRadius: 10,
                      backgroundColor: "#FBFBFB",
                      color: "#7ABA4C",
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginRight: 20,
                    }}
                    onChange={handleChange("time")}
                    value={values["time"]}
                    dir="rtl"
                    type="time"
                    name="time"
                    placeholder="اكتب العنوان"
                  /> */}
                <div
                  style={{
                    width: "20%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    paddingRight: 10,
                  }}
                >
                  <p style={{ marginRight: 3, marginLeft: 3 }}>الآن</p>
                  <input
                    type="checkbox"
                    name="dateNow"
                    value={checked}
                    checked={checked}
                    onChange={() => {
                      setChecked((prevState) => !prevState);
                    }}
                  />
                </div>
              </div>
              <p style={{ color: "red" }}>{errorPublishAt}</p>
            </div>
            <div
              style={{
                width: "50%",
                paddingTop: 20,
                paddingRight: 20,
                paddingLeft: 20,
              }}
            >
              <SectionLabel content={"التعليقات"} />
              <p style={{ marginBottom: 10, marginTop: 10 }}>
                الموافقة على نشر التعليقات
              </p>
              <SelectDropdown
                placeholder={"اختر"}
                responsiveWidth={{ ...width }}
                selectedItem={allow_comments}
                errorMessage={errorAllowComments}
                dataSource={options}
                onSelect={(e) => {
                  setallow_comments(e);
                  if (e) {
                    setErrorAllowComments(null);
                  } else if (
                    typeof allow_comments != "number" &&
                    allow_comments?.length == 0
                  ) {
                    setErrorAllowComments("يلزم ادخال الموافقة على التعليق");
                  }
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              type={"reset"}
              backgroundColor="#F4F4F4"
              textColor="#9F9F9F"
              style={{ marginRight: 10, marginLeft: 10, width: "20%" }}
            >
              إلغاء
            </Button>
            <Button
              type={"none"}
              onClick={() => {
                submit();
              }}
              //  type={"submit"}
              style={{ width: "20%", marginRight: 10, marginLeft: 10 }}
            >
              {"إضافة"}
            </Button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default ArticleCreateScreen;
