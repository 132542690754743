import { Switch } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import {
  getBonusPointsDetails,
  updateBonusPoints,
} from "../../../api/store/actions/saga/settings/bonusPoints";
import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";
import { useLocation } from "react-router-dom";
import { arToEn } from "../../../alogrithms";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
const UpdateBonusPoints = () => {
  const { bonus_id } = useParams();
  const { search } = useLocation();
  const [bonusDetails, setBonusDetails] = useState();
  useEffect(() => {
    resolveRequest(getBonusPointsDetails, setBonusDetails, {
      bonus_id: bonus_id,
    });
  }, []);
  const query = new URLSearchParams(search);
  const submit = async (values) => {
    const body = {
      active: values?.active,
      points: arToEn(values?.points),
      rating_value: arToEn(values?.rating_value),
    };
    if (values?.rating_value?.length == 0) {
      delete body?.rating_value;
    }
    if (values?.points?.length == 0) {
      delete body?.points;
    }

    const res = await updateBonusPoints({ body: body, bonus_id: bonus_id });
    setBackEndErrors(res?.errors, setFieldError);
  };

  console.log("bonusDetails", bonusDetails);
  const formik = useFormik(
    formikSetup(
      initialValues,
      bonusDetails?.data,

      validationSchema,
      "updateBonusPoints",
      submit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log("balues", values);
  const isValid = formikFieldValidation(touched, errors);
  const width = useResponsiveWidth({
    desktop: 100,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });

  /*   const onChange = () => {
    setChecked((prevState) => !prevState);
  }; */
  return (
    <AppModal
      buttonTitle={"تعديل"}
      modalTitle={"تعديل النقاط الاضافية"}
      onClick={handleSubmit}
    >
      {bonusDetails ? (
        <>
          <TextInput
            require
            label={"النقاط"}
            placeholder={"0000"}
            value={values?.points?.toString()}
            onChange={handleChange("points")}
            errorMessage={isValid("points")}
          />
          {bonus_id == 2 ? (
            <TextInput
              require
              value={values?.rating_value}
              onChange={handleChange("rating_value")}
              label={"التقييم"}
              placeholder={"0"}
              errorMessage={isValid("rating_value")}
            />
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>التفعيل</p>
            <Switch
              onColor={"#4BD964"}
              uncheckedIcon={false}
              checkedIcon={false}
              color={"success"}
              onChange={(e) => {
                setFieldValue("active", !values["active"]);
              }}
              checked={values["active"]}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </AppModal>
  );
};

export default UpdateBonusPoints;
