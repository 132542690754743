import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getAllFarmerOffer } from "../../api/store/actions/saga/farmer";
import { getFarmerFarms } from "../../api/store/actions/saga/farms";
import Button from "../../reusableComponents/button";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
//import { getTraderOrders } from "../../api/store/actions/saga/traders";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { FarmerOrderCard } from "../../screenParts/farmer/farmerOrderCard";
import { LabelWIcon } from "../../screenParts/farms/labelWIcon";
import NoData from "../../screenParts/noData";
//import AppPaginate from "../../reusableComponents/pagination/pagination";
//import { FarmCard } from "../../screenParts/farms/farmCard";
//import { OrdersRequestsCard } from "../../screenParts/orders/ordersRequestCard";
export const FarmerOrdersScreen = ({
  searchKey,
  currentPage,
  setCurrentPage,
  filterKey,
}) => {
  const { farmer_id } = useParams();
  const [farmerOrders, setFarmerOrders] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    resolveRequest(getAllFarmerOffer, setFarmerOrders, {
      farmer_id: farmer_id,

      pageNumber: currentPage,

      params: { trader: searchKey, status_id: filterKey },
    });
  }, [searchKey, filterKey, currentPage]);
  console.log("farmerOrders", farmerOrders);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
      }}
    >
      <SectionLabel content={"العروض"} />
      {farmerOrders ? (
        farmerOrders?.data?.length != 0 ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {farmerOrders?.data?.map((item, index) => {
              return (
                <FarmerOrderCard
                  onPress={() => {
                    navigate(
                      `/farmers/${farmer_id}/farmerOrders/orderDetails/${item?.id}`
                    );
                  }}
                  farmAvailability={item?.farm?.count}
                  requestedPrice={item?.farm?.requested_price}
                  farmName={item?.farm?.name}
                  farmerImage={item?.farm?.photo}
                  traderImage={item?.trader?.avatar}
                  traderName={item?.trader?.name}
                  farmerStatus={item?.status}
                  amount={item?.order_details?.amount}
                  dajinHealth={item?.order_details?.dajin_health}
                  dajinType={item?.order_details?.dajin_type?.type}
                  averageWeight={item?.order_details?.average_weight}
                />
              );
            })}
            {setCurrentPage && (
              <AppPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={Math.ceil(farmerOrders?.total / 9)}
              />
            )}
          </div>
        ) : (
          <NoData title="لا يوجد بيانات حالياً" />
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};
