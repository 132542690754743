import SectionLabel from "../../reusableComponents/sectionLabel";
import { MyEditor } from "../../reusableComponents/editor";
import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import avatar from "../../assets/avatar.jpg";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";

import {
  createArticles,
  getArticlesDetails,
  updateArticles,
} from "../../api/store/actions/saga/articles";
import { useNavigate } from "react-router-dom";

import draftToHtml from "draftjs-to-html";
import { dateToIso } from "../../alogrithms/dateAlgo";
import Loading from "../../reusableComponents/loadingScreen/loading";
import { useParams } from "react-router";
import TextInput from "../../reusableComponents/textInput";
import { getCategories } from "../../api/store/actions/saga/categories";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import TimeInput from "../../reusableComponents/timeInput";

function ArticleEditScreen({ isEdit }) {
  const width = useResponsiveWidth({});
  const { id } = useParams();
  const [articleData, setArticleData] = useState();
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [htmlState, setHtmlState] = useState();
  const [articleDetails, setArticlesDetails] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    if (isEdit) {
      resolveRequest(getArticlesDetails, setArticlesDetails, { id: id });
    }
  }, []);

  const [categoriesData, setCategoriesData] = useState();

  const [checked, setChecked] = useState(false);

  const options = [
    { id: 1, name: " نعم" },
    { id: 0, name: "لا" },
  ];
  useEffect(() => {
    resolveRequest(getCategories, setCategoriesData, "articles");
  }, []);
  const checktime = new Date(articleDetails?.data?.publish_at) < new Date();
  useEffect(() => {
    if (checktime) {
      setChecked(true);
    }
  }, [checktime]);
  const [imageChanged, setImageChanged] = useState(false);
  const submitEdit = async (values) => {
    let body;

    if (!imageChanged) {
      console.log(new Date().toISOString());

      body = {
        title: values?.title,
        content: values?.content,
        category_id: values?.category_id,
        allow_comments: values?.allow_comments,
        publish_at: checked
          ? ""
          : dateToIso(new Date(values?.date + " " + values?.time)),
      };
    } else {
      console.log(new Date().toISOString());
      body = {
        title: values?.title,
        content: values?.content,
        category_id: values?.category_id,
        image: values?.image,
        allow_comments: values?.allow_comments,
        publish_at: checked
          ? ""
          : dateToIso(new Date(values?.date + " " + values?.time)),
      };
    }
    const res = await updateArticles({ body: body, id: id });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const submit = async (values) => {
    const body = {
      title: values?.title,
      content: values?.content,
      category_id: values?.category_id,
      image: values?.image,
      allow_comments: values?.allow_comments,
      publish_at: checked
        ? ""
        : dateToIso(new Date(values?.date + " " + values?.time)),
    };
    const res = await createArticles({ body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const formik = useFormik(
    formikSetup(
      initialValues,
      !isEdit ? undefined : articleDetails?.data,
      validationSchema,
      "createArticleNnews",
      !isEdit ? submit : submitEdit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  const isValid = formikFieldValidation(touched, errors);
  console.log(articleDetails?.data);
  console.log(values);
  const navigate = useNavigate();

  return (
    <>
      {categoriesData && (!isEdit || (isEdit && articleDetails?.data)) ? (
        <div
          style={{
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <SectionLabel content={"تفاصيل المقال"} />
          <form style={{}} onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "55%", padding: 20 }}>
                <p style={{ marginBottom: 10, marginTop: 10 }}>العنوان</p>
                <TextInput
                  value={values?.title}
                  placeholder={"اكتب العنوان"}
                  onChange={handleChange("title")}
                  errorMessage={isValid("title")}
                />
                <p style={{ marginBottom: 10, marginTop: 10 }}>القسم</p>
                <SelectDropdown
                  placeholder={"اختر"}
                  errorMessage={isValid("category_id")}
                  responsiveWidth={{ width }}
                  selectedItem={values.category_id}
                  dataSource={categoriesData?.data}
                  onSelect={(e) => {
                    setFieldValue("category_id", e);
                  }}
                />
              </div>
              <div
                style={{
                  width: "45%",
                  padding: 20,
                }}
              >
                <p style={{ marginBottom: 10, marginTop: 10 }}>صورة الخبر</p>
                <div
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#7ABA4C0D",
                    border: "1px dashed #7ABA4C99",
                    height: "80%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    width={100}
                    height={100}
                    style={{ borderRadius: 500 }}
                    src={
                      isEdit && image != undefined
                        ? image
                        : isEdit
                        ? `${articleDetails?.data?.image_url}`
                        : isEdit && image == undefined
                        ? avatar
                        : !isEdit && image == undefined
                        ? avatar
                        : image
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        color: "#7ABA4C",
                        marginRight: 10,
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        onChange={(e) => {
                          setFieldValue("image", e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));

                          setImageChanged(true);
                        }}
                        style={{ display: "none" }}
                      />
                      استعرض
                    </label>
                  </div>
                </div>
                <p style={{ marginBottom: 10, marginTop: 10, color: "red" }}>
                  {isValid("image")}
                </p>
              </div>
            </div>
            <SectionLabel content={"المقالة"} />
            <MyEditor
              placeholder={"اكتب المقالة"}
              content={
                isEdit ? articleDetails?.data?.content?.trim() ?? "" : ""
              }
              onChange={(e) => {
                setFieldValue("content", e);
              }}
            />
            <p style={{ color: "red" }}>{isValid("content")}</p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  paddingTop: 20,
                }}
              >
                <SectionLabel content={"النشر"} />
                <p style={{ marginBottom: 10, marginTop: 10 }}>تاريخ النشر</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{
                      width: "40%",

                      height: 54,
                      border: 0,
                      borderRadius: 10,
                      backgroundColor: "#FBFBFB",
                      color: "#7ABA4C",
                      paddingRight: 20,
                      paddingLeft: 20,
                    }}
                    onChange={handleChange("date")}
                    value={values["date"]}
                    dir="rtl"
                    type="date"
                    name="date"
                    placeholder="اكتب العنوان"
                  />

                  <TimeInput
                    responsiveWidth={{ ...width }}
                    value={values.time}
                    name={"name"}
                    onChange={handleChange("time")}
                  />
                  {/* <input
                    style={{
                      width: "40%",
                      height: 54,
                      border: 0,
                      borderRadius: 10,
                      backgroundColor: "#FBFBFB",
                      color: "#7ABA4C",
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginRight: 20,
                    }}
                    onChange={handleChange("time")}
                    value={values["time"]}
                    dir="rtl"
                    type="time"
                    name="time"
                    placeholder="اكتب العنوان"
                  /> */}
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      justifyContent: "flex-end",
                      paddingRight: 10,
                    }}
                  >
                    <p style={{ marginRight: 3, marginLeft: 3 }}>الآن</p>
                    <input
                      type="checkbox"
                      name="dateNow"
                      value={checked}
                      checked={checked}
                      onChange={() => {
                        setChecked((prevState) => !prevState);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  paddingTop: 20,
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <SectionLabel content={"التعليقات"} />
                <p style={{ marginBottom: 10, marginTop: 10 }}>
                  الموافقة على نشر التعليقات
                </p>
                <SelectDropdown
                  placeholder={"اختر"}
                  responsiveWidth={{ ...width }}
                  selectedItem={values.allow_comments}
                  errorMessage={isValid("allow_comments")}
                  dataSource={options}
                  onSelect={(e) => {
                    setFieldValue("allow_comments", e);
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                type={"reset"}
                backgroundColor="#F4F4F4"
                textColor="#9F9F9F"
                style={{ marginRight: 10, marginLeft: 10, width: "20%" }}
              >
                إلغاء
              </Button>
              <Button
                type={"submit"}
                style={{ width: "20%", marginRight: 10, marginLeft: 10 }}
              >
                {!isEdit ? "إضافة" : "تعديل"}
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default ArticleEditScreen;
