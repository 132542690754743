import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  createFacility,
  getFacilityDetails,
  updateFacility,
} from "../../../api/store/actions/saga/settings/facilities";
import {
  createSupportType,
  getSupportTypeDetails,
  updateSupportType,
} from "../../../api/store/actions/saga/settings/supportTypes";

import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";

const SupportTypeForm = ({ isEdit }) => {
  const { supportId } = useParams();
  const [supportTypeDetails, setSupportTypeDetails] = useState(null);
  useEffect(() => {
    if (isEdit)
      resolveRequest(getSupportTypeDetails, setSupportTypeDetails, {
        id: supportId,
      });
  }, []);
  const submit = async (data) => {
    if (isEdit) {
      const res = await updateSupportType({ id: supportId, body: data });
      setBackEndErrors(res?.errors, setFieldError);
    } else {
      const res = await createSupportType({ body: data });
      setBackEndErrors(res?.errors, setFieldError);
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? supportTypeDetails?.data : {},
      validationSchema,
      "farming",
      submit
    )
  );
  const { values, handleSubmit, handleChange, touched, errors, setFieldError } =
    formik;
  console.log(errors, values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <div>
      <AppModal
        buttonTitleReject={false}
        onClickReject={false}
        buttonTitle={isEdit ? "تعديل" : "إضافة"}
        modalTitle={isEdit ? "تعديل  نوع تربية" : "إضافة نوع تربية"}
        onClick={handleSubmit}
      >
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={"الاسم"}
          value={values.type}
          defaultValue={values.type}
          name={"type"}
          errorMessage={isValid("type")}
          onChange={handleChange("type")}
        />
      </AppModal>
    </div>
  );
};

export default SupportTypeForm;
