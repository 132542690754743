import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  addCategories,
  categoriesDetails,
  updateCategories,
} from "../../api/store/actions/saga/categories";
import TextInput from "../../reusableComponents/textInput";
import AppModal from "../../screenParts/modal/customModal";

const AddCategory = ({ isEdit }) => {
  const { type, categoryId } = useParams();
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isEdit && categoryId && !category) {
      resolveRequest(categoriesDetails, setCategory, {
        type: type,
        body: { name: categoryName },
        id: parseInt(categoryId),
      });
    }
    console.log(category);
    if (category) setCategoryName(category?.data?.name);
  }, [category]);

  const submit = () => {
    if (categoryName) {
      if (errorMessage) setErrorMessage(null);
      if (categoryName?.length > 255) {
        setErrorMessage("يجب ان لا يكون اكثر من ٢٥٥ حرف");
      } else {
        isEdit
          ? updateCategories({
            type: type,
            body: { name: categoryName },
            id: parseInt(categoryId),
          })
          : addCategories({
            type: type,
            body: { name: categoryName },
          });
      }
    } else {
      if (!errorMessage) setErrorMessage("اسم التصنيف فارغ");
    }
  };

  return (
    <AppModal
      buttonTitle={isEdit ? "تعديل" : "إضافة"}
      modalTitle={!isEdit ? "إضافة تصنيف جديد" : "تعديل تصنيف "}
      onClick={submit}
    >
      <TextInput
        require
        defaultValue={isEdit ? category?.data?.name : ""}
        label={"اسم التصنيف"}
        placeholder={"اكتب اسم التصنيف"}
        value={categoryName}
        errorMessage={errorMessage}
        onChange={(e) => {
          if (errorMessage && e.target.value) {
            setErrorMessage(null);
          }
          setCategoryName(e.target.value);
        }}
      />
    </AppModal>
  );
};

export default AddCategory;
