import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = "/admin/v1/admins";

// export const createInstructor = async ({ body }) => {
//     try {
//       const data = await axiosCall.post(apiUrl, objToFormData(body), {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       return dataFormat(data);
//     } catch (e) {
//       return catchFromat(e.response);
//     }
//   };
export const getUsers = async ({
  params = {},
  pageNumber = 1,
  pageCount = 7,
}) => {
  try {
    let filters = concatFilters(params);
    const data = await axiosCall.get(
      `${apiUrl}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const addUser = async ({ body }) => {
  try {
    const data = await axiosCall.post(`${apiUrl}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getUser = async ({ id }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getAdminInf = async () => {
  try {
    const data = await axiosCall.get(`/admin/v1/admin`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateUser = async ({ id, body }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
//   export const updateInstructor = async ({ body, id }) => {
//     try {
//       const data = await axiosCall.post(`${apiUrl}/${id}`, objToFormData(body), {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       return dataFormat(data);
//     } catch (e) {
//       return catchFromat(e.response);
//     }
//   };
//   export const getInstructorDetails = async ({ id }) => {
//     try {
//       const data = await axiosCall.get(`${apiUrl}/${id}`);
//       return dataFormat(data);
//     } catch (e) {
//       return catchFromat(e.response);
//     }
//   };
//   export const deleteInstructor = async ({ id }) => {
//     try {
//       const data = await axiosCall.delete(`${apiUrl}/${id}`);
//       return dataFormat(data);
//     } catch (e) {
//       return catchFromat(e.response);
//     }
//   };
