import { objToFormData } from "../../../../alogrithms/requests";
import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = "/admin/v1/prices";

export const getPrices = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getFinalPrice = async ({ id }) => {
  try {
    const data = await axiosCall.get(`/admin/v1/prices/final/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updatePrice = async ({ body }) => {
  try {
    const data = await axiosCall.put(`/admin/v1/prices`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateOnePrice = async ({ id, body }) => {
  try {
    const data = await axiosCall.put(`/admin/v1/prices/${id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
