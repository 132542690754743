import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getArticles } from "../../api/store/actions/saga/articles";
import { getCourses } from "../../api/store/actions/saga/courses";
import { getAllFarmerRatings } from "../../api/store/actions/saga/farmer";
import { getAllSales } from "../../api/store/actions/saga/sales_transactions";
import {
  getAllTraderRatings,
  getAllTraders,
} from "../../api/store/actions/saga/traders";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";

const FarmerRatings = ({ searchKey }) => {
  const { farmer_id } = useParams();

  const [farmerData, setFarmersData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    // setFarmersData(null);
    resolveRequest(getAllFarmerRatings, setFarmersData, {
      user_id: farmer_id,
      pageNumber: currentPage,
      params: { rater_name: searchKey },
    });
  }, [currentPage, searchKey]);
  useEffect(() => {
    //setFarmersData(null);
  }, [searchKey]);

  console.log("FarmersData ratings", farmerData);
  return (
    <>
      {farmerData ? (
        <ListView
          objName={"traderRating"}
          links={{
            // edit: "editNews",
            // delete: "",
            show: "farmerRatingDetails",

            query: { show: "", delete: "", edit: "" },
          }}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={farmerData}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FarmerRatings;
