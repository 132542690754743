import React from "react";

function LogoLogin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="280.841"
      height="76.062"
      viewBox="0 0 280.841 76.062"
    >
      <g data-name="Group 7" transform="translate(-610 -445.208)">
        <g
          fill="#143727"
          data-name="Group 4"
          transform="translate(610 472.522)"
        >
          <g data-name="Group 2">
            <g data-name="Group 1" transform="translate(0 4.389)">
              <path
                d="M637.033 530.626a3.136 3.136 0 002.288-.98 3.057 3.057 0 00.934-2.241 3.022 3.022 0 00-.958-2.241 3.135 3.135 0 00-2.264-.934 3.189 3.189 0 00-3.175 3.175 3.108 3.108 0 00.934 2.288 3.054 3.054 0 002.241.933z"
                data-name="Path 1"
                transform="translate(-624.286 -524.229)"
              ></path>
              <path
                d="M725.433 609.3a2.307 2.307 0 00-1.728.724 2.54 2.54 0 000 3.5 2.311 2.311 0 001.728.723 2.508 2.508 0 001.774-.7 2.426 2.426 0 00.747-1.774 2.347 2.347 0 00-.747-1.751 2.462 2.462 0 00-1.774-.722z"
                data-name="Path 2"
                transform="translate(-677.667 -575.172)"
              ></path>
              <path
                d="M710.543 611.779a2.347 2.347 0 00-.747-1.751 2.518 2.518 0 00-1.821-.724 2.222 2.222 0 00-1.681.747 2.273 2.273 0 00-.748 1.728 2.385 2.385 0 00.724 1.751 2.332 2.332 0 001.7.723 2.523 2.523 0 001.821-.723 2.351 2.351 0 00.752-1.751z"
                data-name="Path 3"
                transform="translate(-667.213 -575.172)"
              ></path>
              <path
                d="M717.1 623.619a2.245 2.245 0 102.241 2.241 2.125 2.125 0 00-.677-1.588 2.184 2.184 0 00-1.564-.653z"
                data-name="Path 4"
                transform="translate(-672.789 -583.744)"
              ></path>
              <path
                d="M667.9 560.864a14.5 14.5 0 00-4.389-10.786 14.959 14.959 0 00-10.832-4.2h-9.992v5.416h9.713a9.992 9.992 0 017.284 2.638 9.7 9.7 0 012.755 7.167l.047 2.708h-23.77q-3.222 0-3.222-4.109v-13.822h-5.463v20.4a8.463 8.463 0 01-2.077 5.953 7.117 7.117 0 01-10.413 0 8.459 8.459 0 01-2.077-5.953v-15.731H610v15.736a13.571 13.571 0 003.689 9.782 12.559 12.559 0 0017.6.49 13.23 13.23 0 004.109-8.732 4.073 4.073 0 003.316 1.4h29.229l-.047-5.416z"
                data-name="Path 5"
                transform="translate(-610 -537.191)"
              ></path>
            </g>
            <path
              d="M0 0H5.463V36.42H0z"
              data-name="Rectangle 1"
              transform="translate(62.483)"
            ></path>
            <path
              d="M799.211 545.876a11.406 11.406 0 00-6.3 1.868l2.195 4.763a7.851 7.851 0 014.109-1.214 5.758 5.758 0 014.482 1.915 7.243 7.243 0 011.728 5.043v5.557h-14.759v5.416h20.218V558.25a12.344 12.344 0 00-3.338-8.871 11.042 11.042 0 00-8.335-3.503z"
              data-name="Path 6"
              transform="translate(-718.182 -532.802)"
            ></path>
          </g>
          <g data-name="Group 3" transform="translate(101.777 4.296)">
            <path
              d="M900.4 530.858a3 3 0 002.171-.887 3.087 3.087 0 10-4.366-4.366 3.07 3.07 0 002.194 5.253z"
              data-name="Path 7"
              transform="translate(-883.821 -524.415)"
            ></path>
            <path
              d="M879.681 530.858a3.061 3.061 0 002.218-.887 3.072 3.072 0 000-4.366 3.02 3.02 0 00-2.218-.91 3.087 3.087 0 00-2.171 5.276 3 3 0 002.171.887z"
              data-name="Path 8"
              transform="translate(-871.416 -524.415)"
            ></path>
            <path
              d="M1033.91 530.44a3.19 3.19 0 10-2.288-.934 3.111 3.111 0 002.288.934z"
              data-name="Path 9"
              transform="translate(-963.685 -523.996)"
            ></path>
            <path
              d="M963.252 549.308a11.673 11.673 0 00-19.915 8.288 7.328 7.328 0 003.082 6.21h-6.536a2.923 2.923 0 01-2.474-1.1 4.839 4.839 0 01-.841-3.012v-13.818h-5.416V559.7a4.841 4.841 0 01-.841 3.012 2.875 2.875 0 01-2.428 1.1h-1.774q-3.036 0-3.035-4.016v-1.634a12.57 12.57 0 00-3.151-8.848 10.558 10.558 0 00-8.148-3.432q-5.977 0-11.86 6.21v-6.21H894.5v17.93h-4.669q-3.269 0-3.269-4.109v-13.827h-11.208a11.2 11.2 0 00-8.218 3.409 11.332 11.332 0 00-3.455 8.311 11.638 11.638 0 0011.673 11.626 9.354 9.354 0 008.078-3.969 6.42 6.42 0 006.4 3.969h24.047a7.4 7.4 0 006.4-3.222 6.334 6.334 0 005.836 3.222h1.774a6.871 6.871 0 005.977-2.989 6.915 6.915 0 006.023 2.989h15.128a11.655 11.655 0 008.241-19.914zm-82.152 8.241a6.32 6.32 0 01-1.634 4.529 5.417 5.417 0 01-4.109 1.728 5.944 5.944 0 01-4.389-1.845 6.257 6.257 0 010-8.824 5.945 5.945 0 014.389-1.845h5.743zm36.56 1.821a4.633 4.633 0 01-1.074 3.292 3.556 3.556 0 01-2.708 1.144h-13.964v-5.323q6.3-7.19 11.253-7.191a6.128 6.128 0 014.763 1.891 7.225 7.225 0 011.727 5.066zm41.742 2.591a6.222 6.222 0 01-10.6-4.412 6.059 6.059 0 011.821-4.412 6.144 6.144 0 018.778 0 6.256 6.256 0 010 8.824z"
              data-name="Path 10"
              transform="translate(-863.681 -537.098)"
            ></path>
          </g>
        </g>
        <g data-name="Group 6" transform="translate(814.781 445.208)">
          <g data-name="Group 5">
            <path
              fill="#143727"
              d="M1192.672 477.578a29.367 29.367 0 00-13.747-12.38v-19.99H1176a20.457 20.457 0 00-14.627 6.146v-6.146H1140.9a20.264 20.264 0 00-2.926.209 20.486 20.486 0 00-17.343 17.344 20.214 20.214 0 00-.209 2.926v2.926h17.553v52.657h29.253a29.261 29.261 0 0025.45-43.691zm-19.6-26.227v11.409h-11.406a14.672 14.672 0 0111.408-11.409zm-46.514 11.409a14.655 14.655 0 0114.335-11.7h14.626v11.7zm40.665 52.657h-5.852v-11.7h5.849a11.7 11.7 0 100-23.4h-11.7v35.1h-11.7v-46.805h23.4a23.4 23.4 0 010 46.805zm-5.852-17.552v-11.7h5.852a5.85 5.85 0 010 11.7z"
              data-name="Path 11"
              transform="translate(-1120.417 -445.208)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LogoLogin;
