import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  createFacility,
  getFacilityDetails,
  updateFacility,
} from "../../../api/store/actions/saga/settings/facilities";

import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";

const FacilityTypeForm = ({ isEdit }) => {
  const { facilityId } = useParams();
  const [facilityDetails, setFacilityDetails] = useState(null);
  useEffect(() => {
    if (isEdit)
      resolveRequest(getFacilityDetails, setFacilityDetails, {
        id: facilityId,
      });
  }, []);
  const submit = async (data) => {
    if (isEdit) {
      const res = await updateFacility({ id: facilityId, body: data });
      setBackEndErrors(res?.errors, setFieldError);
    } else {
      const res = await createFacility({ body: data });
      setBackEndErrors(res?.errors, setFieldError);
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? facilityDetails?.data : {},
      validationSchema,
      "facility",
      submit
    )
  );
  const { values, handleSubmit, handleChange, touched, errors, setFieldError } =
    formik;
  console.log(errors, values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <div>
      <AppModal
        buttonTitleReject={false}
        onClickReject={false}
        buttonTitle={isEdit ? "تعديل" : "إضافة"}
        modalTitle={isEdit ? "تعديل  مرفق " : "إضافة مرفق"}
        onClick={handleSubmit}
      >
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={"الاسم"}
          value={values.facility}
          defaultValue={values.facility}
          name={"facility"}
          errorMessage={isValid("facility")}
          onChange={handleChange("facility")}
        />
      </AppModal>
    </div>
  );
};

export default FacilityTypeForm;
