import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { dateFormat } from "../../../alogrithms/dateAlgo";
import { useResponsiveWidth } from "../../../alogrithms/responsive";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  addReplyContact,
  getContactDetails,
} from "../../../api/store/actions/saga/support";
import Loading from "../../../reusableComponents/loadingScreen/loading";
import SectionLabel from "../../../reusableComponents/sectionLabel";
import TextArea from "../../../reusableComponents/textArea";
import TitleAndBack from "../../../screenParts/listingViews/titleAndBack";
import Button from "../../../reusableComponents/button";
import { useFormik } from "formik";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  validationSchema,
} from "../../../alogrithms/formikValidation";

const ContactDetails = () => {
  const width = useResponsiveWidth({ desktop: 50, tablet: 100 });
  const [data, setData] = useState(null);
  const { contactId } = useParams();
  const submit = (data) => {
    addReplyContact({ id: contactId, body: data });
  };
  useEffect(() => {
    resolveRequest(getContactDetails, setData, { id: contactId });
  }, []);
  console.log(data);
  const formik = useFormik(
    formikSetup(initialValues, {}, validationSchema, "contactreply", submit)
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
  } = formik;
  console.log(errors, values);
  const isValid = formikFieldValidation(touched, errors);
  return data ? (
    <>
      <TitleAndBack title={"تفاصيل الرسالة"} />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ ...width }}>
          <SectionLabel content={"تفاصيل المرسل"} />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: "40%" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  marginBottom: 10,
                  flexWrap: "wrap",
                }}
              >
                <p
                  style={{
                    fontSize: 14,

                    color: "#7ABA4C",
                    fontFamily: "boldKufi",
                    marginLeft: 15,
                  }}
                >
                  التاريخ
                </p>
                <p>{dateFormat(data?.data?.created_at)}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  marginBottom: 10,
                  flexWrap: "wrap",
                }}
              >
                <p
                  style={{
                    fontSize: 14,

                    color: "#7ABA4C",
                    fontFamily: "boldKufi",
                    marginLeft: 15,
                  }}
                >
                  المرسل
                </p>
                <p>{data?.data?.sender_name}</p>
              </div>
            </div>
            <div style={{ width: "60%" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  marginBottom: 10,
                  flexWrap: "wrap",
                }}
              >
                <p
                  style={{
                    fontSize: 14,

                    color: "#7ABA4C",
                    fontFamily: "boldKufi",
                    marginLeft: 15,
                  }}
                >
                  الحالة
                </p>
                <p>
                  {data?.data?.has_reply === true ? "تم الرد" : "لم يتم الرد"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  marginBottom: 10,
                  flexWrap: "wrap",
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    color: "#7ABA4C",
                    fontFamily: "boldKufi",
                    marginLeft: 5,
                  }}
                >
                  البريد الالكتروني
                </p>
                <p>{data?.data?.sender_email}</p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ ...width }}>
          <SectionLabel content={" الرسالة"} />
          <div
            style={{
              border: "#C2C2C2 1px solid",
              padding: 15,
              borderRadius: 10,
            }}
          >
            <p>{data?.data?.message}</p>
          </div>
        </div>
      </div>
      <div>
        <SectionLabel content={"الرد"} />
        {data?.data?.has_reply ? (
          <div
            style={{
              border: "#C2C2C2 1px solid",
              padding: 15,
              borderRadius: 10,
            }}
          >
            <p>{data?.data?.reply}</p>
          </div>
        ) : (
          <>
            <TextArea
              name={"reply"}
              errorMessage={isValid("reply")}
              onChange={handleChange("reply")}
              value={values.reply}
            />
            <Button type={"submit"} onClick={handleSubmit} width={"20%"}>
              اضافة
            </Button>
          </>
        )}
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default ContactDetails;
