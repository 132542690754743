import SectionLabel from "../../reusableComponents/sectionLabel";
import { MyEditor } from "../../reusableComponents/editor";
import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  createCourses,
  getAllInstructors,
  getCoursesDetails,
  updateCourses,
} from "../../api/store/actions/saga/courses";

import avatar from "../../assets/avatar.jpg";
import Loading from "../../reusableComponents/loadingScreen/loading";
import { useParams } from "react-router";
import { getCategories } from "../../api/store/actions/saga/categories";
import TextInput from "../../reusableComponents/textInput";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { useNavigate } from "react-router-dom";

function CreateCourse({ isEdit = false }) {
  const { id } = useParams();

  const [coursesDetails, setCoursesDetails] = useState();
  const [image, setImage] = useState();
  const [image2, setImage2] = useState();
  const navigate = useNavigate();
  const [coursesData, setCoursesData] = useState();
  useEffect(() => {
    if (isEdit) {
      resolveRequest(getCoursesDetails, setCoursesDetails, { id: id });
    }
  }, []);
  console.log("");

  const [categoriesData, setCategoriesData] = useState();

  useEffect(() => {
    resolveRequest(getCategories, setCategoriesData, "courses");
  }, []);
  const [imageChanged, setImageChanged] = useState(false);
  const [imageChanged2, setImageChanged2] = useState(false);
  const width = useResponsiveWidth({});

  const submitEdit = (values) => {
    let body;
    if (imageChanged && imageChanged2) {
      body = {
        title: values?.title,
        description: values?.description,
        instructor_id: values?.instructor_id,
        category_id: values?.category_id,
        sponsor_logo: values?.sponsor_logo,
        image: values?.image,
      };
    } else if (imageChanged && !imageChanged2) {
      body = {
        title: values?.title,
        description: values?.description,

        instructor_id: values?.instructor_id,
        category_id: values?.category_id,
        // sponsor_logo: values?.sponsor_logo,
        image: values?.image,
      };
    } else if (!imageChanged && imageChanged2) {
      body = {
        title: values?.title,
        description: values?.description,

        instructor_id: values?.instructor_id,
        category_id: values?.category_id,
        sponsor_logo: values?.sponsor_logo,
        //image: values?.image,
      };
    } else {
      body = {
        title: values?.title,
        description: values?.description,

        instructor_id: values?.instructor_id,
        category_id: values?.category_id,
        //sponsor_logo: values?.sponsor_logo,
        //image: values?.image,
      };
    }
    updateCourses({ body: body, id: id });
    console.log("in submit edit");
  };
  const [instructorsData, setInstructorsData] = useState();
  useEffect(() => {
    resolveRequest(getAllInstructors, setInstructorsData);
  }, []);

  const submit = (values) => {
    const body = {
      title: values?.title,
      description: values?.description,

      instructor_id: values?.instructor_id,
      category_id: values?.category_id,
      sponsor_logo: values?.sponsor_logo,
      image: values?.image,
    };
    createCourses({ body: body });
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      !isEdit ? undefined : coursesDetails?.data?.data,
      validationSchema,
      "createCourse",
      !isEdit ? submit : submitEdit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
  } = formik;
  console.log(values);
  const isValid = formikFieldValidation(touched, errors);
  console.log(coursesDetails);
  return (
    <>
      {categoriesData && instructorsData ? (
        <div
          style={{
            backgroundColor: "white",
            paddingBottom: 20,
            borderRadius: 20,
          }}
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "50%", padding: 10 }}>
                <SectionLabel content={"تفاصيل الكورس"} />
                <TextInput
                  label={'العنوان'}
                  require
                  value={values?.title}
                  placeholder={"اكتب العنوان"}
                  onChange={handleChange("title")}
                  errorMessage={isValid("title")}
                />

                <p style={{ marginBottom: 10, marginTop: 10, marginTop: 10 }}>
                  القسم *
                </p>
                <SelectDropdown
                  placeholder={"اختر"}
                  errorMessage={isValid("category_id")}
                  responsiveWidth={{ width }}
                  schema={{ name: "name", id: "id" }}
                  selectedItem={values?.category_id}
                  dataSource={categoriesData?.data}
                  onSelect={(e) => {
                    setFieldValue("category_id", e);
                  }}
                />

                <p style={{ marginBottom: 10, marginTop: 10 }}>صورة العرض *</p>
                <div
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#7ABA4C0D",
                    border: "1px dashed #7ABA4C99",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                >
                  <img
                    width={100}
                    height={100}
                    style={{ borderRadius: 500 }}
                    src={
                      isEdit && image != undefined
                        ? image
                        : isEdit
                          ? `${coursesDetails?.data?.data?.image_url}`
                          : isEdit && image == undefined
                            ? avatar
                            : !isEdit && image == undefined
                              ? avatar
                              : image
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        color: "#7ABA4C",
                        marginRight: 10,
                      }}
                    >
                      <input
                        onChange={(e) => {
                          setFieldValue("image", e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));
                          setImageChanged(true);
                        }}
                        type="file"
                        name="file"
                        style={{ display: "none" }}
                      />
                      استعرض
                    </label>
                  </div>
                </div>
                <p style={{ color: "red" }}>{isValid("image")}</p>
              </div>
              <div
                style={{
                  width: "50%",
                  padding: 10,
                }}
              >
                <SectionLabel content={"تفاصيل المدرب"} />
                <p style={{ marginBottom: 10, marginTop: 10 }}> اسم المدرب *</p>
                <SelectDropdown
                  placeholder={"اختر"}
                  errorMessage={isValid("instructor_id")}
                  responsiveWidth={{ width }}
                  selectedItem={values.instructor_id}
                  dataSource={instructorsData?.data?.data}
                  onSelect={(e) => {
                    setFieldValue("instructor_id", e);
                  }}
                />
                {/*    <select
                  name="instructor_id"
                  placeholder="اختر"
                  onChange={handleChange("instructor_id")}
                  value={values["instructor_id"]}
                  dir="rtl"
                  style={{
                    width: "100%",
                    height: 54,
                    border: 0,
                    borderRadius: 10,
                    backgroundColor: "#FBFBFB",

                    color: "black",
                    paddingRight: 20,
                  }}
                >
                  {instructorsData?.data?.data?.map((item) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
 */}
                <p style={{ marginBottom: 10, marginTop: 10 }}>شعار الراعى *</p>

                <div
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#7ABA4C0D",
                    border: "1px dashed #7ABA4C99",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <img
                    width={100}
                    height={100}
                    style={{ borderRadius: "50%" }}
                    src={
                      isEdit && image2 != undefined
                        ? image2
                        : isEdit
                          ? `${coursesDetails?.data?.data?.sponsor_logo}`
                          : isEdit && image2 == undefined
                            ? avatar
                            : !isEdit && image2 == undefined
                              ? avatar
                              : image2
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        color: "#7ABA4C",
                        marginRight: 10,
                      }}
                    >
                      <input
                        onChange={(e) => {
                          setFieldValue("sponsor_logo", e.target.files[0]);
                          setImage2(URL.createObjectURL(e.target.files[0]));
                          setImageChanged2(true);
                        }}
                        type="file"
                        name="file"
                        style={{ display: "none" }}
                      />
                      استعرض
                    </label>
                  </div>
                </div>
                <p style={{ color: "red" }}>{isValid("sponsor_logo")}</p>
              </div>
            </div>
            <SectionLabel require content={"وصف الكورس"} />
            <MyEditor
              placeholder={"وصف الكورس"}
              content={isEdit ? coursesDetails?.data?.data?.description : ""}
              onChange={(e) => {
                setFieldValue("description", e);
              }}
            />
            <p style={{ color: "red" }}>{isValid("description")}</p>

            <div style={{ display: "flex", marginTop: 20 }}>
              <Button type={"submit"} style={{ width: "20%" }}>
                {!isEdit ? "إضافة" : "تعديل"}
              </Button>

              <Button
                onClick={() => {
                  navigate(-1);
                }}
                //  type={"reset"}
                backgroundColor="#F4F4F4"
                textColor="#9F9F9F"
                style={{ width: "20%", marginRight: 20 }}
              >
                إلغاء
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default CreateCourse;
