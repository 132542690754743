import { objToFormData } from "../../../../../alogrithms/requests";
import axiosCall from "../../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/settings/farming/types`;
export const getFarmingTypes = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const getSFarmingTypeDetails = async ({ id }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const deleteFarmingType = async ({ id }) => {
  try {
    const data = await axiosCall.delete(`${apiUrl}/${id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const updateFarmingType = async ({ id, body }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const createFarmingType = async ({ body }) => {
  try {
    const data = await axiosCall.post(`${apiUrl}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
