import { settingTypes } from "../../../utlity/actionTypes";

const initialState = {
    data: {},
    loading: false,
    error: null,
};
export default function settings(state = initialState, action) {
    switch (action.type) {
        case settingTypes.SETTING_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case settingTypes.SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                setting: action.setting,
            };
        case settingTypes.SETTING_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
                setting: {},
            };

        default:
            return state;
    }
}
