import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getArticles } from "../../api/store/actions/saga/articles";
import { getCategories } from "../../api/store/actions/saga/categories";
import { getCourses } from "../../api/store/actions/saga/courses";
import Loading from "../../reusableComponents/loadingScreen/loading";
import ListView from "../../screenParts/listingViews";
import ListHeader from "../../screenParts/listingViews/listHeader";
import Categories from "../categories";
import InstructorsList from "../instructor";

const CoursesList = () => {
  const activeState = useSelector(state => state?.reload?.active)

  const [searchKey, setSearchKey] = useState("");
  const [courseData, setCourseData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const [active, setActive] = useState(activeState ?? 1);
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    resolveRequest(getCategories, setCategories, "courses");
  }, []);
  useEffect(() => {
    if (active == 1) {
      setCourseData(null);
      resolveRequest(getCourses, setCourseData, {
        pageNumber: currentPage,
        params: { title: searchKey, "category.id": filterKey },
      });
    }
  }, [currentPage, searchKey, filterKey]);
  const onChange = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSearchKey(value);
  };
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
  };
  return (
    <>
      {categories ? (
        <ListHeader
          onChange={onChange}
          onSelect={onSelect}
          hasFilter={active === 1}
          hasSearch={active === 1 || active === 3}
          dataSource={
            categories ? [{ name: "الكل", id: null }, ...categories?.data] : []
          }
          titleOne={"الكورسات"}
          titleTwo={"التصنيفات"}
          addLink={
            active == 1
              ? "addCourse"
              : active == 2
                ? `addCategory/${"courses"}`
                : "addInstructor"
          }
          pages={[
            { title: "الكورسات", id: 1 },
            { title: "التصنيفات", id: 2 },
            { title: "المدربين", id: 3 },
          ]}
          linkType={active == 1 || active == 3 ? undefined : "modal"}
          active={active}
          setActive={(x) => {
            setSearchKey("");
            setActive(x);
          }}
          searchKey={searchKey}
        />
      ) : (
        <></>
      )}
      {active == 1 ? (
        courseData ? (
          <ListView
            objName={"courses"}
            links={{
              edit: "editCourse",
              delete: "",
              show: `lectures`,
              query: {
                show: { key: "title", prop: "title" },
                delete: "",
                edit: "",
              },
            }}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={courseData}
          ></ListView>
        ) : (
          <Loading />
        )
      ) : active == 2 ? (
        <Categories type={"courses"} />
      ) : (
        <InstructorsList
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          active={active}
        />
      )}
    </>
  );
};

export default CoursesList;
