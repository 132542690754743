import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { arToEn } from "../../../alogrithms";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  createBreed,
  getBreedDetails,
  updateBreed,
} from "../../../api/store/actions/saga/settings/breeds";

import {
  createSupportType,
  getSupportTypeDetails,
  updateSupportType,
} from "../../../api/store/actions/saga/settings/supportTypes";

import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";

const BreedsForm = ({ isEdit }) => {
  const { dajinId, breedId } = useParams();
  const [breedDetails, setBreedDetails] = useState(null);
  useEffect(() => {
    if (isEdit)
      resolveRequest(getBreedDetails, setBreedDetails, {
        id: breedId,
        dajinId: dajinId,
      });
  }, []);
  const submit = async (data) => {
    data.poultry_farming_average = arToEn(data?.poultry_farming_average);
    data.poultry_farming_maximum = arToEn(data?.poultry_farming_maximum);

    if (isEdit) {
      const res = await updateBreed({
        dajinId: dajinId,
        id: breedId,
        body: data,
      });
      setBackEndErrors(res?.errors, setFieldError);
    } else {
      const res = await createBreed({ dajinId: dajinId, body: data });
      setBackEndErrors(res?.errors, setFieldError);
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? breedDetails?.data : {},
      validationSchema,
      "breed",
      submit
    )
  );
  const { values, handleSubmit, handleChange, touched, errors, setFieldError } =
    formik;
  console.log(errors, values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <div>
      <AppModal
        buttonTitleReject={false}
        onClickReject={false}
        buttonTitle={isEdit ? "تعديل" : "إضافة"}
        modalTitle={isEdit ? "تعديل سلالة" : "إضافة سلالة"}
        onClick={handleSubmit}
      >
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={"الاسم"}
          value={values.breed_type}
          defaultValue={values.breed_type}
          name={"breed_type"}
          errorMessage={isValid("breed_type")}
          onChange={handleChange("breed_type")}
        />
        <div style={{ marginTop: 10 }}>
          <p></p>
          <TextInput
            label={'متوسط مدة التربية'}
            require
            placeholder={"0000"}
            onChange={handleChange("poultry_farming_average")}
            errorMessage={isValid("poultry_farming_average")}
            defaultValue={values["poultry_farming_average"]}
            value={values["poultry_farming_average"]}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <TextInput
            label={'أقصى مدة للتربية'}
            require
            placeholder={"0000"}
            onChange={handleChange("poultry_farming_maximum")}
            errorMessage={isValid("poultry_farming_maximum")}
            defaultValue={values["poultry_farming_maximum"]}
            value={values["poultry_farming_maximum"]}
          />
        </div>
      </AppModal>
    </div>
  );
};

export default BreedsForm;
