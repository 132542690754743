import React from "react";

function StarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.547"
      height="10.547"
      viewBox="0 0 21.547 20.547"
    >
      <path
        fill="#f8c848"
        d="M50.056 23.114l6.979-.647 2.772-6.438a.559.559 0 011.027 0l2.772 6.438 6.979.647a.559.559 0 01.318.977l-5.266 4.626 1.541 6.838a.559.559 0 01-.831.6l-6.026-3.575-6.027 3.579a.559.559 0 01-.831-.6L55 28.717l-5.266-4.626a.559.559 0 01.322-.977z"
        data-name="Path 78"
        transform="translate(-49.548 -15.692)"
      ></path>
    </svg>
  );
}

export default StarIcon;
