import SectionLabel from "../../reusableComponents/sectionLabel";
import { MyEditor } from "../../reusableComponents/editor";
import { useEffect, useState } from "react";
import Button from "../../reusableComponents/button";
import { useFormik } from "formik";
import avatar from "../../assets/avatar.jpg";
import { useNavigate } from "react-router-dom";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { resolveRequest } from "../../api/middlewares/resolverRequests";

import { useParams } from "react-router";
import TextInput from "../../reusableComponents/textInput";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import MultiSelectDropdown from "../../reusableComponents/dropdown/dropdownMultiSelect";
import { getAllSettings } from "../../api/store/actions/saga/settings";

import {
  addFarm,
  getFarmDetails,
  updateFarm,
} from "../../api/store/actions/saga/farms";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import Loading from "../../reusableComponents/loadingScreen/loading";
import { useTablet } from "../salesTransactions/salesDetails";
import { arToEn } from "../../alogrithms";
import { useSelector } from "react-redux";

function AddFarmScreen({ isEdit }) {
  const navigator = useNavigate();
  const width = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const { farm_id, farmer_id } = useParams();
  //const [articleData, setArticleData] = useState();
  const [farmDetails, setFarmDetails] = useState();
  const [image, setImage] = useState();
  const settingsData = useSelector((state) => state?.settings?.setting);
  useEffect(() => {
    if (isEdit) {
      resolveRequest(getFarmDetails, setFarmDetails, {
        farm_id: farm_id,
      });
    }
  }, []);

  console.log("farmDetails", farmDetails);

  console.log("settings,", settingsData);
  const [imageChanged, setImageChanged] = useState(false);
  const submitEdit = async (values) => {
    let body;

    if (!imageChanged) {
      body = {
        total_count: arToEn(values?.total_count),
        name: values?.name,
        dajin_breed_id: values?.dajin_breed_id,
        /*  poultry_farming_expected_period: arToEn(
          values?.poultry_farming_expected_period
        ), */
        //village: values?.village,
        full_address: values?.full_address,

        ventilation_type_id: values?.ventilation_type_id,

        facilities: values?.facilities,
        rented: values?.rented,

        farming_type_id: values?.farming_type_id,
        farms_capacity_id: values?.farms_capacity_id,
        license_number: arToEn(values?.license_number),
        licensed: values?.licensed == true ? 1 : 0,

        rental_start_date: values?.rental_start_date,
        rental_end_date: values?.rental_end_date,
      };
    } else {
      body = {
        total_count: arToEn(values?.total_count),

        name: values?.name,
        dajin_breed_id: values?.dajin_breed_id,
        /*  poultry_farming_expected_period: arToEn(
          values?.poultry_farming_expected_period
        ), */
        full_address: values?.full_address,
        photo: values?.photo,
        rented: values?.rented,
        //village: values?.village,

        ventilation_type_id: values?.ventilation_type_id,

        facilities: values?.facilities,

        farming_type_id: values?.farming_type_id,
        farms_capacity_id: values?.farms_capacity_id,
        license_number: arToEn(values?.license_number),
        licensed: values?.licensed == true ? 1 : 0,

        rental_start_date: values?.rental_start_date,
        rental_end_date: values?.rental_end_date,
      };
    }
    if (
      farmDetails?.data?.data?.farm?.status?.id != 4 &&
      farmDetails?.data?.data?.farm?.status?.id != 3
    ) {
      delete body?.total_count;
    }
    if (values?.name == farmDetails?.data?.data?.farm?.name) {
      delete body?.name;
    }
    if (values?.full_address?.length == 0) {
      delete body?.full_address;
    }
    if (values?.photo?.length == 0) {
      delete body?.photo;
    }
    if (values?.rental_start_date?.length == 0) {
      delete body?.rental_start_date;
    }
    if (values?.rental_end_date?.length == 0) {
      delete body?.rental_end_date;
    }
    /*   if (values?.poultry_farming_expected_period?.length == 0) {
      delete body?.poultry_farming_expected_period;
    } */
    if (values?.ventilation_type_id?.length == 0) {
      delete body?.ventilation_type_id;
    }
    if (values?.dajin_breed_id?.length == 0) {
      delete body?.dajin_breed_id;
    }
    if (values["rented"] == 0) {
      delete body?.rental_end_date;
      delete body?.rental_start_date;
    }
    if (values["licensed"] == 0) {
      delete body?.license_number;
    }
    if (values["licensed"]?.length == 0) {
      delete body?.license_number;
      delete body?.licensed;
    }
    let res = await updateFarm({ body: body, farm_id: farm_id });
    setBackEndErrors(res?.errors, setFieldError);
  };

  const submit = async (values) => {
    console.log("at submit");
    const body = {
      name: values?.name,
      area: arToEn(values?.area),
      dajin_breed_id: values?.dajin_breed_id,
      /*    poultry_farming_expected_period: arToEn(
        values?.poultry_farming_expected_period
      ), */
      village: values?.village,
      full_address: values?.full_address,
      photo: values?.photo,

      ventilation_type_id: values?.ventilation_type_id,
      city_id: values?.city_id,
      //governorate_id: values?.governorate_id,
      rented: values?.rented,
      facilities: values?.facilities,

      farming_type_id: values?.farming_type_id,
      farms_capacity_id: values?.farms_capacity_id,
      license_number: arToEn(values?.license_number),
      licensed: values?.licensed,
      user_id: farmer_id,

      rental_start_date: values?.rental_start_date,
      rental_end_date: values?.rental_end_date,
    };
    if (values?.full_address?.length == 0) {
      delete body?.full_address;
    }
    if (values?.photo?.length == 0) {
      delete body?.photo;
    }
    if (values?.dajin_breed_id?.length == 0) {
      delete body?.dajin_breed_id;
    }
    /*  if (values?.poultry_farming_expected_period?.length == 0) {
      delete body?.poultry_farming_expected_period;
    } */
    if (values?.ventilation_type_id?.length == 0) {
      delete body?.ventilation_type_id;
    }
    if (values?.rental_start_date?.length == 0) {
      delete body?.rental_start_date;
    }
    if (values?.rental_end_date?.length == 0) {
      delete body?.rental_end_date;
    }
    if (values["rented"] == 0) {
      delete body?.rental_end_date;
      delete body?.rental_start_date;
    }
    if (values["licensed"] == 0) {
      delete body?.license_number;
    }
    if (values["licensed"]?.length == 0) {
      delete body?.license_number;
      delete body?.licensed;
    }
    let res = await addFarm({ body: body });
    setBackEndErrors(res?.errors, setFieldError);
  };

  // console.log("farmerDetails", farmerDetails);
  const formik = useFormik(
    formikSetup(
      initialValues,
      !isEdit ? undefined : farmDetails?.data?.data,

      validationSchema,
      "addFarm",
      !isEdit ? submit : submitEdit
    )
  );
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldError,
  } = formik;
  console.log(
    "settingsData?.data?.data?.farms?.facilities",
    settingsData?.data?.data?.farms?.facilities,
    values?.facilities
  );
  const isValid = formikFieldValidation(touched, errors);

  const halfWidth = useResponsiveWidth({
    desktop: 50,
    tablet: 100,
    mobile: 100,
    defaults: 100,
  });
  const isTablet = useTablet();
  console.log("values?.licensed", values);
  return !isEdit || (isEdit && farmDetails) ? (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: 10,
        }}
      >
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "column" : "row",
            }}
          >
            <div style={{ padding: 20, ...halfWidth }}>
              <SectionLabel content={"تفاصيل العنبر"} />

              <div style={{ marginTop: 10 }}>
                <p>الاسم *</p>
                <TextInput
                  placeholder={"اكتب اسم العنبر"}
                  onChange={handleChange("name")}
                  errorMessage={isValid("name")}
                  defaultValue={values["name"]}
                  value={values["name"]}
                />
              </div>
              {!isEdit && (
                <div style={{ marginTop: 10 }}>
                  <p>مساحة العنبر *</p>
                  <TextInput
                    placeholder={"اكتب مساحة العنبر"}
                    onChange={handleChange("area")}
                    errorMessage={isValid("area")}
                    defaultValue={values["area"]}
                    value={values["area"]}
                  />
                </div>
              )}
              <div style={{ marginTop: 10 }}>
                <p>نوع الداجن </p>
                <SelectDropdown
                  dataSource={settingsData?.data?.data?.farms?.dajin_types}
                  errorMessage={isValid("dajin_id")}
                  selectedItem={values["dajin_id"]}
                  onSelect={(e) => {
                    setFieldValue("dajin_id", e);
                  }}
                  schema={{ id: "id", name: "type" }}
                  placeholder={"اختر"}
                  responsiveWidth={{ ...width }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <p>نوع السلالة </p>
                <SelectDropdown
                  responsiveWidth={{ ...width }}
                  placeholder={"اختر"}
                  dataSource={
                    values?.dajin_id
                      ? settingsData?.data?.data?.farms?.dajin_breed_types
                      : []
                  }
                  errorMessage={isValid("dajin_breed_id")}
                  onSelect={(e) => {
                    console.log(e);
                    setFieldValue("dajin_breed_id", e);
                  }}
                  schema={{ name: "breed_type", id: "id" }}
                  selectedItem={values["dajin_breed_id"]}
                  dependId={values["dajin_id"]}
                  //responsiveWidth={"100%"}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <p>نوع التربية *</p>
                <SelectDropdown
                  responsiveWidth={{ ...width }}
                  placeholder={"اختر"}
                  dataSource={
                    settingsData?.data?.data?.farms?.poultry_farming_types
                  }
                  errorMessage={isValid("farming_type_id")}
                  onSelect={(e) => {
                    console.log(e);
                    setFieldValue("farming_type_id", e);
                  }}
                  schema={{ name: "type", id: "id" }}
                  selectedItem={values["farming_type_id"]}

                  //responsiveWidth={"100%"}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <p>سعة العنبر *</p>
                <SelectDropdown
                  responsiveWidth={{ ...width }}
                  placeholder={"اختر"}
                  dataSource={
                    settingsData?.data?.data?.user?.farmer?.farms_capacities
                  }
                  errorMessage={isValid("farms_capacity_id")}
                  onSelect={(e) => {
                    console.log(e);
                    setFieldValue("farms_capacity_id", e);
                  }}
                  schema={{ name: "capacity", id: "id" }}
                  selectedItem={values["farms_capacity_id"]}

                  //responsiveWidth={"100%"}
                />
              </div>
              {/* <div style={{ marginTop: 10 }}>
                <p>مدة دورة التربية المتوقعة *</p>
                <TextInput
                  placeholder={"00"}
                  onChange={handleChange("poultry_farming_expected_period")}
                  errorMessage={isValid("poultry_farming_expected_period")}
                  defaultValue={values["poultry_farming_expected_period"]}
                  value={values["poultry_farming_expected_period"]}
                />
              </div> */}

              <div style={{ marginTop: 10 }}>
                <p>نوع التهوئة </p>
                <SelectDropdown
                  responsiveWidth={{ ...width }}
                  placeholder={"اختر"}
                  dataSource={settingsData?.data?.data?.farms?.ventilation_type}
                  errorMessage={isValid("ventilation_type_id")}
                  onSelect={(e) => {
                    console.log(e);
                    setFieldValue("ventilation_type_id", e);
                  }}
                  schema={{ name: "type", id: "id" }}
                  selectedItem={values["ventilation_type_id"]}

                  //responsiveWidth={"100%"}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <p> المرافق</p>
                <MultiSelectDropdown
                  responsiveWidth={{ ...width }}
                  placeholder={"اختر"}
                  dataSource={settingsData?.data?.data?.farms?.facilities}
                  errorMessage={isValid("facilities")}
                  onSelect={(e) => {
                    console.log(e);
                    setFieldValue("facilities", e);
                  }}
                  schema={{ name: "facility", id: "id" }}
                  selectedItems={values.facilities}
                  maxSelect={20}
                />
                <p style={{ color: "red" }}>{isValid("facilities")}</p>
              </div>
              <SectionLabel content={"ترخيص العنبر"} />

              <div style={{ marginTop: 10 }}>
                <p> الترخيص </p>
                <SelectDropdown
                  responsiveWidth={{ ...width }}
                  placeholder={"اختر"}
                  dataSource={[
                    { name: "مرخص", id: 1 },
                    { name: "غير مرخص", id: 0 },
                  ]}
                  errorMessage={isValid("licensed")}
                  onSelect={(e) => {
                    console.log(e);
                    setFieldValue("licensed", e);
                  }}
                  schema={{ name: "name", id: "id" }}
                  selectedItem={values["licensed"]}
                  //responsiveWidth={"100%"}
                />
              </div>

              {values?.licensed != undefined && values?.licensed == 1 && (
                <div style={{ marginTop: 10 }}>
                  <p> رقم الترخيص</p>
                  <TextInput
                    placeholder={"اكتب رقم الترخيص"}
                    onChange={handleChange("license_number")}
                    errorMessage={isValid("license_number")}
                    defaultValue={values["license_number"]}
                    value={values["license_number"]}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                ...halfWidth,
                padding: 20,
              }}
            >
              <p style={{ marginBottom: 10, marginTop: 10 }}>صورة العنبر</p>
              <div
                style={{
                  borderRadius: 10,
                  backgroundColor: "#7ABA4C0D",
                  border: "1px dashed #7ABA4C99",
                  height: "10%",
                  display: "flex",

                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  width={50}
                  height={50}
                  style={{ borderRadius: 500 }}
                  src={
                    isEdit && image != undefined
                      ? image
                      : isEdit
                      ? farmDetails?.data?.data?.farm?.photo
                      : isEdit && image == undefined
                      ? avatar
                      : !isEdit && image == undefined
                      ? avatar
                      : image
                  }
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      color: "#7ABA4C",
                      marginRight: 10,
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => {
                        setFieldValue("photo", e.target.files[0]);
                        setImage(URL.createObjectURL(e.target.files[0]));

                        setImageChanged(true);
                      }}
                      style={{ display: "none" }}
                    />
                    استعرض
                  </label>
                </div>
              </div>
              {!isEdit && (
                <>
                  <SectionLabel content={"العنوان"} />
                  <div style={{ marginTop: 10 }}>
                    <p>المحافظة *</p>
                    <SelectDropdown
                      responsiveWidth={{ ...width }}
                      placeholder={"اختر"}
                      dataSource={settingsData?.data?.data?.governorates}
                      errorMessage={isValid("governorate_id")}
                      onSelect={(e) => {
                        console.log(e);
                        setFieldValue("governorate_id", e);
                      }}
                      schema={{ name: "name", id: "id" }}
                      selectedItem={values["governorate_id"]}

                      //responsiveWidth={"100%"}
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <p> المدينة *</p>
                    <SelectDropdown
                      responsiveWidth={{ ...width }}
                      placeholder={"اختر"}
                      dataSource={
                        values?.governorate_id
                          ? settingsData?.data?.data?.cities
                          : []
                      }
                      errorMessage={isValid("city_id")}
                      onSelect={(e) => {
                        console.log(e);
                        setFieldValue("city_id", e);
                      }}
                      schema={{ name: "name", id: "id" }}
                      selectedItem={values["city_id"]}
                      dependId={values["governorate_id"]}
                      //responsiveWidth={"100%"}
                    />
                  </div>
                </>
              )}

              {!isEdit ? (
                <div style={{ marginTop: 10 }}>
                  <p>حي/ قرية *</p>
                  <TextInput
                    placeholder={"اكتب حي/ قرية"}
                    onChange={handleChange("village")}
                    errorMessage={isValid("village")}
                    defaultValue={values["village"]}
                    value={values["village"]}
                  />
                </div>
              ) : (
                <></>
              )}

              <div style={{ marginTop: 10 }}>
                <p> الوصف التفصيلي </p>
                <TextInput
                  placeholder={"اكتب الوصف التفصيلي للعنوان"}
                  onChange={handleChange("full_address")}
                  errorMessage={isValid("full_address")}
                  defaultValue={values["full_address"]}
                  value={values["full_address"]}
                />
              </div>
              {isEdit &&
              (farmDetails?.data?.data?.farm?.status?.id == 4 ||
                farmDetails?.data?.data?.farm?.status?.id == 3) ? (
                <div style={{ marginTop: 10 }}>
                  <p>العدد *</p>
                  <TextInput
                    placeholder={"0000"}
                    onChange={handleChange("total_count")}
                    errorMessage={isValid("total_count")}
                    defaultValue={values["total_count"]}
                    value={values["total_count"]}
                  />
                </div>
              ) : (
                <></>
              )}
              {/*  <div style={{ marginTop: 10 }}>
                <p> الموقع على الخريطة</p>
                <TextInput
                  placeholder={"اكتب الوصف التفصيلي للعنوان"}
                  onChange={handleChange("full_address")}
                  errorMessage={isValid("full_address")}
                  defaultValue={values["full_address"]}
                  value={values["full_address"]}
                />
              </div> */}

              <SectionLabel content={"الملفات المطلوبة"} />

              <div style={{ marginTop: 10 }}>
                <p> تبعية "ملكية" العنبر</p>
                <SelectDropdown
                  responsiveWidth={{ ...width }}
                  placeholder={"اختر"}
                  dataSource={[
                    { name: "ايجار", id: 1 },
                    { name: "تمليك", id: 0 },
                  ]}
                  onSelect={(e) => {
                    console.log(e);
                    setFieldValue("rented", e);
                  }}
                  schema={{ name: "name", id: "id" }}
                  selectedItem={values["rented"]}
                  //responsiveWidth={"100%"}
                />
              </div>

              {values["rented"] == 1 ? (
                <>
                  <div style={{ marginTop: 10 }}>
                    <p> تاريخ بداية الايجار</p>

                    <input
                      style={{
                        width: "100%",

                        height: 54,
                        border: 0,
                        borderRadius: 10,
                        backgroundColor: "#FBFBFB",
                        color: "#7ABA4C",
                        paddingRight: 20,
                        paddingLeft: 20,
                      }}
                      onChange={handleChange("rental_start_date")}
                      value={values["rental_start_date"]}
                      dir="rtl"
                      type="date"
                      name="date"
                      placeholder="اكتب العنوان"
                    />
                    <p style={{ color: "red" }}>
                      {isValid("rental_start_date")}
                    </p>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <p> تاريخ نهاية الايجار</p>

                    <input
                      style={{
                        width: "100%",
                        height: 54,
                        border: 0,
                        borderRadius: 10,
                        backgroundColor: "#FBFBFB",
                        color: "#7ABA4C",
                        paddingRight: 20,
                        paddingLeft: 20,
                      }}
                      onChange={handleChange("rental_end_date")}
                      value={values["rental_end_date"]}
                      dir="rtl"
                      type="date"
                      name="date"
                      placeholder="اكتب العنوان"
                    />
                    <p style={{ color: "red" }}>{isValid("rental_end_date")}</p>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div style={{ display: "flex", marginTop: 20 }}>
            <Button
              type={"reset"}
              backgroundColor="#F4F4F4"
              textColor="#9F9F9F"
              style={{ marginRight: 10, marginLeft: 10, width: "20%" }}
              onClick={() => {
                navigator(-1);
              }}
            >
              إلغاء
            </Button>
            <Button
              type={"submit"}
              style={{ width: "20%", marginRight: 10, marginLeft: 10 }}
            >
              {!isEdit ? "إضافة" : "تعديل"}
            </Button>
          </div>
        </form>
      </div>
    </>
  ) : (
    <Loading />
  );
}

export default AddFarmScreen;
