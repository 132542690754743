import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { getCategories } from "../../api/store/actions/saga/categories";
import Loading from "../../reusableComponents/loadingScreen/loading";
import CategoryCard from "./categoryCard";

const Categories = ({ type }) => {
  const reload = useSelector((state) => state.reload.reload);
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    resolveRequest(getCategories, setCategories, type);
  }, [reload]);
  console.log(categories);
  return categories ? (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {categories?.data?.map((item, index) => {
        return (
          <CategoryCard
            key={index}
            title={item?.name}
            id={item?.id}
            type={type}
          />
        );
      })}
    </div>
  ) : (
    <Loading />
  );
};
export default Categories;
