import axiosCall from "../../../axios/axiosConfig";
import {
  catchFromat,
  concatFilters,
  dataFormat,
  dataFormatPagination,
} from "../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/comments`;
export const getComments = async ({
  pageNumber = 1,
  pageCount = 7,
  params = {},
}) => {
  try {
    let filters = concatFilters(params);
    let data = await axiosCall.get(
      `${apiUrl}?page=${pageNumber}&per_page=${pageCount}&${filters}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const updateComment = async ({ body, id }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${id}`, JSON.stringify(body));
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getCommentsDetails = async ({ id }) => {
  try {
    return await axiosCall.get(`${apiUrl}/${id}`);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const disapproveComment = async ({ id }) => {
  try {
    return dataFormat(await axiosCall.patch(`${apiUrl}/${id}/disapprove `));
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const approveComment = async ({ id }) => {
  try {
    return dataFormat(await axiosCall.put(`${apiUrl}/${id}/approve `));
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const deleteComment = async ({ id }) => {
  try {
    return dataFormat(await axiosCall.delete(`${apiUrl}/${id}`));
  } catch (e) {
    return catchFromat(e.response);
  }
};
