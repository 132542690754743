import React from "react";
export const NextIcon=()=>{

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="16"
          viewBox="0 0 22 16"
        >
          <path
            fill="#fff"
            d="M1.293 11.293l7-7a1 1 0 111.414 1.414L4.414 11H22a1 1 0 110 2H4.414l5.293 5.293a1 1 0 11-1.414 1.414l-7-7a1 1 0 010-1.414z"
            transform="translate(-1 -4)"
          ></path>
        </svg>
      );
    }
    
  