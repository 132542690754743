import axiosCall from "../../../../axios/axiosConfig";
import {
  catchFromat,
  dataFormat,
  dataFormatPagination,
} from "../../../../middlewares/resolverRequests";

const apiUrl = `/admin/v1/settings/time/limited/offer`;

export const getCurrentTimeLimitedOffer = async () => {
  try {
    const data = await axiosCall.get(`${apiUrl}/current`);
    return data;
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const getHistoryTimeLimitedOffer = async ({
  pageNumber = 1,
  pageCount = 7,
}) => {
  try {
    const data = await axiosCall.get(
      `${apiUrl}?page=${pageNumber}&per_page=${pageCount}`
    );
    return dataFormatPagination(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const addTimeLimitedOffer = async ({ body }) => {
  try {
    const data = await axiosCall.post(`${apiUrl}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
export const editTimeLimitedOffer = async ({ body, offer_id }) => {
  try {
    const data = await axiosCall.put(`${apiUrl}/${offer_id}`, body);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const showTimeLimitedOffer = async ({ offer_id }) => {
  try {
    const data = await axiosCall.get(`${apiUrl}/${offer_id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};

export const deactivateTimeLimitedOffer = async ({ offer_id }) => {
  try {
    const data = await axiosCall.delete(`${apiUrl}/${offer_id}`);
    return dataFormat(data);
  } catch (e) {
    return catchFromat(e.response);
  }
};
