import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import {
  getAllFarms,
  getFarmerFarms,
} from "../../api/store/actions/saga/farms";
import Loading from "../../reusableComponents/loadingScreen/loading";
import AppPaginate from "../../reusableComponents/pagination/pagination";
import SectionLabel from "../../reusableComponents/sectionLabel";
import { FarmCard } from "../../screenParts/farms/farmCard";
import ListHeader from "../../screenParts/listingViews/listHeader";
import NoData from "../../screenParts/noData";
export const GetAllFarmsScreen = () => {
  const { farmer_id } = useParams();
  const [farmerFarms, setFarmerFarms] = useState();
  const reload = useSelector((state) => state.reload.reload);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setSFilterKey] = useState(null);
  const onChange = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSearchKey(value);
  };
  const settingsData = useSelector((state) => state?.settings?.setting);

  console.log("settingsData", settingsData);
  const onSelect = (value) => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
    setSFilterKey(value);
    console.log(value, "select");
  };
  useEffect(() => {
    resolveRequest(getAllFarms, setFarmerFarms, {
      pageNumber: currentPage,
      params: { farm_name: searchKey, status_id: filterKey },
      pageCount: 9,
    });
  }, [searchKey, currentPage, filterKey, reload]);

  useEffect(() => {
    console.log("farmData", farmerFarms);
  }, [farmerFarms]);
  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      {settingsData ? (
        <ListHeader
          title={"hلعنابر"}
          onSelect={onSelect}
          //  addLink={active == 2 ? "addFarm" : undefined}
          /*  pages={[
            { title: "بيانات الحساب", id: 1 },
            { title: "العنابر", id: 2 },

            { title: "عروض الشراء", id: 3 },
            { title: "التقييمات", id: 4 },
          ]} */
          //hasAdd={active === 2}
          schema={{ name: "status", id: "id" }}
          dataSource={
            settingsData
              ? [
                  { status: "الكل", id: null },
                  ...settingsData?.data?.data?.farms?.status,
                ]
              : null
          }
          hasFilter={true}
          hasSearch={true}
          hasAdd={false}
          onChange={onChange}
        />
      ) : (
        <></>
      )}
      {farmerFarms ? (
        farmerFarms?.data?.length != 0 ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {farmerFarms?.data?.map((item) => {
              return (
                <FarmCard
                  deleteFarmLink={`/farmers/farmerDetails/deleteFarm/${item?.id}`}
                  farmManagementLink={`/farmers/farmDetails/${item?.id}`}
                  isFarm
                  farmerLink={`/farmers/farmerDetails/${item?.user?.id}`}
                  logLink={`/farmers/farms/farmLog/${item?.id}`}
                  key={item?.id}
                  name={item?.name}
                  location={`${item?.city?.governorate?.name ?? ""}, ${
                    item?.city?.name ?? ""
                  }`}
                  status={item?.status}
                  requested_price={item?.requested_price ?? "0000"}
                  dajin={item?.breed?.dajin?.type}
                  total_count={item?.total_count ?? "0000"}
                  image={item?.photo}
                />
              );
            })}
            {setCurrentPage && (
              <AppPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={Math.ceil(farmerFarms?.total / 9)}
              />
            )}
          </div>
        ) : (
          <NoData title="لا يوجد بيانات حالياً" />
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};
