import { convertToRaw, EditorState } from "draft-js";
import { useFormik } from "formik";
import Button from "../../reusableComponents/button";
import avatar from "../../assets/avatar.jpg";

import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import { MyEditor } from "../../reusableComponents/editor";
import SectionLabel from "../../reusableComponents/sectionLabel";
import TextInput from "../../reusableComponents/textInput";
import { EmailIcon, FacebookIcon, InstaIcon, TwitterIcon } from "./icons";
import { resolveRequest } from "../../api/middlewares/resolverRequests";
import { aboutUs, updateAboutUs } from "../../api/store/actions/saga/aboutUs";
import Loading from "../../reusableComponents/loadingScreen/loading";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../alogrithms/formikValidation";
import { objToFormData } from "../../alogrithms/requests";
import draftToHtml from "draftjs-to-html";
import { editorFormat, isObject } from "../../alogrithms";
import CustomImage from "../../reusableComponents/customImage";
import PartinersGallery from "./partinerSection";
import PhotoSelect from "../../screenParts/photoSelect";
import YoutubeIcon from "../../reusableComponents/iconsSvg/youtubeIcon";
import LinkedInIcon from "../../reusableComponents/iconsSvg/linkedInIcon";

const AboutUs = () => {
  const width = useResponsiveWidth({
    desktop: 50,
    tablet: 50,
    mobile: "auto",
    defaults: 0,
    tabletDesktop: 50,
    tabletMobile: 100,
  });
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);
  useEffect(() => {
    resolveRequest(aboutUs, setData);
  }, []);
  useEffect(() => {
    if (data)
      setImages(
        Object.entries(data?.data?.partners).map((e) => ({
          value: e[1],
          id: e[0],
        }))
      );
  }, [data]);
  const submit = async (data) => {
    data.partners = images.map((item) => {
      console.log(item);
      return { id: item.id, value: isObject(item.value) ? item.value : "" };
    });

    if (!isObject(data?.image)) {
      delete data?.image;
    }
    if (data?.email == "") {
      delete data?.email;
    }
    if (data?.linkedin == "") {
      delete data?.linkedin;
    }
    if (data?.instagram == "") {
      delete data?.instagram;
    }
    if (data?.facebook == "") {
      delete data?.facebook;
    }
    if (data?.youtube == "") {
      delete data?.youtube;
    }
    if (data?.mobile_number == "") {
      delete data?.mobile_number;
    }
    if (data?.image == "") {
      delete data?.image;
    }
    if (data?.description == "") {
      delete data?.description;
    }
    let res = await updateAboutUs({ body: objToFormData(data) });
    setBackEndErrors(res?.errors, setFieldError);

    console.log(data);
  };
  const formik = useFormik(
    formikSetup(initialValues, data?.data, validationSchema, "aboutUs", submit)
  );
  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    touched,
    setFieldError,
  } = formik;
  const errorMessage = formikFieldValidation(touched, errors);
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  console.log("htmlState", values?.description);
  return data ? (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          backgroundColor: "white",
          paddingBottom: 20,
          paddingTop: 20,
          borderRadius: 10,
        }}
      >
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <p style={{ color: "#000000", fontSize: 25, fontWeight: "bold" }}>
            من نحن
          </p>
        </div>
        <SectionLabel content={"الوصف "} />

        <MyEditor
          placeholder={"اكتب"}
          content={data?.data?.description}
          onChange={(value) => {
            setFieldValue("description", value);
          }}
        />
        <div style={{ width: "100%", display: "flex" }}>
          <div
            style={{
              ...width,
            }}
          >
            <SectionLabel content={"الصورة "} />
            <div
              style={{
                padding: 20,
              }}
            >
              <PhotoSelect
                errorMessage={errorMessage("image")}
                src={values.image ?? avatar}
                onChange={(e) => {
                  setFieldValue("image", e.target.files[0]);
                }}
              />
            </div>

            <SectionLabel content={"الشركاء "} />
            <div>
              <PartinersGallery imagesList={images} setImageList={setImages} />
            </div>
          </div>

          <div
            style={{
              ...width,
            }}
          >
            <SectionLabel content={"الأرقام و التواصل "} />
            <TextInput
              name={"email"}
              value={values.email}
              onChange={handleChange}
              errorMessage={errorMessage("email")}
              defaultValue={values?.email}
              icon={<EmailIcon />}
              styles={{ width: "90%", paddingTop: 5, paddingBottom: 5 }}
              label={"البريد الإلكتروني"}
            />
            <TextInput
              name={"mobile_number"}
              value={values.mobile_number}
              onChange={handleChange}
              errorMessage={errorMessage("mobile_number")}
              defaultValue={values?.mobile_number}
              icon
              styles={{ width: "90%", paddingTop: 5, paddingBottom: 5 }}
              label={" الهاتف"}
            />
            <div style={{ width: "90%", paddingTop: 5, paddingBottom: 5 }}>
              <p>حسابات التواصل الاجتماعي</p>
              <TextInput
                name={"facebook"}
                value={values.facebook}
                onChange={handleChange}
                errorMessage={errorMessage("facebook")}
                defaultValue={values?.facebook}
                icon={<FacebookIcon />}
                styles={{ paddingTop: 5, paddingBottom: 5 }}
              />
              <TextInput
                name={"youtube"}
                value={values.youtube}
                onChange={handleChange}
                errorMessage={errorMessage("youtube")}
                defaultValue={values?.youtube}
                icon={<YoutubeIcon />}
                styles={{ paddingTop: 5, paddingBottom: 5 }}
              />
              <TextInput
                name={"instagram"}
                value={values.instagram}
                onChange={handleChange}
                errorMessage={errorMessage("instagram")}
                defaultValue={values?.instagram}
                icon={<InstaIcon />}
                styles={{ paddingTop: 5, paddingBottom: 5 }}
              />
              <TextInput
                name={"linkedin"}
                value={values.linkedin}
                onChange={handleChange}
                errorMessage={errorMessage("linkedin")}
                defaultValue={values?.linkedin}
                icon={<LinkedInIcon />}
                styles={{ paddingTop: 5, paddingBottom: 5 }}
              />
            </div>
          </div>
        </div>
        <Button type={"submit"} style={{ width: "20%", marginTop: 30 }}>
          {"حفظ"}
        </Button>
      </div>
    </form>
  ) : (
    <Loading />
  );
};
export default AboutUs;
