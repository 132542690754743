import { call, put, takeEvery } from "redux-saga/effects";
import { loginTypes } from "../../../../utlity/actionTypes";

import axiosCall from "../../../axios/axiosConfig";
import { catchFromat } from "../../../middlewares/resolverRequests";

const login = async ({ body }) => {
  try {
    let user = await customLogin({ body: body });
    console.log("user login", user);
    return user;
  } catch (e) {
    console.log("user error", e);

    return e;
  }
};
const customLogin = ({ body }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post("/admin/v1/login", body)
      .then((response) => {
        if (response?.status <= 206) {
          resolve(response);
        }
        reject(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export function userLogin(body) {
  console.log("userLogin");
  return {
    type: loginTypes.LOGIN_REQUESTED,
    payload: body,
  };
}

function* fetchUser(action) {
  console.log("ACTION", action);
  try {
    const user = yield login({ body: action.payload });
    console.log("fetch user", user);
    if (user?.status <= 206 || user?.response?.status <= 206) {
      yield put({ type: loginTypes.LOGIN_SUCCESS, user: user?.data?.user });
    } else {
      yield put({
        type: loginTypes.LOGIN_FAILED,
        message: user?.data?.message,
      });
    }
  } catch (e) {
    console.log("MESSAGE", e);
    yield put({ type: loginTypes.LOGIN_FAILED, message: e.message });
  }
}

function* loginSaga() {
  yield takeEvery(loginTypes.LOGIN_REQUESTED, fetchUser);
}
export const logout = () => {
  return {
    type: loginTypes.LOGIN_FAILED,
  };
};

export default loginSaga;
