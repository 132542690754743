import { Link, useLocation } from "react-router-dom";
import {
  DeleteIcon,
  EditIcon,
} from "../../reusableComponents/iconsSvg/tableI/tableIcons";
const CategoryCard = ({ title, id, type }) => {
  const location = useLocation();
  return (
    <div
      style={{
        margin: 10,
        width: "45%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #C2C2C2",
        padding: 10,
        borderRadius: 10,
      }}
    >
      <p>{title}</p>
      <div>
        <Link
          state={{ backgroundLocation: location }}
          to={`deleteCategory/${type}/${id}`}
        >
          <DeleteIcon />
        </Link>{" "}
        &nbsp;
        <Link
          state={{ backgroundLocation: location }}
          to={`editCategory/${type}/${id}`}
        >
          <EditIcon />
        </Link>
      </div>
    </div>
  );
};

export default CategoryCard;

// reusableComponents/iconsSvg/tableI/tableIcons
