import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  formikFieldValidation,
  formikSetup,
  initialValues,
  setBackEndErrors,
  validationSchema,
} from "../../../alogrithms/formikValidation";
import { resolveRequest } from "../../../api/middlewares/resolverRequests";
import {
  createRating,
  getRatingDetails,
  updateRating,
} from "../../../api/store/actions/saga/settings/rating";
import TextInput from "../../../reusableComponents/textInput";
import AppModal from "../../../screenParts/modal/customModal";

const RatingForm = ({ isEdit }) => {
  const { ratingId, type } = useParams();
  const [ratingDetails, setRatingDetails] = useState(null);
  useEffect(() => {
    if (isEdit)
      resolveRequest(getRatingDetails, setRatingDetails, {
        id: ratingId,
        type: type,
      });
  }, []);
  const submit = async (data) => {
    if (isEdit) {
      const res = await updateRating({ id: ratingId, body: data, type: type });
      setBackEndErrors(res?.errors, setFieldError);
    } else {
      const res = await createRating({ body: data, type: type });
      setBackEndErrors(res?.errors, setFieldError);
    }
  };
  const formik = useFormik(
    formikSetup(
      initialValues,
      isEdit ? ratingDetails?.data : {},
      validationSchema,
      "rating",
      submit
    )
  );
  const { values, handleSubmit, handleChange, touched, errors, setFieldError } =
    formik;
  console.log(errors, values);
  const isValid = formikFieldValidation(touched, errors);

  return (
    <div>
      <AppModal
        buttonTitleReject={false}
        onClickReject={false}
        buttonTitle={isEdit ? "تعديل" : "إضافة"}
        modalTitle={isEdit ? "تعديل تقييم" : "إضافة تقييم"}
        onClick={handleSubmit}
      >
        <TextInput
          require
          styles={{ marginTop: 10, marginBottom: 10 }}
          label={"الاسم"}
          value={values.dimension}
          defaultValue={values.dimension}
          name={"dimension"}
          errorMessage={isValid("dimension")}
          onChange={handleChange("dimension")}
        />
      </AppModal>
    </div>
  );
};

export default RatingForm;
