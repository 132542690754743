import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import Button from "../../reusableComponents/button";
import SelectDropdown from "../../reusableComponents/dropdown/dropdownSelect";
import TextInput from "../../reusableComponents/textInput";
import TabsSwitch from "../tabsSwitch";
import BackButton from "./icons";

const ListHeader = ({
  switchType,
  hasAdd = true,
  hasFilter = true,
  hasSearch = true,
  hasTitle = false,
  title,
  addLink,
  linkType = "normal",
  onChange,
  pages,
  setActive,
  active,
  dataSource,
  onSelect,
  searchKey,
  inputsWidth = "60%",
  back = false,
  schema = { name: "name", id: "id" },
  selectedItem = null,
  responsiveWidth,
  responsiveContent,
  hasFilter2,
  onSelect2,
  schema2,
  dataSource2,
  selectedItem2,
}) => {
  let location = useLocation();
  const navigate = useNavigate();
  const filterWidth = useResponsiveWidth({
    desktop: 30,
    tablet: 30,
    tabletDesktop: 30,
    tabletMobile: 30,
  });
  const buttonWidth = useResponsiveWidth({
    defaults: "auto",
  });
  const searchWidth = useResponsiveWidth({
    desktop: 40,
    tablet: 40,
    tabletDesktop: 40,
    tabletMobile: 40,
  });
  const titleWidth = useResponsiveWidth({
    desktop: "auto",
    tablet: "auto",
    mobile: 100,
    defaults: 0,
  });
  return (
    <div
      style={{
        marginBottom: 10,
        marginTop: 10,
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <div style={{ paddingBottom: 5, paddingTop: 5 }}>
        {!hasTitle ? (
          <TabsSwitch
            switchType={switchType}
            pages={pages}
            active={active}
            setActive={setActive}
          />
        ) : (
          <div style={{ ...titleWidth, display: "flex" }}>
            {back && (
              <div
                style={{ marginLeft: 10, cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackButton />
              </div>
            )}
            <p
              style={{
                fontFamily: "boldKufi",
                fontSize: 20,
              }}
            >
              {title ?? "title"}
            </p>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          width: inputsWidth,
          justifyContent: "flex-end",
          flexWrap: "wrap",
          alignItems: "center",
          ...responsiveWidth,
          ...responsiveContent,
        }}
      >
        {hasSearch && (
          <TextInput
            styles={{ paddingLeft: 10, paddingRight: 10 }}
            value={searchKey}
            placeholder={"ابحث هنا … "}
            responsiveWidth={searchWidth}
            onChange={(e) => {
              if (onChange) onChange(e.target.value);
            }}
          />
        )}
        {hasFilter && (
          <SelectDropdown
            selectedItem={selectedItem}
            placeholder={"تصفية"}
            dataSource={dataSource}
            onSelect={onSelect}
            schema={schema}
            responsiveWidth={filterWidth}
          />
        )}
        {hasFilter2 && (
          <div style={{ marginRight: 10, width: filterWidth }}>
            <SelectDropdown
              selectedItem={selectedItem2}
              placeholder={"تصفية"}
              dataSource={dataSource2}
              onSelect={onSelect2}
              schema={schema2}
            />
          </div>
        )}
        {/* <Button */}
        {hasAdd && (
          <div style={{ ...buttonWidth }}>
            <Link
              to={`${addLink}`}
              style={{ display: "contents" }}
              state={{
                backgroundLocation: linkType == "modal" ? location : "",
              }}
            >
              <Button
                style={{
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
                width={"100%"}
                // height={40}
              >
                اضافة
              </Button>
            </Link>
          </div>
        )}
      </div>
      {/* <hr /> */}
    </div>
  );
};

export default ListHeader;
