import React from "react";
import { useResponsiveWidth } from "../../alogrithms/responsive";
import ChickenIcon from "../../reusableComponents/iconsSvg/chickenIcon";
import RedBG from "../../reusableComponents/iconsSvg/redBG";
export const FarmLogCard = ({ BG, text, date }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        border: "1px solid #C2C2C2",
        boxShadow: "10px 10px 50px #c1cfd924",
        padding: 10,
        paddingRight: 20,
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <div
        style={{
          ...useResponsiveWidth({ tabletDesktop: 10, tabletMobile: 20 }),
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", top: "30%", right: "30%" }}>
          <ChickenIcon color="white" />
        </div>
        {BG}
      </div>
      <div
        style={{
          ...useResponsiveWidth({ tabletDesktop: 90, tabletMobile: 80 }),
          paddingRight: 20,
        }}
      >
        <p style={{ fontSize: 16 }}>{text}</p>
        <p style={{ fontSize: 16, color: "#8C8C8C", marginTop: 15 }}>{date}</p>
      </div>
    </div>
  );
};
